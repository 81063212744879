import React, { useState } from 'react';
import { Button } from 'primereact/button';
import ImageUploader from '@/react_app/Other/ImageUploader/ImageUploader';
import { TabStep } from "@/react_app/NewCreateCampaign/NewCreateCampaignAdditional";
import RenderField from './RenderField';
import createCampaignConfig from './createCampaignConfig';
import { FieldTip } from '../img/Icons';
import AttachmentsUploader from './AttachmentsUploader';

const CampaignProgress = ({
  currentTab,
  control,
  watch,
  setValue,
  getValues,
  register,
  errors,
  clearErrors,
  campaign,
  isEditMode,
  handleContinue,
  setCurrentTab,
  checkDisabled,
  checkDisabledDraft,
  isLoader,
  isAdmin,
  coverImage,
  handleImageChange,
  createDraft,
  onError,
  setError,
  trigger,
  imageError,
}) => {
  const attachments = watch('attachments') || [];
  const [isNewUpload, setIsNewUpload] = useState(false);

  if (currentTab === TabStep.Overview || currentTab === TabStep.Completed) {
    return null;
  }

  const currentStepConfig = createCampaignConfig.steps.find(step => step.index === currentTab);
  const renderFields = () => {
    return (
      <div className="step">
        {currentStepConfig.fields.filter(filed => filed?.isRightLayout).map((field, index) => (
          <div key={index} >
            {((field.isAdminOnly && isAdmin) || (!field.isAdminOnly)) && (
              <>
                <p className="fieldLabel">
                  {field.label}
                  {field.isRequired && <span className="redStar">*</span>}
                  {field?.showTooltip && <FieldTip title={field?.tooltipInfo} />}
                </p>
                {field.inputInfo && <p className="fieldLabel inputInfo">{field.inputInfo}</p>}
              </>
            )}
            <RenderField
              field={field}
              control={control}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              register={register}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              trigger={trigger}
              isAdmin={isAdmin}
              isEditMode={isEditMode}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={'rightSide'}>
      {currentTab == TabStep.Start && <div className={'campaignProgress ugc'}>
        <ImageUploader
          currentTab={currentTab}
          coverImage={coverImage}
          onImageChange={handleImageChange}
        />
        {imageError && <div className="createError">
          <p>Cover Image is required.</p>
        </div>
        }
      </div>}
      {currentTab == TabStep.Details && renderFields()}
      {currentTab === TabStep.Instructions && (
        <AttachmentsUploader
          attachments={attachments}
          setAttachments={(updatedAttachments) => setValue('attachments', updatedAttachments)}
          isEditMode={isEditMode}
          isNewUpload={isNewUpload}
          setIsNewUpload={setIsNewUpload}
        />
      )}
      {currentTab !== TabStep.Overview && <div className={'campaignButtonGroup ugc'}>
        {campaign ? (
          <>
            <Button className={'secondaryButton'} disabled={currentTab === 1} onClick={() => setCurrentTab(currentTab - 1)}>Go Back</Button>
            <Button className={'primaryButton'}
              loading={isLoader}
              onClick={() => handleContinue()}>
              {isEditMode && currentTab === TabStep.Budget ? "Update Campaign" : "Continue"}
            </Button>
          </>
        ) : (
          <>
            <Button loading={isLoader}
              type="submit"
              className={'secondaryButton'}
              disabled={() => checkDisabled()}
              onClick={(e) => {
                e.preventDefault();
                createDraft();
              }}
            >Save Draft</Button>
            <Button
              loading={isLoader}
              className={'primaryButton'}
              onClick={() => {
                trigger();
                handleContinue()
              }}>Continue</Button>
          </>
        )}
      </div>}
    </div>
  );
};

export default CampaignProgress;
