import { categoryItemsIcons, MAX_TALKING_POINTS, MAX_URLS } from './CreateCampaignAdditional';

const createCampaignConfig = {
  steps: [
    {
      step: 'Start',
      index: 1,
      fields: [
        {
          name: 'brand',
          label: 'Brand',
          placeholder: 'Select a brand',
          type: 'dropdown',
          validation: { required: 'Brand is required' },
          isRequired: true,
          isAdminOnly: true,
          isCreateModeOnly: true
        },
        {
          name: 'campaignName',
          label: 'Campaign Name',
          placeholder: 'Give your campaign a name',
          type: 'input',
          validation: {
            required: 'Campaign name is required',
            maxLength: {
              value: 150,
              message: 'Campaign name must be fewer than 150 characters'
            }
          },
          showSerectTip: true,
          isRequired: true,
          showTooltip: false,
          tooltipInfo: "Campaign Name is cool."
        },
        {
          name: 'campaignType',
          label: 'Campaign Type',
          placeholder: 'Campaign Type',
          type: 'input',
          disabled: true
        },
        {
          name: 'campaignEndDate',
          label: 'Campaign End Date',
          placeholder: 'Select campaign end date or choose "Until Budget is Complete"',
          type: 'campaignEndDate',
          // validation: {
          //   required: 'Campaign end date is required.',
          //   validate: (value: any) =>
          //     value === 'untilBudget' || !!value || 'Please provide an end date or choose "Until Budget is Complete"',
          // },
          isRequired: true,
        },
        {
          name: 'category',
          type: 'icon-checkbox',
          label: 'Product Category',
          options: [
            { value: 'game', label: 'Game' },
            { value: 'accessory', label: 'Accessory' },
            { value: 'services', label: 'Services' },
            { value: 'other', label: 'Other' }
          ],
          validation: { required: 'Product category is required' },
          icons: categoryItemsIcons,
          isMultiSelect: false,
          isRequired: true,
          storeObject: true
        },
        {
          name: 'country-location',
          type: 'grouped-fields',
          fields: [
            {
              name: 'countries',
              label: 'Country',
              placeholder: 'Select country',
              type: 'dropdown',
              validation: { required: 'Country is required' },
              isRequired: true,
            },
            {
              name: 'languages',
              label: 'Language',
              placeholder: 'Select language',
              type: 'dropdown',
              validation: { required: 'Language is required' },
              isMultiSelect: true,
              isRequired: true,
            }
          ]
        },
      ],
    },
    {
      step: 'Details',
      index: 2,
      fields: [
        {
          name: 'campaignDescription',
          label: 'Campaign Description',
          placeholder: 'Tell creators about your campaign.',
          type: 'textarea',
          validation: { required: 'Campaign description is required' },
          isRequired: true,
          rows: 7,
        },
        {
          name: 'otherUrls',
          label: 'Gamekeys (if applicable)',
          type: 'other-urls',
          validation: {
            required: 'At least one URL is required.'
          },
          isRequired: false,
          maxLength: MAX_URLS,
          fields: [
            {
              name: 'gamekeys',
              label: '',
              placeholder: 'Enter your link to gamekey folder',
              type: 'input',
            },
            {
              name: 'otherUrls',
              label: '',
              type: 'input',
            }
          ]
        },
        {
          name: 'concepts-format',
          type: 'grouped-fields',
          isRightLayout: true,
          fields: [
            {
              name: 'conceptCount',
              type: 'radio',
              label: 'Concepts',
              options: [
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' }
              ],
              validation: { required: 'Concepts is required' },
              isRequired: true,
              isRightLayout: true,
              showTooltip: false,
              tooltipInfo: "Choose no. of concepts."
            },
            {
              name: 'videoFormat',
              type: 'radio',
              label: 'Format',
              options: [
                { value: 'MOV', label: 'MOV' },
                { value: 'MP4', label: 'MP4' }
              ],
              validation: { required: 'Video format is required' },
              isRequired: true,
              isRightLayout: true,
              showTooltip: false,
              tooltipInfo: "Choose video formats."
            }
          ]
        },
        {
          name: 'videoLength',
          type: 'radio',
          label: 'Length',
          options: [
            { value: '10', label: '10 sec' },
            { value: '15', label: '15 sec' },
            { value: '30', label: '30 sec' },
            { value: '60', label: '60 sec' },
            { value: '90', label: '90 sec' }
          ],
          validation: { required: 'Video length is required' },
          isRightLayout: true,
          isRequired: true,
          showTooltip: false,
          tooltipInfo: "Choose video length."
        },
        {
          name: 'aspect-creators',
          type: 'grouped-fields',
          isRightLayout: true,
          fields: [
            {
              name: 'videoAspectRatio',
              type: 'icon-checkbox',
              className: "aspectRatio",
              label: 'Aspect Ratio',
              options: [
                { value: '16:9', label: '16:9' },
                { value: '9:16', label: '9:16' },
                { value: '1:1', label: '1:1' }
              ],
              validation: { required: 'Aspect ratio is required' },
              isRightLayout: true,
              isMultiSelect: true,
              isRequired: true,
              showTooltip: false,
              tooltipInfo: "Choose aspect ratio."
            },
          ]
        },
      ],
    },
    {
      step: 'Instructions',
      index: 3,
      fields: [
        {
          name: 'talkingPoints',
          label: 'Campaign requirements',
          type: 'talking-points',
          placeholder: 'Enter your desired talking point',
          maxLength: MAX_TALKING_POINTS,
          fields: [
            {
            },
            {
              name: 'otherUrls',
              label: '',
              type: 'input',
            }
          ]
        },
        {
          name: 'callToAction',
          label: 'Call to action',
          placeholder: 'Enter your call to action',
          type: 'textarea',
        },
      ],
    },
    {
      step: 'Budget',
      index: 4,
      fields: [
        {
          name: 'noOfCreators',
          label: 'No. of Creators',
          type: 'input',
          inputInfo: 'This is your no. of creators from step 1',
          placeholder: 'Enter the number of creators',
          validation: {
            required: 'No. of creators is required.',
            min: {
              value: 1,
              message: 'At least 1 creator is required.',
            },
            validate: (value: any) => Number.isInteger(Number(value)) || 'Must be a whole number',
          },
          isOnlyNumbers: true,
          isRequired: true,
        },
        {
          name: 'costPerCreator',
          label: 'Cost Per Creator',
          type: 'input',
          inputInfo: "This is your UGC creator Ads's cost for this campaign",
          placeholder: 'Enter cost per creator',
          validation: {
            required: 'Cost per creator is required.',
            min: {
              value: 0,
              message: 'Cost must be a positive number.',
            },
            validate: (value: any) => Number.isInteger(Number(value)) || 'Must be a whole number',
          },
          isOnlyNumbers: true,
          isRequired: true,
        },
        {
          name: 'campaignBudget',
          label: 'Campaign Budget',
          inputInfo: "This is your UGC for this campaign.",
          type: 'input',
          placeholder: 'Enter total campaign budget',
          validation: {
            required: 'Campaign budget is required.',
            min: {
              value: 0,
              message: 'Budget must be a positive number.',
            },
            validate: (value: any) => Number.isInteger(Number(value)) || 'Must be a whole number',
          },
          isOnlyNumbers: true,
          isRequired: true,
          disabled: true,
        },
      ],
    },
    {
      step: 'Overview',
      index: 5,
      fields: [
      ],
    },
    {
      step: 'Completed',
      index: 6,
      fields: [
      ],
    },
  ],
};

export default createCampaignConfig;
