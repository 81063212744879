import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { CloseIcon } from '@/react_app/img/Icons';
import Terms from '@/react_app/components/Terms/Terms';

import './CampaignApplication.css';

const CampaignApplication = ({
    visible, close, accept, campaignType, capAmount
}) => {
    const isCPMCampaign = campaignType === "CPM";

    // State variables for checkboxes
    const [isChecked, setIsChecked] = useState({
        terms: false,
        maxAmount: false,
        draftVideo: false
    });

    // Enable button if all required conditions are met
    const isButtonEnabled =
        isChecked.terms &&
        (isCPMCampaign ? isChecked.maxAmount : true) &&
        isChecked.draftVideo;

    // Update state on checkbox change
    const handleCheckboxChange = (key, checked) => {
        setIsChecked(prevState => ({ ...prevState, [key]: checked }));
    };



    return (
        <Dialog
            visible={visible}
            onHide={close}
            className="campaign-application"
            draggable={false}
        >
            {/* Close Button */}
            <div className="closeBtnWrapper">
                <div className="closeButton" onClick={() => close()}>
                    <CloseIcon />
                </div>
            </div>
            {/* Dialog Content */}
            <div className="mainSection">
                <p className="dialogTitle">Campaign Application</p>
                <div className="bodyWrapper">
                    {<Terms capAmount={capAmount} isCPMCampaign={isCPMCampaign} isChecked={isChecked} handleCheckBox={handleCheckboxChange} />}
                </div>
            </div>

            {/* Submit Button */}
            <div className="actionButton">
                <Button
                    className="acceptButton"
                    disabled={!isButtonEnabled}
                    onClick={accept}
                    aria-label="Accept Campaign"
                >
                    Accept Campaign
                </Button>
            </div>
        </Dialog>
    );
};

export default CampaignApplication;
