import React, { Fragment } from 'react';

const TextWithLinks = ({ text }) => {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+|http\.[^\s]+)/g;
    const parts = (text) ? text.split(urlRegex) : [];

    return (
        <p>
            {parts.map((part, index) => (
                <Fragment key={part + index}>
                    {(urlRegex.test(part)) ?
                        <a  href={part} target="_blank" rel="noopener noreferrer" style={{ color: '#2196f3', textDecoration: 'underline' }}>
                            {part}
                        </a> : <span className='infoText' key={part + index}>{part}</span>}
                </Fragment>
            ))}
            
        </p>
    );
};
export default TextWithLinks