
import { useRoute } from "vue-router";
import {
  useAPI, useAuth, useCreators, useFavorites, usePage,
  useAgencys, useContentItems, useUsers, useCampaigns, useToastModule
} from "@/modules";
import {
  AppPageConfig,
  CampaignData,
  AgencyWorker,
  CampaignPaymentMethod,
  AgencyWorkers,
  BrandData,
  CampaignStatus,
  CampaignApplicationStatus,
  AgencyVideoStatus,
} from "@/data/types";
import { ref, watch, reactive } from "vue";
import Button from "primevue/button";
import OverlayPanelModal from "@/pages/commons/modals/OverlayPanelModal.vue";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import shortCampaignPage from "./shortCampaignPage.vue";
import TermsModal from "../../pages/creators/TermsModal.vue";
import { useSocketIo, useSocketMethods } from "../../services/socket";
import ProgressCampaignCpm from "./ProgressCampaignCPM.vue";
import GenericPrompt from "../../pages/utility/generic/GenericPrompt.vue";
import { CampaignApplicationStatusValues } from "@/data/enums/campaign-application";

import Message from 'primevue/message';
import { AgencyApplicationRef, ApplicationRef,
  CampaignApplicationData, CampaignApplicationPayoutCapProposal } from '../../data/types/campaign-types';


export default {
  name: "CampaignActivePage",
  components: {
    shortCampaignPage,
    OverlayPanelModal,
    ProgressCampaignCpm,
    TermsModal,
    Button,
    Dialog,
    InputText,
    Divider,
    GenericPrompt,
    Message,
  },
  props: {
    item: {
      type: Object,
    },
  },
  setup(props: any) {
    const route = useRoute();
    const user = useAuth();
    const creators = useCreators();
    const favorites = useFavorites();
    const agencyModule = useAgencys();
    const contentItemsModule = useContentItems();
    const { applications: { getApplicationProposals, resolveCapProposal }} = useCampaigns();

    const creator = ref(user.user.value);
    const agencyWorkers = ref<Array<AgencyWorkers>>([]);

    const { config } = usePage<AppPageConfig>({
      title: "campaign-active",
      ...props,
    });

    const creatorsController = useCreators(config.value.viewPerspective);
    const {
      manager: { getUserAppliedCampaigns },
    } = useUsers();
    const socket = useSocketIo(user.user.value?.id as string);
    const socketMethods = useSocketMethods(socket);
    const toast = useToastModule();

    const isUserAppliedToCampaign = ref(false);
    const openCpmPendingModal = ref(false);
    const openProgressChannel = ref();
    const dataProgressChannel = ref();
    const unlistedYouTubeLink = ref();
    const progressCpmCampaign = reactive({
      videoDraft: false,
      draftAccepted: false,
      draftRejected: false,
      liveVideoPosted: false,
      rejectReason: "",
    });
    const progressCpmCampaignChannels = ref<any>([]);

    const showWarningWithdraw = ref(false);
    const showWarningWithdrawChannel = ref(false);
    const withdrawChannelData = ref();
    const showTerms = ref(false);
    const openAcceptWindow = ref(false);
    const searchInfluencers = ref("");
    const selectedChannels = ref<Array<any>>([]);

    const selectedChannelForSendPendingVideo = ref();
    const showModal = ref(false);
    const showAcceptingKey = "show-accepting";
    const showAccepting = localStorage.getItem(showAcceptingKey);
    const showAcceptingCampaign = ref(!showAccepting);
    const showRedBorder = ref(!showAccepting);
    const showCreatingContent = ref(false);
    const showModalBudgetLeft = ref();

    const showApprovedMessagePrompt = ref(false);
    const showRejectedMessagePrompt = ref(false);

    const isCampaignFav = ref(false);
    const applyRequestLoading = ref(false);
    const termsAgreed = ref(false);
    const agencyAlreadyAgreed = ref(false); // store previous state because if an agency joins with another channel they will get "already applied" error
    const gigapayPrompt = ref(false); // remind to set gigapay account
    const cpmApplicationPrompt = ref(false); // CPM application to inform the application will be reviewed
    const disableApplyButton = ref(false);
    const creatorHasChannel = ref(true);
    const applicationStatus = ref<CampaignApplicationStatus>(CampaignApplicationStatusValues.NOT_APPLIED as unknown as CampaignApplicationStatus);
    const isCampaignCPM = ref(false);

    const currentUserId = user.user?.value?.id;
    const currentApplicationInfoForCreator = ref<ApplicationRef>({applicationId: "", applicationStatus: "pending"});
    const currentApplicationInfoForAgency = ref<AgencyApplicationRef[]>([]);

    watch(showAcceptingCampaign, () => {
      if (!showAcceptingCampaign.value) {
        localStorage.setItem(showAcceptingKey, "true");
      }
    });

    watch(showRedBorder, () => {
      if (!showRedBorder.value) {
        localStorage.setItem(showAcceptingKey, "true");
        showModal.value = true;
      }
    });

    watch(user.user, () => {
      if (creator.value && creator.value.agencyWorkers) {
        agencyWorkers.value = creator.value.agencyWorkers;
      }
    });

    watch(applyRequestLoading, () => {
      if (applyRequestLoading.value) {
        disableApplyButton.value = true;
      }
    });

    watch(termsAgreed, () => {
      // creators join straight away after T&C accepting, agencies need to select their channels first
      if (creator.value?.type === "creator") {
        applyForCampaign(campaignEntity.value!, creator?.value?.name);
      }

      showTerms.value = false;

      if (!creator.value?.mangopay) {
        gigapayPrompt.value = true;
      }
    });

    const showCreating = () => {
      window.setTimeout(() => {
        showCreatingContent.value = true;
      }, 1000);
    };

    const { data: campaignEntity, execute: loadCampaign } = useAPI<CampaignData>(`/campaigns/${route.params.campaignID}`, true);
    const loadSingleCampaign = (campaignID: string) => loadCampaign({ url: `/campaigns/${campaignID}` });

    // Fetch the application of the current user for this campaign (if it exists)
    const getApplicationForCurrentUser = async () => {
      try {
        if (user.user.value && campaignEntity?.value) {
          const userId = user.user.value.id;

          const getApplicationsFn = async (userId: string, campaignId: string, fetchApplicationObjectOnly = true) => {
            if (user.user.value?.type === "agencyOwner") {
              return agencyModule.manager.getAgencyWorkersCampaignParticipations(userId, campaignId, "all", fetchApplicationObjectOnly);
            } else if (user.user.value?.type === "creator") {
              return getUserAppliedCampaigns(userId, campaignId, fetchApplicationObjectOnly);
            }
          };

          //@ts-ignore
          const { CampaignApplications: applications } = await getApplicationsFn(userId, campaignEntity.value.id, true);

          await setApplicationStateOnLoad(applications, user.user.value.type, campaignEntity.value);

        }
      } catch (err) {}
    };

    // Set ref flags and prepare new state
    const setApplicationStateOnLoad = async (applications: CampaignApplicationData[], userType: string, { paymentMethod, id }: any) => {
      if (applications?.length <= 0) {
        return;
      }

      if (userType === "creator") {
        const application = applications[0]; // they have one application

        if (paymentMethod === CampaignPaymentMethod.CPM) {
          if (application.status === CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus) {
            applicationStatus.value = application.status; //added this for remove closed status
            disableApplyButton.value = true;
            cpmApplicationPrompt.value = true;
          } else if (application.status === CampaignApplicationStatusValues.ACCEPTED as unknown as CampaignApplicationStatus &&
            !application.draftUploaded) {

            if (application?.paymentCapProposals && application?.paymentCapProposals?.length > 0 &&
              application.paymentCapProposals[0].clientDecision === "approved") {
              disableApplyButton.value = true;
              showApprovedMessagePrompt.value = true;
              applicationStatus.value = application.status;
              isUserAppliedToCampaign.value = true;
            } else {
              cpmApplicationPrompt.value = true;
              applicationStatus.value = CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus;
            }
          } else if (application.status === CampaignApplicationStatusValues.REJECTED as unknown as CampaignApplicationStatus) {
            disableApplyButton.value = true;
            showRejectedMessagePrompt.value = true;
          }
        } else if (paymentMethod !== CampaignPaymentMethod.CPM && applications?.length > 0) {
          isUserAppliedToCampaign.value = true; // CPI and CPC campaigns do not require approval and if there is an application then they are part of campaign
        }

        const applicationStateObject = {
          applicationId: application.id,
          applicationStatus: application.status,
          capProposals: application.paymentCapProposals,
        };

        await setPayoutCapProposalsOnLoad([applicationStateObject], id, userType);
      } else if (userType === "agencyOwner") {
        const newApplicationState: AgencyApplicationRef[] = [];
        let agencyHasAnyApproval = false;
        let agencyAllRejected = true;

        applications.forEach((application: CampaignApplicationData) => {
          // If at least one channel participation is accepted display prompt for accepted state
          if (application.status === CampaignApplicationStatusValues.ACCEPTED as unknown as CampaignApplicationStatus) {

            if (application?.paymentCapProposals && application?.paymentCapProposals?.length > 0 &&
              application.paymentCapProposals[0].clientDecision === "approved") {
              showApprovedMessagePrompt.value = true;
              applicationStatus.value = application.status; // UI control ref, no need to differentiate the different application statuses
              agencyHasAnyApproval = true;
              agencyAllRejected = false;
            }
          }

          if (application.status === CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus) {
            agencyAllRejected = false;
          }

          newApplicationState.push({
            applicationId: application.id,
            applicationStatus: application.status,
            applicationChannel: application.channel!,
            capProposals: application.paymentCapProposals,
          });
        });

        // All agency applications are pending, display prompt for pending state
        if (!agencyHasAnyApproval && !agencyAllRejected) {
          cpmApplicationPrompt.value = true;
        }

        // All agency applications are rejected, display prompt for rejected state
        if (agencyAllRejected) {
          showRejectedMessagePrompt.value = true;
        }

        await setPayoutCapProposalsOnLoad(newApplicationState, id, userType);
      }
    };

    // Fetch the payout cap offerings and wrap up the state object to be set as a ref
    const setPayoutCapProposalsOnLoad = async (applications: AgencyApplicationRef[] | ApplicationRef[], campaignId: string, userType: string) => {
      if (applications?.length > 0) {
        try {
          for await (const application of applications) {
            const proposalsObject = await getApplicationProposals(campaignId, application.applicationId, CampaignApplicationStatusValues.ALL);
            application.capProposals = (proposalsObject as CampaignApplicationPayoutCapProposal[]);
          }

          if (userType === "agencyOwner") {
            currentApplicationInfoForAgency.value = applications as AgencyApplicationRef[];
          } else if (userType === "creator") {
            currentApplicationInfoForCreator.value = applications[0];
          }

        } catch (err) {}
      }
    };

    // Prep work on campaign load
    (async () => {
      await loadSingleCampaign(route.params.campaignID as string);

      const { status: campaignStatus, paymentMethod: campaignPaymentMethod } = campaignEntity!.value!;
      isCampaignCPM.value = campaignPaymentMethod === CampaignPaymentMethod.CPM;

      if (campaignStatus === CampaignStatus.Completed) {
        disableApplyButton.value = true;
      }

      if (user?.user?.value) {
        const { youtube, type } = user.user.value;

        // Creators without linked channels cannot participate in CPM campaigns
        if (type === "creator" && youtube!.length <= 0 && isCampaignCPM.value) {
          disableApplyButton.value = true;
          creatorHasChannel.value = false;
        }

        await getApplicationForCurrentUser();
      }
    })();

    const getProgressCpm = () => {
      if (user?.user?.value && route.params.campaignID) {
        const calculatedChannelIDs: Array<string> = [];

        if (user.user.value?.isAgencyOwner() && user.user.value?.agencyWorkers?.length) {
          user.user.value.agencyWorkers.map((worker) => {
            calculatedChannelIDs.push(worker?.channel?.channelId);
            return worker;
          });
        }

        contentItemsModule.manager.getStatusVideoCpm(user?.user?.value?.id as string, route.params.campaignID as string, calculatedChannelIDs).then((data) => {
          if (user.user.value?.isCreator()) {
            progressCpmCampaign.videoDraft = data.videoDraft;
            progressCpmCampaign.draftAccepted = data.draftAccepted;
            progressCpmCampaign.rejectReason = data.rejectReason;
            progressCpmCampaign.draftRejected = data.draftRejected;
            progressCpmCampaign.liveVideoPosted = data.liveVideoPosted;
          }
          if (user.user.value?.isAgencyOwner()) {
            progressCpmCampaignChannels.value = data;
          }
        });
      }
    };

    getProgressCpm();

    const findProgressChannel = (channelId: string) => {
      // Find in the array the first approved draft. If you have multiple rejected drafts and one approved
      // you should be able to submit your final video.
      const approvedDraft = progressCpmCampaignChannels.value?.find((stats: AgencyVideoStatus) => stats.channelId === channelId && stats.draftAccepted);

      if (!approvedDraft) {
        const rejectedDraft = progressCpmCampaignChannels.value?.find((stats: AgencyVideoStatus) => stats.channelId === channelId);
        return rejectedDraft;
      } else {
        return approvedDraft;
      }
    };

    (() => creatorsController.list.load(props.limit))();

    const getIndividualChannelInfo = (channelId: string, campaign: any, info: "applicationId" | "status") => {
      if (user.user.value && user.user.value?.isAgencyOwner() && user.user.value?.agencyWorkers && campaign?.applications) {
        const currentContactChannelIndex = campaign.applications.findIndex((application: { channel: string; status: "pending" | "accepted" | "rejected" }) => {          
          if (info === "applicationId") {
            return application.channel === channelId && application.status === "accepted";
          } else if (info === "status") {
            return application.channel === channelId;
          }
        });

        const returnProperty = info === "applicationId" ? "id" : "status";

        return campaign.applications[currentContactChannelIndex]?.[returnProperty];
      }
    };

    const getIndividualChannelApplicationDisplay = (channelId: string, currentApplicationInfoForAgency: AgencyApplicationRef[]) => {
      const relevantApplication = currentApplicationInfoForAgency.find((application: AgencyApplicationRef) => application.applicationChannel === channelId);

      if (relevantApplication?.applicationStatus === "pending") {
        return "Pending application";
      } else if (relevantApplication?.applicationStatus === "rejected") {
        return "Rejected application";
      }

      if (relevantApplication?.capProposals && relevantApplication?.capProposals?.length > 0){
        return relevantApplication.capProposals[0].clientDecision === "pending" ? "Pending cap proposal" : "Rejected cap proposal";
      }
    };

    const toggleCampaignFavorite = async () => {
      await favorites.likeOrDislike("campaigns", user.user.value?.id as string, route.params.campaignID);
    };

    const isCampaignFavorite = async () => {
      const myFavoriteCampaigns = await favorites.getUSerFavorites("campaigns", user.user.value?.id as string);
      const index = myFavoriteCampaigns.allUserFavorites.findIndex((el: any) => el.campaigns?.id === route.params?.campaignID);
      if (index >= 0) isCampaignFav.value = true;
      if (index < 0) isCampaignFav.value = false;
    };

    isCampaignFavorite();

    // creator
    const withdrawCampaign = async () => {
      if (user.user.value && currentApplicationInfoForCreator?.value?.applicationId) {
        isUserAppliedToCampaign.value = false;
        showWarningWithdraw.value = false;

        await creatorsController.manager.withdrawCampaign(route.params.campaignID as string, currentApplicationInfoForCreator.value.applicationId);
        await getUserAppliedCampaigns(user.user.value?.id);

        await loadSingleCampaign(route.params.campaignID as string);
        await getApplicationForCurrentUser();
      }
    };

    const sendCampaignApplicationRequest = async (campaignId: string, costPerInstallMin: number) => {
      applyRequestLoading.value = true;

      await creators.manager.applyCampaign(campaignId, user.user.value?.id as string, costPerInstallMin, false,0,isCampaignCPM.value);
      await loadSingleCampaign(campaignId);

      applyRequestLoading.value = false;
      await getApplicationForCurrentUser();

    //isUserAppliedToCampaign.value = true; // comment this code and add below condition because of show pending button issue.
      isUserAppliedToCampaign.value = campaignEntity.value?.paymentMethod !== "CPM";
      applicationStatus.value = CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus;
    };

    const selectChannel = (channel: AgencyWorker) => {
      const index = selectedChannels.value.findIndex((el) => el.channel?.channelId === channel?.channel?.channelId);
      if (index < 0) {
        selectedChannels.value.push(channel);
      } else {
        selectedChannels.value.splice(index, 1);
      }
    };

    const userId = user?.user?.value?.id ? user.user.value.id : "";

    const isSelectedChannel = (channel: AgencyWorker) => {
      const index = selectedChannels.value.findIndex((el) => el?.channel?.channelId === channel?.channel?.channelId);
      if (index >= 0) {
        return true;
      }
      return false;
    };

    const selectAllItems = (arrayChannels: AgencyWorker[],campaign:any) => {
      const filteredAgencyWorkers = arrayChannels.filter((channel:AgencyWorker) => {
      return !getIndividualChannelInfo(channel?.channel?.channelId as string, campaign, 'applicationId') 
      && !displayChannelApplicationStatusCondition(channel?.channel?.channelId as string, currentApplicationInfoForAgency.value) 
    })
      selectedChannels.value = filteredAgencyWorkers;
    };

    const acceptChannels = async () => {
      if (selectedChannels.value?.length) {
        for await (const channel of selectedChannels.value) {
          const updatedChannel = {
          channelData: channel,
          ...(isCampaignCPM.value && {
            cap: 0,
          }),
          };
          await agencyModule.manager.applyChannelsCampaign(updatedChannel, route.params.campaignID as string);
          selectedChannels.value = selectedChannels.value.filter((currentChannel) => channel.channel?.channelId !== currentChannel.channel?.channelId);
        }
        await loadSingleCampaign(route.params.campaignID as string).then(async () => {
          await getProgressCpm();
          if(!!isCampaignCPM.value){
            window.location.reload();
          }
          toast.showSuccess("Accepted campaign successfully!");
        })
        //showTerms.value = true;
      }
    };
    function checkCondition(A: number, B: number) {
      const difference = A - B;
      const percentage = (difference / A) * 100;

      return percentage <= 15;
    }

    const showTutorialModal = () => {
      const key = "hadModal";
      const hadModal = localStorage.getItem(key);
      if (!hadModal) {
        showModal.value = true;
        localStorage.setItem(key, "true");
      }

      showModalBudgetLeft.value = checkCondition(campaignEntity.value?.budget?.amount as number, campaignEntity.value?.budget?.amountLeft as number);
    };

    const withdrawChannelApplication = async (campaign: any) => {
      let applicationId = "";
      if (user.user.value && user.user.value.isAgencyOwner() && user.user.value.agencyWorkers && campaign.applications) {
        const currentContactChannelIndex = campaign.applications.findIndex(
          (application: { channel: string }) => application.channel === withdrawChannelData.value.channel.channelId
        );
        applicationId = campaign.applications[currentContactChannelIndex]?.id;
      }

      agencyModule.manager.withdrawCampaignChannel(campaign.id, applicationId, withdrawChannelData.value.channel.channelId).then(async () => {
        showWarningWithdrawChannel.value = false;
        withdrawChannelData.value = null;
        await loadSingleCampaign(route.params.campaignID as string);
      });
    };

    const searchInfluencersUser = () => {
      const regExp = new RegExp(searchInfluencers.value, "gi");
      if (creator.value && creator.value.agencyWorkers) {
        agencyWorkers.value = creator.value.agencyWorkers.filter((el: { channel: { title: string } }) => regExp.test(el.channel?.title));
      }
    };

    searchInfluencersUser();

    const submitUnlistedVideo = () => {
      let IDs = [];

      if (unlistedYouTubeLink.value.includes("youtu.be")) {
        IDs = ["&id=" + unlistedYouTubeLink.value.split("/").filter(Boolean)[2]];
      } else {
        IDs = [`&id=${unlistedYouTubeLink.value.split("=")[1]}`];
      }

      contentItemsModule.manager
        .createUnlistedContent(user.user.value?.id as string, route.params.campaignID as string, IDs, selectedChannelForSendPendingVideo.value)
        .then(async (data) => {
          if (data) {
            progressCpmCampaign.videoDraft = true;
            openCpmPendingModal.value = false;
            unlistedYouTubeLink.value = "";
            await getProgressCpm();
          }
        });
    };

    const applyForCampaign = async ({ id, name, brand, budget }: CampaignData, creatorName?: string) => {
      const { amount } = budget;
      const { id: brandId, creator } = brand as BrandData;

      // Only if the creator agreed to the terms and conditions then finalize the process
      if (termsAgreed.value) {
        // CPI and CPC campaigns applications are automatically approved but CPM campaigns need an admin approval
        const requiresApproval = isCampaignCPM.value ? true : false;

        sendCampaignApplicationRequest(id, amount);

        if (requiresApproval) {
          isUserAppliedToCampaign.value = false; // add false if CPM is method to resolve show pending button issue        } else {
          showTutorialModal();

          socketMethods.addNewNotification({
            message: `<b>${creatorName}</b> applied to the <b>${name}</b> campaign.`,
            from: userId,
            to: creator, // for brand
            type: "apply",
            campaignId: id,
            brandId: brandId,
            status: "new",
          });
        }
      }
    };

    const unmountPrompt = (promptToUnmount: "gigapayPrompt" | "cpmApplicationPrompt") => {
      if (promptToUnmount === "gigapayPrompt") {
        gigapayPrompt.value = false;
      } else if (promptToUnmount === "cpmApplicationPrompt") {
        cpmApplicationPrompt.value = false;
      }
    };

    // Creator
    const getApplyButtonLabel = () => {
      if (campaignEntity.value) {
        if (!creatorHasChannel.value && isCampaignCPM.value) {
          return "Cannot apply without linked YouTube channel.";
        }

        if (applicationStatus.value === (CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus)) {
          disableApplyButton.value = true;
          return "Pending application";
        }

        if (disableApplyButton.value) {
          return "Closed";
        } else if (!isUserAppliedToCampaign.value) {
          if (isCampaignCPM.value) {
            return "Apply";
          } else {
            return "Accept";
          }
        } else {
          return "";
        }
      }
    };

    const agencyApplyOnClick = () => {
      if (applicationStatus.value === (CampaignApplicationStatusValues.NOT_APPLIED as unknown as CampaignApplicationStatus)) {
        if (termsAgreed.value) {
          return () => (openAcceptWindow.value = true);
        }
      } else if (applicationStatus.value === (CampaignApplicationStatusValues.ACCEPTED as unknown as CampaignApplicationStatus)) {
        // agencies can view the participation dropdown even with 1 accepted application since they can rejoin with another channel
        return () => (openAcceptWindow.value = !openAcceptWindow.value);
      }
    };

    const displayModeratedContent = () => {
      if (applicationStatus.value === (CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus)) {
        return false;
      } else if (applicationStatus.value === (CampaignApplicationStatusValues.NOT_APPLIED as unknown as CampaignApplicationStatus)) {
        return false;
      } else if (applicationStatus.value === (CampaignApplicationStatusValues.REJECTED as unknown as CampaignApplicationStatus)) {
        return false;
      }

      return true;
    };

    const displayChannelApplicationStatusCondition = (channelId: string, agencyInfo: any) => {
      const relevantApplication = agencyInfo?.find((application: AgencyApplicationRef) => application.applicationChannel === channelId);

      if (!relevantApplication) {
        return false;
      }

      // It will display application status as a button instead of being able to upload a video
      if (relevantApplication?.status === "pending" || relevantApplication?.status === "rejected") {
        return true;
      }

      const acceptedProposalCap = relevantApplication?.capProposals.find((capProposal: CampaignApplicationPayoutCapProposal) => capProposal.clientDecision === "approved");

      if (!acceptedProposalCap) {
        return true;
      }

      return false;
    };

    const resolveCapProposalWrapperFn = (action: "accept" | "reject", campaignId: string, applicationId: string, channelId?: string) => {
      resolveCapProposal(action, campaignId, applicationId, channelId).then(() => {
        toast.showSuccess("Successfully resolved payout cap proposal.");
        getApplicationForCurrentUser();
      }).catch(() => {
        toast.showError("Unable to resolve payout cap proposal.");
      });
    };


    return {
      showRejectedMessagePrompt,
      getIndividualChannelApplicationDisplay,
      displayChannelApplicationStatusCondition,
      showApprovedMessagePrompt,
      agencyApplyOnClick,
      displayModeratedContent,
      getApplyButtonLabel,
      gigapayPrompt,
      unmountPrompt,
      termsAgreed,
      applyForCampaign,
      showModalBudgetLeft,
      showTutorialModal,
      submitUnlistedVideo,
      showModal,
      showAcceptingKey,
      showAccepting,
      showCreating,
      showCreatingContent,
      showRedBorder,
      showAcceptingCampaign,
      socketMethods,
      isCampaignFav,
      openAcceptWindow,
      toggleCampaignFavorite,
      isCampaignFavorite,
      withdrawCampaign,
      isSelectedChannel,
      selectAllItems,
      acceptChannels,
      withdrawChannelApplication,
      searchInfluencersUser,
      agencyWorkers,
      showWarningWithdrawChannel,
      withdrawChannelData,
      loadingAccept: agencyModule.manager.applyChannelsCampaignLoading,
      showWarningWithdraw,
      applyRequestLoading,
      campaign: campaignEntity,
      selectChannel,
      getIndividualChannelInfo,
      creator,
      isUserAppliedToCampaign,
      currentUserId,
      showTerms,
      userId,
      searchInfluencers,
      selectedChannels,
      openCpmPendingModal,
      unlistedYouTubeLink,
      progressCpmCampaign,
      selectedChannelForSendPendingVideo,
      findProgressChannel,
      openProgressChannel,
      dataProgressChannel,
      disableApplyButton,
      applicationStatus,
      isCampaignCPM,
      cpmApplicationPrompt,
      currentApplicationInfoForAgency,
      currentApplicationInfoForCreator,
      resolveCapProposal,
      resolveCapProposalWrapperFn,
      progressCpmCampaignChannels
    };
  },
};
