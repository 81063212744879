import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import noImage from "../../img/no-image-1-gray.png";

import { generateGoogleDriveLinks } from '@/utils/campaign-utils';

import { CloseIcon, RedX, UploadFileIcon } from '@/react_app/img/Icons';

import './UGCVideoDialog.css';
import { useUgcManager } from '@/modules/ugc/ugc-manager-module';
import { useAuth, useToastModule } from '@/modules';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

const UGCVideoDialog = (props) => {
  const {
    open, close, conceptCount, videoAspectRatio, currentType, campaignId, handleUpload, latestUGCSubmits, selectedChannel
  } = props;
  const user = useAuth();
  const isAgency = user?.user?.value?.isAgencyOwner();
  const toast = useToastModule();
  const { id } = user?.user?.value;
  const { uploadUgcDraftVideo, uploadUgcPublishVideo, uploadUgcVideo, deleteUgcVideo } = useUgcManager();
  const [videoLinks, setVideoLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState({});

  const fileInputRefs = useRef(
    Array(conceptCount)
      .fill(null)
      .map(() => videoAspectRatio?.map(() => React.createRef()))
  );

  useEffect(() => {
    if (latestUGCSubmits && latestUGCSubmits?.length > 0) {
      const data = generateGoogleDriveLinks(conceptCount, videoAspectRatio, latestUGCSubmits);
      setVideoLinks(data);
    } else {
      const data = generateGoogleDriveLinks(conceptCount, videoAspectRatio, []);
      setVideoLinks(data);
    }
  }, [conceptCount, videoAspectRatio, latestUGCSubmits]);

  useEffect(() => {
    validateLinks();
  }, [videoLinks])

  const validateLinks = () => {
    if (currentType === 'publish') {
      return videoLinks.every(concept =>
        concept?.aspectRatios?.every(ratio => ratio?.videoUrl?.trim() !== ''));
    } else if (currentType === 'draft') {
      return videoLinks.every(concept =>
        concept?.aspectRatios.some(ratio => (ratio?.videoUrl?.trim() !== '')));
    }
    return false;
  };

  const handleFileChange = (file, conceptIndex, ratio, ratioIndex) => {
    if (!file.length) return;
    const formData = new FormData();
    formData.set("files[]", file[0]);
    formData.set("concept", conceptIndex + 1);
    formData.set("ratio", ratio);
    formData.set("isRequiredThumbnail", false);

    setLoading(true);
    setLoadingFile({ conceptIndex, ratioIndex });

    uploadUgcVideo(campaignId, formData)
      .then((res) => {
        const updatedLinks = [...videoLinks];
        updatedLinks[conceptIndex].aspectRatios[ratioIndex] = {
          ...updatedLinks[conceptIndex].aspectRatios[ratioIndex],
          videoUrl: res?.videoUrl,
          previewUrl: res.thumbnailUrl,
          fileName: res.fileName,
          fileSize: res.fileSize,
          status: currentType === 'publish' ? 'accepted' : 'pending',
        };
        setVideoLinks(updatedLinks);
      })
      .catch((error) => {
        const inputRef = fileInputRefs.current[conceptIndex][ratioIndex];
        if (inputRef.current) {
          inputRef.current.value = null;
        }
      }).finally(() => {
        setLoading(false);
        setLoadingFile({});
      });
  };

  const handleRemoveVideo = (conceptIndex, ratioIndex) => {
    const updatedLinks = [...videoLinks];
    const currentAspectRatioInfo = updatedLinks[conceptIndex].aspectRatios[ratioIndex];
    setLoadingFile({ conceptIndex, ratioIndex });
    setLoading(true);
    deleteUgcVideo(campaignId, {
      url: currentAspectRatioInfo.videoUrl,
      thumbnailUrl: currentAspectRatioInfo.previewUrl,
      ratio: currentAspectRatioInfo.ratio,
      concept: conceptIndex + 1,
      ...(isAgency && { channelId: selectedChannel?.channel?.channelId })
    }).then(res => {
      if (res) {
        updatedLinks[conceptIndex].aspectRatios[ratioIndex].videoUrl = '';
        updatedLinks[conceptIndex].aspectRatios[ratioIndex].previewUrl = '';
        setVideoLinks(updatedLinks);
        toast.showSuccess("Video deleted successfully.")
      }
    }).catch(error => {
      const inputRef = fileInputRefs.current[conceptIndex][ratioIndex];
      if (inputRef.current) {
        inputRef.current.value = null;
      }
    }).finally(() => {
      setLoading(false);
      setLoadingFile({});
    })
  };

  const handleUploadDraftVideo = () => {
    const updatedLinks = videoLinks.map(concept => {
      const updatedAspectRatios = concept.aspectRatios.map(aspect => {
        if (aspect.videoUrl?.trim()) {
          return {
            ...aspect,
            status: currentType === "draft" ? aspect.status : 'accepted',
            submittedAt: new Date().toISOString().split('T')[0],
          };
        } else {
          return {
            ...aspect,
            status: currentType === "draft" ? aspect.status : 'accepted',
            submittedAt: new Date().toISOString().split('T')[0],
            fileName: videoLinks[concept.concept - 1]?.[aspect.ratio]?.name || '',
            fileSize: videoLinks[concept.concept - 1]?.[aspect.ratio]?.size || 0,
          };
        }
      });

      return {
        ...concept,
        aspectRatios: updatedAspectRatios
      };
    });

    const filterData = updatedLinks.map(concept => ({
      concept: concept.concept,
      aspectRatios: concept.aspectRatios.filter(aspect => aspect?.videoUrl?.trim() !== '')
    })).filter(concept => concept.aspectRatios.length > 0);

    if (currentType === "draft") {
      if (currentType === "draft") {
        if (filterData?.every(concept => concept?.aspectRatios.some(ratio => (ratio?.status === 'rejected')))) {
          toast.showError("Please re-upload the rejected videos.");
          return;
        }
      }
      uploadUgcDraftVideo(id, campaignId, filterData, selectedChannel).then(res => {
        if (res) handleUpload(res);
      });
    } else {
      uploadUgcPublishVideo(id, campaignId, filterData, selectedChannel).then(res => {
        if (res) handleUpload(res);
      });
    }
  };

  const handleImageClick = (conceptIndex, ratioIndex) => {
    const inputRef = fileInputRefs.current[conceptIndex][ratioIndex];
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const getCurrentVideoInfo = (conceptIndex, ratioIndex) => {
    return videoLinks[conceptIndex]?.aspectRatios[ratioIndex];
  }

  const getCurrentVideoLink = (conceptIndex, ratioIndex) => {
    return videoLinks[conceptIndex]?.aspectRatios[ratioIndex]?.videoUrl || "";
  }

  const getCurrentVideosPreviewLink = (conceptIndex, ratioIndex) => {
    return videoLinks[conceptIndex]?.aspectRatios[ratioIndex]?.previewUrl || ""
  }

  const showFileSizeInMB = (bytes) => (bytes / (1024 * 1024))?.toFixed(1);

  return (
    <Dialog key={currentType} visible={open} onHide={() => close()} className='ugcDialog'>
      <div className='headerWrapper'>
        <div className='closeRow'>
          <div className='closeButton' onClick={() => close()}><CloseIcon /></div>
        </div>
        <p className='headerTitle'>{`Upload ${currentType} video(S)`}</p>
        {
          currentType !== 'publish' ?
            <p className='headerSubTitle'>Upload one draft video for each concept.</p> :
            <p className='headerSubTitle'>Upload a video for each concept and aspect ratio.</p>
        }
      </div>
      <input
        type="file"
        style={{ display: 'none' }}
        accept="video/mp4,video/quicktime"
      />
      <div className='mainWrapper'>
        {[...Array(conceptCount)].map((_, conceptIndex) => {
          const conceptHasVideo = videoAspectRatio?.some((ratio, ratioIndex) =>
            getCurrentVideoLink(conceptIndex, ratioIndex))
          return (
            <div className='mainWrapperItem' key={conceptIndex}>
              <div className='concept-wrap'>
                <span className="conceptLabel">{`Concept ${conceptIndex + 1}`}</span>
                <span className="aspectRatioLabel">Aspect Ratio <span className="redStar">*</span></span>
              </div>
              {videoAspectRatio?.map((ratio, ratioIndex) => {
                const isUploading = loadingFile.conceptIndex === conceptIndex && loadingFile.ratioIndex === ratioIndex;
                const currentVideoLink = getCurrentVideoLink(conceptIndex, ratioIndex);
                const isDisabled = conceptHasVideo && !currentVideoLink && currentType !== 'publish';
                return (
                  <div key={ratio} className='video-wrap'>
                    <p className='ratio'>{ratio}</p>
                    <div className={`video-wrapper ${!getCurrentVideoLink(conceptIndex, ratioIndex) ? 'no-file' : ''}`}>
                      <div className='video-preview'>
                        <FileUpload
                          multiple={false}
                          headerTemplate={() => (
                            <div
                            >
                              {!!getCurrentVideoLink(conceptIndex, ratioIndex) &&
                                <>
                                  <Image
                                    // src={!!getCurrentVideosPreviewLink(conceptIndex, ratioIndex) ?
                                    //   getCurrentVideosPreviewLink(conceptIndex, ratioIndex) : noImage}
                                    src={noImage}
                                    alt='file preview'
                                    className={`${(!getCurrentVideosPreviewLink(conceptIndex, ratioIndex) && validateLinks()) ? "disabled" : ''}`}
                                    // onClick={() => handleImageClick(conceptIndex, ratioIndex)}
                                    style={{ display: 'none' }}
                                  />
                                  <video
                                    src={`${getCurrentVideoLink(conceptIndex, ratioIndex)}#t=5`}
                                    controls={false}
                                    onError={(e) => {
                                      e.target.style.display = 'none';
                                      const img = e.target.previousElementSibling;
                                      if (img) {
                                        img.style.display = 'block';
                                      }
                                    }}
                                  />
                                </>
                              }
                              {!getCurrentVideoLink(conceptIndex, ratioIndex) &&
                                <div
                                  className={`upload-file-wrap 
                                  ${(isUploading || loading) ? "loading disabled" : ""} 
                                  ${((!getCurrentVideoLink(conceptIndex, ratioIndex) && validateLinks() && !isUploading) || isDisabled) ? "disabled" : ''}`

                                  }
                                  onClick={() => handleImageClick(conceptIndex, ratioIndex)}
                                >
                                  <UploadFileIcon />
                                  <p className='file-name'>Please click here to upload the file</p>
                                </div>
                              }
                              <input
                                type="file"
                                ref={fileInputRefs.current[conceptIndex][ratioIndex]}
                                style={{ display: 'none' }}
                                accept="video/mp4,video/quicktime"
                                multiple
                                onChange={(e) =>
                                  handleFileChange(e.target.files, conceptIndex, ratio, ratioIndex)
                                }
                                disabled={(!getCurrentVideosPreviewLink(conceptIndex, ratioIndex) && validateLinks()) || isDisabled || loading}
                              />
                            </div>)
                          }
                          chooseOptions={{
                            className: 'p-button-outlined',
                            style: { display: 'none' },
                          }}
                        />
                        {isUploading ? <CustomLoader /> : ""}
                      </div>
                      {getCurrentVideoLink(conceptIndex, ratioIndex) && (
                        <div className='videoInfo'>
                          <p className='file-name'>{getCurrentVideoInfo(conceptIndex, ratioIndex)?.fileName || ""}</p>
                          <p className='file-size'>{showFileSizeInMB(getCurrentVideoInfo(conceptIndex, ratioIndex)?.fileSize || 0)} MB</p>
                          <p className={`file-status ${getCurrentVideoInfo(conceptIndex, ratioIndex)?.status}`}>{getCurrentVideoInfo(conceptIndex, ratioIndex)?.status}</p>
                        </div>
                      )}
                    </div>
                    {!!getCurrentVideoLink(conceptIndex, ratioIndex) && <RedX className={`remove-file ${getCurrentVideoInfo(conceptIndex, ratioIndex)?.status}`}
                      onClick={() => handleRemoveVideo(conceptIndex, ratioIndex)} />
                    }
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div className='footerWrapper'>
        <Button className="selectButton" disabled={!validateLinks() || loading} onClick={() => handleUploadDraftVideo()}>{`Upload ${currentType} video(S)`}</Button>
        <p className={'warningText'}>By submitting your content to Gameinfluencer, you acknowledge that you agree to Gameinfluencer’s terms and conditions. please be sure not to violate others copyright or privacy rights. Learn more.</p>
      </div>
    </Dialog>
  );
};

export default UGCVideoDialog;