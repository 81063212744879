import { ref, watch } from 'vue';
import { useUserManager } from './users/user-manager-module';
import { MANAGER, useContext } from './context-module';
import HTTPService from '../services/http/HTTPService';
import { Perspective, User, UserData, UserType } from '../data/types';
import { useAPI } from "@/modules/api-module";

const AUTH_KEY = 'gi-auth-token';
const REFRESH_KEY = 'gi-refresh-token';

const user = ref<User | undefined>(undefined);
const authenticationDone = ref(false);

export const useAuth = () => {
  const manager = useUserManager();
  const context = useContext(MANAGER);

  const loadUser = (token: string | null = window.localStorage.getItem(AUTH_KEY), refreshTok?: string): Promise<User | undefined> => {
    if (token) {
      return manager.loadMyProfile(token)
        .then((loggedUser) => {
          if (loggedUser) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            login(loggedUser, token, refreshTok);
          } else {
            window.localStorage.removeItem(AUTH_KEY); // token is invalid
            window.localStorage.removeItem(REFRESH_KEY); // token is invalid
          }

          authenticationDone.value = true;
          return loggedUser;
        });
    }

    authenticationDone.value = true;
    return Promise.resolve(undefined);
  };

  const login = (loggedUser?: User | UserData, token = window.localStorage.getItem(AUTH_KEY), refreshToken?: string) => {
    if (!loggedUser) {
      return;
    }

    if (token) {
      window.localStorage.setItem(AUTH_KEY, token);
      HTTPService.setAuthorizationHeader(token);
    }
    if (refreshToken) {
      window.localStorage.setItem(REFRESH_KEY, refreshToken);
    }

    if (loggedUser instanceof User) {
      user.value = loggedUser;
    } else {
      user.value = new User(loggedUser);
    }

    // eslint-disable-next-line no-unused-expressions,no-nested-ternary
    context.setPerspective?.(user.value.type === UserType.BrandOwner ? Perspective.BRAND : user.value.type
      === UserType.Agency ? Perspective.AGENCY : user.value.type === UserType.Admin ? Perspective.ADMIN : Perspective.CREATOR);
  };

  const logout = (): Promise<void> => {
    window.localStorage.removeItem(AUTH_KEY);
    window.localStorage.removeItem(REFRESH_KEY);
    user.value = undefined;
    localStorage.removeItem('socialIn');
    localStorage.removeItem('isEditProfile');
    return Promise.resolve();
  };

  const userHasBrandEmail = (email: string): boolean => email.includes("pages.plusgoogle.com");

  const {
    loading: restorePasswordProgress,
    error: restorePasswordError,
    data: restorePasswordResult,
    execute: restorePasswordRequest,
  } = useAPI('', false);
  const restorePassword = (
    email: string,
    action: 'create-code' | 'check-code',
    resetCode?: number,
    password?: string
  ) => restorePasswordRequest({
    url: `/auth/restore-password`,
    method: 'POST',
    data: {
      email,
      action,
      password,
      resetCode,
    },
  });
  // restore password

  return {
    userHasBrandEmail,
    restorePassword,
    restorePasswordResult,
    restorePasswordError,
    restorePasswordProgress,
    // restore
    authenticationDone,
    user,
    loadUser,
    login,
    logout,
  };
};
