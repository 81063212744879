import { createApp } from 'vue';

import PrimeVue from 'primevue/config';
import { MotionPlugin } from '@vueuse/motion';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';

import 'vue-advanced-cropper/dist/style.css';
import './assets/style/main.scss';
import 'primevue/resources/primevue.css';
import 'primeflex/primeflex.scss';
import 'primeicons/primeicons.css';
import App from './App.vue';
import router from './router';

const currentDesign = localStorage.getItem('new-design-toggle');

if (!currentDesign || currentDesign === 'deactivated') {
  localStorage.setItem('new-design-toggle', 'activated');
  window.location.reload();
}

const app = createApp(App);

app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(MotionPlugin);
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.mount('#app');
