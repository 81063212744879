import React, { useEffect, useState } from "react";

import { Image } from 'primereact/image';
import { Column } from "primereact/column";
import { Divider } from 'primereact/divider';
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Paginator } from 'primereact/paginator';

import { useAdmins } from "@/modules";
import { formatDate } from '@/utils/globals/date-utils';
import { PAGINATION_LIMIT } from '@/utils/globals/constants';
import { compactNumber } from '@/utils/globals/curency-utils';

import noImage from "../../img/no-image.png";
import { BackArrowIcon } from "@/react_app/NewAdmin/img/Icons";

import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

import "./NewBrandInfoPage.css";

const campaignManagementTabs = [
  { value: 'All', type: 'all' },
  { value: 'Active', type: 'active' },
  { value: 'Drafts', type: 'draft' },
  { value: 'Completed', type: 'completed' },
  { value: 'Pending', type: 'pending' },
];

const campaignBodyTemplate = (rowData) => (
  <div className='campaignTemplate'>
    <Image src={rowData.photo} alt='campaign cover' className='campaignTemplateImage' />
    <div className='campaignTemplateText'>
      <p className='campaignTemplateTitle'>{rowData.name}</p>
      <p className='campaignTemplateDescription'>{rowData.productName}</p>
    </div>
  </div>
);
const statusBodyTemplate = (rowData) => {
  const statusTab = campaignManagementTabs.find(tab => tab.type === rowData.status);
  return (
    <div className='statusTemplate'>
      {statusTab && (
        <div className={`statusTemplateBox status${(statusTab.value)}`}>
          {statusTab.value}
        </div>
      )}
    </div>
  );
};
const marketBodyTemplate = (rowData) => (
  <div className='marketTemplate'>
    {rowData.targetMarket.title}
  </div>
);
const dateBodyTemplate = (rowData) => (
  <div className='dateTemplate'>
    {rowData.dates.endDate ?
      formatDate(rowData.dates.endDate) :
      '—'
    }
  </div>
);
const priceBodyTemplate = (rowData) => (
  <div className='priceTemplate'>
    {`${compactNumber(rowData.budget?.amount) || 0} ${rowData.budget.currency}`}
  </div>
);

export default function (props) {
  const { brand } = props;

  const adminsModule = useAdmins();
  const [time, setTime] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [filterOptions, setFilterOptions] = useState({ skip: 0, search: '', status: 'all' })
  const { skip, search, status } = filterOptions;

  const getCampaigns = (currentSkip, currentSearch, currentStatus) => {
    setIsLoading(true);
    const tempStatus = (currentStatus === 'all') ? '' : currentStatus
    adminsModule.manager
      .getBrandCampaigns(PAGINATION_LIMIT, currentSkip, currentSearch, tempStatus, brand._id)
      .then((response) => {
        if (response.campaigns?.length) {
          setAllCampaigns(response.campaigns);
          setTotalCount(response.pagination);
        } else {
          setAllCampaigns([]);
          setTotalCount(0);
        }
        setIsLoading(false)
      });
  };

  useEffect(() => {
    getCampaigns(skip, search, status);
  }, []);

  useEffect(() => {
    setNoResults(!(allCampaigns?.length))
  }, [allCampaigns]);

  const handleSearch = (event) => {
    const { value } = event.target
    setFilterOptions((prev) => ({ ...prev, search: value }));
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        getCampaigns(0, value, status);
        setFilterOptions((prev) => ({ ...prev, skip: 0, search: value }));
      }, 1000)
    );
  }
  const handleChangeTab = (currentStatus) => {
    getCampaigns(0, search, currentStatus);
    setFilterOptions((prev) => ({ ...prev, skip: 0, search: '', status: currentStatus }))
  }
  const onPageChange = (event) => {
    const { first } = event;
    getCampaigns(first, search, status);
    setFilterOptions((prev) => ({ ...prev, skip: first }));
  };

  return (
    <div className='brand-info-page'>
      <div className='returnWrapper' onClick={() => props.handleReturn()}>
        <BackArrowIcon />
        Overview Brands
      </div>
      <div className='mainInfoWrapper'>
        <div className='mainInfoSquare'>
          <Image alt='brand photo' src={brand?.photo ? brand?.photo : noImage} className='brandPhoto' />
          <p className='brandName'>{brand?.name}</p>
          <p className='brandLabel'>Brand</p>
        </div>
        <div className='legalInfo'>
          <p className='brandInfoTitle'>Brand Info</p>
          <div className='infoGroupsWrapper'>
            <div className='infoGroup'>
              <p className='infoData'><span className='infoLabel'>Person type: </span>{brand?.uniquePayments[0]?.paymentInformation?.PersonType ?? '-'}</p>
              <p className='infoData'><span className='infoLabel'>Legal person type: </span>{brand?.uniquePayments[0]?.paymentInformation?.LegalPersonType ?? '-'}</p>
              <p className='infoData'><span className='infoLabel'>Birthday: </span>{brand?.uniquePayments[0]?.paymentInformation?.Birthday ?? '-'}</p>
            </div>
            <Divider layout="vertical" className='verticalDivider' />
            <div className='infoGroup'>
              <p className='infoData marginDouble'>Legal representative address</p>
              <p className='infoData'><span className='infoLabel'>Address line 1: </span>{brand?.uniquePayments[0]?.paymentInformation?.LegalRepresentativeAddress?.AddressLine1 ?? '-'}</p>
              <p className='infoData'><span className='infoLabel'>City: </span>{brand?.uniquePayments[0]?.paymentInformation?.LegalRepresentativeAddress?.City ?? '-'}</p>
              <p className='infoData'><span className='infoLabel'>Postal code: </span>{brand?.uniquePayments[0]?.paymentInformation?.LegalRepresentativeAddress?.PostalCode ?? '-'}</p>
              <p className='infoData'><span className='infoLabel'>Country: </span>{brand?.uniquePayments[0]?.paymentInformation?.LegalRepresentativeAddress?.Country ?? '-'}</p>
            </div>
            <Divider layout="vertical" className='verticalDivider' />
            <div className='infoGroup'>
              <p className='infoData'><span className='infoLabel'>First Name: </span>{brand?.uniquePayments[0]?.paymentInformation?.FirstName ?? '-'}</p>
              <p className='infoData'><span className='infoLabel'>Last Name: </span>{brand?.uniquePayments[0]?.paymentInformation?.LastName ?? '-'}</p>
              <p className='infoData'><span className='infoLabel'>Nationality: </span>{brand?.uniquePayments[0]?.paymentInformation?.Nationality ?? '-'}</p>
              <p className='infoData'><span className='infoLabel'>Country of residence: </span>{brand?.uniquePayments[0]?.paymentInformation?.CountryOfResidence ?? '-'}</p>
            </div>
          </div>
        </div>
      </div>
      <p className='campaignsTitle'>Campaigns</p>
      <div>
        <div className='tabsWrapper'>
          <div className='tabs'>
            {campaignManagementTabs.map((item) =>
              <div key={item.type} className={item.type === filterOptions.status ? 'tabSelected' : 'tabNormal'} onClick={() => handleChangeTab(item.type)}>
                {item.value}
              </div>)}
          </div>
        </div>
        <div className='campaignsSearchBarWrapper'>
          <i className="pi pi-search" style={{ position: 'absolute', left: '11px', top: '10px' }} />
          <InputText className='campaignsSearchBar' placeholder='Search for campaign' value={search} onChange={(e) => handleSearch(e)} />
        </div>
      </div>
      <DataTable value={allCampaigns} removableSort emptyMessage="No campaigns found."
        loading={isLoading} loadingIcon={<CustomLoader />} style={{ minHeight: 500 }}>
        <Column field="name" header="Campaign" body={campaignBodyTemplate} sortable style={{ width: '40%' }}></Column>
        <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ width: '15%' }}></Column>
        <Column field="targetMarket.title" header="Market" body={marketBodyTemplate} sortable style={{ width: '15%' }}></Column>
        <Column field="dates.endDate" header="Time Left" body={dateBodyTemplate} sortable style={{ width: '15%' }}></Column>
        <Column field="budget.amount" header="Price" body={priceBodyTemplate} sortable style={{ width: '15%' }}></Column>
      </DataTable>
      {!noResults && <Paginator first={filterOptions.skip} rows={PAGINATION_LIMIT} totalRecords={totalCount} onPageChange={onPageChange} style={{ background: '#130A23' }} />}

    </div>
  )
}
