import React, { useEffect, useState } from "react";
import "./NewEditBrandModal.css";
import NewImageCropper from "@/react_app/Other/NewImageCropper/NewImageCropper";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { MESSAGE_WRAPPER } from '@/utils/globals/constants';
import ImageUploader from '@/react_app/Other/ImageUploader/ImageUploader';
import noImage from "./../../../img/no-image-1-gray.png";

export default function (props) {
  const {
    updateBrand, brand, onHide, working, visible
  } = props;
  const [brandPhoto, setBrandPhoto] = useState('');
  const [openCropper, setOpenCropper] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ firstName: "", lastName: "", brandName: "" });
  const [userDetails, setUserDetails] = useState({ firstName: "", lastName: "", brandName: "", brandDescription: "" });

  useEffect(() => {
    setUserDetails({
      brandName: brand?.name,
      firstName: brand?.firstName,
      lastName: brand?.lastName,
      brandDescription: brand?.description
    })
    if (brand?.photo) {
      setBrandPhoto(brand.photo);
    }
    return () => {
      setErrorMessage({})
    }
  }, [props, brand, onHide]);


  const handleChange = (key, value) => {
    setUserDetails((prev) => ({ ...prev, [key]: value }));
    if (key !== 'brandDescription')
      setErrorMessage((prev) => ({ ...prev, [key]: (value) ? "" : MESSAGE_WRAPPER[key] }))
  }
  const validateForm = () => {
    const newErrors = { ...errorMessage };

    if (!userDetails?.brandName) {
      newErrors.brandName = MESSAGE_WRAPPER['brandName'];
    }

    if (!userDetails.firstName) {
      newErrors.firstName = MESSAGE_WRAPPER['firstName'];

    }

    if (!userDetails.lastName) {
      newErrors.lastName = MESSAGE_WRAPPER['lastName'];
    }

    setErrorMessage(newErrors);

    return userDetails.brandName && userDetails.firstName && userDetails.lastName
  }
  const handleSubmit = () => {
    const isValid = validateForm();

    if (isValid) {
      updateBrand({
        photo: brandPhoto, name: userDetails.brandName, description: userDetails.brandDescription,
        firstName: userDetails.firstName, lastName: userDetails.lastName
      }).then(onHide());
    }
  }


  useEffect(() => {
    if (!!userDetails) {
      validateForm()
    }
  }, [userDetails])

  const handleImageChange = (newImage) => {
    setBrandPhoto(newImage)
  }

  return (
    <Dialog className={'edit-brand-modal'} visible={visible} onHide={onHide}>
      <div className={'top-layer'}>
        <i className="pi pi-times" style={{ fontSize: '25px', color: "#fff", cursor: "pointer" }} onClick={() => {
          const allErrorsClear = Object.values(errorMessage).every(msg => msg === "");
          if (allErrorsClear) {
            onHide();
          }
        }} />
      </div>
      <div className={'content'}>
        <p className={'title'}>Brand Info</p>
        <div className={'content-main'}>
          <div className={'content-subgroup'}>
            <ImageUploader
              currentTab={1}
              coverImage={brandPhoto || noImage}
              onImageChange={handleImageChange}
              isCreatorProfile={true}
            />
          </div>
          {/* <div className={'content-subgroup'}>
          </div> */}
          <div>
            <p className={'label'}>Brand Name <span className="required">*</span></p>
            <InputText value={userDetails.brandName} onChange={(e) => handleChange('brandName', e.target.value)} className={'inputField'} placeholder={"Enter your brand's name"} />
            <div className={'registerError'} style={{ minHeight: 20 }}>{errorMessage.brandName && <p>{errorMessage.brandName}</p>}</div>
            <div className="first-last-wrap">
              <div className="input-wrap">
                <p className={'label'}>First Name <span className="required">*</span></p>
                <InputText value={userDetails.firstName} className={'inputField'} onChange={(e) => handleChange('firstName', e.target.value)} />
                <div className={'registerError'} style={{ minHeight: 20 }}>{errorMessage.firstName && <p>{errorMessage.firstName}</p>}</div>
              </div>
              <div className="input-wrap">
                <p className={'label'}>Last Name <span className="required">*</span></p>
                <InputText value={userDetails.lastName} className={'inputField'} onChange={(e) => handleChange('lastName', e.target.value)} />
                <div className={'registerError'} style={{ minHeight: 20 }}>{errorMessage.lastName && <p>{errorMessage.lastName}</p>}</div>
              </div>
            </div>
            <p className={'label'}>Brand Description</p>
            <InputTextarea value={userDetails.brandDescription} onChange={(e) => handleChange('brandDescription', e.target.value)} className={'inputField'} rows={6} maxLength={200} autoResize placeholder={"Enter your brand's description"} />
          </div>
        </div>
        <div className={'buttonLayer'}>
          <Button className={'saveButton'} loading={working} disabled={working} onClick={() => handleSubmit()}>Save Changes</Button>
        </div>
      </div>
      <NewImageCropper visible={openCropper} onHide={() => setOpenCropper(false)} image={brandPhoto} setCroppedPhoto={setBrandPhoto} />
    </Dialog>
  )
}
