
import { useRoute, useRouter } from 'vue-router';

import { defineComponent, ref, watch } from 'vue';
import { applyPureReactInVue } from 'veaury';

import { useContentItems } from '@/modules';
import VideoReason from '@/react_app/Other/VideoReason/VideoReason';

export default defineComponent({
  components: {
    VideoReason: applyPureReactInVue(VideoReason),
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const contentModule = useContentItems();

    const reason = ref();
    const contentId = ref(route.params.content);

    const goToCampaign = (id: string) => {
      router.push({ name: 'campaign-active', params: { campaignID: id } });
    };

    const fetchVideoReason = (id: string) => {
      contentModule.manager.getVideoReason(id).then((response) => {
        if (response) {
          reason.value =
            response.contentItem?.reasonReject ||
            response.contentItem?.approveReason;
        }
      });
    };

    if (contentId.value) {
      fetchVideoReason(contentId.value as string);
    }

    watch(
      () => route.params.content,
      (newContentId) => {
        if (newContentId) {
          contentId.value = newContentId;
          fetchVideoReason(newContentId as string);
        }
      }
    );

    return {
      reasonData: contentModule.manager.getVideoReasonResult,
      reason,
      goToCampaign,
    };
  }
});
