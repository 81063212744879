import React from 'react';
import { Image } from "primereact/image";

import youtube from "@/react_app/img/youtube.png";
import { formatSubsCount } from "@/utils/globals/curency-utils";

import "./NewInfluencerItem.css";
import 'primeicons/primeicons.css';

export default function (props) {

  const {
    userType, goToProfile, influencer, addToFavorites, favorites
  } = props;

  return (
    <div className={'latest-influencer'}>
      <div className={'profilePhotoWrapper'} onClick={() => goToProfile(influencer?.id)}>
        <Image src={influencer?.profile.photo} alt={'profile photo'} className={'profilePhoto'} />
      </div>
      <div className={'influencerInfo'}>
        <p className={'username'} onClick={() => goToProfile(influencer?.id)}>{influencer?.name}</p>
        {formatSubsCount(influencer?.youtube?.at(-1)?.followers || 0)} Subscribers
        <div className={'icons'}>
          <Image src={youtube} alt={'icon'} className={'icon'} title={'youtube'} />
        </div>
      </div>
      {userType === "brandOwner" && <div className={'favorites'} onClick={() => addToFavorites(influencer?.id)}>
        {favorites?.allUserFavorites.find((item) => item.influences?.id === influencer?.id) ?
          <i className="pi pi-star-fill starIcon" /> :
          <i className="pi pi-star starIcon" />
        }
      </div>}
    </div>
  )
}
