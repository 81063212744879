import React from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { CloseIcon } from '@/react_app/img/Icons';

import './Dialog.css';

const DeleteCampaign = (props) => {
    const { open, close,  deleteCampaign,loading } = props;
    return (
        <Dialog visible={open} onHide={() => close()} className='campaignDialog'>
            <div className='closeRow'>
                <div className='closeButton' onClick={() => close()}><CloseIcon /></div>
            </div>
            <div className='reasonContent'>
                <p className='reasonTitle'>DELETE/ARCHIVE CAMPAIGN</p>
                <p className='reasonText'>You are about to delete this campaign. If you delete it, the campaign will be permanently removed and cannot be recovered.</p>
                <p className='reasonText'>Alternatively, you can archive the campaign instead. Archiving will hide the campaign from view but allow you to restore it later.</p>
               
                <div className='reasonButtonGroup'>
                    <div>
                        <Button className='reasonButtonCancel' onClick={() => close()}>Cancel</Button>
                    </div>
                    <div className='actionButton'>
                        <Button className='reasonButtonArchive' loading={loading.archived} outlined onClick={() => deleteCampaign('archived')}>Archive</Button>
                        <Button className='reasonButtonSave' loading={loading.delete} onClick={() => deleteCampaign('delete')}>Delete</Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default DeleteCampaign
