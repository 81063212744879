import React, { useEffect, useState } from "react";
import "./NewSubmitDraft.css";
import {
  CloseIcon, CopyIconSmall, DesktopIcon, ExpandArrowDown, ExpandArrowUp, TrashIcon
} from "@/react_app/img/Icons";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useAuth, useContentItems, useToastModule } from "@/modules";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { formatDate } from "@/utils/globals/date-utils";
import UGCVideoDialog from '../components/Dialogs/UGCVideoDialog';
import noImage from "../../../src/react_app/img/no-image-1-gray.png";


const videoTemplate = (rowData) => {
  return (
    <div className={'videoTemplate'}>
      <Image src={rowData.thumbnails.default.url} alt={'video thumbnail'} className={'videoThumbnail'} />
      <p className={'videoTitle'}>{rowData.title}</p>
    </div>
  )
};

const urlTemplate = (rowData) => {
  return (
    <div className={'urlTemplate'}>
      <p className={'urlLink'}>{rowData?.url}</p>
      <div onClick={() => navigator.clipboard.writeText(rowData?.url)}>
        <CopyIconSmall />
      </div>
    </div>
  )
};

const statusTemplate = () => {
  return (
    <div className={'statusTemplate'}>
      Uploaded
    </div>
  )
};

const dateTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      {formatDate(rowData.createdAt || rowData?.aspectRatios[0]?.submittedAt)}
    </div>
  )
};

const deleteTemplate = () => {
  return (
    <div className={'deleteTemplate'}>
      <TrashIcon />
    </div>
  )
};

const conceptTemplate = (rowData) => {
  return (
    <div className={'conceptTitleWrap'}>
      <p className={'conceptTitle'} style={{ height: 'auto' }}>Concept</p>
      <p className={'conceptValue'} style={{ height: 'auto' }}>{rowData.concept}</p>
    </div>
  )
};

export default function (props) {
  const { isUGCCampaign, latestUGCSubmits, getUGCUploadedContents } = props;
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openUGCUploadDialog, setOpenUGCUploadDialog] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [isLinkUploading, setIsLinkUploading] = useState(false);
  const [showBadChannelError, setShowBadChannelError] = useState(false);
  const [videoInfoError, setNoVideoInfoError] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);


  const { user } = useAuth();
  const toast = useToastModule();
  const extractVideoIDs = () => {
    let IDs;
    if (youtubeLink?.includes("youtu.be")) {
      IDs = ["&id=" + youtubeLink?.split("/").filter(Boolean)[2]];
    } else {
      IDs = [`&id=${youtubeLink?.split("=")[1]}`];
    }
    return IDs;
  };

  const [progressCpmCampaign, setProgressCpmCampaign] = useState({
    videoDraft: false,
    draftAccepted: false,
    draftRejected: false,
    liveVideoPosted: false,
    rejectReason: "",
  });

  const [progressCpmCampaignChannels, setProgressCpmCampaignChannels] = useState([]);

  const contentItemsModule = useContentItems();

  const getProgressCpm = () => {
    if (user?.value && props.campaignID) {
      const calculatedChannelIDs = [];

      if (user?.value?.isAgencyOwner() && user?.value?.agencyWorkers?.length) {
        user?.value.agencyWorkers.map((worker) => {
          calculatedChannelIDs.push(worker?.channel?.channelId);
          return worker;
        });
      }

      contentItemsModule.manager.getStatusVideoCpm(user?.value?.id, props.campaignID, calculatedChannelIDs).then((data) => {
        if (user?.value?.isCreator()) {
          setProgressCpmCampaign({
            videoDraft: data.videoDraft,
            draftAccepted: data.draftAccepted,
            rejectReason: data.rejectReason,
            draftRejected: data.draftRejected,
            liveVideoPosted: data.liveVideoPosted,
          });
        }
        if (user?.value?.isAgencyOwner()) {
          setProgressCpmCampaignChannels(data);
        }
      });
    }
  };

  const submitUnlistedVideo = () => {
    setIsLinkUploading(true);
    const IDs = extractVideoIDs(youtubeLink);
    contentItemsModule.manager.createUnlistedContent(user?.value?.id, props.campaignID, IDs, props.selectedChannel)
      .then(async (data) => {
        if (data) {
          setProgressCpmCampaign(prevState => ({ ...prevState, videoDraft: true, draftRejected: false }));
          toast.showSuccess("Your Draft Video Uploaded Successfully!");
          setOpenUploadDialog(false);
          props?.setOpenCPMPendingDraftModal(false)
          if (!user?.value?.isAgencyOwner()) {
            props?.handleChangeTab(2, true);
          }
          setYoutubeLink('');
          props?.setSelectedChannel();
          props?.updateDataPage();
          await getProgressCpm();
          if (user?.value?.isAgencyOwner()) {
            props?.getAgencyProgress()
          }
        }
        setIsLinkUploading(false);
      }).catch(error => {
        setIsLinkUploading(false);
      })
  };

  const handleUploadUGCDraft = async (res) => {
    setProgressCpmCampaign(prevState => ({ ...prevState, videoDraft: true, draftRejected: false }));
    toast.showSuccess("Your Draft Video Uploaded Successfully!");
    setOpenUGCUploadDialog(false);
    props?.setOpenCPMPendingDraftModal(false)
    if (!user?.value?.isAgencyOwner()) {
      props?.handleChangeTab(2, true);
    }
    props?.updateDataPage();
    getUGCUploadedContents("draft");
    await getProgressCpm();
    if (user?.value?.isAgencyOwner()) {
      props?.getAgencyProgress()
    }
  }


  const handleOpenDialog = () => {
    if (isUGCCampaign) setOpenUGCUploadDialog(true)
    else setOpenUploadDialog(true);
  }

  useEffect(() => {
    if (props && props?.progressCpmCampaign) {
      setProgressCpmCampaign(prevState => ({ ...prevState, ...props?.progressCpmCampaign }))
    }
  }, [props])


  const expanderTemplate = (rowData) => {
    return (
      <div className='expanderTemplate'>
        {expandedRows.includes(rowData) ?
          <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  };
  const rowExpansionTemplate = (rowData) => {
    const showFileSizeInMB = (bytes) => (bytes / (1024 * 1024))?.toFixed(1);

    return (
      <>
        <div className='darkLine' />
        <div className='expansionWrapper ugcUploadedContent'>
          <div className='expansionDataSquare'>
            <div className='video-wrap header'>
              <div className='ratio'>
                <p className='headerTitle'>Aspect Ratio</p>
              </div>
              <div className={`video-wrapper`}>
                <p className='headerTitle'>
                  Video Info
                </p>
              </div>
              <div className='videoInfoDate'>
                <p className='headerTitle'>Submission Date</p>
              </div>
              <div className='videoInfo videoStatus'>
                <p className='headerTitle'>Status</p>
              </div>
            </div>
            {rowData?.aspectRatios?.length > 0 ? <div className='request-info type-name'>
              {rowData?.aspectRatios?.map((ratio) => {
                return (
                  <div key={ratio?.ratio} className='video-wrap'>
                    <p className='ratio'>{ratio?.ratio}</p>
                    <div className={`video-wrapper`}>
                      <div className='video-preview'>
                        <div
                        >
                          <Image
                            src={noImage}
                            alt='file preview'
                            style={{ display: 'none' }}
                          />
                          <video
                            src={`${ratio?.videoUrl}#t=5`}
                            controls={false}
                            onError={(e) => {
                              e.target.style.display = 'none';
                              const img = e.target.previousElementSibling;
                              if (img) {
                                img.style.display = 'block';
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='videoInfo'>
                        <div className={'urlTemplate'} style={{ marginBottom: '5px' }}>
                          <p className='file-name'>{ratio?.fileName || ""}</p>
                          <div onClick={() => navigator.clipboard.writeText(ratio?.videoUrl)}>
                            <CopyIconSmall />
                          </div>
                        </div >
                        <p className='file-size'>{showFileSizeInMB(ratio?.fileSize || 0)} MB</p>
                      </div>
                    </div>
                    <div className='videoInfoDate'>
                      <p className='ratio'>{ratio?.submittedAt || ""}</p>
                    </div>
                    <div className='videoInfo videoStatus'>
                      <p className={`file-status ${ratio?.status}`}>{ratio?.status}</p>
                    </div>
                  </div>
                )
              })}
            </div> :
              <p className='noUploadsFound'>No uploads found</p>
            }
          </div >
        </div >
      </>
    );
  };

  return (
    <div className={props.displayNone ? 'submit-draft-content display-none' : 'submit-draft-content'}>
      {props.selectedChannel && <p className={'channelName'}>Selected channel: {props.selectedChannel.channel.title}</p>}
      <div className={'topRow'}>
        <div className={'uploadSquare'}>
          <DesktopIcon />
          <p className={'uploadTitle'}>Upload Draft</p>
          <p className={'uploadText'}>Upload the relevant unlisted video from your youtube channel</p>
          <Button className={'uploadButton'}
            disabled={isLinkUploading || progressCpmCampaign.liveVideoPosted || progressCpmCampaign.draftAccepted}
            onClick={() => handleOpenDialog()}>Upload Draft</Button>
          <a className={'tutorialLink'} href={'https://www.youtube.com/watch?v=S6v1QZ675AI'} target={'_blank'}>Watch a step-by-step tutorial</a>
        </div>
        <div className={'linkAndGamekeys'}>
          <p className={'title'}>Your Process for Content Submission</p>
          <ol className='process-steps'>
            <li>{`${isUGCCampaign ? "Upload a URL to your draft video on Google Drive." : "Upload the relevant unlisted video from your youtube channel."}`}</li>
            <li>Send your draft for an approval.</li>
            <li>Wait for our feedback.</li>
            <li>{`Once your draft is approved, ${isUGCCampaign ? 'upload the final video' : 'publish the video and update the URL'}.`}</li>
            <li>Done! Enjoy the result.</li>
          </ol>
        </div>
      </div>
      {!isUGCCampaign && <div>
        <p className={'uploadedContentTitle'}>Uploaded Content</p>
        <DataTable value={props?.latestSubmits}>
          <Column field="title" header="Video" body={videoTemplate} style={{ width: '45%' }}></Column>
          <Column field="url" header="Video Link" body={urlTemplate} style={{ width: '28%' }}></Column>
          <Column field="" header="Status" body={statusTemplate} style={{ width: '14%' }}></Column>
          <Column field="createdAt" header="Submission Date" body={dateTemplate} style={{ width: '14%' }}></Column>
          <Column field="" header="" body={deleteTemplate} style={{ width: '9%' }}></Column>
        </DataTable>
        <div className={'buttonGroup'}>
          <Button className={'leaveButton'} onClick={() => props.leaveCampaign()}>Leave Campaign</Button>
        </div>
      </div>}
      {isUGCCampaign && <div>
        <p className={'uploadedContentTitle'}>Uploaded Content</p>
        <DataTable value={latestUGCSubmits}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column field="concept" header="Concept" body={conceptTemplate} style={{ width: '95%', textAlign: 'left' }} align={'left'}></Column>
          <Column header="Action" expander={true} body={expanderTemplate} style={{ width: '5%' }} />
        </DataTable>
        <div className={'buttonGroup'}>
          <Button className={'leaveButton'} onClick={() => props.leaveCampaign()}>Leave Campaign</Button>
        </div>
      </div>}

      <Dialog visible={openUploadDialog || props.openSubmit} onHide={() => { setOpenUploadDialog(false); props.setOpenSubmit(false); }} className={'uploadDialog'}>
        <div className={'closeWrapper'}>
          <div onClick={() => { setOpenUploadDialog(false); props.setOpenSubmit(false); }}><CloseIcon /></div>
        </div>
        <div className={'uploadDialogContent'}>
          <p className={'uploadDialogTitle'}>Upload draft video</p>
          <p className={'youtubeLinkTitle'}>Your unlisted youtube link</p>
          <InputText className={'youtubeLinkInput'} placeholder={'Add a link to your draft YouTube video'} value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)} />
          <p className={'exampleLink'}>Example: https://www.youtube.com/watch?v=example/</p>
          {showBadChannelError &&
            <p className={'linkError'}>
              The video you are trying to download was uploaded by a YouTube channel that is not added to your profile.
              Please add your YouTube channel to your profile first.
            </p>
          }
          {videoInfoError &&
            <p className={'linkError'}>
              This video and the data from it are not available.
            </p>
          }
          <Button className={'uploadButton'} disabled={isLinkUploading || progressCpmCampaign.liveVideoPosted || progressCpmCampaign.draftAccepted} onClick={() => submitUnlistedVideo()} loading={isLinkUploading}>Upload draft video</Button>
          <p className={'warningText'}>By submitting your content to Gameinfluencer, you acknowledge that you agree to Gameinfluencer’s terms and conditions. please be sure not to violate others copyright or privacy rights. Learn more.</p>
        </div>
      </Dialog>
      <Dialog visible={props.openCPMPendingDraftModal} onHide={() => props.setOpenCPMPendingDraftModal(false)} className={'uploadDialog'}>
        <div className={'closeWrapper'}>
          <div onClick={() => props.setOpenCPMPendingDraftModal(false)}><CloseIcon /></div>
        </div>
        <div className={'uploadDialogContent'}>
          <p className={'uploadDialogTitle'}>Upload draft video</p>
          {/* {progressCpmCampaign?.rejectReason &&
            <p className={'uploadDialogSubTitle redText'}>You last draft video rejected with reason:{progressCpmCampaign?.rejectReason}</p>}
          <p className={'uploadDialogSubTitle'}>
            All CPM videos must be submitted as an unlisted YouTube video to be reviewed.
            Once they are accepted you will be notified and will be good to post your live YouTube
            video and submit it here.
          </p> */}
          <p className={'youtubeLinkTitle'}>Your unlisted youtube link</p>
          <InputText className={'youtubeLinkInput'} placeholder={'Add a link to your draft YouTube video'} value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)} />
          <p className={'exampleLink'}>Example: https://www.youtube.com/watch?v=example/</p>
          {showBadChannelError &&
            <p className={'linkError'}>
              The video you are trying to download was uploaded by a YouTube channel that is not added to your profile.
              Please add your YouTube channel to your profile first.
            </p>
          }
          {videoInfoError &&
            <p className={'linkError'}>
              This video and the data from it are not available.
            </p>
          }
          <Button className={'uploadButton'} disabled={isLinkUploading || progressCpmCampaign.liveVideoPosted || progressCpmCampaign.draftAccepted} onClick={() => submitUnlistedVideo()} loading={isLinkUploading}>Upload draft video</Button>
          <p className={'warningText'}>By submitting your content to Gameinfluencer, you acknowledge that you agree to Gameinfluencer’s terms and conditions. please be sure not to violate others copyright or privacy rights. Learn more.</p>
        </div>
      </Dialog>
      {isUGCCampaign && <UGCVideoDialog
        open={user?.value?.isAgencyOwner() ? props.openCPMPendingUGCDraftModal : openUGCUploadDialog}
        close={() => user?.value?.isAgencyOwner() ? props.setOpenCPMPendingUGCDraftModal(false) : setOpenUGCUploadDialog(false)} conceptCount={props?.conceptCount}
        videoAspectRatio={props?.videoAspectRatio}
        currentType="draft"
        campaignId={props?.campaignID}
        handleUpload={handleUploadUGCDraft}
        latestUGCSubmits={latestUGCSubmits}
        selectedChannel={props.selectedChannel} />}
    </div >
  )
}
