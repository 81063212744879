
import { useCampaigns } from '@/modules';
import { defineComponent, onMounted, ref } from 'vue';
import { applyPureReactInVue } from 'veaury';
import NewCampaigns from '@/react_app/NewCampaigns/NewCampaigns';
import OldCampaigns from './OldCampaigns.vue';
import { isNewCampaignsPageEnabled } from '@/utils/globals/feature-flags';
import router from '@/router';
import { AppPageProps, CampaignList, Perspective } from '@/data/types';

export default defineComponent({
  name: 'Campaigns',
  components: {
    NewCampaigns: applyPureReactInVue(NewCampaigns),
    OldCampaigns,
  },
  props: {
    brandOwnerView: Boolean,
    showSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 8,
    },
    ...AppPageProps,
  },

  setup(props) {
    const skip = ref(0);
    const searchValue = ref('');
    const campaigns = ref();
    const totalCount = ref(0);
    const isLoading = ref(true);
    const campaign = useCampaigns();

    const getCampaigns = (
      skip: number,
      search?: string,
      status?: string,
      ageLimit?: boolean,
      category?: string,
      platform?: string,
      language?: string,
      region?: string
    ) => {
      isLoading.value = true;
      campaign.list
        .getCampaignList(
          false,
          props.limit,
          skip,
          search,
          status,
          undefined,
          category,
          platform,
          language,
          region
        )
        .then((data?: CampaignList) => {
          campaigns.value = data?.campaigns;
          totalCount.value = data?.pagination || 0;
          isLoading.value = false;
        });
    };
    onMounted(() => {
      // getCampaigns(skip.value, '');
    });

    const goToCampaign = (itemId: any) => {
      if (
        props.viewPerspective === Perspective.CREATOR ||
        props.viewPerspective === Perspective.AGENCY
      ) {
        router.push({
          name: 'campaign-active',
          params: { campaignID: itemId },
        });
      } else if (props.viewPerspective === Perspective.BRAND) {
        router.push({ name: 'campaign', params: { campaignID: itemId } });
      }
    };
    const handlePageChange = (page: number) => {
      skip.value = page;
      // getCampaigns(page, '');
    };
    const handleSearch = (search: string) => {
      searchValue.value = search;
      // getCampaigns(skip.value, search);
    };
    return {
      campaigns,
      isNewCampaignsPageEnabled,
      goToCampaign,
      isLoading,
      totalCount,
      handlePageChange,
      handleSearch,
      getCampaigns
    };
  },
});
