
import { defineComponent, ref, watch } from 'vue';
import {
  CampaignData,
  CampaignPaymentMethod,
  CampaignsEarningsExpenses,
} from '@/data/types';
import { calculateCpiRate } from '@/utils/globals/cpi-rate';
import { useAdmins, useCampaigns, useTransfers, useCreators } from '@/modules';
import { compactNumber } from '@/utils/globals/curency-utils';
import { formatDate } from '@/utils/globals/date-utils';
import { TrackingLink, User } from '@/data/types';
import { applyPureReactInVue } from 'veaury';
import NewImportantStats from '@/react_app/NewAdmin/NewImportantStats/NewImportantStats';
import OldImportantStats from '@/pages/admin/OldImportantStats.vue';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';
import router from '@/router';

interface AppliedCreator {
  email: string;
  name: string;
  photo: string;
}

export default defineComponent({
  components: {
    NewImportantStats: applyPureReactInVue(NewImportantStats),
    OldImportantStats,
  },
  setup() {
    const adminsModule = useAdmins();
    const transfersModule = useTransfers();
    const { manager } = useCreators();
    const campaigns = useCampaigns();
    adminsModule.manager.checkAdmin();

    const selectedAll = ref(false);
    const openBasic = ref(false);
    const selectedCampaignForActions = ref();
    const newCampaignStatus = ref();
    const newBudgetAmount = ref();
    const newBudgetCurrency = ref(
      selectedCampaignForActions.value?.budget?.currency
    );
    const showMonthly = ref('');
    const showAppliedCreatorsForSelectedCampaign = ref(false);
    const apiCallFinished = ref(false);
    const appliedCreatorsForSelectedCampaign = ref([] as AppliedCreator[]);
    const campaignExpenses = ref([]);
    const campaignExpensesLoaded = ref(false);
    const loading = ref(true);
    const limitCampaigns = ref(10);
    const skipCampaigns = ref(0);
    const priorityCampaignStatus = ref('active');
    const searchValueCampaign = ref('');
    const adminStatistics = ref('');

    const statuses = ref([
      {
        value: 'all',
        selected: false,
      },
      {
        value: 'active',
        selected: true,
      },
      {
        value: 'draft',
        selected: false,
      },
      {
        value: 'pending',
        selected: false,
      },
      {
        value: 'completed',
        selected: false,
      },
      {
        value: 'live',
        selected: false,
      },
    ]);

    const changeStatus = ref([
      {
        status: 'active',
      },
      {
        status: 'draft',
      },
      {
        status: 'pending',
      },
      {
        status: 'completed',
      },
      {
        status: 'live',
      },
    ]);

    const updateCampaignData = async (
      campaign: any,
      status: string,
      budget: number
    ) => {
      await adminsModule.manager
        .updateCampaignData(campaign.id || campaign._id, {
          status: status || campaign.status,
          budget: {
            amount: budget || campaign.budget?.amount,
            currency: campaign.budget?.currency,
          },
        })
        .then((res) => {
          if (res) {
            getCampaigns(
              limitCampaigns.value,
              skipCampaigns.value,
              priorityCampaignStatus.value,
              searchValueCampaign.value
            );
          }
        });
    };

    // Inside the campaigns there are tracking links. The tracking links store the user id only
    // Make an API call to fetch the necessary information to display user information.
    const parseAppliedCreators = async (
      campaignTrackingLinks: TrackingLink[]
    ) => {
      const creatorInfos: AppliedCreator[] = [];

      for await (const link of campaignTrackingLinks) {
        const { creatorId } = link;
        const user = (await manager.load(creatorId)) as User;

        creatorInfos.push({
          email: user.email,
          name: user.name,
          photo: user.profile.photo,
        } as AppliedCreator);
      }

      appliedCreatorsForSelectedCampaign.value = creatorInfos;
      apiCallFinished.value = true;
      return creatorInfos;
    };

    const getCampaigns = (
      limit: number,
      skip: number,
      status: string,
      search: string
    ) => {
      loading.value = true;
      adminsModule.manager
        .getAllCampaigns(limit, skip, status, search)
        .then(() => {
          loading.value = false;
        });
    };
    const getStatistics = () => {
      loading.value = true;
      adminsModule.manager.getAdminStatistics().then((res) => {
        if (res) adminStatistics.value = res;
      });
    };

    watch(priorityCampaignStatus, () => {
      skipCampaigns.value = 0;
      getCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValueCampaign.value
      );
    });
    if (isNewAdminEnabled) {
      skipCampaigns.value = 0;
      getCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValueCampaign.value
      );
      getStatistics();
    }

    const searchCampaigns = (searchValue: string) => {
      skipCampaigns.value = 0;
      getCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValue
      );
    };
    const onPage = (event: {
      page: number;
      first: number;
      rows: number;
      pageCount: number;
    }) => {
      skipCampaigns.value = event.first;
      getCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValueCampaign.value
      );
    };
    const addPlatform = (platform: any) => {
      priorityCampaignStatus.value = platform;
      statuses.value.forEach((el) => {
        el.selected = el.value === platform;
      });
      selectedAll.value = platform === 'all';
    };

    watch(adminsModule.manager.getAllCampaignsResult, async () => {
      const campaignIDs =
        await adminsModule.manager.getAllCampaignsResult.value?.campaigns?.map(
          (camp: CampaignData | any) => camp?._id
        );
      if (campaignIDs) {
        await getOverallTransfers(campaignIDs);
        const appliedStatusFilter = statuses.value.find(
          (currentStatus) => currentStatus.selected
        );
        const campaignsExpensesResponse =
          await adminsModule.manager.getMultipleCampaignExpenses(
            limitCampaigns.value,
            skipCampaigns.value,
            appliedStatusFilter!.value,
            false
          );
        campaignExpenses.value = campaignsExpensesResponse;
        campaignExpensesLoaded.value = true;
      }
    });

    const getOverallTransfers = async (campaignIDs: Array<string>) => {
      await transfersModule.list.getOverallByCampaigns(campaignIDs);
    };
    const getCurrentCampaignExpenses = (campaignId: string) => {
      const expenses = campaignExpenses.value.filter(
        (currentCampaignExpenses: any) => {
          return (
            currentCampaignExpenses?.id === campaignId.toString() ||
            currentCampaignExpenses?._id === campaignId.toString()
          );
        }
      ) as CampaignsEarningsExpenses[];

      return expenses.length > 0 ? expenses[0].totalAmount : 0;
    };
    const appliedCreatorsOnClose = () => {
      showAppliedCreatorsForSelectedCampaign.value = false;
      apiCallFinished.value = false;
      appliedCreatorsForSelectedCampaign.value = [];
    };
    const saveTrackingLink = async (campaign: any, trackingLink: string) => {
      await campaigns.manager.editTrackingLink(
        campaign?.id || campaign?._id,
        trackingLink,
        false
      );
      await campaigns.manager.loadSingle(
        campaign?.id || (campaign?._id as string)
      );
    };
    const seeCampaignDetails = (campaign: any) => {
      router.push({
        name: 'campaign',
        params: { campaignID: campaign._id || campaign.id },
      });
    };
    const handleCampaignDelete = () => {
      getCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValueCampaign.value
      );
    };

    const handleCampaign = (campaignID: string) => {
        router.push({
          name: 'campaigns-stats',
          params: { campaignID },
          query:{isAdminEdit:'true'}
        });
    };


    return {
      apiCallFinished,
      appliedCreatorsOnClose,
      appliedCreatorsForSelectedCampaign,
      parseAppliedCreators,
      showAppliedCreatorsForSelectedCampaign,
      searchCampaigns,
      onPage,
      updateCampaignData,
      compactNumber,
      formatDate,
      addPlatform,
      calculateCpiRate,
      showMonthly,
      creatorsCounts: adminsModule.manager.getCreatorsCountResult,
      brandsCount: adminsModule.manager.getBrandsCountResult,
      adminsCount: adminsModule.manager.getAdminsCountResult,
      campaignsList: adminsModule.manager.getAllCampaignsResult,
      transfersByCampaigns: transfersModule.list.getOverallByCampaignsResult,
      getCurrentCampaignExpenses,
      campaignExpensesLoaded,
      campaignExpenses,
      statuses,
      priorityCampaignStatus,
      searchValueCampaign,
      limitCampaigns,
      openBasic,
      selectedCampaignForActions,
      changeStatus,
      newCampaignStatus,
      newBudgetAmount,
      newBudgetCurrency,
      selectedAll,
      CampaignPaymentMethod,
      defaultImage: require('../campaigns/assets/images/default.png'),
      isNewAdminEnabled,
      saveTrackingLink,
      seeCampaignDetails,
      loading,
      adminStatistics,
      handleCampaignDelete,
      handleCampaign
    };
  },
});
