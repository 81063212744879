import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective, User, UserData } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useUserManager = () => {
  const toasts = useToastModule();

  // load my profile
  const {
    loading: loadingMyProfile,
    error: loadingMyProfileError,
    execute: executeLoadMyProfile,
    data: myProfile,
  } = useAPI<User>('/users/me', false);

  const loadMyProfile = (accessToken?: string) => {
    const headers: any = {};
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    return executeLoadMyProfile({
      headers,
      parseResponse: (raw: UserData) => new User(raw),
    });
  };
  watch(loadingMyProfileError, toasts.handleRequestError);
  // < load my profile

  // verify
  const {
    loading: verifying,
    error: verificationError,
    data: verificationSuccess,
    execute: sendVerify,
  } = useAPI<User>('/users', false);

  const verify = (perspective: AppPagePerspective, payload: Record<string, any>) => {
    return sendVerify({
      method: 'PUT',
      data: {
        action: 'verify',
        perspective,
        ...payload,
      },
      parseResponse: (userData: UserData) => new User(userData),
    })
  };
  watch(verificationError, toasts.handleRequestError);
  // < verify

  // email user change. 
  //TO DO: Make these API calls more generic
  const {
    loading,
    error: emailChangeError,
    data: emailChangeSuccess,
    execute: executeEmailChange,
  } = useAPI<User>('/users', false);

  const changeEmail = (email: string) => executeEmailChange({
    method: 'PUT',
    data: {
      action: 'updateEmail',
      email: email,
    },
    parseResponse: (userData: UserData) => new User(userData),
  });

  // signin
  const {
    loading: signingIn,
    error: signinError,
    data: signinSuccess,
    execute: sendSingIn,
  } = useAPI<{ tokens: any }>('/auth/login', false);

  const signin = (email: string, password: string, userType: 'user' | 'admin', perspective: string) => sendSingIn({
    method: 'POST',
    data: { email, password, perspective, userType },
  });
  watch(signinError, toasts.handleRequestError);
  // < sign in

  // sign up
  const {
    loading: signingUn,
    error: signUpError,
    data: signUpSuccess,
    execute: sendSignUpn,
  } = useAPI<{ tokens: any }>('/auth/registration', false);

  const signUp = (email: string, firstName: string, lastName: string, password: string, perspective: string, verificationCode: string, name?: string) => sendSignUpn({
    method: 'POST',
    data: {
      email,
      firstName,
      lastName,
      password,
      perspective,
      verificationCode,
      name,
    },
  });
  watch(signUpError, toasts.handleRequestError);
  // < sign up

  // refresh
  const {
    loading: getRefreshLoading,
    error: getRefreshError,
    data: getRefreshData,
    execute: getRefreshReq,
  } = useAPI<{ tokens: any }>('/auth/refresh', false);

  const getRefresh = (refresh: string) => getRefreshReq({
    method: 'POST',
    data: { refresh },
  });

  // virify
  const {
    loading: verificationEmailLoading,
    error: verificationEmailError,
    data: verificationEmailData,
    execute: verificationEmailReq,
  } = useAPI('/users/verify-email', false);

  const verificationEmail = (email: string) => verificationEmailReq({
    method: 'POST',
    data: { email },
  });
  watch(verificationEmailError, toasts.handleRequestError);

  // Get specific campaign application or all applications
  const {
    loading: gettingUserAppliedCampaigns,
    error: getUserAppliedCampaignsError,
    data: getUserAppliedCampaignsResult,
    execute: sendGetUserAppliedCampaignsRequest,
  } = useAPI("", false);

  const getUserAppliedCampaigns = (creatorID: string, campaignID?: string, applicationsOnly = false) =>
    sendGetUserAppliedCampaignsRequest({
      url: `/users/${creatorID}/applied${campaignID ? `/${campaignID}` : ""}`,
      method: "GET",
      params: { applicationsOnly },
    });
  watch(getUserAppliedCampaignsError, toasts.handleRequestError);

  const {
    loading: registerNewsletterLoading,
    error: registerNewsletterError,
    data: registerNewsletterSuccess,
    execute: registerNewsletterRequest,
  } = useAPI('/auth/newsletter', false);

  const registerNewsletter = (email: string) => registerNewsletterRequest({
    method: 'POST',
    data: { email },
  });
  watch(registerNewsletterError, toasts.handleRequestError);

  return {
    // Get user campaign applications
    gettingUserAppliedCampaigns,
    getUserAppliedCampaignsError,
    getUserAppliedCampaignsResult,
    getUserAppliedCampaigns,
    // virify email
    verificationEmail,
    verificationEmailData,
    verificationEmailError,
    verificationEmailLoading,
    // refresh
    getRefresh,
    getRefreshData,
    getRefreshError,
    getRefreshLoading,
    // load my profile
    loadMyProfile,
    loadingMyProfile,
    loadingMyProfileError,
    myProfile,
    // verification
    verify,
    verifying,
    verificationError,
    verificationSuccess,
    // email change
    changeEmail,
    // signin
    signin,
    signingIn,
    signinError,
    signinSuccess,
    // signUp
    signUp,
    signUpSuccess,
    signUpError,
    signingUn,

    //newsletter

    registerNewsletter,
    registerNewsletterError,
    registerNewsletterSuccess,
    registerNewsletterLoading
  };
};
