import React, { useEffect, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';

import { useAuth, useCommonApi } from '@/modules';

import Skeleton from 'react-loading-skeleton';

import CampaignFilterMenu from '@/react_app/Other/CampaignFilterMenu/CampaignFilterMenu';
import NewActiveCampaignItem from "@/react_app/NewLatestItems/NewActiveCampaignItem/NewActiveCampaignItem";

import "./NewCampaigns.css";
import 'react-loading-skeleton/dist/skeleton.css';
import 'primeicons/primeicons.css';

export default function (props) {
  const { user: { value: creator } } = useAuth();
  const { manager: { getMasterData } } = useCommonApi();

  const {
    totalCount, limit, isLoading, campaigns, getCampaigns
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [time, setTime] = useState("");
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [languageList, setLanguageList] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [influencerCategoryOptions, setInfluencerCategoryOptions] = useState([]);
  const [ageLimitOptions, setAgeLimitOptions] = useState([{ name: '16 +', value: '1', id: '1' }]);

  const [extendedCategories, setExtendedCategories] = useState({
    platform: true,
    region: false,
    genre: false,
    agelimit: false,
    language: false,
  });

  const [campaignFilters, setCampaignFilters] = useState({
    platform: [],
    region: [],
    genre: [],
    agelimit: [],
    language: [],
  });

  useEffect(() => {
    setNoResults(!(campaigns?.length))
  }, [campaigns]);

  const onPageChange = (event) => {
    setFirst(event.first);
    getCampaigns(
      event.first,
      searchValue,
      'all',
      campaignFilters?.agelimit.length > 0,
      campaignFilters?.genre?.map(item => item.id)?.join(','),
      campaignFilters?.platform?.map(item => item.id)?.join(','),
      campaignFilters?.language?.map(item => item.id)?.join(','),
      campaignFilters?.region?.map(item => item.id)?.join(',')
    )
  };

  const handleSearch = (value) => {
    clearTimeout(time);
    setSearchValue(value);
    setTime(
      setTimeout(() => {
        setFirst(0);
        getCampaigns(
          0,
          value,
          'all',
          campaignFilters?.agelimit.length > 0,
          campaignFilters?.genre?.map(item => item.id)?.join(','),
          campaignFilters?.platform?.map(item => item.id)?.join(','),
          campaignFilters?.language?.map(item => item.id)?.join(','),
          campaignFilters?.region?.map(item => item.id)?.join(',')
        )
      }, 800)
    );
  }

  useEffect(() => {
    if (platformOptions.length <= 0) {
      getOptionsList();
    }
  }, [])

  useEffect(() => {
    if (campaignFilters) {
      setFirst(0);
      getCampaigns(
        0,
        searchValue,
        'all',
        campaignFilters?.agelimit.length > 0,
        campaignFilters?.genre?.map(item => item.id)?.join(','),
        campaignFilters?.platform?.map(item => item.id)?.join(','),
        campaignFilters?.language?.map(item => item.id)?.join(','),
        campaignFilters?.region?.map(item => item.id)?.join(',')
      )
    }
  }, [campaignFilters])

  const getOptionsList = () => {
    setIsLoadingOptions(true);
    getMasterData('').then((res) => {
      if (res) {
        setIsLoadingOptions(false);
        setLanguageList(res.data.languages.map(language => {
          return {
            value: language.id, name: language.name, id: language.id, code: language.code, isActive: language.isActive
          }
        }));
        setPlatformOptions(res.data.platforms.map(platform => {
          return {
            platform: platform.name, value: [""], name: platform.name, platformId: platform.id, id: platform.id, isActive: platform.isActive
          }
        }));
        setCategoryOptions(res.data.categories
          .map(category => {
            return {
              value: category.id, name: category.name, id: category.id, isActive: category.isActive
            }
          }));
        setCountryOptions(res.data.countries.map(country => {
          return {
            value: country.id, name: country.name, id: country.id, code: country.code
          }
        }));
        setInfluencerCategoryOptions(res.data.categories
          .filter(category => {
            const matchingCategories = (creator?.categories || []).find(
              (response) => {
                return response === category.id
              }
            );
            return matchingCategories;
          }));
      } else {
        setLanguageList([]);
        setPlatformOptions([]);
        setCategoryOptions([]);
      }
    }).catch(error => {
      setIsLoadingOptions(false);
    });
  }

  const toggleExtended = (category) => {
    setExtendedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleCheckboxChange = (item, category) => {
    setCampaignFilters((prev) => {
      const currentItems = prev[category];
      const updatedItems = currentItems?.includes(item)
        ? currentItems?.filter((i) => i !== item)
        : [...currentItems, item];
      return { ...prev, [category]: updatedItems };
    });
  };


  return (
    <div className={'campaigns'}>
      <div className={'titleRow'}>
        <p className={'title'}>Campaigns {`${!isLoading ? `(${props.totalCount || 0})` : 0}`}</p>
      </div>
      <div className={'content'}>
        <CampaignFilterMenu
          // platformOptions={platformOptions}
          platformOptions={[]}
          // regionsCategories={regionsCategories}
          // influencerCategoryOptions={categoryOptions}
          languageOptions={languageList}
          // ageLimitOptions={ageLimitOptions}
          extendedCategories={extendedCategories}
          toggleExtended={toggleExtended}
          campaignFilters={campaignFilters}
          handleCheckboxChange={handleCheckboxChange}
          showDefaultFilter={true}
        />
        <div className={'campaignsResult'}>
          <div className={'searchBar'}>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText placeholder="Search for campaigns" onChange={(e) => handleSearch(e.target.value)} value={searchValue} />
            </span>
            {!noResults &&
              <Paginator first={first} rows={limit} totalRecords={totalCount} onPageChange={onPageChange} template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }} />
            }
          </div>
          {isLoading ?
            <div className={'campaignsGrid'}>
              {[0, 1, 2, 3, 4, 5, 6, 7].map((campaign, index) =>
                <div key={index} className={'campaignItem'}>
                  <Skeleton count={1} />
                </div>)}
            </div>
            :
            <div>
              <div className={'campaignsGrid'}>
                {campaigns?.map((campaign, index) =>
                  <div key={index} className={'campaignItem'}>
                    <NewActiveCampaignItem campaign={campaign} goToCampaign={props.goToCampaign} userType={creator.type} />
                  </div>)}
                {noResults &&
                  <p className={'noResult'}>There are no campaigns matching the current search.</p>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
