import React, { useEffect, useState } from 'react';

import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from 'primereact/column';
import { Divider } from "primereact/divider";
import { DataTable } from 'primereact/datatable';
import { InputText } from "primereact/inputtext";

import { getRegion } from '@/utils/campaign-utils';
import { formatDate } from '@/utils/globals/date-utils';
import { calculateCpiRate } from "@/utils/globals/cpi-rate";

import Terms from '../components/Terms/Terms';
import TextWithLinks from '../components/TextWithLink';
import NewLiveVideo from "@/react_app/NewLiveVideo/NewLiveVideo";
import NewSubmitDraft from "@/react_app/NewSubmitDraft/NewSubmitDraft";
import CampaignApplication from '../components/Dialogs/CampaignApplication';
import NewDraftApproval from "@/react_app/NewDraftApproval/NewDraftApproval";
import NewSubmitContent from "@/react_app/NewSubmitContent/NewSubmitContent";
import NewContentListItem from "../NewLatestItems/NewContentListItem/NewContentListItem";
import AttachmentsDisplay from '@/react_app/Other/AttachmentsDisplay/AttachmentsDisplay';
import NewGenericPrompt from "@/react_app/NewCampaignActivePage/NewGenericPrompt/NewGenericPrompt";

import { CampaignPaymentMethod, CampaignStatus } from "@/data/types";

import { useUgcManager } from '@/modules/ugc/ugc-manager-module';
import { useBrands, useToastModule, useContentItems, useAuth } from "@/modules";
import { useCampaignContracts } from "@/modules/campaigns/campaign-contracts-module";

import {
  AndroidIcon, AppleIcon,
  AppStoreIconDefault, CheckMark,
  GooglePlayIconDefault, WebPageIconDefault,
  BackIcon, CloseIcon, RedTriangle, CopyIcon,
  SelectedChannelIcon,
  SelectChannelIcon,
  LinkIcon,
  CopyIconSmall,
  TrashIcon, UGCConceptIcon,
  UGCFormatIcon,
  UGCVideoAspectRatioIcon,
  UGCVideoLengthIcon,
  UGCVideoNoOfCreatorsIcon,
  ExpandArrowUp,
  ExpandArrowDown,
} from "@/react_app/img/Icons";

import noImage from "../img/no-image.png";


import "./NewCampaignActivePage.css";

const TabStep = {
  CampaignAccepted: 1,
  SubmitContent: 2,
  LiveVideoPosted: 3,
}

const tabItems = [
  { index: TabStep.CampaignAccepted, name: 'Campaign Accepted' },
  { index: TabStep.SubmitContent, name: 'Submit Content' },
  { index: TabStep.LiveVideoPosted, name: 'Live Video Posted' },
]

const TabStepCPM = {
  CampaignAccepted: 1,
  SubmitDraft: 2,
  DraftApproval: 3,
  SubmitContent: 4,
  LiveVideoPosted: 5,
}

const tabItemsCPM = [
  { index: TabStepCPM.CampaignAccepted, name: 'Campaign Accepted' },
  { index: TabStepCPM.SubmitDraft, name: 'Upload Draft' },
  { index: TabStepCPM.DraftApproval, name: 'Draft Approval' },
  { index: TabStepCPM.SubmitContent, name: 'Submit Content' },
  { index: TabStepCPM.LiveVideoPosted, name: 'Live Video Posted' },
]

export function OtherUrlIcon({ type }) {
  return (
    <div>
      {type === "Webpage" && <WebPageIconDefault />}
      {type === "Google" && <GooglePlayIconDefault />}
      {type === "Apple" && <AppStoreIconDefault />}
    </div>
  )
}

const getUrlName = (link) => {
  if (link.type === "Google") {
    return 'Google Play Link';
  } else if (link.type === "Apple") {
    return 'Apple App Store Link';
  }
  return 'Product Website Link';
}

const FavoriteIconActive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
      {/* eslint-disable-next-line max-len,vue/max-len */}
      <path d="M12.2538 18.3292L12 18.1793L11.746 18.3288L5.92615 21.7527L5.92526 21.7532C5.21304 22.1742 4.40529 21.5396 4.57656 20.8203L6.11911 14.3563L6.19048 14.0572L5.95574 13.8586L0.809056 9.50343L0.808879 9.50328C0.224393 9.00915 0.51753 8.04957 1.32415 7.98691L1.32415 7.98691L1.32673 7.9867L8.10017 7.42519L8.39813 7.40048L8.51737 7.1263L11.1679 1.03183L11.1687 1.02987C11.4721 0.323948 12.5269 0.323948 12.8303 1.02987L12.831 1.03146L15.4815 7.13962L15.6006 7.41416L15.8989 7.43888L22.6723 8.0004L22.6749 8.0006C23.4815 8.06326 23.7746 9.02284 23.1901 9.51697L23.19 9.51712L18.0433 13.8723L17.8085 14.0709L17.8799 14.37L19.4225 20.834C19.5937 21.5532 18.786 22.1879 18.0738 21.7669L18.0736 21.7668L12.2538 18.3292Z" fill="#E73D38" stroke="white" />
    </svg>
  )
}

const FavoriteIconInactive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
      {/* eslint-disable-next-line max-len,vue/max-len */}
      <path d="M12.2538 18.3292L12 18.1793L11.746 18.3288L5.92615 21.7527L5.92526 21.7532C5.21304 22.1742 4.40529 21.5396 4.57656 20.8203L6.11911 14.3563L6.19048 14.0572L5.95574 13.8586L0.809056 9.50343L0.808879 9.50328C0.224393 9.00915 0.51753 8.04957 1.32415 7.98691L1.32415 7.98691L1.32673 7.9867L8.10017 7.42519L8.39813 7.40048L8.51737 7.1263L11.1679 1.03183L11.1687 1.02987C11.4721 0.323948 12.5269 0.323948 12.8303 1.02987L12.831 1.03146L15.4815 7.13962L15.6006 7.41416L15.8989 7.43888L22.6723 8.0004L22.6749 8.0006C23.4815 8.06326 23.7746 9.02284 23.1901 9.51697L23.19 9.51712L18.0433 13.8723L17.8085 14.0709L17.8799 14.37L19.4225 20.834C19.5937 21.5532 18.786 22.1879 18.0738 21.7669L18.0736 21.7668L12.2538 18.3292Z" stroke="#E73D38" />
    </svg>
  )
}

const videoTemplate = (rowData) => {
  return (
    <div className={'videoTemplate'}>
      <Image src={rowData.thumbnails.default.url} alt={'video thumbnail'} className={'videoThumbnail'} />
      <p className={'videoTitle'}>{rowData.title}</p>
    </div>
  )
};

const urlTemplate = (rowData) => {
  return (
    <div className={'urlTemplate'}>
      <p className={'urlLink'}>{rowData.url}</p>
      <div onClick={() => navigator.clipboard.writeText(rowData.url)}>
        <CopyIconSmall />
      </div>
    </div>
  )
};

const creatorTemplate = (rowData) => {
  return (
    <div className={'creatorTemplate'}>
      <Image alt={''} src={rowData?.channel?.photo ?? noImage} className={'agencyWindowCreatorImage'} />
      {rowData?.channel?.title}
    </div>
  )
};

const statusTemplate = () => {
  return (
    <div className={'statusTemplate'}>
      Uploaded
    </div>
  )
};

const dateTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      {formatDate(rowData.createdAt)}
    </div>
  )
};

const conceptTemplate = (rowData) => (
  <div className='conceptTitleWrap'>
    <p className='conceptTitle' style={{ height: 'auto' }}>Concept</p>
    <p className='conceptValue' style={{ height: 'auto' }}>{rowData.concept}</p>
  </div>
);


export default function (props) {
  const [filteredAttachments, setFilteredAttachments] = useState([]);
  const [brand, setBrand] = useState('');
  const [content, setContent] = useState([]);
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [openAgencyWindow, setOpenAgencyWindow] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [openCPMPendingModal, setOpenCPMPendingModal] = useState(false);
  const [openCPMPendingUGCModal, setOpenCPMPendingUGCModal] = useState(false);
  const [openCPMPendingDraftModal, setOpenCPMPendingDraftModal] = useState(false);
  const [openCPMPendingUGCDraftModal, setOpenCPMPendingUGCDraftModal] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [latestSubmits, setLatestSubmits] = useState(undefined);
  const [latestUGCSubmits, setLatestUGCSubmits] = useState(undefined);
  const [latestAgencyUGCSubmits, setLatestAgencyUGCSubmits] = useState(undefined);
  const brands = useBrands();
  const contentItems = useContentItems();
  const { getUGCUploadedVideosLinks } = useUgcManager();
  const { user } = useAuth();
  const contractManager = useCampaignContracts(props.viewPerspective);
  const [contract, setContract] = useState(undefined);
  const [agencyOwner, setAgencyOwner] = useState(undefined);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipIndex, setTooltipIndex] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);
  const isCPMCampaign = props.campaign?.paymentMethod === "CPM";
  const isCreator = user.value.isCreator() || user.value.isAgencyOwner() ;


  // State variables for checkboxes
  const [isChecked, setIsChecked] = useState({
    terms: false,
    maxAmount: false,
    draftVideo: false
  });

  const isButtonEnabled = isChecked.terms && (isCPMCampaign ? isChecked.maxAmount : true) && isChecked.draftVideo;
  const totalCAP = Object.values(props.currentAgencyCreatorsCap).reduce((sum, entry) => sum + (entry.data?.CAP || 0), 0);

  const toast = useToastModule();
  const isCpmUgcCampaign = ['CPM', 'UGC'].includes(props.campaign?.paymentMethod);
  const isCpiCpcCampaign = ['CPI', 'CPC'].includes(props.campaign?.paymentMethod);
  const isUGCCampaign = ['UGC'].includes(props.campaign?.paymentMethod);

  const handleCheckboxChange = (key, checked) => {
    setIsChecked(prevState => ({ ...prevState, [key]: checked }));
  };
  useEffect(() => {
    if (props.campaign) {
      setFilteredAttachments(props.campaign.attachments.filter(file => !!file.url));
      if (props?.campaign?.brand?.id) {
        brands.manager.loadBrand(props?.campaign?.brand?.id).then((result) => {
          setBrand(result);
        });
      }
      if (content && content.length < 0) {
        contentItems.list.getCampaignContent(props.campaign.id).then((result) => {
          setContent(result);
        });
      }
      if (!latestUGCSubmits && user?.value.id && isUGCCampaign && !props.creator?.isAgencyOwner()) {
        const { draftAccepted, liveVideoPosted, videoDraft } = props?.progressCpmCampaign || {};

        getUGCUploadedVideosLinks(props.campaign.id, videoDraft ?
          (liveVideoPosted ? true : false) : true).then((data) => {
            if (data) {
              setLatestUGCSubmits(data?.contents[0]?.googleDriveLinks);
            }
          });
      }

      if (!latestSubmits && user?.value.id && !isUGCCampaign) {
        contentItems.list.loadMyLatest(user?.value.id).then((data) => {
          setLatestSubmits(data?.contentItems.filter((item) => (item?.campaignId?.id || item?.campaignId) === props?.campaignID).filter(item => !item?.delete && item?.campaignId?.paymentMethod !== "UGC"));
        });
      }

      if (!!props && props?.campaign?.id && props?.currentUserId) {
        updateDataPage()
      }
    }
  }, [props.campaign]);

  useEffect(() => {
    if (!!props) {
      setIsLoading(props?.isLoading)
    }
  }, [props?.isLoading])


  const goToUrl = (url) => {
    window.open(url, '_blank').focus();
  }

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
  };

  const handleCampaignAccept = () => {
    props.handleApply();
    setOpenAcceptDialog(false);
  }

  const handleCampaignLeave = () => {
    props.leaveCampaign();
    setOpenLeaveDialog(false);
  }

  const handleCampaignLeaveChannel = (campaign, channel) => {
    props.withdrawChannelApplication(campaign, channel);
  }

  const handleChannelClick = (channel) => {
    if (!props.getIndividualChannelInfo(channel?.channel?.channelId, props.campaign, 'applicationId') && !props.displayChannelApplicationStatusCondition(channel?.channel?.channelId, props.currentApplicationInfoForAgency)) {
      props.selectChannel(channel);
    }
  }

  const handleSelectAllChannel = (agencyWorkers) => {
    const filteredAgencyWorkers = agencyWorkers.filter((channel) => {
      return !props.getIndividualChannelInfo(channel?.channel?.channelId, props.campaign, 'applicationId') && !props.displayChannelApplicationStatusCondition(channel?.channel?.channelId, props.currentApplicationInfoForAgency)
    })
    props.selectAllItems(filteredAgencyWorkers);
  }

  const handleChangeTab = (index, draftUploaded) => {
    if (isCpmUgcCampaign) {
      if (index == 0 && !props?.progressCpmCampaign?.videoDraft) {
        setCurrentTab(index + 1);
      }

      if (props.progressCpmCampaign?.liveVideoPosted && index == 4) {
        setCurrentTab(index + 1);
      }
      if (props.currentApplicationInfoForCreator?.capProposals?.[0]?.clientDecision === 'approved' &&
        (((index == 0 && (!props?.progressCpmCampaign?.videoDraft))) ||
          (index == 1 && !props?.progressCpmCampaign?.draftAccepted) ||
          (index == 1 && props?.progressCpmCampaign?.videoDraft && !props?.progressCpmCampaign?.draftAccepted && isUGCCampaign)
        )
      ) {
        setCurrentTab(index + 1);
      }

      if (props.currentApplicationInfoForCreator?.capProposals?.[0]?.clientDecision === 'pending' &&
        ((index == 1 && props?.progressCpmCampaign?.videoDraft && !props?.progressCpmCampaign?.draftAccepted && isUGCCampaign))
      ) {
        setCurrentTab(index + 1);
      }
      if (props?.progressCpmCampaign?.videoDraft && !props?.progressCpmCampaign?.draftAccepted && index == 2) {
        setCurrentTab(index + 1);
      }

      if (index == 2 && !!draftUploaded) {
        setCurrentTab(index + 1);
        if (props) {
          props?.getProgressCpm();
        }
      }

      if (index == 4 && !!draftUploaded) {
        setCurrentTab(index + 1);
        if (props) {
          props?.getProgressCpm();
        }
      }

      if (props?.progressCpmCampaign?.videoDraft && props?.progressCpmCampaign?.draftAccepted && index == 3 && !props.progressCpmCampaign?.liveVideoPosted) {
        setCurrentTab(index + 1);
      }
    } else {
      if (index == 0 && !props?.progressCpmCampaign?.liveVideoPosted) {
        setCurrentTab(index + 1);
      }
      if (index == 2 && !!draftUploaded) {
        setCurrentTab(index + 1);
        if (props) {
          props?.getProgressCpm();
        }
      }

      if (props.progressCpmCampaign?.liveVideoPosted && index == 2) {
        setCurrentTab(index + 1);
      }
    }
  }

  useEffect(() => {
    if (props && props?.progressCpmCampaign && props?.isUserAppliedToCampaign) {
      if (props.progressCpmCampaign?.liveVideoPosted) {
        if (isCpmUgcCampaign) {
          setCurrentTab(5)
        } else {
          setCurrentTab(3)
        }
      } else if (props?.progressCpmCampaign?.videoDraft && props?.progressCpmCampaign?.draftAccepted) {
        setCurrentTab(4);
      } else if (props.progressCpmCampaign?.videoDraft) {
        setCurrentTab(3)
      } else {
        setCurrentTab(1)
      }
    }
  }, [props, props?.progressCpmCampaign, props?.isUserAppliedToCampaign])

  const updateDataPage = () => {
    setIsLoading(true);
    contractManager.loadSingle(props.campaign?.id, props.currentUserId).then((campaignWithContract) => {
      setContract(campaignWithContract?.campaign);
      setAgencyOwner(campaignWithContract?.agencyOwner);
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
    });
  };

  const handleCopyCreatorTrackingLinkURL = (creatorTrackingLink) => {
    navigator.clipboard.writeText(creatorTrackingLink)
      .then(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const getUGCUploadedContents = async (contentType = "draft", channelId = undefined) => {
    if (user?.value.id && isUGCCampaign) {
      getUGCUploadedVideosLinks(
        props?.campaign?.id,
        contentType === "draft" ? false : true,
        props.creator?.isAgencyOwner() ? channelId : undefined
      ).then((data) => {
        if (data) {
          setLatestUGCSubmits(data?.contents[0]?.googleDriveLinks);
        }
      });
    }
  }

  const handleDeleteContent = (contentItemId) => {
    contentItems.manager.update(contentItemId, {
      "delete": true
    }).then((result) => {
      if (result) toast.showSuccess('Video deleted successfully');
      if (user?.value.id) {
        contentItems.list.loadMyLatest(user?.value.id).then((data) => {
          setLatestSubmits(data?.contentItems.filter((item) => item?.campaignId?.id || item?.campaignId === props?.campaignID).filter(item => !item?.delete && item?.campaignId?.paymentMethod !== "UGC"));
        });
      }
    });
  };

  const deleteTemplate = (rowData) => {
    return (
      <div className={'deleteTemplate'} onClick={() => {
        handleDeleteContent(rowData._id)
      }}>
        <TrashIcon />
      </div>
    )
  };
  
  const handleCreatorApply = () => {
    if (props.campaign?.paymentMethod === CampaignPaymentMethod.CPM && props.creator?.isCreator()) {
      if (!props.isUserAppliedToCampaign) {
        if (!props?.currentCreatorCap?.CAP) {
          toast.showError("You are not eligible for this campaign.");
        } else {
          setOpenAcceptDialog(true);
        }
      }
    } else {
      setOpenAcceptDialog(true);
    }
  }

  const findChannelInfo = (channelId) => props?.agencyWorkers?.find(channel => channel?.channel?.channelId === channelId)?.channel;

  function mergeGoogleDriveLinks(contents) {
    return contents
      .flatMap(contentItem =>
        contentItem.googleDriveLinks.map(content => ({
          concept: content.concept,
          aspectRatios: content.aspectRatios.map(aspect => ({
            ...aspect,
            channelId: contentItem?.channelId,
            channelInfo: findChannelInfo(contentItem?.channelId)
          }))
        }))).reduce((acc, item) => {
          const existingConcept = acc.find(conceptItem => conceptItem?.concept === item?.concept);

          if (existingConcept) {
            existingConcept.aspectRatios.push(...item.aspectRatios);
          } else {
            acc.push({ ...item });
          }

          return acc;
        }, []);
  }

  useEffect(() => {
    if (user?.value.id && isUGCCampaign && props.creator?.isAgencyOwner()) {
      getUGCUploadedVideosLinks(
        props?.campaign?.id,
        true,
        undefined
      ).then((data) => {
        if (data) {
          const updatedAgencySubmits = mergeGoogleDriveLinks(data?.contents || []);
          setLatestAgencyUGCSubmits(updatedAgencySubmits);
        }
      });
    }
  }, [isUGCCampaign]);

  const expanderTemplate = (rowData) => (
    <div className='expanderTemplate'>
      {expandedRows.includes(rowData) ?
        <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
          <ExpandArrowUp />
        </div> :
        <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
          <ExpandArrowDown />
        </div>
      }
    </div>
  );

  const rowExpansionTemplate = (rowData) => {
    const showFileSizeInMB = (bytes) => (bytes / (1024 * 1024))?.toFixed(1);
    return (
      <>
        <div className='darkLine' />
        <div className='expansionWrapper ugcUploadedContent ugcAgencyUploadedContent'>
          <div className='expansionDataSquare'>
            <div className='video-wrap header'>
              <div className='ratio user'>
                <p className='headerTitle'>User</p>
              </div>
              <div className='ratio'>
                <p className='headerTitle'>Aspect Ratio</p>
              </div>
              <div className={`video-wrapper`}>
                <p className='headerTitle'>
                  Video Info
                </p>
              </div>
              <div className='videoInfoDate'>
                <p className='headerTitle'>Submission Date</p>
              </div>
              <div className='videoInfo videoStatus'>
                <p className='headerTitle'>Status</p>
              </div>
            </div>
            {rowData?.aspectRatios?.length > 0 ? <div className='request-info type-name'>
              {rowData?.aspectRatios?.map((ratio) => {
                return (
                  <div key={ratio?.ratio} className='video-wrap'>
                    <p className='ratio user'>
                      <div className={`agencyWindowCreatorItemInfo cpm`} >
                        <div className='profile-info'>
                          <Image alt={''} src={ratio?.channelInfo?.photo ?? noImage} className={'agencyWindowCreatorImage'} />
                          {ratio?.channelInfo?.title}
                        </div>
                      </div>
                    </p>
                    <p className='ratio'>{ratio?.ratio}</p>
                    <div className={`video-wrapper`}>
                      <div className='video-preview'>
                        <div
                        >
                          <Image
                            src={noImage}
                            alt='file preview'
                            style={{ display: 'none' }}
                          />
                          <video
                            src={`${ratio?.videoUrl}#t=5`}
                            controls={false}
                            onError={(e) => {
                              e.target.style.display = 'none';
                              const img = e.target.previousElementSibling;
                              if (img) {
                                img.style.display = 'block';
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='videoInfo'>
                        <div className={'urlTemplate'} style={{ marginBottom: '5px' }}>
                          <p className='file-name'>{ratio?.fileName || ""}</p>
                          <div onClick={() => navigator.clipboard.writeText(ratio?.videoUrl)}>
                            <CopyIconSmall />
                          </div>
                        </div >
                        <p className='file-size'>{showFileSizeInMB(ratio?.fileSize || 0)} MB</p>
                      </div>
                    </div>
                    <div className='videoInfoDate'>
                      <p className='ratio'>{ratio?.submittedAt || ""}</p>
                    </div>
                    <div className='videoInfo videoStatus'>
                      <p className={`file-status ${ratio?.status}`}>{ratio?.status}</p>
                    </div>
                  </div>
                )
              })}
            </div> :
              <p className='noUploadsFound'>No uploads found</p>
            }
          </div >
        </div >
      </>
    );
  };

  return (
    <div className={'campaign-active-page'}>
      {isLoading ?
        <div className={'loading-wrap'}><span className="loader wrap"></span></div> :
        <div>
          <div className={'goBack'} onClick={() => props.handleBackToAll()}>
            <BackIcon />
            <span className={'goBackLabel'}>All Campaigns</span>
          </div>
          {props.isUserAppliedToCampaign &&
            <div className={'topInfo'}>
              <p className={'campaignTitle'}>{props.campaign?.name}</p>
              <Button className={'leaveButton'} onClick={() => setOpenLeaveDialog(true)}>Leave Campaign</Button>
            </div>
          }
          {(props.isUserAppliedToCampaign || currentTab === TabStep.SubmitContent) &&
            <div className={'tabLayer'}>
              {isCpiCpcCampaign && tabItems.map((item, index) =>
                <div key={index} className={(item.index === currentTab) ? 'tabLayerItemCurrent' : 'tabLayerItem'}
                  onClick={() => handleChangeTab(index)}>
                  <div className={(item.index === currentTab) ? 'tabNumberSelected' : 'tabNumber'}>
                    0{item.index}
                  </div>
                  <span className={'tabName'}>{item.name}</span>
                  {(item.index === currentTab) && <RedTriangle />}
                </div>)}
              {(isCpmUgcCampaign) && tabItemsCPM.map((item, index) =>
                <div key={index} className={(item.index === currentTab) ? 'tabLayerItemCurrent' : 'tabLayerItem'}
                  onClick={() => handleChangeTab(index)}>
                  <div className={(item.index === currentTab) ? 'tabNumberSelected' : 'tabNumber'}>
                    0{item.index}
                  </div>
                  <span className={'tabName'}>{item.name}</span>
                  {(item.index === currentTab) && <RedTriangle />}
                </div>)}
            </div>
          }
          {currentTab === TabStep.CampaignAccepted &&
            <div className={'campaignDetails'}>
              <div className={'coverBackground'} style={{ background: `linear-gradient(rgba(19, 10, 35, 0.7), rgba(19, 10, 35, 0.7)), url(${props.campaign?.photo})` }}>
                <p className={'coverTitle'}>{props.campaign?.name}</p>
                <div className={'coverGrid'}>
                  <div>
                    <p className={'coverLabel'}>Category:</p>
                    <p className={'coverValue'}>{props.campaign?.targetMarket.title}</p>
                  </div>
                  <div>
                    <p className={'coverLabel'}>Campaign {props.campaign?.paymentMethod}:</p>
                    {props.campaign?.paymentMethod === CampaignPaymentMethod.CPC &&
                      <p className={'coverValue'}>{(isCreator) ? calculateCpiRate(props.campaign?.cpc.amount, props.campaign?.appliedFee) : props.campaign?.cpc.amount} {props.campaign?.cpc?.currency}</p>
                    }
                    {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM &&
                      <p className={'coverValue'}>{(isCreator) ? calculateCpiRate(props.campaign?.cpm.amount, props.campaign?.appliedFee) : props.campaign?.cpm.amount} {props.campaign?.cpm?.currency}</p>
                    }
                    {props.campaign?.paymentMethod === CampaignPaymentMethod.CPI &&
                      <div>
                        <div className={'coverValue'}>
                          <AndroidIcon />
                          {(isCreator) ? calculateCpiRate(props.campaign?.cpi.android.amount, props.campaign?.appliedFee) : props.campaign?.cpi.android.amount}{' '}
                          {props.campaign?.cpi.android.currency}
                        </div>
                        <div className={'coverValue'}>
                          <AppleIcon />
                          {(isCreator) ? calculateCpiRate(props.campaign?.cpi.ios.amount, props.campaign?.appliedFee) : props.campaign?.cpi.ios.amount}{' '}
                          {props.campaign?.cpi.ios.currency}
                        </div>
                      </div>
                    }
                    {props.campaign?.paymentMethod === CampaignPaymentMethod.UGC &&
                      <p className='coverValue'>{`${props.campaign?.ugc.costPerCreator} ${props.campaign?.ugc?.currency}`}</p>
                    }
                  </div>
                  {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM && props.creator?.isCreator() && <div>
                    <p className={'coverLabel'}>Max. Cap:</p>
                    <p className={'coverValue'}>{((props.isUserAppliedToCampaign) ?
                      props.currentApplicationInfoForCreator?.capProposals?.[0]?.proposedPayoutCap :
                      props?.currentCreatorCap?.CAP)
                      || 0} {props.campaign?.cpm?.currency}</p>
                  </div>}
                  <div>
                    <p className={'coverLabel'}>Application time left:</p>
                    <p className={'coverValue'}>{props.campaign?.dates.endDate ? new Date(props.campaign.dates.endDate).toLocaleDateString
                      ('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Until budget is complete'}</p>
                  </div>
                  {isCpmUgcCampaign &&
                    <>
                      <div>
                        <p className={'coverLabel'}>Language:</p>
                        <p className={'coverValue'}>{props?.campaign?.languages?.map(language => language?.name)?.join(',')}</p>
                      </div>
                      {isUGCCampaign && <div>
                        <p className='coverLabel'>Country:</p>
                        <p className={'coverValue'}>{props?.campaign?.countries?.map(country => country?.name)?.join(',')}</p>
                      </div>}
                    </>}
                  {isCpiCpcCampaign && <div>
                    <p className={'coverLabel'}>Region:</p>
                    <p className={'coverValue'}>{getRegion(props.campaign, user.value?.type)}</p>
                  </div>}
                </div>
                {/*INITIAL ACTION FOR CREATOR*/}
                {!props.isUserAppliedToCampaign && props.creator?.isCreator() && props.campaign?.status !== CampaignStatus.Completed &&
                  <div className={'acceptWrapper'}>
                    <Button onClick={() => handleCreatorApply()} disabled={props.disableApplyButton} className={'acceptButton'}>{props.getApplyButtonLabel()}</Button>
                  </div>
                }
                {/*INITIAL ACTION FOR AGENCY*/}
                {!props.isUserAppliedToCampaign && props.creator?.isAgencyOwner() && props.campaign?.status !== CampaignStatus.Completed &&
                  <div className={'acceptWrapper agencyWrapper'}>
                    <Button className={'acceptButton widerButton'} disabled={props.disableApplyButton} onClick={() => setOpenAgencyWindow(!openAgencyWindow)}>{props.isCampaignCPM ? "Apply" : "Accept"}</Button>
                  </div>
                }
                {/*CONTENT SUBMISSION*/}
                {props.isUserAppliedToCampaign && props.currentUserId && props.campaign?.status !== 'completed'
                  && isCpiCpcCampaign
                  && props.creator?.isCreator() &&
                  <div className={'acceptWrapper'}>
                    {!props?.progressCpmCampaign?.liveVideoPosted &&
                      <Button className={'acceptButton widerButton'} onClick={() => setCurrentTab(2)}>Submit Content</Button>
                    }
                    {
                      props?.progressCpmCampaign?.liveVideoPosted &&
                      <div className='video-posted'>
                        <p className='coverValue'>Your video has been published!</p>
                        <Button className={'homeButton'} onClick={() => { props?.handleRoutingHomepage() }}>Return to Homepage</Button>
                      </div>
                    }
                  </div>
                }
                {props.isUserAppliedToCampaign && props.currentUserId && props.campaign?.status !== 'completed'
                  && isUGCCampaign
                  && props.creator?.isCreator() &&
                  <div className={'acceptWrapper'}>
                    {!props?.progressCpmCampaign?.liveVideoPosted &&
                      <Button className={'acceptButton widerButton'} onClick={() => setCurrentTab(2)}>Submit Content</Button>
                    }
                    {
                      props?.progressCpmCampaign?.liveVideoPosted &&
                      <div className='video-posted'>
                        <p className='coverValue'>Your video has been published!</p>
                        <Button className={'homeButton'} onClick={() => { props?.handleRoutingHomepage() }}>Return to Homepage</Button>
                      </div>
                    }
                  </div>
                }
                {props.isUserAppliedToCampaign && props.currentUserId && props.campaign?.status !== 'completed' && props.isCampaignCPM && props.creator?.isCreator() &&
                  <div className={'acceptWrapper'}>
                    {(props.currentApplicationInfoForCreator?.capProposals?.[0]?.clientDecision === 'approved' && ((!props.progressCpmCampaign.videoDraft && props.isUserAppliedToCampaign)
                      || props.progressCpmCampaign?.draftRejected) && !props.progressCpmCampaign?.draftAccepted) &&
                      <Button className={'acceptButton extraWideButton'} onClick={() => {
                        setCurrentTab(2)
                      }}>Submit Content CPM</Button>
                    }
                    {props.progressCpmCampaign.draftAccepted && !props?.progressCpmCampaign?.liveVideoPosted &&
                      <Button className={'acceptButton widerButton'} onClick={() => setCurrentTab(2)}>Submit Content</Button>
                    }
                    {
                      props?.progressCpmCampaign?.liveVideoPosted &&
                      <div className='video-posted'>
                        <p className='coverValue'>Your video has been published!</p>
                        <Button className={'homeButton'} onClick={() => { props?.handleRoutingHomepage() }}>Return to Homepage</Button>
                      </div>
                    }
                  </div>
                }
                <div className={'coverStatus'}>
                  {props.campaign?.status}
                </div>
                <div className={'favorite'} onClick={() => props.toggleCampaignFavorite()}>
                  {props.isCampaignFav ? <FavoriteIconActive /> : <FavoriteIconInactive />}
                </div>
              </div>
              {props.isCampaignCPM &&
                <div>
                  <p className={'infoText'}>
                    ALL CPM CAMPAIGNS HAVE A 30 DAY TRACKING PERIOD. ONCE YOUR VIDEO IS LIVE ON YOUTUBE AND IS SUBMITTED HERE
                    THE 30 DAYS OF TRACKING BEGINS. ALL VIEWS GENERATED WITHIN THIS 30 DAY PERIOD WILL BE PAID IN ACCORDANCE WITH
                    THE CAMPAIGN CPM, MAX PAYOUT AND T&C REGARDING THE BUDGET RUNNING OUT. MAKE SURE TO UPLOAD YOUR VIDEO HERE
                    WITHIN 24 HOURS OF IT GOING LIVE ON YOUTUBE. YOU ARE NOT GUARANTEED PARTICIPATION OR COMPENSATION IN THIS
                    CAMPAIGN UNTIL YOUR DRAFT IS ACCEPTED.
                  </p>
                </div>
              }
              {/* {props.displayModeratedContent(props.isCampaignCPM, props.applicationStatus) && */}
              <div className={'infoMain'}>
                <div className={'infoMainLeft'}>
                  <div>
                    <p className={'infoLabel'}>Campaign Details</p>
                    <p className={'infoText'}>{props.campaign?.productName}</p>
                  </div>
                  {props.campaign?.paymentMethod === CampaignPaymentMethod.UGC &&
                    <div className='ugcInfoWrap'>
                      <div className='ugcInfo'>
                        <UGCConceptIcon />
                        <p className={'ugcInfoLabel'}>Concepts:</p>
                        <p className={'ugcInfoValue'}>{props?.campaign?.conceptCount}</p>
                      </div>
                      <div className='ugcInfo'>
                        <UGCFormatIcon />
                        <p className={'ugcInfoLabel'}>Format:</p>
                        <p className={'ugcInfoValue'}>{props?.campaign?.videoFormat}</p>
                      </div>
                      <div className='ugcInfo'>
                        <UGCVideoLengthIcon />
                        <p className={'ugcInfoLabel'}>Length:</p>
                        <p className={'ugcInfoValue'}>{props?.campaign?.videoLength}</p>
                      </div>
                      <div className='ugcInfo'>
                        <UGCVideoAspectRatioIcon />
                        <p className={'ugcInfoLabel'}>Aspect Ratio:</p>
                        <p className={'ugcInfoValue'}>{props?.campaign?.videoAspectRatio?.join(', ')}</p>
                      </div>
                      <div className='ugcInfo'>
                        <UGCVideoNoOfCreatorsIcon />
                        <p className={'ugcInfoLabel'}>No. of Creators:</p>
                        <p className={'ugcInfoValue'}>{props?.campaign?.ugc?.noOfCreator}</p>
                      </div>
                    </div>
                  }
                  <div>
                    <p className={'infoLabel'}>Campaign requirements</p>
                    <div className={'talkingPointsWrapper'}>
                      {props.campaign?.talkingPoints?.filter(item => item !== '').map((item, index) =>
                        <div key={index} className={'talkingPoint'}>
                          <CheckMark />
                          <div className={'talkingPointText'}>
                            <TextWithLinks text={item} />
                          </div>
                        </div>)}
                    </div>
                  </div>
                </div>
                <div className={'infoMainRight'}>
                  <div>
                    <p className={'infoLabel'}>Campaign URl</p>
                    <div className={'linkWrapper'}>
                      {props.campaign?.productUrls?.map((item, index) =>
                        <>
                          {item.value &&
                            <div key={index}>
                              <p className={'linkLabel'}>{getUrlName(item)}</p>
                              <div className={'link'}>
                                <OtherUrlIcon type={item.type} />
                                <span className={'overflowEllipsis'} onClick={() => goToUrl(item.value)}>{item.value}</span>
                                {item.value && <CopyIcon onClick={() => copyUrl(item.value)} />}
                              </div>
                            </div>}
                        </>)}
                    </div>
                  </div>
                  <div>
                    <p className={'infoLabel'}>Call to Action</p>
                    <div className={'callToActionBox'}>{props.campaign?.callToAction}</div>
                  </div>
                  <div>
                    <p className={'infoLabel'}>Attachments</p>
                    <AttachmentsDisplay attachments={filteredAttachments} />
                  </div>
                </div>
              </div>
              {/* } */}
            </div>
          }
          {
            (currentTab === TabStep.CampaignAccepted && !props.isUserAppliedToCampaign) && !props.creator?.isAgencyOwner() &&
            <div>
              <Divider className={'divider'} />
              <div className={'overviewBottom'}>
                <div className={'overviewBottomLeft'}>
                  <p className={'overviewLabel'}>About Brand</p>
                  <div className={'brandPhotoRow'}>
                    <Image src={brand?.photo} alt={'brand photo'} className={'brandPhoto'} />
                    <span>{brand?.name}</span>
                  </div>
                  <p className={'overviewText'}>{brand?.description}</p>
                </div>
                <div className={'overviewBottomRight'}>
                  <div className={'contentItemsTopRow'}>
                    <p className={'overviewLabel'}>Content</p>
                    <span className={'seeAll'} onClick={() => props.handleRoutingAll()}>See All</span>
                  </div>
                  <div className={'contentItemsWrapper'}>
                    {content.slice(0, 4).map((item, index) =>
                      <div key={index} className={'contentItem'}>
                        <NewContentListItem item={item} handleRouting={() => props.handleContentRouting(item.id)} />
                      </div>)}
                  </div>
                </div>
              </div>
            </div>
          }

          {props.creator?.isAgencyOwner() &&
            <>
              {!isUGCCampaign ? <div>
                <Divider className={'divider'} />
                <p className={'uploadedContentTitle'}>Uploaded Content</p>
                <DataTable value={latestSubmits}>
                  <Column field="title" header="Video" body={videoTemplate} style={{ width: '30%' }}></Column>
                  <Column field="url" header="Influencer" body={creatorTemplate} style={{ width: '22%' }}></Column>
                  <Column field="url" header="Video Link" body={urlTemplate} style={{ width: '22%' }}></Column>
                  <Column field="" header="Status" body={statusTemplate} style={{ width: '14%' }}></Column>
                  <Column field="createdAt" header="Submission Date" body={dateTemplate} style={{ width: '14%' }}></Column>
                  <Column field="" header="" body={deleteTemplate} style={{ width: '9%' }}></Column>
                </DataTable>
              </div>
                :
                <div>
                  <p className={'uploadedContentTitle'}>Uploaded Content</p>
                  <DataTable value={latestAgencyUGCSubmits}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                  >
                    <Column field="concept" header="Concept" body={conceptTemplate} style={{ width: '90%', textAlign: 'left' }} align={'left'}></Column>
                    <Column header="Action" expander={true} body={expanderTemplate} style={{ width: '5%' }} />
                  </DataTable>
                </div>
              }
            </>
          }
          {
            isCpiCpcCampaign && <NewSubmitContent viewPerspective={props.viewPerspective} route={props.route} campaignID={props.campaign?.id}
              submissionID={props.currentUserId} enableChannelChecking={props.enableChannelChecking}
              leaveCampaign={() => setOpenLeaveDialog(true)} openCPMPendingModal={openCPMPendingModal}
              setOpenCPMPendingModal={setOpenCPMPendingModal} openSubmit={openSubmit} setOpenSubmit={setOpenSubmit}
              selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
              displayNone={!(currentTab === TabStep.SubmitContent && (props.isUserAppliedToCampaign))}
              campaign={props?.campaign}
              progressCpmCampaign={props?.progressCpmCampaign}
              handleChangeTab={handleChangeTab}
              isCampaignCPM={props?.isCampaignCPM}
              latestSubmits={latestSubmits}
              contract={contract}
              agencyOwner={agencyOwner}
              updateDataPage={updateDataPage}
              getAgencyProgress={props?.getProgressCpm}
            />
          }

          {
            isCpmUgcCampaign && <NewSubmitDraft campaignID={props.campaign?.id}
              submissionID={props.currentUserId} enableChannelChecking={props.enableChannelChecking}
              leaveCampaign={() => setOpenLeaveDialog(true)}
              openCPMPendingDraftModal={openCPMPendingDraftModal}
              openCPMPendingUGCDraftModal={openCPMPendingUGCDraftModal}
              setOpenCPMPendingDraftModal={setOpenCPMPendingDraftModal}
              setOpenCPMPendingUGCDraftModal={setOpenCPMPendingUGCDraftModal}
              openSubmit={openSubmit} setOpenSubmit={setOpenSubmit}
              selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
              displayNone={!(currentTab === TabStepCPM.SubmitDraft && (props.isUserAppliedToCampaign))}
              campaign={props.campaign}
              progressCpmCampaign={props?.progressCpmCampaign}
              handleChangeTab={handleChangeTab}
              latestSubmits={latestSubmits}
              latestUGCSubmits={latestUGCSubmits}
              updateDataPage={updateDataPage}
              agencyOwner={agencyOwner}
              getAgencyProgress={props?.getProgressCpm}
              conceptCount={props.campaign?.conceptCount}
              videoAspectRatio={props?.campaign?.videoAspectRatio}
              isUGCCampaign={isUGCCampaign}
              getUGCUploadedContents={getUGCUploadedContents}
            />
          }

          {
            isCpmUgcCampaign && <NewDraftApproval campaignID={props.campaign?.id}
              submissionID={props.currentUserId}
              leaveCampaign={() => setOpenLeaveDialog(true)} openCPMPendingModal={openCPMPendingModal}
              setOpenCPMPendingModal={setOpenCPMPendingModal}
              openSubmit={openSubmit} setOpenSubmit={setOpenSubmit}
              selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
              displayNone={!(currentTab === TabStepCPM.DraftApproval && (props.isUserAppliedToCampaign))}
              campaign={props.campaign}
              handleChangeTab={handleChangeTab}
              progressCpmCampaign={props?.progressCpmCampaign}
              handleRoutingHomepage={props?.handleRoutingHomepage}
              latestSubmits={latestSubmits}
              latestUGCSubmits={latestUGCSubmits}
              isUGCCampaign={isUGCCampaign}
            />
          }

          {
            isCpmUgcCampaign && <NewSubmitContent
              viewPerspective={props.viewPerspective}
              route={props.route} campaignID={props.campaign?.id}
              submissionID={props.currentUserId}
              enableChannelChecking={props.enableChannelChecking}
              leaveCampaign={() => setOpenLeaveDialog(true)}
              openCPMPendingModal={openCPMPendingModal}
              setOpenCPMPendingModal={setOpenCPMPendingModal}
              openCPMPendingUGCModal={openCPMPendingUGCModal}
              setOpenCPMPendingUGCModal={setOpenCPMPendingUGCModal}
              openSubmit={openSubmit} setOpenSubmit={setOpenSubmit}
              selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
              displayNone={!(currentTab === TabStepCPM.SubmitContent && (props.isUserAppliedToCampaign))}
              campaign={props.campaign}
              progressCpmCampaign={props?.progressCpmCampaign}
              handleChangeTab={handleChangeTab}
              isCampaignCPM={props?.isCampaignCPM}
              latestSubmits={latestSubmits}
              latestUGCSubmits={latestUGCSubmits}
              getUGCUploadedContents={getUGCUploadedContents}
              contract={contract}
              agencyOwner={agencyOwner}
              updateDataPage={updateDataPage}
              getAgencyProgress={props?.getProgressCpm}
              conceptCount={props.campaign?.conceptCount}
              videoAspectRatio={props?.campaign?.videoAspectRatio}
              isUGCCampaign={isUGCCampaign}
            />
          }

          <NewLiveVideo campaignID={props.campaign?.id}
            submissionID={props.currentUserId} enableChannelChecking={props.enableChannelChecking}
            leaveCampaign={() => setOpenLeaveDialog(true)} openCPMPendingModal={openCPMPendingModal}
            setOpenCPMPendingModal={setOpenCPMPendingModal} openSubmit={openSubmit} setOpenSubmit={setOpenSubmit}
            selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
            displayNone={!((currentTab === TabStepCPM.LiveVideoPosted || (isCpiCpcCampaign && currentTab === TabStep.LiveVideoPosted)) && (props.isUserAppliedToCampaign))}
            campaign={props.campaign}
            handleChangeTab={handleChangeTab}
            progressCpmCampaign={props?.progressCpmCampaign}
            isCampaignCPM={props?.isCampaignCPM}
            handleRoutingHomepage={props?.handleRoutingHomepage}
            latestSubmits={latestSubmits}
            latestUGCSubmits={latestUGCSubmits}
            isUGCCampaign={isUGCCampaign}
          />
          <Dialog visible={openLeaveDialog} onHide={() => setOpenLeaveDialog(false)} className={'mainDialog'}>
            <div className={'topRow'}>
              <div onClick={() => setOpenLeaveDialog(false)}><CloseIcon /></div>
            </div>
            <div className={'content'}>
              <p className={'title'}>Warning!</p>
              <p className={'subTitle'}>Are you sure you want to withdraw the application from the campaign?</p>
              <p className={'campaignName'}>{props.campaign?.name}</p>
              <p className={'subTitle'}>It leads to deleting the submitted content from the campaign and termination of any further payments except sums to be due prior to the moment of withdrawal.</p>
              <div className={'buttonGroupDialog'}>
                <Button className={'cancelButton'} onClick={() => setOpenLeaveDialog(false)}>Cancel</Button>
                <Button className={'leaveButton'} onClick={() => handleCampaignLeave()}>Leave Campaign</Button>
              </div>
            </div>
          </Dialog>
          {
            <Dialog key={`agency-key-${JSON.stringify(props?.campaign)}`}
              visible={openAgencyWindow}
              onHide={() => setOpenAgencyWindow(false)} className={'mainDialog agency-accept-modal campaign-active-page'} style={{ width: "1060px" }}>
              <div className={'topRow'}>
                <div onClick={() => setOpenAgencyWindow(false)}><CloseIcon /></div>
              </div>
              <div className={'agencyWrapper'}>
                <div className={'agencyWindow'}>
                  <p className={'agencyWindowTitle'}>{props.isCampaignCPM ? "Apply as:" : "Accept as:"}</p>
                  <div className='agencyWindowInnerWrapper'>
                    <p className={'agencyWindowLabel'}>Select the channel you would like to invite</p>
                    <span className="p-input-icon-left inputFieldWrapper">
                      <i className="pi pi-search" />
                      <InputText placeholder="Search for an influencer" className={'inputField agencyWindowSearch'} onChange={(e) => props.searchInfluencersUser(e.target.value)} />
                    </span>
                    {props.isLoadingAgencyCap ? (
                      <div className={'loading-wrap'}>
                        <span className="loader wrap"></span> {/* Replace with your actual loader component */}
                      </div>
                    ) : (
                      props.agencyWorkers ?
                        <div className={'agencyWindowCreatorsWrapper'}>
                          {props.agencyWorkers.map((channel, index) => {
                            let trackingLink = "";
                            const hasApplied = props?.getIndividualChannelInfo(channel?.channel?.channelId, props.campaign, 'applicationId');
                            const progressChannelInfo = props?.findProgressChannel(channel?.channel?.channelId);
                            const individualChannelInfo = props.getIndividualChannelInfo(channel?.channel?.channelId, props.campaign, 'applicationId');
                            const displayChannelApplicationStatus = props.displayChannelApplicationStatusCondition(channel?.channel?.channelId, props.currentApplicationInfoForAgency);
                            const individualChannelApplicationDisplay = props.getIndividualChannelApplicationDisplay(channel?.channel?.channelId, props.currentApplicationInfoForAgency);
                            const individualChannelApplicationPayoutCap = props.getIndividualChannelApplicationPayoutCap(channel?.channel?.channelId, props.currentApplicationInfoForAgency);
                            const disableBtn = hasApplied || displayChannelApplicationStatus;
                            const disableSubmitContentBtn = progressChannelInfo?.liveVideoPosted;
                            const disableDraftBtn = progressChannelInfo?.videoDraft && !progressChannelInfo?.draftAccepted && !progressChannelInfo?.draftRejected;
                            const draftRejected = progressChannelInfo?.videoDraft && !progressChannelInfo?.draftAccepted && progressChannelInfo?.draftRejected;
                            if (user.value && contract) {
                              if (user?.value?.isAgencyOwner()) {
                                contract.trackingLinks.map((currentLink) => {
                                  if (currentLink.channelId === channel?.channel?.channelId) {
                                    trackingLink = currentLink?.link;
                                  }
                                  else if (user?.value?.agencyOwners?.some((agencyWorker) => agencyWorker?.channel?.channelId === currentLink?.channelId)) {
                                    trackingLink = currentLink[0]?.link;
                                  }
                                });
                              }
                            }
                            return <div key={index} className={props.selectedChannels.includes(channel) ?
                              'agencyWindowCreatorItem border ' :
                              'agencyWindowCreatorItem'}
                              onClick={() => handleChannelClick(channel)}>
                              <div className={`agencyWindowCreatorItemInfo ${props?.isCampaignCPM ? "cpm" : ''}`} >
                                <div className='profile-info'>
                                  <Image alt={''} src={channel?.channel?.photo ?? noImage} className={'agencyWindowCreatorImage'} />
                                  {channel?.channel?.title}
                                </div>
                                {props?.isCampaignCPM && <div className='max-cap-info'>
                                  <p>Max. Cap</p>
                                  <p>
                                    {props?.currentAgencyCreatorsCap[channel?.channel?.channelId]?.isLoading ? (
                                      <div className="loading-wrap max-cap">
                                        <span className="loader wrap"></span>
                                      </div>) : (
                                      `${hasApplied || displayChannelApplicationStatus
                                        ? individualChannelApplicationPayoutCap || 0
                                        : props?.currentAgencyCreatorsCap[channel?.channel?.channelId]?.data?.CAP || 0} USD`
                                    )}
                                  </p>
                                </div>}
                              </div>
                              <div className={'agencyWindowCreatorItemActions'}>
                                {props.campaign?.status !== 'completed' &&
                                  props.isCampaignCPM && individualChannelInfo &&
                                  !displayChannelApplicationStatus &&
                                  progressChannelInfo?.liveVideoPosted &&
                                  <Button className={'agencyButtonSecondary'} >
                                    Live Video Posted
                                  </Button>}
                                {props.campaign?.status !== 'completed' &&
                                  isUGCCampaign && individualChannelInfo &&
                                  // !displayChannelApplicationStatus &&
                                  progressChannelInfo?.liveVideoPosted &&
                                  <Button className={'agencyButtonSecondary'} >
                                    Live Video Posted
                                  </Button>}
                                {props.campaign?.status !== 'completed' &&
                                  isCpiCpcCampaign && individualChannelInfo &&
                                  progressChannelInfo?.liveVideoPosted &&
                                  <Button className={'agencyButtonSecondary'} >
                                    Live Video Posted
                                  </Button>}
                                {props.campaign?.status !== 'completed' &&
                                  isCpiCpcCampaign &&
                                  individualChannelInfo &&
                                  !progressChannelInfo?.liveVideoPosted &&
                                  <Button className={'agencyButtonMain'}
                                    onClick={() => {
                                      setOpenCPMPendingModal(true);
                                      setSelectedChannel(channel);
                                      setSelectedChannel(channel)
                                    }}
                                    disabled={disableSubmitContentBtn}
                                  >Submit Content
                                  </Button>
                                }
                                {/* Submit Content CPM */}
                                {props.campaign?.status !== 'completed' && props.isCampaignCPM &&
                                  individualChannelInfo &&
                                  !displayChannelApplicationStatus &&
                                  progressChannelInfo?.draftAccepted &&
                                  !progressChannelInfo?.liveVideoPosted &&
                                  <Button className={'agencyButtonMain'} onClick={() => {
                                    setOpenCPMPendingModal(true);
                                    setSelectedChannel(channel)
                                  }}
                                    disabled={disableSubmitContentBtn}
                                  >
                                    Submit Content
                                  </Button>}
                                {props.campaign?.status !== 'completed' &&
                                  props.isCampaignCPM && individualChannelInfo &&
                                  !displayChannelApplicationStatus &&
                                  !progressChannelInfo?.draftAccepted &&
                                  <Button className={'agencyButtonMain'} onClick={() => {
                                    setOpenCPMPendingDraftModal(true);
                                    setSelectedChannel(channel)
                                  }} disabled={disableDraftBtn}>
                                    {draftRejected ? "Rejected" : "Submit Draft"}
                                  </Button>}

                                {/* UGC */}
                                {props.campaign?.status !== 'completed' && isUGCCampaign &&
                                  individualChannelInfo &&
                                  // !displayChannelApplicationStatus &&
                                  progressChannelInfo?.draftAccepted &&
                                  !progressChannelInfo?.liveVideoPosted &&
                                  <Button className={'agencyButtonMain'} onClick={() => {
                                    // setOpenCPMPendingModal(true);
                                    setOpenCPMPendingUGCModal(true);
                                    getUGCUploadedContents(progressChannelInfo?.liveVideoPosted ? 'publish' : 'draft', channel?.channel?.channelId)
                                    setSelectedChannel(channel)
                                  }}
                                    disabled={disableSubmitContentBtn}
                                  >
                                    Submit Content
                                  </Button>}

                                {props.campaign?.status !== 'completed' &&
                                  isUGCCampaign && individualChannelInfo &&
                                  // !displayChannelApplicationStatus &&
                                  !progressChannelInfo?.draftAccepted &&
                                  <Button className={'agencyButtonMain'} onClick={() => {
                                    setOpenCPMPendingUGCDraftModal(true);
                                    getUGCUploadedContents('draft', channel?.channel?.channelId)
                                    setSelectedChannel(channel)
                                  }} >
                                    Submit Draft
                                  </Button>}
                                {/* UGC End */}

                                {props.campaign?.status !== 'completed' && displayChannelApplicationStatus
                                  && individualChannelApplicationDisplay && isCpmUgcCampaign &&
                                  <Button disabled={true} className={'agencyButtonSecondary'}>
                                    {individualChannelApplicationDisplay}
                                  </Button>}
                                {props.campaign?.status !== 'completed' && displayChannelApplicationStatus
                                  && individualChannelApplicationDisplay && isCpiCpcCampaign &&
                                  <Button disabled={true} className={'agencyButtonSecondary'}>
                                    {individualChannelApplicationDisplay}
                                  </Button>}
                                {individualChannelInfo &&
                                  <Button onClick={() => {
                                    setOpenAgencyWindow(false);
                                    handleCampaignLeaveChannel(props.campaign, channel)
                                  }} className={'agencyButtonSecondary'}>
                                    Leave Campaign
                                  </Button>}
                              </div>
                              {!hasApplied ? props.selectedChannels.includes(channel) ?
                                <SelectedChannelIcon disabled={disableBtn} /> : <SelectChannelIcon disabled={disableBtn} /> :
                                !isUGCCampaign ? <div className={`creator-tracking-link-url-copy ${!trackingLink ? 'disable' : ''}`}>
                                  {(progressChannelInfo?.draftAccepted || isCpiCpcCampaign) && <div className='text-wrap'>
                                    <Button title="Copy Tracking Link"
                                      className='creator-tracking-link-url-btn'
                                      onClick={() => {
                                        handleCopyCreatorTrackingLinkURL(trackingLink, index)
                                        setTooltipIndex(index);
                                      }}>
                                      <LinkIcon
                                        scale={0.8} color={trackingLink ? "#fff" : "gray"} />
                                    </Button>
                                    <div className={`tooltip ${(showTooltip && tooltipIndex === index) ? 'show' : ''}`}>Copied!</div>
                                  </div>}
                                </div> : <div div className={`creator-tracking-link-url-copy ${!trackingLink ? 'disable' : ''}`}></div>
                              }
                            </div>
                          })}
                        </div>
                        :
                        <p>No influencers found.</p>)
                    }
                    <ul className='agencySelectUnSelect'>
                      <li> <p className='agencyWindowLabel'>{props?.selectedChannels?.length} Selected</p></li>
                      <li> <p className='agencyWindowLabel'> <a href="#" onClick={() => {
                        handleSelectAllChannel(props?.agencyWorkers);
                      }}>Select All</a></p></li>
                      <li> <p className='agencyWindowLabel'><a href="#" onClick={() => {
                        props.resetSelectChannel()
                      }}>Unselect All</a></p></li>
                    </ul>
                    <div className='bodyWrapper'>
                      <Terms capAmount={totalCAP} isCPMCampaign={isCPMCampaign} isChecked={isChecked} handleCheckBox={handleCheckboxChange} />
                    </div>
                    <Button disabled={props.loadingAccept || props.selectedChannels.length === 0 || !isButtonEnabled} onClick={() => {
                      setOpenAgencyWindow(false);
                      props?.acceptChannels();
                    }}
                      className={'agencyWindowButton'}>{props.isCampaignCPM ? "Apply" : "Accept"}</Button>
                  </div>
                </div>
              </div>
            </Dialog>
          }
          <CampaignApplication visible={openAcceptDialog} close={() => setOpenAcceptDialog(false)} accept={() => handleCampaignAccept()}
            campaignType={props?.campaign?.paymentMethod} capAmount={((props.isUserAppliedToCampaign) ?
              props.currentApplicationInfoForCreator?.capProposals?.[0]?.proposedPayoutCap :
              props?.currentCreatorCap?.CAP)
              || 0} />
          {props.gigapayPrompt && <NewGenericPrompt version={'gigapayReminder'} submitFunciton={props.unmountPrompt} useComponentVersionFn={true} showSecondaryButton={true} />}
          {props.cpmApplicationPrompt && !props.creator?.isAgencyOwner() && <NewGenericPrompt version={'cpmApplicationsWaitApproval'} submitFunciton={props.unmountPrompt}
            useComponentVersionFn={true} closeBtnLabel="Dismiss" showSecondaryButton={false} />}
          {props.showRejectedMessagePrompt && <NewGenericPrompt version={'cpmApplicationRejectedCreator'} submitFunciton={props.unmountPrompt} useComponentVersionFn={true} showSecondaryButton={true} />}
        </div>}
    </div >
  )
}