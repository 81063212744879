/* eslint-disable vue/max-len */
import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

import NewInfluencerEarnings from "./NewInfluencerEarnings/NewInfluencerEarnings";
import NewCreatorProfile from "@/react_app/NewCreatorProfile/NewCreatorProfile";
import NewPaymentInfoDialog from "@/react_app/NewCreatorAccount/NewPaymentInfoDialog/NewPaymentInfoDialog";
import NewRestorePasswordDialog from "@/react_app/NewAuth/NewRestorePasswordDialog/NewRestorePasswordDialog";

import { UserType } from "@/data/types";
import {
  CloseIcon, LoopIcon, PaymentIcon, EditPencilWhite, StarDocumentIcon
} from "@/react_app/img/Icons";

import "./NewCreatorAccount.css";
import "./NewCreatorAccountAdditional.css";

const TabStep = {
  MyAccount: 0,
  Login: 1,
  Earning: 2,
  Support: 3,
};

const tabNames = {
  [TabStep.MyAccount]: 'My Account',
  [TabStep.Login]: 'Login',
  [TabStep.Earning]: 'Earnings',
  [TabStep.Support]: 'Support',
};


const tabsItems = ["Account", "Login", "Earnings", "Support"];

const faqItems = [
  {
    header: 'How can I get started?',
    content: <span>Once you have registered as a creator you are free to check out all the available campaigns and see
      which ones look like the best fit for your channel! If you need further help, you can check out the
      tutorial for the site by clicking on the <strong>?</strong> in the bottom left.</span>,
  },
  {
    header: 'What kind of ad do I need to do in my videos?',
    content: <span>The length and style of the ad is entirely up to you, however, you of course want to make it as
      high quality as possible to generate the most downloads. Some of the most popular formats are:<br /><br />
      <b>30-60 second pre-roll:</b> a short ad showing off the game using the assets and talking
      points provided.<br /><br />
      <b>Dedicated video:</b> an entire video of you playing the game, this type
      of ad tends to work best when the game fits the style of your channel. Videos of you playing the game will
      also best attract the attention of the developer to offer to purchase licensing of your video.<br /><br />
      <b>10-30 second end-roll:</b> a short ad placed at the end of your video, quickly showing off the
      assets and mentioning a couple of the talking points.
      You can also share your personalised download link across all of your social media to increase your reach</span>,
  },
  {
    header: 'What is the advanced licensing creator program? (Selling the license to your ads)',
    content: <span>One of the benefits of gameinfluencer.io is the ability to earn extra revenue by allowing the
      license to your ads to be sold directly to the developers.<br /><br />
      With our program you can sell licenses to each of the content pieces you manage to create and distribute to the
      brands.<br /><br />
      This way you can allow your creator ads to be featured for additional licensing which will grant the brand the
      <strong>right to publish the video content of your creator ads on their own media channels, such as TV,
        Googlemarketing campaigns etc.</strong> earning you a specific amount.<br /><br />
      If the brand is interested in additional licensing you will receive a request notification on your account
      outlining their desired terms for the additional license.</span>,
  }, {
    header: 'What is CPI, CPC, and CPM?',
    content: <span>The campaigns on gameinfluencer.io work on either a CPI, CPC, or CPM basis.<br /><br />
      <strong>CPI:</strong> Cost Per Install works using the number of installs a game or app gets through your
      specific tracking link. The CPI for each campaign will be different, as well as the CPI for Android vs IOS
      devices, you can see these when going through the campaign preview.<br /><br />
      <strong>CPC:</strong> Cost per Click works by counting the number of clicks on the personalised tracking link.
      This link will take the user to the landing/home page of the game, and you will be paid out per click. CPC rates
      tend to be lower than CPI rates, but clicks are far easier to acquire than downloads.<br /><br />
      <strong>CPM:</strong> Cost-per-Mille values the number of views your sponsored video gets, with a rate per 1000 views.
      The views are tracked and paid out for the first 30 days that the video is live - all future views
      will not be counted. This is beneficial to you as it allows you to gauge how much you would be likely
      to earn from posting a sponsored video.<br /><br />
      It is important to note that each of these pricing models has its own advantages and disadvantages,
      and what works best for a particular campaign will depend on various factors such as the goals,
      target audience, budget, and type of product or service being advertised.</span>,
  }, {
    header: 'Is my channel too small to take part in campaigns?',
    content: <span>No, there is no minimum size requirement for influencers to take part in campaigns on GameInfluencer.IO.<br /><br />
      GameInfluencer.IO is designed to enable creators of all sizes to monetize their content
      and earn from their passion. So, whether you have a small, medium, or large following,
      you can participate in campaigns and earn from them simultaneously.<br /><br />
      Don't let the size of your channel hold you back from earning money through sponsorships and collaborations!</span>,
  },
  {
    header: 'Can I take part in multiple campaigns?',
    content: <span>Of course, you can participate in as many campaigns as you would like and earn from them all
      simultaneously.</span>,
  },
  {
    header: 'How does payment work?',
    content: <span>Payouts occur once a month. Creators will need to reach a minimum of $100 earned per 30-day cycle to
      activate their payout; if the payout limit is not reached then this amount will carry over to the following
      month and will accumulate.</span>
  },
];

const getTabName = (activeTab) => {
  return tabNames[activeTab] || '';
}

export default function (props) {
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const [openAgencyDialog, setOpenAgencyDialog] = useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);

  const handleTabClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const handleSwitch = () => {
    if (props.creator?.type === UserType.Creator) {
      if (props?.creator?.agencyData?.name) {
        props.switchAccount();
      } else {
        setOpenAgencyDialog(true);
      }
    }
    else {
      props.switchAccount();
    }
  };

  useEffect(() => {
    const socialInData = localStorage.getItem('socialIn');
    if (socialInData) {
      const parsedData = JSON.parse(socialInData);
      if (!parsedData.hasFirsAndLastName) {
        props?.handleAccountSetupRouting();
      }
    }
  }, []);


  return (
    <div className='creator-account'>
      <p className='title'>
        {getTabName(activeTab)}
      </p>
      <div className='content'>
        <div className='tabsWrapper'>
          <div className='tabs'>
            {tabsItems.map((item, index) => (
              <div key={item}
                className={`tabItem ${index === activeTab ? 'tabItemSelected' : 'tabItemNormal'}`}
                onClick={() => setActiveTab(index)}>
                {item}
              </div>
            ))}
          </div>

        </div>
        <div className={'contentMain'}>
          {activeTab === TabStep.MyAccount &&
            <div>
              <div className={'creatorProfileButtonsWrapper'}>
                <Button className={'creatorProfileButton'}
                  onClick={() => props?.handleAccountSetupRouting()}
                >
                  <EditPencilWhite />
                  Edit Profile
                </Button>
                <Button className={'creatorProfileButton'} onClick={() => setShowPaymentInfo(true)}>
                  <PaymentIcon />
                  Edit Payment Info
                </Button>
                <Button className={'creatorProfileButton'} onClick={() => handleSwitch(true)}>
                  <LoopIcon />
                  {props.creator?.type === UserType.Creator ? 'Switch To Agency' : 'Switch to creator'}
                </Button>
              </div>
              <NewCreatorProfile creator={props.creator} channelsYT={props.channelsYT} isCreator={true} user={props.user} openConnectPopup={props.openConnectPopup}
                handleContentRouting={props.handleContentRouting} loadChannels={props.loadChannels} content={props.content} />
            </div>
          }
          {activeTab === TabStep.Login &&
            <div className={'loginWrapper'}>
              <p className={'loginBold'}>Account Login</p>
              <div className={'loginRow'}>
                <p className={'loginLabel'}>Email</p>
                <p className={'loginBold secondColumn'}>{props.creator.email}</p>
              </div>
              <Divider layout="horizontal" className={'loginDivider'} />
              <div className={'loginRow'}>
                <p className={'loginLabel'}>Password</p>
                <p className={'loginBold secondColumn'}>* * * * * * * * * *</p>
                <div className={'buttonWrapper'}>
                  <Button className={'loginChangeButton'} onClick={() => setOpenChangePasswordDialog(true)}>Change</Button>
                </div>
              </div>
            </div>
          }
          {activeTab === TabStep.Earning &&
            <NewInfluencerEarnings />
          }
          {activeTab === TabStep.Support &&
            <div className={'support'}>
              <div>
                <p className={'supportTitle'}>Contact us</p>
                <p className={'supportText'}>Reach out to our team for any questions or queries here: <b>contact@gameinfluencer.com</b></p>
              </div>
              <div className={'faqField'}>
                <p className={'supportTitle'}>FAQ</p>
                <div>
                  {faqItems.map((item, index) => {
                    return (
                      <div key={index}>
                        <div onClick={() => handleTabClick(index)} className={'faqHeader'}>
                          <i className={activeIndex === index ? 'pi pi-plus' : 'pi pi-minus'} style={{ color: '#E73D38', fontSize: '18px', marginRight: '18px' }} />
                          {item.header}
                        </div>
                        {activeIndex === index &&
                          <div className={'faqAnswer'}>
                            <p className={'faqText'}>{item.content}</p>
                          </div>
                        }
                        {index !== (faqItems.length - 1) && <Divider className={'faqDivider'} />}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <NewPaymentInfoDialog hide={() => setShowPaymentInfo(false)} visible={showPaymentInfo} currentPaymentInfo={props.currentPaymentInfo}
        paymentInfoChange={props.paymentInfoChange} />
      <NewRestorePasswordDialog visible={openChangePasswordDialog} onHide={() => setOpenChangePasswordDialog(false)} />
      <Dialog visible={openAgencyDialog} onHide={() => setOpenAgencyDialog(false)} className={'agencyDialog'}>
        <div className={'agencyDialogClose'}>
          <div onClick={() => setOpenAgencyDialog(false)}><CloseIcon /></div>
        </div>
        <div className={'agencyDialogContent'}>
          <p className={'agencyDialogTitle'}>Learn More About Becoming an Agency</p>
          <p className={'agencyDialogText'}>Build the community of influencers, apply to campaigns together and double your chances for success.</p>
          <div className={'agencyDialogIconWrapper'}>
            <StarDocumentIcon />
          </div>
          <Divider className={'agencyDialogDivider'} />
          <div className={'agencyDialogReasoning'}>
            <div>
              <p className={'agencyRedText'}>More Campaigns, More Opportunities</p>
              <p className={'agencyDialogText textLeft'}>With an agency account you can invite other influencers and apply to campaigns together. You can get an access to the agency statistics and more.</p>
            </div>
            <div>
              <p className={'agencyRedText'}>Build a Community</p>
              <p className={'agencyDialogText textLeft'}>Create a community of influencers that have the same interests and chase opportunities together.</p>
            </div>
          </div>
          {/* <p className={'referenceTitle'}>Agency member reference</p>
          <div className={'reference'}>
            <div className={'referenceQuote'}>
              <Image className={'referenceUserPhoto'} alt={'user photo'} src={user} />            
              <p className={'referenceText'}>Lorem ipsum dolor sit amet consectetur. Feugiat nec felis pellentesque vitae sed lobortis sed convallis dictum. Nullam odio ornare sodales fusce vel nunc.</p>
            </div>
            <p className={'referenceUserName'}>— Agency Member Name</p>
          </div> */}
          <Button className={'becomeAnAgencyButton'} onClick={async () => {
            await props.switchAccount();
            setOpenAgencyDialog(false);
          }}>Become an agency</Button>
        </div>
      </Dialog>
    </div>
  )
}
