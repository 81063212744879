import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewEarnings = _resolveComponent("NewEarnings")!

  return (_ctx.isNewAdminEnabled)
    ? (_openBlock(), _createBlock(_component_NewEarnings, {
        key: 0,
        earnings: _ctx.earnings,
        loading: _ctx.isLoading,
        changePage: _ctx.handlePageChange,
        changeType: _ctx.handleChangeType,
        limit: _ctx.limit
      }, null, 8, ["earnings", "loading", "changePage", "changeType", "limit"]))
    : _createCommentVNode("", true)
}