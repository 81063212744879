import React, { useEffect, useState } from "react";
import "./NewMyContent.css";
import {
  ViewsIconTransparent,
  ClicksIconTransparent,
  WalletIcon,
  MoneyIcon,
  CopyIcon,
  VideosIconRed,
  VideosIconGrey,
  PlayIconRed, PlayIconGrey, BackIcon, CommentsIcon, LikesIcon
} from "@/react_app/img/Icons";
import { useCreators } from "@/modules";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";

const contentTabs = [
  { value: 'All', type: 'all' },
  { value: 'Public', type: true },
  { value: 'Drafts', type: false },
  { value: 'Pending', type: 'pending' },
  { value: 'Declined', type: 'declined' },
];

const sideMenuItems = [
  { activeIcon: <VideosIconRed/>, normalIcon: <VideosIconGrey/>, title: 'My Videos', route: 'my-content-items/my' },
  { activeIcon: <PlayIconRed/>, normalIcon: <PlayIconGrey/>, title: 'Campaigns', route: 'my-campaigns'},
];

const videoBodyTemplate = (rowData) => {
  return (
    <div className={'videoTemplate'}>
      <Image src={rowData.previewPhoto} alt={'video thumbnail'} className={'videoTemplateImage'}/>
      <div className={'videoTemplateText'}>
        <p className={'videoTemplateTitle'}>{rowData.title}</p>
        <div className={'videoTemplateLink'}><span>{rowData.url}</span> <CopyIcon onClick={() => navigator.clipboard.writeText(rowData.url)}/></div>
      </div>
    </div>
  );
};

const statusBodyTemplate = (rowData) => {
  return (
    <div className={rowData.public ? 'statusTemplatePublic' : 'statusTemplateDraft'}>
      {rowData.public ? 'Public' : 'Draft'}
    </div>
  );
};

const dateBodyTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      <p className={'dateValue'}>{new Date(rowData.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
      <p className={'dateText'}>{rowData.public ? 'Published' : 'Uploaded'}</p>
    </div>
  )
}

const viewsBodyTemplate = (rowData) => {
  return (
    <div className={'statsTemplate'}>
      {rowData.statistics?.viewCount || '-'}
    </div>
  )
}

const likesBodyTemplate = (rowData) => {
  return (
    <div className={'statsTemplate'}>
      {rowData.statistics?.likeCount || '-'}
    </div>
  )
}

const commentBodyTemplate = (rowData) => {
  return (
    <div className={'statsTemplate'}>
      {rowData.statistics?.commentCount || '-'}
    </div>
  )
}

export default function(props){
  const [totalViews, setTotalViews] = useState(0);
  const [totalClicks, setTotalClicks] = useState(0);
  const [totalComments, setTotalComments] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const [monthlyEarnings, setMonthlyEarnings] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const creators = useCreators();

  useEffect(() => {
    creators.manager.overallMonthlyEarningSoFar(props.user?.id).then((result) =>
      setTotalEarnings(result.amount));
    creators.manager.overallMonthlyEarningSoFar(props.user?.id).then((result) =>
      setMonthlyEarnings(result.amount));
  }, [props.user]);

  useEffect(() => {
    let sumViews = 0, sumLikes = 0, sumComments = 0;

    props.contentItems?.forEach(item => {
      sumViews += parseInt(item.statistics?.viewCount || 0);
      sumLikes += parseInt(item.statistics?.likeCount || 0);
      sumComments += parseInt(item.statistics?.commentCount || 0);
    });
    setTotalViews(sumViews);
    setTotalLikes(sumLikes);
    setTotalComments(sumComments);
  }, [props.contentItems]);

  useEffect(() => {
    props.selectStatusCategory(contentTabs[activeTab].type);
    setSearchValue('');
  }, [activeTab]);

  const overviewItems = [
    {
      title: 'Total Views',
      icon: <ViewsIconTransparent />,
      value: totalViews,
    },
    {
      title: 'Total Clicks',
      icon: <ClicksIconTransparent />,
      value: totalClicks,
    },
    {
      title: 'Total Comments',
      icon: <CommentsIcon />,
      value: totalComments,
    },
    {
      title: 'Total Likes',
      icon: <LikesIcon />,
      value: totalLikes,
    },
  ];

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    props.searchValueChange(e.target.value);
  }

  return(
    <div className={'my-content'}>
      <div className={'sideMenu'}>
        <div className={'goBack'} onClick={() => props.goBack()}>
          <BackIcon />
          Back
        </div>
        <div className={'sideMenuItemsWrapper'}>
          {sideMenuItems.map((item, index) =>
            <div key={index} className={item === sideMenuItems[0] ? 'sideMenuItemSelected' : 'sideMenuItem'} onClick={() => props.routeTo(item.route)}>
              {item.activeIcon}
              <p className={'sideMenuItemTitle'}>{item.title}</p>
            </div>)}
        </div>
      </div>
      <div className={'mainContent'}>
        <div className={'title'}>My Video Content ({props.contentItems?.length})</div>
        <div className={'topLayer'}>
          <div className={'overviewWrapper'}>
            <p className={'sectionLabel'}>Overview</p>
            <div className={'overviewSquare'}>
              {overviewItems.map((item, index) =>
                <div className={'overviewItem'} key={index}>
                  <div className={'overviewItemIcon'}>
                    {item.icon}
                  </div>
                  <div className={'overviewItemText'}>
                    <p className={'overviewItemTitle'}>{item.title}</p>
                    <p className={'overviewItemValue'}>{item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                  </div>
                </div>)}
            </div>
          </div>
          <div className={'earningsWrapper'}>
            <p className={'sectionLabel'}>Earning</p>
            <div className={'earningsFieldsWrapper'}>
              <div className={'monthlyEarnings'}>
                <WalletIcon/>
                <p className={'overviewItemTitle'}>Overall Monthly Earning so far:</p>
                <p className={'overviewItemValue'}>$ {Math.round(monthlyEarnings * 100) / 100}</p>
              </div>
              <div className={'totalEarnings'}>
                <MoneyIcon/>
                <p className={'overviewItemTitle'}>Total Earning so far:</p>
                <p className={'overviewItemValue'}>$ {Math.round(totalEarnings * 100) / 100}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={'tabsWrapper'}>
            <div className={'tabs'}>
              {contentTabs.map((item, index) =>
                <div key={index} className={index === activeTab ? 'tabSelected' : 'tabNormal'} onClick={() => setActiveTab(index)}>
                  {item.value}
                </div>)}
            </div>
          </div>
          <div className={'campaignsSearchBarWrapper'}>
            <i className="pi pi-search searchIcon"/>
            <InputText className={'campaignsSearchBar'} placeholder={'Search for video content'} value={searchValue} onChange={(e) => handleSearch(e)}/>
          </div>
        </div>
        <DataTable value={props.contentItems} removableSort emptyMessage={"No live content found."} onRowClick={(e) => props.goToVideo(e.data.id)}>
          <Column field={"title"} header={"Video"} body={videoBodyTemplate} sortable style={{ width: '40%' }}></Column>
          <Column field={"public"} header={"Status"} body={statusBodyTemplate} sortable style={{ width: '12%' }}></Column>
          <Column field={"createdAt"} header={"Date"} body={dateBodyTemplate} sortable style={{ width: '12%' }}></Column>
          <Column field={"statistics.viewCount"} header={"Views"} body={viewsBodyTemplate} sortable style={{ width: '12%' }}></Column>
          <Column field={"statistics.likeCount"} header={"Likes"} body={likesBodyTemplate} sortable style={{ width: '12%' }}></Column>
          <Column field={"statistics.commentCount"} header={"Comments"} body={commentBodyTemplate} sortable style={{ width: '12%' }}></Column>
        </DataTable>
      </div>
    </div>
  )
}
