import React, { useRef } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { cancelOptions, chooseOptions, uploadOptions } from './CreateCampaignAdditional';

const AttachmentsUploader = ({
  attachments, setAttachments, isEditMode, isNewUpload, setIsNewUpload
}) => {
  const fileInputRef = useRef(null);

  const handleDeleteAttachment = (file) => {
    const updatedAttachments = attachments.filter(attachment => !isSameFile(attachment, file));
    setAttachments(updatedAttachments);
    if (updatedAttachments.length === 0) {
      setIsNewUpload(false);
      if (fileInputRef.current) {
        fileInputRef.current.clear();
      }
    }
  };

  const onClear = () => {
    setAttachments([]);
    setIsNewUpload(false);
  };

  const onTemplateSelect = (e) => {
    const { files } = e;
    const combinedAttachments = [...attachments, ...files];
    const seen = new Set();

    const filteredAttachments = combinedAttachments.filter(item => {
      const url = item.url || item.objectURL || item.name;
      if (!url || seen.has(url)) {
        return false;
      }
      seen.add(url);
      return true;
    });

    setAttachments(filteredAttachments);
    setIsNewUpload(true);

  };

  const isSameFile = (attachment, file) => {
    return (attachment.url && attachment.url === file.url) || (attachment.objectURL && attachment.objectURL === file.objectURL) ||
      (attachment.name && attachment.name === file.name && attachment.size === file.size)
      ;
  };

  const onTemplateRemove = (e) => {
    const { file } = e;
    const updatedAttachments = attachments.filter(attachment => !isSameFile(attachment, file));
    setAttachments(updatedAttachments);
  };

  const FileUploadRow = ({ file, onDelete }) => (
    <div className="p-fileupload-row" data-pc-section="file">
      <img
        role="presentation"
        className="p-fileupload-file-thumbnail"
        src={file.url ?? file.objectURL}
        width="50"
        data-pc-section="thumbnail"
        alt={file.name}
      />
      <div data-pc-section="details">
        <div className="p-fileupload-filename" data-pc-section="filename">{file.name}</div>
        <span data-pc-section="filesize">{file.size}</span>
        <span className="p-fileupload-file-badge p-badge p-component p-badge-warning">Pending</span>
      </div>
      <div data-pc-section="actions">
        <button
          className="p-button p-component p-button-icon-only p-button-text p-button-rounded p-button-danger"
          type="button"
          data-pc-name="button"
          data-pc-section="root"
          onClick={() => onDelete(file)}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="p-icon"
            aria-hidden="true"
          >
            <path
              /* eslint-disable-next-line max-len,vue/max-len */
              d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z"
              fill="currentColor"
            />
          </svg>
          <span className="p-button-label p-c">&nbsp;</span>
        </button>
      </div>
    </div>
  );

  const FileUploadList = ({ files, onDelete, isNewUpload }) => (
    <div className={`uploaded-attachments-list ${isNewUpload ? 'show' : 'hide'}`}>
      {(!isEditMode && files.length < 1)
        ? <p className={'uploadText'}>Drag and drop files to here to upload.</p>
        : files.map(file => <FileUploadRow key={file.id} file={file} onDelete={onDelete} />)}
    </div>
  );

  return (
    <div className={`file-upload-wrap ${!isEditMode ? 'new-upload create' : 'edit-mode'} ${isNewUpload ? 'new-upload' : ''}`}>
      <p className={'talkingPointsTitle'}>Attachments</p>
      <FileUpload
        ref={fileInputRef}
        className={'fileUpload'}
        multiple
        emptyTemplate={
          !!isEditMode && attachments.length > 0 ? (
            <FileUploadList key="one" files={attachments} onDelete={handleDeleteAttachment} isNewUpload={false} />
          ) : (
            <p className={'uploadText'}>Drag and drop files to here to upload.</p>
          )
        }
        onRemove={onTemplateRemove}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        onSelect={onTemplateSelect}
        chooseLabel="Upload"
        onClear={onClear}
        {...(isEditMode ? {} : { itemTemplate: () => null })}
      />
      {isNewUpload && !!isEditMode && (
        <FileUploadList
          files={[...attachments.filter(attachment => !!isEditMode ? !!attachment.url : !!attachment.objectUrl)]}
          onDelete={handleDeleteAttachment}
          isNewUpload={true} />
      )}
      {!isEditMode && <FileUploadList
        key="two"
        files={[...attachments.filter(attachment => !!attachment)]}
        onDelete={handleDeleteAttachment}
        isNewUpload={true} />}
    </div>
  );
};

export default AttachmentsUploader;
