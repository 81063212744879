
import { ref, defineComponent } from 'vue';

export default defineComponent({
  props: {
    isMobile: Boolean,
    view: String,
  },
  setup() {
    const sectionRef = ref<HTMLElement | null>(null);
    return {
      sectionRef,
    };
  },

});
