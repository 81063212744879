import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useUgcManager = (perspective?: AppPagePerspective) => {
  const toast = useToastModule();

  const {
    loading: uploadUgcDraftVideoLoading,
    error: uploadUgcDraftVideoError,
    data: uploadUgcDraftVideoResult,
    execute: uploadUgcDraftVideoRequest,
  } = useAPI('', false);

  const uploadUgcDraftVideo = (
    creatorId: string,
    campaignId: string,
    googleDriveLinks: any,
    channel: any
  ) =>
    uploadUgcDraftVideoRequest({
      url: `/content/create-unlisted/ugc/${creatorId}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { campaignId, googleDriveLinks, channel },
    });
  watch(uploadUgcDraftVideoError, toast.handleRequestError);

  //uploadUgcPublishVideo
  const {
    loading: uploadUgcPublishVideoLoading,
    error: uploadUgcPublishVideoError,
    data: uploadUgcPublishVideoResult,
    execute: uploadUgcPublishVideoRequest,
  } = useAPI('', false);

  const uploadUgcPublishVideo = (
    creatorId: string,
    campaignId: string,
    googleDriveLinks: any,
    channel: any
  ) =>
    uploadUgcPublishVideoRequest({
      url: `/content/create/ugc/${creatorId}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { campaignId, googleDriveLinks, channel },
    });
  watch(uploadUgcPublishVideoError, toast.handleRequestError);

  // Resolve Requests
  const {
    loading: resolveUGCLinksRequestsProgress,
    error: resolveUGCLinksRequestsError,
    data: resolveUGCLinksRequestsData,
    execute: resolveRequest,
  } = useAPI('', false);

  const resolveUGCLinksRequestsAction = (
    action: 'accept' | 'reject' | string,
    contentId: string,
    payload: {
      concept: number,
      ratio: string,
      rejectReason?: string,
    }[]
  ) =>
    resolveRequest({
      url: `/admins/contents/${contentId}/${action}`,
      method: 'POST',
      data: payload,
    });

  const {
    loading: getUGCUploadedVideosLinksProgress,
    error: getUGCUploadedVideosLinksError,
    data: getUGCUploadedVideosLinksResult,
    execute: getUGCUploadedVideosLinksRequest,
  } = useAPI('', false);
  const getUGCUploadedVideosLinks = (campaignId: string, type: boolean, channelId: string) => getUGCUploadedVideosLinksRequest({
    url: `/content/list/campaign/${campaignId}/${type}`,
    method: 'GET',
    params: { channelId },
  });
  watch(getUGCUploadedVideosLinksError, toast.handleRequestError);



  const {
    loading: uploadUgcVideoLoading,
    error: uploadUgcVideoError,
    data: uploadUgcVideoResult,
    execute: uploadUgcVideoRequest,
  } = useAPI('', false);

  const uploadUgcVideo = (campaignId: string, formData: FormData) =>
    uploadUgcVideoRequest({
      url: `/content/create-unlisted/video/${campaignId}`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });

  watch(uploadUgcVideoError, toast.handleRequestError);


  const {
    loading: deleteUgcVideoLoading,
    error: deleteUgcVideoError,
    data: deleteUgcVideoResult,
    execute: deleteUgcVideoRequest,
  } = useAPI('', false);

  const deleteUgcVideo = (campaignId: string, urlData: { url: string, thumbnailUrl: string, ratio: string, concept: number, channelId?: string }) =>
    deleteUgcVideoRequest({
      url: `/content/create-unlisted/video/${campaignId}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: urlData,
    });

  watch(deleteUgcVideoError, toast.handleRequestError);


  return {
    uploadUgcDraftVideo,
    uploadUgcDraftVideoLoading,
    uploadUgcDraftVideoResult,

    uploadUgcPublishVideo,
    uploadUgcPublishVideoLoading,
    uploadUgcPublishVideoResult,

    resolveUGCLinksRequestsAction,
    resolveUGCLinksRequestsProgress,
    resolveUGCLinksRequestsError,
    resolveUGCLinksRequestsData,

    getUGCUploadedVideosLinksProgress,
    getUGCUploadedVideosLinksError,
    getUGCUploadedVideosLinksResult,
    getUGCUploadedVideosLinks,

    uploadUgcVideo,
    uploadUgcVideoResult,
    uploadUgcVideoLoading,

    deleteUgcVideo,
    deleteUgcVideoResult,
    deleteUgcVideoLoading,
  };
};
