
import { defineComponent, ref } from 'vue';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import { emailRegex } from '@/utils/globals/regex';
import { useToastModule, useUsers } from '@/modules';
import { scrollToComponent, copyToClipboard } from './utils';

export default defineComponent({
  props: {
    view: {
      type: String,
    },
    isMobile: Boolean,
  },
  components: {
    InputText,
    Button
  },
  setup() {

    const users = useUsers();
    const toast = useToastModule();

    const email = ref('');
    const currentYear = ref(new Date().getFullYear());

    const openExternalLink = (link: string) => {
      window.open(link, '_blank');
    }
    const handleChangeEvent = async () => {
      users.manager.registerNewsletter(email.value).then((response) => {
        if (response)
          toast.showSuccess('You are successfully registered with newsletter');
      });
    };

    return {
      scrollToComponent,
      copyToClipboard,
      currentYear,
      openExternalLink,
      handleChangeEvent,
      email, emailRegex
    };
  },
});
