
import Carousel from 'primevue/carousel';
import { defineComponent, ref, computed, onMounted } from 'vue';

export default defineComponent({
  components: {
    Carousel
  },
  setup() {
    const carouselItems = ref([
      {
        image: require('@/assets/landing/case-study-1.png'),
        logo: require('@/assets/landing/case-study-logo-1.svg'),
        link: "https://gameinfluencer-webclient-bucket-prod.s3.eu-central-1.amazonaws.com/misc/HSR_+Aug+-+May+G.IO+Campaigns+Case+Study.pdf",
        campaignName: 'Honkai: Star Rail',
        influencerCount: 240,
        marketCount: 6,
        totalViews: 12000000,
      },
      {
        image: require('@/assets/landing/case-study-2.png'),
        logo: require('@/assets/landing/case-study-logo-new-2.svg'),
        link: 'https://gameinfluencer-webclient-bucket-prod.s3.eu-central-1.amazonaws.com/misc/RAID+Shadow+Legends+Case+Study.pdf',
        campaignName: 'RAID: Shadow Legends',
        influencerCount: 28,
        videoAds: 31,
        totalViews: 1900000,
      },
      {
        image: require('@/assets/landing/case-study-3.png'),
        logo: require('@/assets/landing/case-study-logo-3.svg'),
        link: 'https://gameinfluencer-webclient-bucket-prod.s3.eu-central-1.amazonaws.com/misc/X8+-+Case+Study.pdf',
        campaignName: 'X8 VR Hero Shooter',
        videoAds: 20,
        totalClicks: 6300,
        totalViews: 30000,
      },
      {
        image: require('@/assets/landing/case-study-4.png'),
        logo: require('@/assets/landing/case-study-logo-4.svg'),
        link: 'https://gameinfluencer-webclient-bucket-prod.s3.eu-central-1.amazonaws.com/misc/Albion+Online+UGC+Campaign+Case+Study.pdf',
        campaignName: 'Albion Online',
        influencerCount: 14,
        videoAds: 14,
        totalViews: 506000,
      },
      {
        image: require('@/assets/landing/case-study-5.png'),
        logo: require('@/assets/landing/case-study-logo-5.svg'),
        link: 'https://gameinfluencer-webclient-bucket-prod.s3.eu-central-1.amazonaws.com/misc/Warhammer+Combat+Cards+Case+Study.pdf',
        campaignName: 'Warhammer Combat Cards',
        influencerCount: 32,
        totalClicks: 5000,
        totalViews: 13000,
      },
      {
        image: require('@/assets/landing/case-study-6.png'),
        logo: require('@/assets/landing/case-study-logo-6.svg'),
        link: 'https://gameinfluencer-webclient-bucket-prod.s3.eu-central-1.amazonaws.com/misc/Warpath+Case+Study.pdf',
        campaignName: 'Warpath: Ace Shooter',
        influencerCount: 7,
        engagement: 11600,
        totalViews: 415000,
      },
    ]);

    const responsiveOptions = ref([
      {
        breakpoint: '2500px',
        numVisible: 5,
        numScroll: 1
      },
      {
        breakpoint: '1900px',
        numVisible: 4,
        numScroll: 1
      },
      {
        breakpoint: '1400px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '1000px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      }
    ]);

    function formatNumber(number: number): string {
      return number.toLocaleString('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      });
    }

    function openLink(link: string | undefined) {
      if (link) {
        window.open(link, '_blank');
      }
    }

    return {
      carouselItems,
      responsiveOptions,
      formatNumber,
      openLink
    };
  },
});
