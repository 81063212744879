import { watch } from "vue";
import { useAPI, useAuth } from "@/modules";
import { AppPagePerspective, AgencyWorker, CampaignData, AgencyApplyPayload } from "@/data/types";
import { useToastModule } from "@/modules/messages/toast-module";

export const useAgencyManager = (perspective: AppPagePerspective) => {
  const { user } = useAuth();
  const toast = useToastModule();

  const { loading: switchAccountLoading, error: switchAccountError, data: switchAccountResult, execute: switchAccountReq } = useAPI("", false);

  const switchAccount = () =>
    switchAccountReq({
      url: "/agency/switch-account",
      method: "POST",
    });
  watch(switchAccountError, toast.handleRequestError);
  // < switchAccount

  const { loading: addCreatorLoading, error: addCreatorError, data: addCreatorResult, execute: addCreatorReq } = useAPI("", false);

  const addCreator = (channelData: AgencyWorker) =>
    addCreatorReq({
      url: "/agency/add-channel",
      method: "POST",
      data: { channelData },
    });
  watch(addCreatorError, toast.handleRequestError);
  // < add creator

  const { loading: deleteChannelLoading, error: deleteChannelError, data: deleteChannelResult, execute: deleteChannelReq } = useAPI("", false);

  const deleteChannel = (channelId: string) =>
    deleteChannelReq({
      url: "/agency/delete-channel",
      method: "DELETE",
      data: { channelId },
    });
  watch(deleteChannelError, toast.handleRequestError);
  // < delete

  const { loading: searchChannelsLoading, error: searchChannelsError, data: searchChannelsResult, execute: searchChannelsReq } = useAPI("", false);
  const searchChannels = (searchValue: string) =>
    searchChannelsReq({
      url: "/agency/search-channels",
      method: "GET",
      params: { searchValue },
    });
  watch(searchChannelsError, toast.handleRequestError);
  // < search youtube channels

  //search youtube channels by link

  const { loading: searchChannelsByLinkLoading, error: searchChannelsByLinkError, data: searchChannelsByLinkResult, execute: searchChannelsByLinkReq } = useAPI("", false);
  const searchChannelByLink = (url: string) =>
    searchChannelsByLinkReq({
      url: "/agency/search-channels-by-link",
      method: "GET",
      params: { url },
    });
  watch(searchChannelsByLinkError, toast.handleRequestError);

  const { loading: applyChannelsCampaignLoading, error: applyChannelsCampaignError, data: applyChannelsCampaignResult, execute: applyChannelsCampaignReq } = useAPI("", false);

  const applyChannelsCampaign = (payload: AgencyApplyPayload, campaignId: string) =>
    applyChannelsCampaignReq({
      url: `/agency/accept-campaign/${campaignId}`,
      method: "POST",
      data: payload,
    });
  watch(applyChannelsCampaignError, toast.handleRequestError);
  // < add creator

  const {
    loading: withdrawCampaignChannelCampaign,
    error: withdrawCampaignChannelError,
    data: withdrawCampaignChannelResult,
    execute: withdrawCampaignChannelReq,
  } = useAPI<CampaignData>("", false);
  const withdrawCampaignChannel = (campaignID: string, applicationID: string, channelId: string) =>
    withdrawCampaignChannelReq({
      url: `/campaigns/applications/${campaignID}/${applicationID}/${channelId}`,
      method: "DELETE",
    });
  watch(withdrawCampaignChannelError, toast.handleRequestError);
  // < withdraw campaign

  const { loading: createAgencyCampaign, error: createAgencyError, data: createAgencyResult, execute: createAgencyReq } = useAPI("", false);
  const createAgency = (agencyPayload: { agencyName: string; agencyDescription: string; agencyPhoto: string; photoLink?: string }) =>
    createAgencyReq({
      url: "/agency/create-agency",
      method: "POST",
      data: agencyPayload,
    });
  watch(createAgencyError, toast.handleRequestError);
  // < create agency

  const { loading: confirmCreateAgencyLoading, error: confirmCreateAgencyError, data: confirmCreateAgencyResult, execute: confirmCreateAgencyReq } = useAPI("", false);
  const confirmCreateAgency = (secretCode: string, userId: string) =>
    confirmCreateAgencyReq({
      url: "/agency/confirm-create-agency",
      method: "POST",
      data: { secretCode, userId },
    });
  watch(confirmCreateAgencyError, toast.handleRequestError);
  // < confirm create agency

  const { loading: getEarningsForAgencyLoading, error: getEarningsForAgencyError, data: getEarningsForAgencyResult, execute: getEarningsForAgencyRequest } = useAPI("", false);
  const getEarningsForAgency = (limit = 50, skip = 0, eligiblePaymentsOnly = false, agencyId: string) =>
    getEarningsForAgencyRequest({
      url: `/agency/earnings`,
      method: "GET",
      params: { limit, skip, eligiblePaymentsOnly, agencyId },
    });
  watch(getEarningsForAgencyError, toast.handleRequestError);
  // < overview brands


  const { loading: getAgencyWorkersCampaignParticipationsLoading, error: getAgencyWorkersCampaignParticipationsError, data: getAgencyWorkersCampaignParticipationsResult, execute: getAgencyWorkersCampaignParticipationsRequest } = useAPI("", false);
  const getAgencyWorkersCampaignParticipations = (agencyId: string, campaignId: string, status: string, fetchApplicationObjectOnly = true) =>
    getEarningsForAgencyRequest({
      url: `/agency/${agencyId}/applied/${status}${campaignId ? `/${campaignId}` : ""}`,
      method: "GET",
      params: { fetchApplicationObjectOnly },
    });
  watch(getEarningsForAgencyError, toast.handleRequestError);
  // < overview brands
  return {
    getAgencyWorkersCampaignParticipationsLoading,
    getAgencyWorkersCampaignParticipationsError,
    getAgencyWorkersCampaignParticipationsResult,
    getAgencyWorkersCampaignParticipations,
    getEarningsForAgencyLoading,
    getEarningsForAgencyError,
    getEarningsForAgencyResult,
    getEarningsForAgency,
    confirmCreateAgency,
    confirmCreateAgencyResult,
    confirmCreateAgencyError,
    confirmCreateAgencyLoading,
    // confirm
    createAgency,
    createAgencyResult,
    createAgencyError,
    createAgencyCampaign,
    // create new agency
    withdrawCampaignChannel,
    withdrawCampaignChannelResult,
    withdrawCampaignChannelError,
    withdrawCampaignChannelCampaign,
    // < withdraw campaign
    applyChannelsCampaign,
    applyChannelsCampaignResult,
    applyChannelsCampaignError,
    applyChannelsCampaignLoading,
    // apy channels campaign
    searchChannelsLoading,
    searchChannelsError,
    searchChannelsResult,
    searchChannels,
    // search channels
    addCreator,
    addCreatorResult,
    addCreatorError,
    addCreatorLoading,
    // < add creator
    deleteChannel,
    deleteChannelResult,
    deleteChannelError,
    deleteChannelLoading,
    // delete creator
    switchAccount,
    switchAccountResult,
    switchAccountError,
    switchAccountLoading,
    // switchAccount

    //search by link
    searchChannelByLink,
    searchChannelsByLinkLoading,
    searchChannelsByLinkResult
  };
};
