import { watch } from "vue";
import { useAPI } from "@/modules";
import { CampaignList } from "@/data/types";
import { useToastModule } from "@/modules/messages/toast-module";

export const useAdminsManager = () => {
  const toast = useToastModule();

  const { loading: checkAdminLoading, error: checkAdminError, data: checkAdminResult, execute: checkAdminRequest } = useAPI("", false);
  const checkAdmin = () =>
    checkAdminRequest({
      url: "/admins",
      method: "GET",
    });
  watch(checkAdminError, toast.handleRequestError);
  // < get status admin

  const { loading: getCreatorsCountLoading, error: getCreatorsCountError, data: getCreatorsCountResult, execute: getCreatorsCountRequest } = useAPI("", false);
  const getCreatorsCount = () =>
    getCreatorsCountRequest({
      url: "/admins/stats/count-creators",
      method: "GET",
    });
  watch(getCreatorsCountError, toast.handleRequestError);
  // < get status admin

  const { loading: getCreatorsEarningsLoading, error: getCreatorsEarningsError, data: getCreatorsEarningsResult, execute: getCreatorsEarningsRequest } = useAPI("", false);
  const getCreatorsEarnings = (limit = 10, skip = 0) =>
    getCreatorsEarningsRequest({
      url: "/admins/stats/creators-earning-list",
      method: "GET",
      params: {
        limit,
        skip,
      },
    });
  watch(getCreatorsEarningsError, toast.handleRequestError);
  // < get stats creators

  const { loading: getBrandsCountLoading, error: getBrandsCountError, data: getBrandsCountResult, execute: getBrandsCountRequest } = useAPI("", false);
  const getBrandsCount = () =>
    getBrandsCountRequest({
      url: "/admins/stats/count-brands",
      method: "GET",
    });
  watch(getBrandsCountError, toast.handleRequestError);
  // < get count brands

  const { loading: getAdminsCountLoading, error: getAdminsCountError, data: getAdminsCountResult, execute: getAdminsCountRequest } = useAPI("", false);
  const getAdminsCount = () =>
    getAdminsCountRequest({
      url: "/admins/stats/count-admins",
      method: "GET",
    });
  watch(getAdminsCountError, toast.handleRequestError);
  // < get count admins

  const { loading: getAllCampaignsLoading, error: getAllCampaignsError, data: getAllCampaignsResult, execute: getAllCampaignsRequest } = useAPI("", false);
  const getAllCampaigns = (limit = 10, skip = 0, status: string, searchValue?: string) =>
    getAllCampaignsRequest({
      url: `/admins/campaigns/${limit}/${skip}`,
      method: "GET",
      params: { status, searchValue },
    });
  watch(getAllCampaignsError, toast.handleRequestError);
  // < get all campaigns

  const { loading: getCampaignContentLoading, error: getCampaignContentError, data: getCampaignContentResult, execute: getCampaignContentRequest } = useAPI("", false);
  const getCampaignContent = (campaignId: string, limit = 10, skip = 0, searchValue?: string) =>
    getCampaignContentRequest({
      url: `/admins/contents/campaign-contents/${campaignId}`,
      method: "GET",
      params: { searchValue, limit, skip },
    });
  watch(getCampaignContentError, toast.handleRequestError);
  // < get all campaigns

  const { loading: updateCampaignDataLoading, error: updateCampaignDataError, data: updateCampaignDataResult, execute: updateCampaignDataRequest } = useAPI("", false);
  const updateCampaignData = (campaignId: string, data: { budget?: { amount: number; currency: string }; status?: string }) =>
    updateCampaignDataRequest({
      url: `/admins/update-campaign/${campaignId}`,
      method: "PUT",
      data,
    });
  watch(getCampaignContentError, toast.handleRequestError);
  // < get all campaigns

  const {
    loading: getCampaignsWithPendingVideoCpmLoading,
    error: getCampaignsWithPendingVideoCpmError,
    data: getCampaignsWithPendingVideoCpmResult,
    execute: getCampaignsWithPendingVideoCpmRequest,
  } = useAPI("", false);
  const getCampaignsWithPendingVideoCpm = (
    limit: number,
    skip: number,
    statusAccept = "pending",
    filters?: { campaignName: string; authorName: string; date?: any[]; ownerName?: string; ownerType?: string }
  ) =>
    getCampaignsWithPendingVideoCpmRequest({
      url: "/admins/campaign-pending-videos",
      method: "GET",
      params: {
        limit,
        skip,
        statusAccept,
        filters,
      },
    });

  watch(getCampaignsWithPendingVideoCpmError, toast.handleRequestError);
  // < get all pending video campaigns

  const { loading: approvalCpmVideoLoading, error: approvalCpmVideoError, data: approvalCpmVideoResult, execute: approvalCpmVideoRequest } = useAPI("", false);
  const approvalCpmVideo = (contentItemId: string, approveReason: string) =>
    approvalCpmVideoRequest({
      url: "/admins/campaign-pending-videos/approve",
      method: "POST",
      params: {
        contentItemId,
        approveReason,
      },
    });
  watch(approvalCpmVideoError, toast.handleRequestError);

  // Calculate the expenses the campaign has to pay to creators by campaignId
  const { loading: getCampaignExpensesLoading, error: getCampaignExpensesError, data: getCampaignExpensesResult, execute: getCampaignExpensesRequest } = useAPI("", false);
  const getCampaignExpenses = (campaignId: string, includeCpiEarnings = true) =>
    getCampaignExpensesRequest({
      url: `/admins/expenses/${campaignId}/cpi=${includeCpiEarnings}`,
      method: "GET",
    });
  watch(getCampaignExpensesError, toast.handleRequestError);

  // Calculate the expenses for multiple campaigns using skip and limit count params (used in admin panel)
  const {
    loading: getMultipleCampaignExpensesLoading,
    error: getMultipleCampaignExpensesError,
    data: getMultipleCampaignExpensesResult,
    execute: getMultipleCampaignExpensesRequest,
  } = useAPI("", false);
  const getMultipleCampaignExpenses = (limit: number, skip: number, statusValue: string, includeCpiEarnings = true) =>
    getMultipleCampaignExpensesRequest({
      url: `/admins/expenses/multiple/${limit}/${skip}/cpi=${includeCpiEarnings}`,
      method: "GET",
      params: {
        status: statusValue,
      }
    });
  watch(getMultipleCampaignExpensesError, toast.handleRequestError);

  const { loading: rejectCpmVideoLoading, error: rejectCpmVideoError, data: rejectCpmVideoResult, execute: rejectCpmVideoRequest } = useAPI("", false);
  const rejectCpmVideo = (contentItemId: string, rejectReason: string) =>
    rejectCpmVideoRequest({
      url: "/admins/campaign-pending-videos/reject",
      method: "POST",
      params: {
        contentItemId,
        rejectReason,
      },
    });
  watch(rejectCpmVideoError, toast.handleRequestError);

  const { loading: getOverviewBrandsLoading, error: getOverviewBrandsError, data: getOverviewBrandsResult, execute: getOverviewBrandsRequest } = useAPI("", false);
  const getOverviewBrands = (limit = 10, skip = 0, searchValue?: string) =>
    getOverviewBrandsRequest({
      url: `/admins/brands/overview`,
      method: "GET",
      params: { limit, skip, searchValue },
    });
  watch(getOverviewBrandsError, toast.handleRequestError);
  // < overview brands

  const { loading: getEarningsForAdminLoading, error: getEarningsForAdminError, data: getEarningsForAdminResult, execute: getEarningsForAdminRequest } = useAPI("", false);
  const getEarningsForAdmin = (limit = 10, skip = 0, eligiblePaymentsOnly = false, search?:string,campaignType?:string) =>
    getEarningsForAdminRequest({
      url: `/admins/payments/overview`,
      method: "GET",
      params: {
            limit, skip, eligiblePaymentsOnly,search, campaignType 
      },
    });
  watch(getEarningsForAdminError, toast.handleRequestError);
  // < overview brands

  const { loading: getDetectedFraudsLoading, error: getDetectedFraudsError, data: getDetectedFraudsResult, execute: getDetectedFraudsRequest } = useAPI("", false);
  const getDetectedFrauds = (includeDismissed = true, limit?:number,skip?:number) =>
    getDetectedFraudsRequest({
      url: `/admins/frauds`,
      method: "GET",
      params: { includeDismissed,limit,skip },
    });
  watch(getDetectedFraudsError, toast.handleRequestError);

  const { loading: dismissFraudLoading, error: dismissFraudError, data: dismissFraudData, execute: dismissFraudRequest } = useAPI("", false);
  const dismissFraud = (fraudId: string) =>
    dismissFraudRequest({
      url: `/admins/frauds/dismiss/${fraudId}`,
      method: "POST",
    });
  watch(getDetectedFraudsError, toast.handleRequestError);

  const {
    loading: getCpmCampaignApplicationsLoading,
    error: getCpmCampaignApplicationsError,
    data: getCpmCampaignApplicationsResult,
    execute: getCpmCampaignApplicationsRequest,
  } = useAPI("", false);
  const getCpmCampaignApplications = (limit: number, skip: number, status = "pending", filters?: { campaignName: string; username: string }) =>
    getCpmCampaignApplicationsRequest({
      url: "/admins/cpm-applications",
      method: "GET",
      params: {
        limit,
        skip,
        status,
        filters,
      },
    });

    const { loading: statisticsLoading, error: statisticsError, data:statisticsData, execute: statisticsRequest } = useAPI("", false);
    const getAdminStatistics = () =>
      statisticsRequest({
        url: '/admins/stats/statistics',
        method: "GET",
      });
    watch(getDetectedFraudsError, toast.handleRequestError);

    const { loading: deleteCampaignLoading, error: deleteCampaignError, data: deleteCampaignResult, execute: deleteCampaignRequest } = useAPI("", false);
    const deleteCampaign = (campaignId:string,type:string) =>
      deleteCampaignRequest({
        url: `/campaigns/${campaignId}/${type}`,
        method: "delete",
      });
    watch(deleteCampaignError, toast.handleRequestError);


    const {
      loading: getBrandCampaignsLoading,
      error: getBrandCampaignsError,
      data: getBrandCampaignsData,
      execute: getBrandCampaignsRequest,
    } = useAPI<CampaignList>('/campaigns', false);
  
    const getBrandCampaigns = (
      limit :number,
      skip: number,
      campaignName :string,
      status : string,
      brandID: string,
      perspective = 'brand'
    ) => {
      const targetID = brandID;
  
      const params: {
        perspective: string;
        targetID: string;
        campaignName?: string;
        limit: number;
        skip: number;
        status: string;
      } = {
        perspective,
        targetID,
        campaignName,
        limit,
        skip,
        status,
      };
  
      return getBrandCampaignsRequest({ params });
    };
  return {

    //getBrand all campaigns

    getBrandCampaigns,
    getBrandCampaignsData,
    getBrandCampaignsError,
    getBrandCampaignsLoading,

    // get applications for CPM campaigns
    getCpmCampaignApplicationsLoading,
    getCpmCampaignApplicationsError,
    getCpmCampaignApplicationsResult,
    getCpmCampaignApplicationsRequest,
    getCpmCampaignApplications,
    dismissFraudLoading,
    dismissFraudError,
    dismissFraudData,
    dismissFraud,
    getDetectedFraudsLoading,
    getDetectedFraudsError,
    getDetectedFraudsResult,
    getDetectedFrauds,
    getEarningsForAdmin,
    getEarningsForAdminResult,
    getEarningsForAdminError,
    getEarningsForAdminLoading,
    // earnings
    getOverviewBrands,
    getOverviewBrandsResult,
    getOverviewBrandsError,
    getOverviewBrandsLoading,
    // overview brands
    rejectCpmVideo,
    rejectCpmVideoResult,
    rejectCpmVideoError,
    rejectCpmVideoLoading,
    // reject
    approvalCpmVideo,
    approvalCpmVideoResult,
    approvalCpmVideoError,
    approvalCpmVideoLoading,
    // approve
    getCampaignsWithPendingVideoCpm,
    getCampaignsWithPendingVideoCpmResult,
    getCampaignsWithPendingVideoCpmError,
    getCampaignsWithPendingVideoCpmLoading,
    // < get all pending video campaigns
    getAdminsCount,
    getAdminsCountResult,
    getAdminsCountError,
    getAdminsCountLoading,
    // < get count admins
    getBrandsCount,
    getBrandsCountResult,
    getBrandsCountError,
    getBrandsCountLoading,
    // < get count brands
    updateCampaignData,
    updateCampaignDataResult,
    updateCampaignDataError,
    updateCampaignDataLoading,
    // update campaign data
    getCampaignContent,
    getCampaignContentResult,
    getCampaignContentError,
    getCampaignContentLoading,
    // get campaign content
    getAllCampaigns,
    getAllCampaignsResult,
    getAllCampaignsError,
    getAllCampaignsLoading,
    // get campaign expenses
    getCampaignExpenses,
    getCampaignExpensesResult,
    getCampaignExpensesError,
    getCampaignExpensesLoading,
    // get multiple campaign expenses with limit count and skip count
    getMultipleCampaignExpenses,
    getMultipleCampaignExpensesResult,
    getMultipleCampaignExpensesError,
    getMultipleCampaignExpensesLoading,
    // get all campaigns
    getCreatorsCount,
    getCreatorsCountResult,
    getCreatorsCountError,
    getCreatorsCountLoading,
    // get registred creators count
    checkAdmin,
    checkAdminResult,
    checkAdminError,
    checkAdminLoading,
    // creator earning list
    getCreatorsEarnings,
    getCreatorsEarningsResult,
    getCreatorsEarningsError,
    getCreatorsEarningsLoading,

    //admin statistics
    getAdminStatistics,
    statisticsData,
    statisticsError,
    statisticsLoading,

    //delete/archive campaign

    deleteCampaign,
    deleteCampaignRequest,
    deleteCampaignError,
    deleteCampaignResult,
    deleteCampaignLoading,


  };
};
