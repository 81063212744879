import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewPendingVideos = _resolveComponent("NewPendingVideos")!
  const _component_OldPendingVideos = _resolveComponent("OldPendingVideos")!

  return (_ctx.isNewAdminEnabled)
    ? (_openBlock(), _createBlock(_component_NewPendingVideos, {
        key: 0,
        videos: _ctx.contentNeedActionAdmin,
        changeStatus: _ctx.changeStatus,
        rejectVideo: _ctx.rejectVideo,
        approveVideo: _ctx.approveVideo,
        goToCampaign: _ctx.goToCampaign,
        loadingState: _ctx.loadingState,
        limit: _ctx.limit,
        pageChange: _ctx.pageChange,
        search: _ctx.searchVideos,
        getPendingVideosListReq: _ctx.getPendingVideosListReq
      }, null, 8, ["videos", "changeStatus", "rejectVideo", "approveVideo", "goToCampaign", "loadingState", "limit", "pageChange", "search", "getPendingVideosListReq"]))
    : (_openBlock(), _createBlock(_component_OldPendingVideos, { key: 1 }))
}