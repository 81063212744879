
import {
  defineComponent, PropType, ref, onMounted, watch
} from "vue";
import OverlayPanel from "primevue/overlaypanel";
import Badge from "primevue/badge";
import InputText from "primevue/inputtext";
import { MenuItem } from "@/modules/menu-module";
import { useRoute } from "vue-router";
import { useGlobalState } from "@/store/store";
import Dropdown from "primevue/dropdown";
import OverlayPanelModal from "@/pages/commons/modals/OverlayPanelModal.vue";
import TutorialItem from "@/pages/content/item/TutorialItem.vue";
import { useAuth } from "@/modules";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  name: "PageMenuList",
  components: {
    Badge,
    OverlayPanel,
    InputText,
    Dropdown,
    TutorialItem,
    OverlayPanelModal,
  },
  props: {
    items: Array as PropType<Array<MenuItem>>,
    level: {
      type: Number,
      default: 1,
    },
    isSmallSidebar: Boolean,
  },
  emits: ["sent"],
  setup(props) {
    const route = useRoute();
    const { user } = useAuth();
    const { state } = useGlobalState();
    const toast = useToast();
    const openedSubmenus = ref<Array<number>>([]);
    const showRedBorder = ref(false);
    const op = ref();
    const opb = ref();
    const showModal = ref(false);
    const supportEmail = ref("contact@gameinfluencer.com");
    const tutorial = {
      type: "Content Submission",
      description: "You can upload or link a content to the platform and specify details about it.",
      image: "@/assets/images/tutorial.svg",
    };
    if (!localStorage.getItem("show-modal")) {
      showRedBorder.value = true;
    }
    onMounted(() => {
      (props.items || []).forEach((item, index) => {
        if (item.items && item.opened) {
          openedSubmenus.value.push(index);
        }
      });
    });
    const redBorderForInfluencer = ref(false);
    if (!localStorage.getItem("show-inf-red")) {
      setTimeout(() => {
        redBorderForInfluencer.value = true;
      }, 5000);
    }
    watch(
      () => route.name,
      () => {
        // TODO: this one should open a parent group if you click on it
        // it does not work with programmatic navigation (if you click back and you go to child of this item)
        // make it work :)

        (props.items || []).forEach((item, index) => {
          if (item.to && item.to.name && item.to.name === route.name) {
            if (item.items && !openedSubmenus.value.includes(index)) {
              openedSubmenus.value.push(index);
            }
          }
        });
      }
    );
    const toggle = (event: Event) => {
      op.value?.toggle(event);
    };
    function func(item: any, event: Event): void {
      if (item.label === "System Tutorials") {
        op.value?.toggle(event);
      }
    }
    async function openIcon(item: any, event: Event) {
      if (item.label === "System Tutorials") {

        const videoUrl = user.value?.type === "creator" ?
          "https://www.youtube.com/watch?v=S6v1QZ675AI" : "https://www.youtube.com/watch?v=Ix9DFewoctk";
        const newWindow = window.open(videoUrl, "_blank", "noopener,noreferrer");
        if (newWindow) {
          newWindow.opener = null;
        }
      }
      if (item.label === "Support") {
        await copy();
      }
    }
    const approve = () => {
      showModal.value = true;
    };
    function discord(item: any, event: Event): void {
      if (item.label === "Discord Community") {
        const newWindow = window.open("https://discord.gg/qw255Zgj6m", "_blank", "noopener,noreferrer");
        if (newWindow) {
          newWindow.opener = null;
        }
      }
    }
    async function copy() {
      await navigator.clipboard.writeText(supportEmail.value);
      await toast.add({ detail: "You copied the email" });
    }

    const isNewDesignEnabled = localStorage.getItem('new-design-toggle') === 'activated';

    return {
      discord,
      func,
      toggle,
      approve,
      copy,
      openIcon,
      user,
      openedSubmenus,
      state,
      showRedBorder,
      redBorderForInfluencer,
      op,
      opb,
      tutorial,
      showModal,
      supportEmail,
      isNewDesignEnabled
    };
  },
});
