import React, { useState } from "react";

import Performance from "./components/PerformanceTable";
import UGC from "./components/UGCTable";

import "./NewEarnings.css";

const Tabs = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className='tabs'>
      {tabs.map((item) => (
        <div
          key={item.value}
          className={item.value === activeTab ? 'tabSelected' : 'tabNormal'}
          onClick={() => onTabChange(item.value)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default function (props) {

  const {
    earnings, changePage, loading, changeType, limit
  } = props;

  const statusTabs = [
    { label: 'Performance', value: 'Performance' },
    { label: 'UGC', value: 'UGC' },
  ];
  const [activeTab, setActiveTab] = useState('Performance');

  const handleTabChange = (type) => {
    setActiveTab(type);
    changeType(type);
  }

  return (
    <div className='earnings'>
      <p className='title'>Earnings</p>
      {/* <div className='tabsWrapper'>
        <Tabs tabs={statusTabs} activeTab={activeTab} onTabChange={handleTabChange} />
      </div> */}
      {activeTab === 'Performance' && <Performance earnings={earnings} changePage={changePage} loading={loading} limit={limit} />}
      {activeTab === 'UGC' && <UGC earnings={earnings} changePage={changePage} loading={loading} limit={limit} />}
    </div>
  )
}
