import React, { useEffect, useMemo, useState } from 'react';
import {
  useAuth, useCampaigns, useCommonApi, usePage, useToastModule,
  useWhitelisting
} from "@/modules";
import { categoryItems, createTabItems, managementTabItems, TabStep } from "@/react_app/CreateCampaign/CreateCampaignAdditional";
import { useForm } from 'react-hook-form';
import moment from 'moment';

import CampaignTitle from './CampaignTitle';
import CampaignTabs from './CampaignTabs';
import CampaignSteps from './CampaignSteps';
import CampaignProgress from './CampaignProgress';

import noImage from "../img/no-image-1-gray.png";

import './CreateCampaign.css';
import './CreateCampaignAdditional.css';
import './CreateCampaignResponsive.css';
import 'primeicons/primeicons.css';
import { DEFAULT_UGC_CAMPAIGN_FEE } from '@/utils/globals/cpi-rate';
import { regionsOptions } from '@/pages/campaigns/create/data.campaign';
import { ENGAGEMENT_MINIMUM } from '../NewCreateCampaign/NewCreateCampaignAdditional';
import NewCampaignOverview from '../NewCampaignOverview/NewCampaignOverview';
import NewCampaignComplete from '../NewCampaignComplete/NewCampaignComplete';

export default function CreateCampaign(props) {
  const { user } = useAuth();
  const toast = useToastModule();
  const { manager: { getMasterData } } = useCommonApi();
  const { config } = usePage({ title: 'create campaign' });
  const page = useCampaigns(config?.value?.viewPerspective);
  const {
    manager: {
      getAllBrands
    },
  } = useWhitelisting();

  const isAdmin = user.value.isAdmin();
  const isEditMode = !!props?.campaign;

  const [currentTab, setCurrentTab] = useState(1);
  const [reachedTab, setReachedTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [disableLaunchButton, setDisableLaunchButton] = useState(false);
  const [disableDraftButton, setDisableDraftButton] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [newlyCreatedCampaign, setNewlyCreatedCampaign] = useState('');

  const tabItems = useMemo(
    () => (!props.isCampaignManagement ? createTabItems : managementTabItems),
    [props.isCampaignManagement]
  );

  const getDefaultValues = (campaign = {}) => {
    const {
      brand = '',
      name = '',
      targetMarket = {},
      endDate = '',
      campaignEndDate,
      paymentMethod = 'UGC',
      marketTier = '',
      marketCountries = '',
      coverImage = noImage,
      campaignDescription = '',
      brandTrackingLink = '',
      attachments = [],
      gamekeys = '',
      previewURL = '',
      otherUrls = ['', '', ''],
      talkingPoints = ['', ''],
      callToAction = '',
      campaignCPC = { amount: 0 },
      campaignCPM = { amount: 0, payoutCap: 0 },
      campaignCPI = { ios: { amount: 0 }, android: { amount: 0 } },
      campaignBudget = 0,
      isUntilBudget = true,
      untilBudgetDate = '',
      languages = '',
      countries = '',
      videoAspectRatio = ['16:9'],
      conceptCount = 1,
      videoFormat = 'MP4',
      videoLength = '10',
      noOfCreators = 5,
      costPerCreator = 500,
      desiredRegions = [{ value: '', name: '' }],
      photo,
      trackingLink,
      productUrls = [{
        "type": "",
        "value": "",
      }, {
        "type": "",
        "value": "",
      }, {
        "type": "",
        "value": "",
      }, {
        "type": "",
        "value": "",
      }],
      cpc,
      cpm,
      cpi,
      budget,
      ugc,
      productName,
      dates,
    } = campaign;

    return {
      brand: brand?.id,
      campaignName: name,
      campaignType: 'UGC Creator Ads',
      category: {
        value: targetMarket?.type || categoryItems[0].type,
        label: targetMarket?.title || categoryItems[0].name,
      },
      campaignEndDate: dates?.endDate,
      paymentMethod,
      marketTier: desiredRegions[0]?.value,
      marketCountries: desiredRegions[0]?.name,
      coverImage: photo,
      campaignDescription: productName,
      brandTrackingLink: trackingLink,
      attachments: attachments?.filter(file => !!file?.url),
      gamekeys,
      previewURL: productUrls[0]?.value,
      otherUrls: productUrls.slice(1).map(item => item.value),
      talkingPoints,
      callToAction,
      campaignCPC: { amount: cpc?.amount || 0 },
      campaignCPM: { amount: cpm?.amount || 0, payoutCap: cpm?.payoutCap || 0 },
      campaignCPI: { ios: { amount: cpi?.ios?.amount || 0 }, android: { amount: cpi?.android?.amount || 0 } },
      campaignBudget: budget?.amount,
      isUntilBudget,
      untilBudgetDate,
      languages: languages[0]?.id,
      countries: countries[0]?.id,
      videoAspectRatio,
      conceptCount,
      videoFormat,
      videoLength: videoLength?.toString(),
      noOfCreators: ugc?.noOfCreator || 5,
      costPerCreator: ugc?.costPerCreator || 500,
    };
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    clearErrors,
    setError,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(props.campaign),
    shouldUnregister: false,
  });

  const campaign = watch();

  const noOfCreators = watch('noOfCreators');
  const costPerCreator = watch('costPerCreator');



  const calculateBudget = () => {
    const totalBudget = (costPerCreator * noOfCreators) / 0.5;
    const ugdCommission = totalBudget * 0.5;

    setValue('campaignBudget', totalBudget);
    setValue('ugdCommission', ugdCommission);
  };

  const validateField = (fieldName, value, message) => {
    if (value?.length < 1 || value == null) {
      setError(fieldName, { message });
    } else {
      clearErrors(fieldName);
    }
  };

  useEffect(() => {
    calculateBudget();
  }, [noOfCreators, costPerCreator]);

  useEffect(() => {
    validateField("videoAspectRatio", campaign.videoAspectRatio, "Aspect ratio is required");
    validateField("category", campaign.category, "Product category is required");
  }, [campaign.videoAspectRatio, campaign.category]);


  const checkMinimalBudget = (engagementAmount) => {
    return campaign.campaignBudget < (ENGAGEMENT_MINIMUM * engagementAmount) || campaign.campaignBudget === 0;
  }

  const isTab1Valid = useMemo(() => {
    return (
      !campaign.campaignName ||
      (!campaign.isUntilBudget && !campaign.campaignEndDate) ||
      campaign?.languages?.length === 0 ||
      campaign?.countries?.length === 0 ||
      !campaign.coverImage ||
      (isAdmin && !campaign.brand) ||
      errors.campaignName ||
      errors.campaignEndDate ||
      errors.countries ||
      errors.languages
    );
  }, [campaign, errors, isAdmin, imageError]);

  const isTab2Valid = useMemo(() => {
    return !campaign.campaignDescription || errors.campaignDescription || errors.videoAspectRatio || !campaign.videoAspectRatio;
  }, [campaign, errors]);

  const isBudgetValid = useMemo(() => {
    return !campaign.campaignBudget || errors.campaignBudget ||
      !campaign.noOfCreators || errors.noOfCreators ||
      !campaign.costPerCreator || errors.costPerCreator
      || checkMinimalBudget(campaign.campaignCPM.amount);
  }, [campaign, errors]);

  const checkDisabled = () => {
    if (!campaign.coverImage) {
      setImageError(true);
    }
    if (disableLaunchButton) return true;
    if (currentTab === 1 && isTab1Valid) return true;
    if (currentTab === 2 && isTab2Valid) return true;
    if (currentTab === 4 && isBudgetValid) return true;

    return false;
  };

  const checkDisabledDraft = () => {
    if (isTab1Valid || isTab2Valid) return true;

    return false;
  };

  const handleContinue = (directUpdate = false) => {
    if (checkDisabled()) {
      return;
    }
    if (directUpdate || (isEditMode && currentTab === TabStep.Budget)) {
      handleSaveEdit();
    } else {
      setCurrentTab(currentTab + 1);
    }
  };

  const handleImageChange = (newImage) => {
    setValue('coverImage', newImage);
    setImageError(false);
    if (isEditMode && newImage && props?.setCampaignManagementPreview) {
      props.setCampaignManagementPreview(newImage);
    }
  };

  const handleChangeCalendar = (value) => {
    setValue('campaignEndDate', value);
    setValue('isUntilBudget', false);
    setValue('untilBudgetDate', moment(value).format('YYYY-MM-DD HH:mm:ss'));
    if (value) {
      clearErrors('campaignEndDate');
    }
  };

  const handleChangeUntilBudget = () => {
    const isUntilBudgetActive = watch('isUntilBudget');

    if (isUntilBudgetActive) {
      setValue('isUntilBudget', false);
      setValue('untilBudgetDate', "");
      clearErrors('campaignEndDate');
    } else {
      const untilBudgetDate = watch('untilBudgetDate');
      const newUntilBudgetDate = untilBudgetDate
        ? moment(untilBudgetDate).add(366, 'days').format('YYYY-MM-DD HH:mm:ss')
        : moment().add(366, 'days').format('YYYY-MM-DD HH:mm:ss');

      setValue('campaignEndDate', "");
      setValue('isUntilBudget', true);
      setValue('untilBudgetDate', newUntilBudgetDate);
    }

    if (!campaign.campaignEndDate) {
      setError('campaignEndDate', { message: 'Campaign end date is required' })
    } else {
      clearErrors('campaignEndDate');
    }
    checkDisabled();
  };

  const onError = () => {
    toast.showError("Please fill the required fields correctly before submitting.");
  };

  const getOptionsList = () => {
    setIsLoading(true);
    getMasterData('').then((res) => {
      if (res) {
        setIsLoading(false);
        setLanguageOptions(res.data.languages.map(language => {
          return {
            value: language.id, label: language.name, name: language.name, id: language.id, code: language.code, isActive: language.isActive
          }
        }));
        setCountryOptions(res.data.countries.map(country => {
          return {
            value: country.id, label: country.name, name: country.name, id: country.id, code: country.code
          }
        }));
      } else {
        setLanguageOptions([]);
        setCountryOptions([])
      }
    }).catch(error => {
      setIsLoading(false);
    });

    if (isAdmin) {
      if (brandsOptions.length <= 0) {
        getAllBrands().then(brands => {
          setBrandsOptions(brands.map(brand => {
            return { name: brand.name, value: brand.id, label: brand.name }
          }));
        }).catch((error) => console.log('error', error));
      }
    }

  }


  const createDraft = async (pushRouter, campaignPayload) => {
    setIsLoader(true);
    setDisableDraftButton(true);
    const updatedCampaignPayload = { ...campaignPayload };
    const createdDraft = await page.manager.create(campaignPayload).then(async (res) => {
      if (res) {
        toast.showSuccess(`UGC campaign created successfully!`);
        if (campaign.attachments?.length) {
          const formData = new FormData();
          for await (const file of campaign.attachments) {
            formData.set('files[]', file);
            await page.manager.attachments(res?.id, formData);
          }
        }
        if (pushRouter) {
          await props.pushDraft(res);
        } else {
          setNewlyCreatedCampaign(res);
          setCurrentTab(currentTab + 1);
        }
        reset();
        setIsLoader(false);
        setDisableLaunchButton(false);
        setDisableDraftButton(false);
      }
    }).catch((error) => {
      toast.showError(error.message || "Something went wrong!");
      setIsLoader(false);
      setDisableLaunchButton(false);
      setDisableDraftButton(false);
    });
    return createdDraft;
  };

  const saveCampaign = async (pushRouter, campaignPayload) => {
    setIsLoader(true);
    const updatedCampaignPayload = { ...campaignPayload };

    updatedCampaignPayload.attachments = [...campaignPayload.attachments]

    if (updatedCampaignPayload.attachments?.length) {
      const formData = new FormData();
      for await (const file of updatedCampaignPayload.attachments) {
        if (!file.url) {
          formData.set('files[]', file);
          await page.manager.attachments(props?.campaign?.id, formData).then(async (response) => {
            if (response && response.attachments.length > 0) {
              const latestUpload = response.attachments[response.attachments.length - 1];
              updatedCampaignPayload.attachments.push(latestUpload)
            }
          }).catch(error => {
            if (error) {
              console.log('attachments error', error)
            }
          });
        }
      }
    }

    updatedCampaignPayload.attachments = updatedCampaignPayload.attachments.filter(attachment => attachment.url);

    props.saveEdit(updatedCampaignPayload).then(async (res) => {
      if (res) {
        setIsLoader(false);
        setDisableLaunchButton(false);
        props.cancelEdit();
      }
    }).catch(() => {
      setIsLoader(false);
      setDisableLaunchButton(false);
    });
  };

  const handleSaveEdit = () => {
    handleSubmit((data) => onSubmit(data, true, true))();
  }


  const handleDraft = () => {
    if (checkDisabledDraft()) {
      // onError();
    }
    handleSubmit((data) => onSubmit(data, true, false))();
  }

  const handleLaunchCampaign = async () => {
    setDisableLaunchButton(true);
    if (checkDisabledDraft()) {
      // onError();
    }
    if (!props.campaign) {
      handleSubmit((data) => onSubmit(data, false, false))();
    }
  };

  const handleRouting = async (createdCampaign) => {
    await props.pushDraft(createdCampaign);
    await page.manager.pending(createdCampaign?.id);
  }

  const onSubmit = (data, pushRoute = false, isEdit = false) => {
    setIsLoader(true);
    if (!data.coverImage) {
      setImageError(true);
      return;
    }

    if (checkDisabledDraft()) {
      toast.showError("Please fill the required fields correctly before submitting.");
      return;
    }

    const campaignOtherUrls = data.otherUrls.map((link, index) => {
      if (index === 0) return { type: "Google", value: link };
      if (index === 1) return { type: "Apple", value: link };
      return { type: "Webpage", value: link };
    });

    campaignOtherUrls.unshift({ type: "Webpage", value: data.previewURL });

    const payload = {
      brandId: data.brand,
      name: data.campaignName,
      budget: { amount: Number(data.campaignBudget || 5000), currency: 'USD', amountLeft: props.campaign?.budget?.amountLeft || 0 },
      dates: { startDate: props?.campaign?.dates?.startDate || new Date(), endDate: data.campaignEndDate ? data.campaignEndDate : '' },
      targetMarket: { type: data.category.value, title: data.category.label },
      desiredRegions: { name: regionsOptions[0].name, value: regionsOptions[0].value },
      productName: data.campaignDescription,
      productUrls: campaignOtherUrls,
      callToAction: data.callToAction,
      talkingPoints: data.talkingPoints,
      attachments: data.attachments,
      gamekeys: data.gamekeys,
      cpi: { ios: { amount: data?.campaignCPI?.ios?.amount }, android: { amount: data.campaignCPI.android.amount } },
      cpc: { amount: data.campaignCPC.amount },
      cpm: { amount: data.campaignCPM.amount, payoutCap: data.campaignCPM.payoutCap },
      paymentMethod: data.paymentMethod,
      photo: data.coverImage,
      paymentInformation: {},
      appliedFee: DEFAULT_UGC_CAMPAIGN_FEE,
      isUntilBudget: data.isUntilBudget,
      untilBudgetDate: data.untilBudgetDate
        ? moment(data.untilBudgetDate).format('YYYY-MM-DD HH:mm:ss')
        : moment().add(366, 'days').format('YYYY-MM-DD HH:mm:ss'),
      languages: data.languages,
      countries: data.countries,
      ugc: {
        noOfCreator: data.noOfCreators,
        costPerCreator: data.costPerCreator || 500,
      },
      conceptCount: Number(data.conceptCount),
      videoFormat: data.videoFormat,
      videoAspectRatio: data.videoAspectRatio.join(','),
      videoLength: data?.videoLength,
    };

    if (!isAdmin) {
      delete payload.brandId;
    }

    if (isEdit) {
      saveCampaign(false, payload);
    } else {
      createDraft(pushRoute, payload);
    }
  };

  useEffect(() => {
    if (reachedTab < currentTab) {
      setReachedTab(currentTab);
    }
  }, [currentTab, reachedTab]);


  useEffect(() => {
    if (languageOptions.length <= 0) {
      getOptionsList();
    }
  }, [])

  return (
    <div className={`create-ugc-campaign create-campaign ${isEditMode ? "edit-campaign" : ""} 
    ${props.isCampaignManagement ? "manage-campaign" : ""}`}>
      {currentTab < TabStep.Completed && (
        <div>
          <CampaignTitle
            currentTab={currentTab}
            isAdmin={isAdmin}
            isCampaign={props.campaign}
            cancelEdit={props.cancelEdit}
            handleContinue={handleContinue}
            checkDisabled={() => false}
            setCurrentTab={setCurrentTab}
            isLoader={isLoader}
          />
          <CampaignTabs
            currentTab={currentTab}
            reachedTab={reachedTab}
            tabItems={tabItems}
            setCurrentTab={setCurrentTab}
            isCampaignManagement={props?.isCampaignManagement}
          />
        </div>
      )}
      <form
        onSubmit={(e) => e.preventDefault()}
      >
        <div className={`mainLayer step-${currentTab}`}>
          <CampaignSteps
            currentTab={currentTab}
            TabStep={tabItems}
            register={register}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            getValues={getValues}
            trigger={trigger}
            handleChangeCalendar={handleChangeCalendar}
            handleChangeUntilBudget={handleChangeUntilBudget}
            fieldOptions={{ countries: countryOptions || [], languages: languageOptions || [], brand: brandsOptions || [] }}
            isAdmin={isAdmin}
            isEditMode={isEditMode}
          />
          <CampaignProgress
            currentTab={currentTab}
            TabStep={tabItems}
            register={register}
            errors={errors}
            clearErrors={clearErrors}
            control={control}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            campaign={props.campaign}
            isEditMode={isEditMode}
            handleContinue={handleContinue}
            setCurrentTab={setCurrentTab}
            checkDisabled={checkDisabled}
            checkDisabledDraft={checkDisabledDraft}
            isCampaignManagement={props.isCampaignManagement}
            createDraft={() => handleDraft()}
            isLoading={isLoading}
            isLoader={isLoader}
            isAdmin={isAdmin}
            coverImage={campaign.coverImage}
            handleImageChange={handleImageChange}
            onError={onError}
            setError={setError}
            trigger={trigger}
            imageError={imageError}
          />
        </div>
        {currentTab === TabStep.Overview && !props?.isCampaignManagement &&
          <NewCampaignOverview
            launchCampaign={() => handleLaunchCampaign()}
            saveDraft={() => handleDraft()}
            disableLaunchButton={disableLaunchButton}
            campaign={campaign}
            setCurrentTab={setCurrentTab}
            loading={disableDraftButton}
            languageOptions={languageOptions}
            countryOptions={countryOptions}
          />
        }
        {currentTab === TabStep.Completed && !props?.isCampaignManagement &&
          <NewCampaignComplete campaign={newlyCreatedCampaign} handleRouting={() => handleRouting(newlyCreatedCampaign)} />
        }
      </form>
    </div>
  );
}
