import React from 'react';
import { RedTriangle } from "@/react_app/img/Icons";

const CampaignTabs = ({
  currentTab, reachedTab, tabItems, setCurrentTab, isCampaignManagement
}) => {
  const handleKeyPress = (event, itemIndex) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (itemIndex <= reachedTab) {
        setCurrentTab(itemIndex);
      }
    }
  };

  return (
    <div className="tabLayer">
      {tabItems.map((item, index) => (
        <div
          key={index}
          role="tab"
          tabIndex={item.index}
          aria-selected={item.index === currentTab}
          className={item.index === currentTab ? 'tabLayerItemCurrent' : 'tabLayerItem'}
          onClick={() => {
            if (item.index <= reachedTab) setCurrentTab(item.index)
          }}
          onKeyDown={(event) => handleKeyPress(event, item.index)}
          style={{ width: !isCampaignManagement ? '20%' : '25%' }}
        >
          <div className={item.index === currentTab ? 'tabNumberSelected' : 'tabNumber'}>
            0{item.index}
          </div>
          <span className="tabName">{item.name}</span>  {/* Render the name */}
          {item.index === currentTab && <RedTriangle />}
        </div>
      ))}
    </div>
  );
};

export default CampaignTabs;
