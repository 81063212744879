import React from 'react';
import RenderField from './RenderField';
import createCampaignConfig from './createCampaignConfig';
import { FieldTip } from '../img/Icons';

const CampaignSteps = ({
  currentTab, control,
  watch,
  setValue,
  getValues,
  register,
  errors,
  setError,
  clearErrors,
  trigger,
  handleChangeCalendar,
  handleChangeUntilBudget,
  fieldOptions,
  isAdmin,
  isEditMode
}) => {
  const currentStepConfig = createCampaignConfig.steps.find(step => step.index === currentTab);
  return (
    <div className={`step step-${currentTab}`} >
      {currentStepConfig.fields.filter(filed => !filed?.isRightLayout).map((field, index) => {

        const options = (field.type === 'dropdown' || (field.type === 'grouped-fields' && field.fields.some(f => f.type === 'dropdown')))
          ? fieldOptions[field.name] || field.options
          : field.options;

        if (field.type === 'grouped-fields') {
          field.fields.forEach(subField => {
            if (subField.type === 'dropdown') {
              subField.options = fieldOptions[subField.name] || subField.options;
            }
          });
        }

        return (

          <div key={index} >
            {((field.isAdminOnly && isAdmin) || (!field.isAdminOnly)) && (
              <>
                <p className="fieldLabel">
                  {field.label}
                  {field.isRequired && <span className="redStar">*</span>}
                  {field?.showTooltip && <FieldTip title={field?.tooltipInfo} />}
                </p>
                {field.inputInfo && <p className="fieldLabel inputInfo">{field.inputInfo}</p>}
              </>
            )}
            <RenderField
              field={{
                ...field,
                options
              }}
              control={control}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              register={register}
              errors={errors}
              setError={setError}
              trigger={trigger}
              clearErrors={clearErrors}
              handleChangeCalendar={handleChangeCalendar}
              handleChangeUntilBudget={handleChangeUntilBudget}
              isAdmin={isAdmin}
              isEditMode={isEditMode}
            />
          </div>
        )
      })}
    </div>
  );
};

export default CampaignSteps;
