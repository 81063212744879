import React, { useEffect, useState } from "react";
import "./NewDraftApproval.css";
import { CopyIconSmall, TrashIcon, ExpandArrowDown, ExpandArrowUp } from "@/react_app/img/Icons";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { formatDate } from "@/utils/globals/date-utils";
import congrats from "../img/congrats.gif";
import noImage from "../../../src/react_app/img/no-image-1-gray.png";


const videoTemplate = (rowData) => {
  return (
    <div className={'videoTemplate'}>
      <Image src={rowData.thumbnails.default.url} alt={'video thumbnail'} className={'videoThumbnail'} />
      <p className={'videoTitle'}>{rowData.title}</p>
    </div>
  )
};

const urlTemplate = (rowData) => {
  return (
    <div className={'urlTemplate'}>
      <p className={'urlLink'}>{rowData.url}</p>
      <div onClick={() => navigator.clipboard.writeText(rowData.url)}>
        <CopyIconSmall />
      </div>
    </div>
  )
};

const statusTemplate = () => {
  return (
    <div className={'statusTemplate'}>
      Uploaded
    </div>
  )
};

const dateTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      {formatDate(rowData.createdAt || rowData?.aspectRatios[0]?.submittedAt)}
    </div>
  )
};

const deleteTemplate = () => {
  return (
    <div className={'deleteTemplate'}>
      <TrashIcon />
    </div>
  )
};

const statusTemplateUGC = (rowData) => {
  return rowData?.aspectRatios?.map(aspectRatio => (
    <div className={`statusTemplate ${aspectRatio?.status}`} style={{ marginBottom: "5px" }}>
      {aspectRatio?.status}
    </div>
  ))
};

const conceptTemplate = (rowData) => {
  return (
    <div className={'conceptTitleWrap'}>
      <p className={'conceptTitle'} style={{ height: 'auto' }}>Concept</p>
      <p className={'conceptValue'} style={{ height: 'auto' }}>{rowData.concept}</p>
    </div>
  )
};

export default function (props) {
  const { isUGCCampaign, latestUGCSubmits } = props;
  const [progressCpmCampaign, setProgressCpmCampaign] = useState({
    videoDraft: false,
    draftAccepted: false,
    draftRejected: false,
    liveVideoPosted: false,
    rejectReason: "",
  });
  const [hasRejectedStatus, setHasRejectedStatus] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if (props && props?.progressCpmCampaign) {
      setProgressCpmCampaign(prevState => ({ ...prevState, ...props?.progressCpmCampaign }))
    }
    if (latestUGCSubmits && latestUGCSubmits?.length > 0) {
      const hasRejectedStatus = latestUGCSubmits.some(concept =>
        concept.aspectRatios.some(ratio => ratio?.status === "rejected"));
      setHasRejectedStatus(hasRejectedStatus);
    }
  }, [props])

  const expanderTemplate = (rowData) => {
    return (
      <div className='expanderTemplate'>
        {expandedRows.includes(rowData) ?
          <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  };
  const rowExpansionTemplate = (rowData) => {
    const showFileSizeInMB = (bytes) => (bytes / (1024 * 1024))?.toFixed(1);
    return (
      <>
        <div className='darkLine' />
        <div className='expansionWrapper ugcUploadedContent'>
          <div className='expansionDataSquare'>
            <div className='video-wrap header'>
              <div className='ratio'>
                <p className='headerTitle'>Aspect Ratio</p>
              </div>
              <div className={`video-wrapper`}>
                <p className='headerTitle'>
                  Video Info
                </p>
              </div>
              <div className='videoInfoDate'>
                <p className='headerTitle'>Submission Date</p>
              </div>
              <div className='videoInfo videoStatus'>
                <p className='headerTitle'>Status</p>
              </div>
            </div>
            {rowData?.aspectRatios?.length > 0 ? <div className='request-info type-name'>
              {rowData?.aspectRatios?.map((ratio) => {
                return (
                  <div key={ratio?.ratio} className='video-wrap'>
                    <p className='ratio'>{ratio?.ratio}</p>
                    <div className={`video-wrapper`}>
                      <div className='video-preview'>
                        <div
                        >
                          <Image
                            src={noImage}
                            alt='file preview'
                            style={{ display: 'none' }}
                          />
                          <video
                            src={`${ratio?.videoUrl}#t=5`}
                            controls={false}
                            onError={(e) => {
                              e.target.style.display = 'none';
                              const img = e.target.previousElementSibling;
                              if (img) {
                                img.style.display = 'block';
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='videoInfo'>
                        <div className={'urlTemplate'} style={{ marginBottom: '5px' }}>
                          <p className='file-name'>{ratio?.fileName || ""}</p>
                          <div onClick={() => navigator.clipboard.writeText(ratio?.videoUrl)}>
                            <CopyIconSmall />
                          </div>
                        </div >
                        <p className='file-size'>{showFileSizeInMB(ratio?.fileSize || 0)} MB</p>
                      </div>
                    </div>
                    <div className='videoInfoDate'>
                      <p className='ratio'>{ratio?.submittedAt || ""}</p>
                    </div>
                    <div className='videoInfo videoStatus'>
                      <p className={`file-status ${ratio?.status}`}>{ratio?.status}</p>
                    </div>
                  </div>
                )
              })}
            </div> :
              <p className='noUploadsFound'>No uploads found</p>
            }
          </div >
        </div >
      </>
    );
  };

  return (
    <div className={props.displayNone ? 'submit-draft-approval display-none' : 'submit-draft-approval'}>
      <div className={`coverBackground ${isUGCCampaign ? 'ugc' : ''}`} style={{ background: `linear-gradient(rgba(19, 10, 35, 0.7), rgba(19, 10, 35, 0.7)), url(${props.campaign?.photo})` }}>
        {progressCpmCampaign?.draftAccepted && <Image src={congrats} alt={'congrats gif'} className={'congratsGif'} />}
        {!progressCpmCampaign?.draftAccepted ?
          <>
            {progressCpmCampaign?.draftRejected ? <div className={'linkAndGamekeys'}>
              <p className={'title'}>Your content has been Rejected!</p>
              <p className={'text'}>Make sure to read our comments and adjust your video content.</p>
            </div> :
              <div className={'linkAndGamekeys'}>
                <p className={'title'}>{`${(hasRejectedStatus && isUGCCampaign) ? 'Your one of the draft video got rejected, upload draft video again.'
                  : 'You have just submitted your draft video for an approval!'}`}</p>
                <p className={'text'}>As our platform is still in its MVP stage, all videos will await approval, a member of our team will review the content and you will get a notification once it gets approved.</p>
              </div>}
          </>
          :
          <div className={'linkAndGamekeys approved'}>
            <p className={'title'}>Your content has been approved!</p>
            <p className={'text'}>Now it's time to make your YouTube video public, so everyone can see it and you can start earning from your content!</p>
          </div>
        }
        <div className={`acceptWrapper ${progressCpmCampaign?.draftAccepted ? 'approved' : ''}`}>
          {!progressCpmCampaign?.draftAccepted ?
            <>
              {progressCpmCampaign?.draftRejected ?
                <Button className={'acceptButton'} onClick={() => props.handleChangeTab(1)}>Submit Draft Content</Button> :
                (hasRejectedStatus && isUGCCampaign) ?
                  <Button className={'acceptButton'} onClick={() => { props.handleChangeTab(1) }}>Submit Draft Content</Button> :
                  <Button className={'homeButton'} onClick={() => { props?.handleRoutingHomepage() }}>Return to Homepage</Button>
              }
            </> :
            <>
              {!progressCpmCampaign?.liveVideoPosted ? <Button className={'acceptButton'} onClick={() => props.handleChangeTab(3)}>Submit Content</Button>
                : <Button className={'homeButton'} onClick={() => { props?.handleRoutingHomepage() }}>Return to Homepage</Button>}
            </>
          }
        </div>
      </div>
      {!isUGCCampaign && <div>
        <p className={'uploadedContentTitle'}>Uploaded Content</p>
        <DataTable value={props?.latestSubmits}>
          <Column field="title" header="Video" body={videoTemplate} style={{ width: '45%' }}></Column>
          <Column field="url" header="Video Link" body={urlTemplate} style={{ width: '28%' }}></Column>
          <Column field="" header="Status" body={statusTemplate} style={{ width: '14%' }}></Column>
          <Column field="createdAt" header="Submission Date" body={dateTemplate} style={{ width: '14%' }}></Column>
          <Column field="" header="" body={deleteTemplate} style={{ width: '9%' }}></Column>
        </DataTable>
        <div className={'buttonGroup'}>
          <Button className={'leaveButton'} onClick={() => props.leaveCampaign()}>Leave Campaign</Button>
        </div>
      </div>}
      {isUGCCampaign && <div>
        <p className={'uploadedContentTitle'}>Uploaded Content</p>
        <DataTable value={latestUGCSubmits}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column field="concept" header="Concept" body={conceptTemplate} style={{ width: '95%', textAlign: 'left' }} align={'left'}></Column>
          <Column header="Action" expander={true} body={expanderTemplate} style={{ width: '5%' }} />
        </DataTable>
        <div className={'buttonGroup'}>
          <Button className={'leaveButton'} onClick={() => props.leaveCampaign()}>Leave Campaign</Button>
        </div>
      </div>}
    </div>
  )
}
