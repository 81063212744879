import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoReason = _resolveComponent("VideoReason", true)!

  return (_openBlock(), _createBlock(_component_VideoReason, {
    reasonData: _ctx.reasonData,
    reason: _ctx.reason,
    goToCampaign: _ctx.goToCampaign
  }, null, 8, ["reasonData", "reason", "goToCampaign"]))
}