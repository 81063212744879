import { useAPI, useAuth, useToastModule } from '@/modules';
import { CampaignData, AppPagePerspective, Perspective, CampaignStatus } from '@/data/types';
import { ref, watch } from 'vue';
import { useCampaignsList } from './campaigns-list-module';

const { user } = useAuth();

export const useCampaignInvitations = (perspective: AppPagePerspective) => {
  const toast = useToastModule();
  const selectedCampaignID = ref<string>('');
  const invitations = ref<Array<CampaignData>>([]);
  const associatedCampaigns = ref<Array<CampaignData>>([]);

  const { loading, finished, data: loadedInvitationCampaigns, execute } = useAPI<Array<CampaignData>>('/campaigns/invitations/', false);
  const allCampaigns = useCampaignsList(perspective);

  const loadInvitations = () => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error('Could not load campaigns for a brand, because brandID is missing!');
    }

    if (perspective === Perspective.BRAND || perspective === Perspective.ADMIN) {
      allCampaigns.loadCampaigns();
    }

    return execute({ params: { perspective, targetID } });
  };

  watch(loadedInvitationCampaigns, () => {
    if (perspective === Perspective.CREATOR) {
      associatedCampaigns.value = loadedInvitationCampaigns.value || [];
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    filterList();
  });

  watch(allCampaigns.campaigns, () => {
    associatedCampaigns.value = allCampaigns.campaigns
      .value?.filter((campaign: CampaignData) => campaign.status !== CampaignStatus.Draft)
      || [];
  });

  // send an invitation
  const {
    data: inviteSuccess,
    error: inviteError,
    loading: inviting,
    execute: sendInvite,
  } = useAPI('', false);
  const invite = (campaignID: string, userID: string) => {
    sendInvite({
      url: `/campaigns/invitations/${campaignID}/${userID}`,
      method: 'POST',
    });
  };
  // < send an invitation

  watch(inviteSuccess, toast.handleRequestSuccess('Invitation sent successfully!'));
  watch(inviteError, toast.handleRequestError);

  // filtering

  const filterList = () => {
    const campaignsWithInvitations = loadedInvitationCampaigns
      .value?.filter((campaign) => !!campaign.invitations?.length) || [];

    if (selectedCampaignID.value) {
      invitations.value = campaignsWithInvitations.filter((campaign) => campaign.id === selectedCampaignID.value);
    } else {
      invitations.value = campaignsWithInvitations;
    }
  };

  const filterByCampaignID = (campaignID = '') => {
    selectedCampaignID.value = campaignID;
    filterList();
  };

    // change invitation status
    const {
      data: inviteStatusChangeSuccess,
      error: inviteStatusChangeError,
      loading: inviteStatusChangeLoading,
      execute: inviteStatusChangeRequest,
    } = useAPI('', false);
    const changeInviteStatus = (campaignId:string,invitationId:string,statusObj:any) => 
      inviteStatusChangeRequest({
        url: `/campaigns/invitations/${campaignId}/${invitationId}`,
        method: 'PUT',
        data: statusObj,
      });
  
    watch(inviteStatusChangeError, toast.handleRequestError);

  return {
    invitations,
    loading,
    finished,
    loadInvitations,
    filterByCampaignID,
    associatedCampaigns,
    // inviting a user
    inviting,
    invite,
    inviteSuccess,
    inviteError,

    //change status
    changeInviteStatus,
    inviteStatusChangeError,
    inviteStatusChangeSuccess,
    inviteStatusChangeLoading
  };
};
