import React, { useMemo } from 'react';
import { Chart } from 'primereact/chart';

export const ViewCountChart = ({ budget, currentTotalViewCount, currentCampaignAmountInfo }) => {
  budget.viewCount = 0;
  const totalViews = (budget?.totalBudget / currentCampaignAmountInfo?.amount) * 1000;
  const dataViews = useMemo(() => ({
    datasets: [
      {
        data: [totalViews - (budget?.viewCount + currentTotalViewCount), budget?.viewCount + currentTotalViewCount],
        backgroundColor: ['#E73D38', 'lightgray'],
        borderWidth: 0,
      },
    ],
  }), [budget, currentTotalViewCount]);

  const optionsRemaining = {
    cutout: '70%',
    plugins: { legend: { display: false } },
    interaction: { mode: false, intersect: false },
  };

  return (
    <div className={'chartWrapper'}>
      <Chart type="doughnut" data={dataViews} options={optionsRemaining} className={'chartGraphic'} />
      <div className={'chartPercentage'}>{100 - (Math.ceil(((budget?.viewCount + currentTotalViewCount) / totalViews) * 100))}%</div>
      <div className={'budgetText'}>
        <p className={'budgetLabel'}>View Count:</p>
        <p className={'budgetValue'}>{Math.round(budget?.viewCount + currentTotalViewCount).toLocaleString()}</p>
      </div>
    </div>
  );
};

export default ViewCountChart;
