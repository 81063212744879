
import {
  defineComponent, Ref, ref, computed,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStepValidation } from '@/pages/campaigns/create/step-validation-module';
import { useAuth, useUsers } from '@/modules';
import { AppPageConfig, AppPageProps, Perspective, User } from '@/data/types';

export default defineComponent({
  components: {
  },
  props: AppPageProps,
  setup(props) {
    const router = useRouter();
    const { user, login } = useAuth();
    const perspective = props.viewPerspective || 'creator';

    const config = ref<AppPageConfig>({
      title: `${perspective} Info`,
      ...props,
    });

    // common
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');

    const badConfirmPassword = computed(() => password.value !== confirmPassword.value);

    // validation
    const fields: Record<string, Ref> = { email }; // TODO
    if (perspective === 'brand') {
      // fields.slogan = slogan;
      email.value = user?.value?.email || '';
    } else {
      email.value = user?.value?.email || '';
    }

    const validation = useStepValidation(fields);
    const controller = useUsers();

    const register = () => {
      if (perspective === 'brand' ? true : validation.validate()) {
        const payload: any = {};
        // if (perspective === 'creator') {
        //   payload.password = password.value;
        //   payload.email = email.value;
        // }
        if (perspective === 'brand') {
          payload.photo = '';
          payload.name = localStorage.getItem('username') || 'Your brand name';
          payload.slogan = 'Brand slogan';
        }
        controller.manager.verify(perspective as Perspective, { [perspective]: payload })
          .then((success?: User | undefined) => {
            if (success && perspective !== 'brand') {
              login(success);
              localStorage.removeItem('brandName');
              router.push({ name: 'creator-account-setup', params: { creatorID: success?.id ? success?.id : "" } });
              localStorage.setItem('new-design-toggle', 'activated');
            }
            if (perspective === 'brand') {
              login(success);
              if (success?.id) {
                router.push({ name: 'brand', params: { brandID: success?.brand?.id ? success?.brand?.id : "" } });
              } else {
                router.push({ name: 'create-campaign' });
              }
            }
            if (perspective === 'creator') {
              login(success);
              if (success?.id) {
                router.push({ name: 'creator-account-setup', params: { creatorID: success?.id ? success?.id : "" } });
                localStorage.setItem('new-design-toggle', 'activated');
              } else {
                router.push({ name: 'create-campaign' });
              }
            }
          });
      }
    };
    if (perspective === 'brand' || perspective === 'creator') {
      // router.push({ name: 'create-campaign' });
      register(); // register by default data...
    }
    return {
      password,
      confirmPassword,
      badConfirmPassword,
      config,
      user,
      loading: controller.manager.verifying,
      register,
      // common
      email,
      validation,
    };
  },
});
