import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { CloseIcon } from '@/react_app/img/Icons'; // Assuming you use this icon elsewhere

import './PreviewDialog.css';

const PreviewDialog = (props) => {
  const {
    previewDetails, open, closeDialog, attachmentType = 'image', isDownload = false
  } = props;

  const footer = (
    <div className="dialog-footer">
      <Button
        label="Download"
        icon="pi pi-download"
        className="downloadBtn"
        onClick={() => window.open(previewDetails?.url, '_blank')}
      />
    </div>
  );

  const renderPreviewContent = () => {
    if (!previewDetails?.url) return <p>No content to preview</p>;
    const isVideo = attachmentType === 'video';
    return (isVideo) ? <video src={previewDetails.url} controls /> : <img src={previewDetails.url} alt="Preview" />
  };

  return (
    <div>
      <Dialog
        showHeader={false}
        className="preview-dialog"
        visible={open}
        onHide={() => closeDialog(false, '')}
        footer={(isDownload) ? footer : ''}
        breakpoints={{ '960px': '75vw', '640px': '90vw' }}
        draggable={false}
        focusOnShow={false}
      >
        <div className='closeRow'>
          <p>Preview</p>
          <div className='closeButton' onClick={() => closeDialog(false, '')}><CloseIcon /></div>
        </div>
        <div className="preview-container" style={{ textAlign: 'center' }}>
          {renderPreviewContent()}
        </div>
      </Dialog>
    </div>
  );
};

export default PreviewDialog;
