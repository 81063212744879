import React, { useEffect, useState } from 'react';
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { ProgressBar } from "primereact/progressbar";
import {
  AndroidIcon, AppleIcon,
  AppStoreIconDefault,
  CheckMark,
  EditPencil,
  GooglePlayIconDefault,
  UGCConceptIcon,
  UGCFormatIcon,
  UGCVideoAspectRatioIcon,
  UGCVideoLengthIcon,
  UGCVideoNoOfCreatorsIcon,
  WebPageIconDefault
} from "@/react_app/img/Icons";

import { useAuth } from "@/modules";
import { CampaignPaymentMethod } from "@/data/types";
import { calculateCpiRate } from "@/utils/globals/cpi-rate";

import TextWithLinks from '../components/TextWithLink';
import AttachmentsDisplay from '@/react_app/Other/AttachmentsDisplay/AttachmentsDisplay';

import './NewCampaignOverview.css';

export function OtherUrlIcon(props) {
  return (
    <div>
      {props.index === 0 && <GooglePlayIconDefault />}
      {props.index === 1 && <AppStoreIconDefault />}
      {props.index > 1 && <WebPageIconDefault />}
    </div>
  )
}

export function PaymentMethodDisplay(props) {
  const { isCreator } = props;
  return (
    <div>
      {props.campaign?.paymentMethod === CampaignPaymentMethod.CPC &&
        <p className={'coverValue'}>{(isCreator) ? calculateCpiRate(props.campaign?.campaignCPC, props.campaign?.appliedFee) : props.campaign?.campaignCPC} USD</p>
      }
      {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM &&
        <p className={'coverValue'}>{(isCreator) ? calculateCpiRate(props.campaign?.campaignCPM?.amount, props.campaign?.appliedFee) : props.campaign?.campaignCPM} USD</p>
      }
      {props.campaign?.paymentMethod === CampaignPaymentMethod.CPI &&
        <div>
          <div className={'coverValue'}>
            <AndroidIcon />
            {(isCreator) ? calculateCpiRate(props.campaign?.campaignCPI?.android, props.campaign?.appliedFee) : props.campaign?.campaignCPI?.android}{' '} USD
          </div>
          <div className={'coverValue'}>
            <AppleIcon />
            {(isCreator) ? calculateCpiRate(props.campaign?.campaignCPI?.ios, props.campaign?.appliedFee) : props.campaign?.campaignCPI?.ios}{' '} USD
          </div>
        </div>
      }
    </div>
  )
}

const getUrlName = (index) => {
  if (index === 0) {
    return 'Google Play Link';
  } else if (index === 1) {
    return 'Apple App Store Link';
  }
  return 'Product Website Link';
}

export default function (props) {

  const { user } = useAuth();  
  const [brand, setBrand] = useState('');
  const [filteredAttachments, setFilteredAttachments] = useState([]);
  const [applicationTimeLeft, setApplicationTimeLeft] = useState('');
  const isCreator = user.value.isCreator() || user.value.isAgencyOwner();

  useEffect(() => {
    if (props.campaign) {
      setFilteredAttachments(props.campaign.attachments);
      setApplicationTimeLeft(props.campaign?.campaignEndDate ? props.campaign.campaignEndDate.toLocaleDateString
        ('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Until budget is complete');
    }

    if (user) {
      setBrand(user?.value);
    }

  }, [props.campaign]);

  return (
    <div className={'campaign-overview'}>
      {!props.campaign?.paymentMethod === CampaignPaymentMethod.UGC && <div className={'topLayer'}>
        <p className={'progressBarText'}>99% completed</p>
        <ProgressBar value={99} />
        <p className={'topText'}>You’re about to launch your campaign! Make sure to review all details!</p>
      </div>}
      <div className={'coverBackground'} style={{ background: `linear-gradient(rgba(19, 10, 35, 0.7), rgba(19, 10, 35, 0.7)), url(${props?.campaign?.coverImage})` }}>
        <p className={'coverTitle'}>{props.campaign?.campaignName}</p>
        <div className={'coverGrid'}>
          {props.campaign?.paymentMethod === CampaignPaymentMethod.UGC && <div>
            <p className={'coverLabel'}>Type:</p>
            <p className={'coverValue'}>{props.campaign?.campaignType}</p>
          </div>}
          <div>
            <p className={'coverLabel'}>Product Category:</p>
            <p className={'coverValue'}>{props.campaign?.paymentMethod === CampaignPaymentMethod.UGC ? props.campaign?.category.value : props.campaign?.category.name}</p>
          </div>
          {!props.campaign?.paymentMethod === CampaignPaymentMethod.UGC && <div>
            <p className={'coverLabel'}>Campaign {props.campaign?.paymentMethod}:</p>
            <PaymentMethodDisplay campaign={props.campaign} isCreator={isCreator} />
          </div>}
          {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM &&
            <div>
              <p className={'coverLabel'}>Max payout:</p>
              <p className={'coverValue'}>{props.campaign?.campaignCPM?.payoutCap} USD</p>
            </div>
          }
          <div>
            <p className={'coverLabel'}>Application time left:</p>
            <p className={'coverValue'}>{applicationTimeLeft}</p>
          </div>
          {props.campaign?.paymentMethod === CampaignPaymentMethod.UGC &&
            <>
              <div>
                <p className={'coverLabel'}>Language:</p>
                <p className={'coverValue'}>{props?.languageOptions?.find(language => language.value == props?.campaign?.languages)?.name}</p>
              </div>
              <div>
                <p className={'coverLabel'}>Country:</p>
                <p className={'coverValue'}>{props?.countryOptions?.find(country => country.value == props?.campaign?.countries)?.name}</p>
              </div>
            </>
          }
          {props.campaign?.paymentMethod !== CampaignPaymentMethod.UGC &&
            <>
              {(props.campaign?.paymentMethod === CampaignPaymentMethod.CPM) ?
                <div>
                  <p className={'coverLabel'}>Language:</p>
                  <p className={'coverValue'}>{props?.campaign?.languages?.map(language => language?.name)?.join(',')}</p>
                </div> : <div>
                  <p className={'coverLabel'}>Region:</p>
                  <p className={'coverValue'}>{(props.campaign?.desiredRegions?.length) ? props.campaign?.desiredRegions[0]?.value : (props?.campaign.marketTier ? props?.campaign.marketTier : '-')}</p>
                </div>
              }
            </>
          }
        </div>
        <Button className={'launchButton'} disabled={props.disableLaunchButton} onClick={() => props.launchCampaign()}>Launch Campaign</Button>
      </div>
      <div className={'overviewMain'}>
        <div className={'overviewMainLeft'}>
          <div>
            <p className={'overviewLabel'}>Campaign Details <EditPencil onClick={() => props.setCurrentTab(2)} /></p>
            <p className={'overviewText'}>{props.campaign?.campaignDescription}</p>
          </div>
          {props.campaign?.paymentMethod === CampaignPaymentMethod.UGC && <div className='ugcInfoWrap'>
            <div className='ugcInfo'>
              <UGCConceptIcon />
              <p className={'ugcInfoLabel'}>Concepts:</p>
              <p className={'ugcInfoValue'}>{props?.campaign?.conceptCount}</p>
            </div>
            <div className='ugcInfo'>
              <UGCFormatIcon />
              <p className={'ugcInfoLabel'}>Format:</p>
              <p className={'ugcInfoValue'}>{props?.campaign?.videoFormat}</p>
            </div>
            <div className='ugcInfo'>
              <UGCVideoLengthIcon />
              <p className={'ugcInfoLabel'}>Length:</p>
              <p className={'ugcInfoValue'}>{props?.campaign?.videoLength}</p>
            </div>
            <div className='ugcInfo'>
              <UGCVideoAspectRatioIcon />
              <p className={'ugcInfoLabel'}>Aspect Ratio:</p>
              <p className={'ugcInfoValue'}>{props?.campaign?.videoAspectRatio?.join(', ')}</p>
            </div>
            <div className='ugcInfo'>
              <UGCVideoNoOfCreatorsIcon />
              <p className={'ugcInfoLabel'}>No. of Creators:</p>
              <p className={'ugcInfoValue'}>{props?.campaign?.noOfCreators}</p>
            </div>
          </div>
          }
          <div>
            <p className={'overviewLabel'}>Campaign requirements <EditPencil onClick={() => props.setCurrentTab(3)} /></p>
            <div className={'talkingPointsWrapper'}>
              {props.campaign?.talkingPoints?.filter(item => item !== '').map((item, index) =>
                <div key={index} className={'talkingPoint'}>
                  <CheckMark />
                  <div className={'talkingPointText'}>
                  <TextWithLinks text={item} />
                  </div>
                </div>)}
            </div>
          </div>
        </div>
        <div className={'overviewMainRight'}>
          <div>
            <p className={'overviewLabel'}>Campaign URls <EditPencil onClick={() => props.setCurrentTab(2)} /></p>
            <div className={'linkWrapper'}>
              {props.campaign?.otherUrls?.map((item, index) =>
                <div key={index}>
                  <p className={'linkLabel'}>{getUrlName(index)}</p>
                  <div className={'link'}>
                    <OtherUrlIcon index={index} />
                    <span className={'overflowEllipsis'}>{item}</span>
                  </div>
                </div>)}
            </div>
          </div>
          <div>
            <p className={'overviewLabel'}>Call to Action <EditPencil onClick={() => props.setCurrentTab(3)} /></p>
            <div className={'callToActionBox'}>{props.campaign?.callToAction}</div>
          </div>
          <div>
            <p className={'overviewLabel'}>Attachments<EditPencil onClick={() => props.setCurrentTab(3)} /></p>
            <AttachmentsDisplay attachments={filteredAttachments} isEditMode={true} />
          </div>
        </div>
      </div>
      <Divider className={'divider'} />
      <div className={'overviewBottom'}>
        <div className={'overviewBottomLeft'}>
          <p className={'overviewLabel'}>About Brand</p>
          <div className={'brandPhotoRow'}>
            <Image src={brand?.brand?.photo} alt={'brand photo'} className={'brandPhoto'} />
            <span>{brand?.brand?.name}</span>
          </div>
          <p className={'overviewText'}>{brand?.brand?.description}</p>
        </div>
        <div className={'overviewBottomRight'}>
          <Button className={'saveDraftButton'} onClick={() => props.saveDraft(true)}
            loading={props.loading} disabled={props.loading}>Save Draft</Button>
          <Button loading={props?.disableLaunchButton} className={'launchButton'} onClick={() => props.launchCampaign()}>Launch Campaign</Button>
        </div>
      </div>
    </div >
  )
}
