import React from 'react';

import { Image } from 'primereact/image';
import noUser from '@/assets/images/no-user.png';

import './NewBrandPhoto.css';


export default function (props) {
  return (
    <>
      {props.photo ? (
        <div className={props.photo ? 'brand-photo' : 'brand-photo-no'}>
          <Image
            src={props.photo}
            className={props.redBorder ? 'photoBrandRedBorder' : 'photoBrand'}
            alt='brand-photo'
          />
        </div>
      ) : (
        <div className='brand-photo-no'>
          <img
            src={noUser}
            width={150}
            height={150}
            className='photoBrand'
            alt='no-user'
          />
        </div>
      )}
    </>
  );
}
