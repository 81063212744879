import React, { useEffect, useState } from 'react';
import './NewPaymentInfoDialog.css';
import { CloseIcon } from "@/react_app/img/Icons";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { countries, gigapaySupportedCountries } from '@/data/static/countries';
import { getUTCTimestampFromDate } from '@/utils/globals/date-utils';

export default function(props) {
  const [isLegalUser, setIsLegalUser] = useState(false);

  const [legalPersonType, setLegalPersonType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [nationality, setNationality] = useState('');
  const [countryOfResidence, setCountryOfResidence] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [countryAddress, setCountryAddress] = useState('');
  const [iban, setIban] = useState('');

  useEffect(() => {
    if(props.currentPaymentInfo) {
      setLegalPersonType(props.currentPaymentInfo.LegalPersonType);
      setFirstName(props.currentPaymentInfo.FirstName);
      setLastName(props.currentPaymentInfo.LastName);
      setDateOfBirth(new Date(props.currentPaymentInfo.Birthday));
      setNationality(countries.find(item => item.code === props.currentPaymentInfo.Nationality));
      setCountryOfResidence(countries.find(item => item.code === props.currentPaymentInfo.CountryOfResidence));
      setAddressLine(props.currentPaymentInfo.LegalRepresentativeAddress.AddressLine1);
      setCity(props.currentPaymentInfo.LegalRepresentativeAddress.City);
      setPostalCode(props.currentPaymentInfo.LegalRepresentativeAddress.PostalCode);
      setCountryAddress(countries.find(item => item.code === props.currentPaymentInfo.LegalRepresentativeAddress.Country));
      setIban(props.currentPaymentInfo.IBAN);
    }

  }, [props.currentPaymentInfo]);

  const handleSubmit = () => {
    const payload = {
        PersonType: isLegalUser ? 'LEGAL' : 'NATURAL',
        LegalRepresentativeAddress: {
          AddressLine1: addressLine,
          City: city,
          PostalCode: postalCode,
          Country: countryAddress.code,
        },
        IBAN: iban,
        Birthday: getUTCTimestampFromDate(dateOfBirth),
        CountryOfResidence: countryOfResidence.code,
        Nationality: nationality.code,
        FirstName: firstName,
        LastName: lastName,
    }

    if (isLegalUser) {
      payload.LegalPersonType = legalPersonType;
    }

    props.paymentInfoChange(payload);
    props.hide();
  }

  return (
    <Dialog visible={props.visible} onHide={() => props.hide()} className={'payment-info-modal'}>
      <div className={'paymentInfoClose'}>
        <div onClick={() => props.hide()}><CloseIcon/></div>
      </div>
      <div className={'paymentInfoMain'}>
        <p className={'paymentInfoTitle'}>Payment Information</p>
        <div className={'dialogSwitch'}>
          <span className={!isLegalUser ? 'dialogSwitchTextRed' : 'dialogSwitchTextGrey'}>Natural User</span>
          <InputSwitch checked={isLegalUser} onChange={(e) => setIsLegalUser(e.value)}/>
          <span className={isLegalUser ? 'dialogSwitchTextRed' : 'dialogSwitchTextGrey'}>Legal User</span>
        </div>
        <div className={'dialogSubField'}>
          <p className={'subFieldTitle'}>Payment Information</p>
          {isLegalUser &&
            <div>
              <p className={'subFieldLabel'}>Legal Person Type</p>
              <InputText value={legalPersonType} onChange={(e) => setLegalPersonType(e.target.value)} className={'subFieldInput'}/>
            </div>
          }
          <div>
            <p className={'subFieldLabel'}>{isLegalUser && 'Legal representative'} First name</p>
            <InputText value={firstName} onChange={(e) => setFirstName(e.target.value)} className={'subFieldInput'}/>
          </div>
          <div>
            <p className={'subFieldLabel'}>{isLegalUser && 'Legal representative'} Last name</p>
            <InputText value={lastName} onChange={(e) => setLastName(e.target.value)} className={'subFieldInput'}/>
          </div>
          <div>
            <p className={'subFieldLabel'}>{isLegalUser && 'Legal representative'} Date of birth</p>
            <Calendar value={dateOfBirth} onChange={(e) => setDateOfBirth(e.value)} className={'subFieldCalendar'} showIcon iconDisplay="input"/>
          </div>
          <div>
            <p className={'subFieldLabel'}>{isLegalUser && 'Legal representative'} Nationality</p>
            <Dropdown value={nationality} onChange={(e) => setNationality(e.value)} filter className={'subFieldDropdown'} optionLabel="name" options={countries} placeholder="Select a Nationality"/>
          </div>
          <div>
            <p className={'subFieldLabel'}>{isLegalUser && 'Legal representative'} Country of Residence</p>
            <Dropdown value={countryOfResidence} onChange={(e) => setCountryOfResidence(e.value)} filter className={'subFieldDropdown'} optionLabel="name" options={gigapaySupportedCountries} placeholder="Select a Country of Residence"/>
          </div>
        </div>
        <div className={'dialogSubField'}>
          <p className={'subFieldTitle'}>Address</p>
          <div>
            <p className={'subFieldLabel'}>Address line 1</p>
            <InputText value={addressLine} onChange={(e) => setAddressLine(e.target.value)} className={'subFieldInput'}/>
          </div>
          <div>
            <p className={'subFieldLabel'}>City</p>
            <InputText value={city} onChange={(e) => setCity(e.target.value)} className={'subFieldInput'}/>
          </div>
          <div>
            <p className={'subFieldLabel'}>Postal code</p>
            <InputText value={postalCode} onChange={(e) => setPostalCode(e.target.value)} className={'subFieldInput'}/>
          </div>
          <div>
            <p className={'subFieldLabel'}>Country</p>
            <Dropdown value={countryAddress} onChange={(e) => setCountryAddress(e.value)} filter className={'subFieldDropdown'} optionLabel="name" options={countries} placeholder="Select a country"/>
          </div>
          <div>
            <p className={'subFieldLabel'}>IBAN/SWIFT</p>
            <InputText value={iban} onChange={(e) => setIban(e.target.value)} className={'subFieldInput'}/>
          </div>
        </div>
        {(!firstName || !lastName || !countryOfResidence || !iban) && <p className={'errorMessage'}>First and Last name, Country of Residence and IBAN/SWIFT are required.</p>}
        <Button className={'submitPaymentInfoButton'} disabled={!firstName || !lastName || !countryOfResidence || !iban} onClick={() => handleSubmit()}>Submit info</Button>
      </div>
    </Dialog>
  )
}
