
export const formatAndAbbreviateNumber: any = (number: number) => {
  if (number < 1000) {
    return number.toString();
  }

  // Number with thousand separators
  const numberWithSeparators = number.toLocaleString();

  // Abbreviation logic
  const abbreviations = [
    { value: 1, suffix: '' },
    { value: 1e3, suffix: 'k' },
    { value: 1e6, suffix: 'M' },
    { value: 1e9, suffix: 'B' },
    { value: 1e12, suffix: 'T' }
  ];

  let abbreviated = '';

  for (let i = abbreviations.length - 1; i >= 0; i--) {
    if (number >= abbreviations[i].value) {
      abbreviated = (number / abbreviations[i].value).toFixed(0) + abbreviations[i].suffix;
      break;
    }
  }

  return {
    numberWithSeparators,
    abbreviated
  };
}


export function formatNumber(number: number): string {
  return number.toLocaleString('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 1,
  });
}
export const calculateCommission = (value:number,rate:number) => {
  return value ? (value - (value * rate / 100)).toFixed(2) : 0;
};