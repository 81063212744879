import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { InputTextarea } from 'primereact/inputtextarea';

import moment from 'moment';

import DragDropList from '@/react_app/Other/DragDropList/DragDropList';
import NewMarketModal from "@/react_app/NewMarketModal/NewMarketModal";
import ImageUploader from '@/react_app/Other/ImageUploader/ImageUploader';
import NewCampaignOverview from "@/react_app/NewCampaignOverview/NewCampaignOverview";
import NewCampaignComplete from "@/react_app/NewCampaignComplete/NewCampaignComplete";
import { paymentMethodsOptions, regionsOptions } from "@/pages/campaigns/create/data.campaign";
import {
  PaymentMethodInfo, PaymentStep, getLinkFieldText, ENGAGEMENT_MINIMUM, MAX_URLS, MAX_TALKING_POINTS, progressPoints,
  categoryItemsIcons, cancelOptions, uploadOptions, chooseOptions, categoryItems, createTabItems, managementTabItems, TabStep
} from "@/react_app/NewCreateCampaign/NewCreateCampaignAdditional";

import { CampaignPaymentMethod } from "@/data/types";
import { DEFAULT_CAMPAIGN_FEE } from "@/utils/globals/cpi-rate";
import {
  useAuth, useCampaigns, useCommonApi, usePage, useWhitelisting
} from "@/modules";

import noImage from "../img/no-image-1-gray.png";
import { WhitelistDropdownIcon } from '../NewAdmin/img/Icons';
import { CopyIconMenu, GenerateTrackingLinkIcon } from '../img/Icons';
import {
  AppStoreIcon, BackIconWhite, FieldTip,
  GooglePlayIcon, LinkIcon, RedTriangle, RedX, SecretTip, WebPageIcon
} from "@/react_app/img/Icons";

import './NewCreateCampaign.css';
import './NewCreateCampaignAdditional.css';
import './NewCreateCampaignResponsive.css';
import 'primeicons/primeicons.css';


function LinkFieldIcon(props) {
  return (
    <div>
      {props.index === 0 && <GooglePlayIcon />}
      {props.index === 1 && <AppStoreIcon />}
      {props.index >= 2 && <WebPageIcon />}
    </div>
  )
}

export default function (props) {
  const {
    manager: {
      getAllBrands
    },
  } = useWhitelisting();
  const { user } = useAuth();
  const isAdmin = user.value.isAdmin()
  const [progressCampaign, setProgressCampaign] = useState({});
  const [currentTab, setCurrentTab] = useState(1);
  const [progressBar, setProgressBar] = useState(0);
  const [reachedTab, setReachedTab] = useState(1);
  const [newlyCreatedCampaign, setNewlyCreatedCampaign] = useState('');
  const [openMarketModal, setOpenMarketModal] = useState(false);
  const [isEndDateExact, setIsEndDateExact] = useState(false);
  const [disableLaunchButton, setDisableLaunchButton] = useState(false);
  const [disableDraftButton, setDisableDraftButton] = useState(false);
  const [campaign, setCampaign] = useState({
    campaignName: '',
    category: categoryItems[0],
    campaignEndDate: "",
    paymentMethod: CampaignPaymentMethod.CPC,
    marketTier: '',
    marketCountries: '',
    coverImage: noImage,
    campaignDescription: '',
    brandTrackingLink: '',
    attachments: [],
    gamekeys: '',
    previewURL: '',
    otherUrls: ['', '', ''],
    talkingPoints: ['', ''],
    callToAction: '',
    campaignCPC: 0,
    campaignCPM: { amount: 0, payoutCap: 0 },
    campaignCPI: { ios: 0, android: 0 },
    campaignBudget: 0,
    isUntilBudget: true,
    untilBudgetDate: '',
    languages: [],
  });
  const [postBackURL, setPostBackURL] = useState('');
  const [isPostBackURLGenerating, setIsPostBackURLGenerating] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isNewUpload, setIsNewUpload] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');

  const { config } = usePage({ title: 'create campaign' });
  const page = useCampaigns(config?.value?.viewPerspective);
  const { manager: { getMasterData } } = useCommonApi();
  const endDate = new Date();
  const isNotCPM = (campaign?.paymentMethod !== CampaignPaymentMethod.CPM);

  useEffect(() => {
    getMasterData('').then((res) => {
      if (res) {
        setLanguageList(res.data.languages.map(language => {
          return {
            value: language.name, name: language.name, id: language.id, code: language.code, isActive: language.isActive
          }
        }));
      } else {
        setLanguageList([]);
      }
    });
  }, []);
  useEffect(() => {
    if (reachedTab < currentTab) {
      setReachedTab(currentTab);
      if (reachedTab <= 3) {
        setProgressBar(reachedTab * 20);
      } else {
        setProgressBar(99);
      }
    }
    return () => {
      setIsNewUpload(false);
    }
  }, [currentTab]);


  useEffect(() => {
    if (props.campaign) {
      setCampaign(({
        campaignName: props.campaign.name,
        category: { type: props.campaign.targetMarket.type, name: props.campaign.targetMarket.title },
        campaignEndDate: props.campaign.dates.endDate,
        paymentMethod: props.campaign.paymentMethod,
        marketTier: props.campaign.desiredRegions[0]?.value,
        marketCountries: props.campaign.desiredRegions[0]?.name,
        coverImage: props.campaign.photo,
        campaignDescription: props.campaign.productName,
        brandTrackingLink: props.campaign.trackingLink,
        attachments: props.campaign.attachments?.filter(file => !!file?.url),
        gamekeys: props.campaign.gamekeys,
        otherUrls: props.campaign.productUrls.slice(1).map(item => item.value),
        previewURL: props.campaign.productUrls[0]?.value || "",
        talkingPoints: props.campaign.talkingPoints,
        callToAction: props.campaign.callToAction,
        campaignCPC: props.campaign?.cpc?.amount,
        campaignCPM: { amount: props.campaign?.cpm?.amount, payoutCap: props.campaign?.cpm?.payoutCap },
        campaignCPI: { ios: props.campaign?.cpi?.ios?.amount, android: props.campaign?.cpi?.android?.amount },
        campaignBudget: props.campaign.budget.amount,
        isUntilBudget: props.campaign?.isUntilBudget,
        untilBudgetDate: props.campaign?.untilBudgetDate,
        languages: props.campaign?.languages || [],
      }));

      if (props.campaign?.dates?.endDate) setIsEndDateExact(true);
      setSelectedBrand(props?.campaign?.brand?.id);
    }
    if (isAdmin) {
      if (brandsOptions.length <= 0) {
        getAllBrands().then(brands => {
          setBrandsOptions(brands.map(brand => {
            return { name: brand.name, value: brand.id, label: brand.name }
          }));
        }).catch((error) => console.log('error', error));
      }
    }
  }, [props.campaign]);

  useEffect(() => {
    const campaignOtherUrls = campaign.otherUrls.map(function (link, index) {
      if (index === 0) {
        return { type: "Google", value: link };
      } else if (index === 1) {
        return { type: "Apple", value: link };
      }
      return { type: "Webpage", value: link };
    });
    campaignOtherUrls.unshift({ type: "Webpage", value: campaign.previewURL })

    setProgressCampaign({
      name: campaign.campaignName,
      budget: { amount: campaign.campaignBudget, currency: 'USD', amountLeft: props.campaign?.budget?.amountLeft || 0 },
      dates: { startDate: props?.campaign?.dates?.startDate || new Date(), endDate: campaign.campaignEndDate },
      targetMarket: { type: campaign.category.type, title: campaign.category.name },
      desiredRegions: { name: campaign.marketCountries, value: campaign.marketTier },
      productName: campaign.campaignDescription,
      productUrls: campaignOtherUrls,
      callToAction: campaign.callToAction,
      talkingPoints: campaign.talkingPoints,
      trackingLink: campaign.brandTrackingLink,
      attachments: campaign.attachments,
      gamekeys: campaign.gamekeys,
      cpi: { ios: { amount: campaign.campaignCPI.ios }, android: { amount: campaign.campaignCPI.android } },
      cpc: { amount: campaign.campaignCPC },
      cpm: { amount: campaign.campaignCPM.amount, payoutCap: campaign.campaignCPM.payoutCap },
      paymentMethod: campaign.paymentMethod,
      photo: campaign.coverImage,
      paymentInformation: {},
      appliedFee: DEFAULT_CAMPAIGN_FEE,
      isUntilBudget: campaign?.isUntilBudget,
      untilBudgetDate: (campaign.untilBudgetDate) ? moment(campaign?.untilBudgetDate).format('YYYY-MM-DD HH:mm:ss') : moment().add(366, 'days').format('YYYY-MM-DD HH:mm:ss'),
      languages: campaign.languages.map(language => language.id).join("") || "",
      // subscribers: campaign.subscribers,
      // categories: campaign.categories,
      // ageLimit: campaign.ageLimit,
      // platforms: campaign?.platforms?.join(',') || '',
    });
  }, [campaign]);
  const createDraft = async (pushRouter) => {
    setIsLoader(true);
    setDisableDraftButton(true);

    const updatedProgressCampaign = { ...progressCampaign };
    if (isAdmin) {
      updatedProgressCampaign.brandId = selectedBrand;
    }
    const createdDraft = await page.manager.create(updatedProgressCampaign);
    if (campaign.attachments?.length) {
      const formData = new FormData();
      for await (const file of campaign.attachments) {
        formData.set('files[]', file);
        await page.manager.attachments(createdDraft?.id, formData);
      }
    }
    if (pushRouter) {
      await props.pushDraft(createdDraft);
    }

    setDisableDraftButton(false);
    setIsLoader(false);
    return createdDraft;
  };
  const launchCampaign = async () => {
    setDisableLaunchButton(true);
    if (!props.campaign) {
      await createDraft(false).then(async (createdCampaign) => {
        if (createdCampaign?.id) {
          setCurrentTab(currentTab + 1);
          setNewlyCreatedCampaign(createdCampaign);
        }
      });
    }
    setDisableLaunchButton(false);
  };
  const handleUrlChange = (e, index) => {
    setCampaign(prevState => ({
      ...prevState,
      otherUrls: prevState.otherUrls.map((item, i) => (i === index ? e.target.value : item)),
    }));
  };
  const handleRemoveUrl = (index) => {
    const newUrls = [...campaign.otherUrls];
    newUrls.splice(index, 1);
    setCampaign((prevState) => ({ ...prevState, otherUrls: newUrls }));
  };
  const handleTalkingPointChange = (e, index) => {
    setCampaign(prevState => ({
      ...prevState,
      talkingPoints: prevState.talkingPoints.map((item, i) => (i === index ? e.target.value : item)),
    }));
  };
  const handleRemoveTalkingPoint = (index) => {
    const newTalkingPoints = [...campaign.talkingPoints];
    newTalkingPoints.splice(index, 1);
    setCampaign((prevState) => ({ ...prevState, talkingPoints: newTalkingPoints }));
  };
  const checkMinimalBudget = (engagementAmount) => {
    return campaign.campaignBudget < (ENGAGEMENT_MINIMUM * engagementAmount) || campaign.campaignBudget === 0;
  }
  const isValidPreviewUrl = (url) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(url);
  }
  const isValidCampaignNameLength = (name) => {
    return name.length <= 150;
  }
  const isEditMode = props?.campaign;
  const disableAfterActive = props?.campaign?.status && !(props?.campaign?.status === "pending" || props?.campaign?.status === "draft");
  const validPreviewUrl = campaign.previewURL.length > 0 && !isValidPreviewUrl(campaign.previewURL) && !disableAfterActive;
  const validCampaignNameLength = campaign.campaignName.length > 0 && !isValidCampaignNameLength(campaign.campaignName);
  const tab1Validations = !campaign.campaignName || ((isNotCPM) ? !campaign.marketCountries : campaign.languages.length === 0) || campaign.coverImage === noImage
    || validCampaignNameLength || (isAdmin && !selectedBrand)
  const tab2Validations = (!campaign.previewURL) || (validPreviewUrl) || !campaign.brandTrackingLink;

  const checkDisabled = () => {
    if (disableLaunchButton) {
      return true;
    }
    else if (currentTab === 1 && tab1Validations) {
      return true;
    }
    else if (currentTab === 2 && tab2Validations) {
      return true;
    }
    else if (currentTab === 4) {
      if (!campaign.campaignBudget) {
        return true;
      }
      if (campaign.paymentMethod === CampaignPaymentMethod.CPC) {
        return checkMinimalBudget(campaign.campaignCPC);
      } else if (campaign.paymentMethod === CampaignPaymentMethod.CPM) {
        return checkMinimalBudget(campaign.campaignCPM.amount);
      }
      return checkMinimalBudget(Math.max(campaign.campaignCPI.android, campaign.campaignCPI.ios));
    }
  }

  const checkDisabledDraft = () => {
    if (disableDraftButton) {
      return true;
    }
    else if (!campaign.campaignName || tab1Validations) {
      return true;
    }

    else if (currentTab === 2 && tab2Validations) {
      return true;
    }

    return false;
  }

  const handleContinue = () => {
    if (isEditMode && currentTab === TabStep.Budget)
      handleSaveEdit();
    else setCurrentTab(currentTab + 1);
  }

  const onTemplateSelect = (e) => {
    if (!!isEditMode) {
      setIsNewUpload(true);
    }
    const { files } = e;

    const combinedAttachments = [...campaign.attachments, ...files];
    const seen = new Set();

    const filteredAttachments = combinedAttachments.filter(item => {
      const url = item.url || item.objectURL || item.name;
      if (!url || seen.has(url)) {
        return false;
      }
      seen.add(url);
      return true;
    });

    setCampaign(prevState => ({ ...prevState, attachments: filteredAttachments }));
  };
  const isSameFile = (attachment, file) => {
    return (attachment.url && attachment.url === file.url) || (attachment.objectURL && attachment.objectURL === file.objectURL);
  };
  const onTemplateRemove = (e) => {
    const { file } = e;
    setCampaign(prevState => ({
      ...prevState,
      attachments: prevState.attachments.filter(attachment => !isSameFile(attachment, file))
    }));
  };
  const handleRouting = async (createdCampaign) => {
    await props.pushDraft(createdCampaign);
    await page.manager.pending(createdCampaign?.id);
  }
  const handleSaveEdit = async () => {
    const updatedProgressCampaign = {
      ...progressCampaign,
    }

    updatedProgressCampaign.attachments = [...progressCampaign.attachments]

    if (updatedProgressCampaign.attachments?.length) {
      const formData = new FormData();
      for await (const file of updatedProgressCampaign.attachments) {
        if (!file.url) {
          formData.set('files[]', file);
          await page.manager.attachments(props?.campaign?.id, formData).then(async (response) => {
            if (response && response.attachments.length > 0) {
              const latestUpload = response.attachments[response.attachments.length - 1];
              updatedProgressCampaign.attachments.push(latestUpload)
            }
          }).catch(error => {
            if (error) {
              console.log('attachments error', error)
            }
          });
        }
      }
    }

    updatedProgressCampaign.attachments = updatedProgressCampaign.attachments.filter(attachment => attachment.url);

    await props.saveEdit(updatedProgressCampaign).then(async (res) => {
      props.cancelEdit();
    })
  }
  const generatePostBackURL = async () => {
    setIsPostBackURLGenerating(true);
    const formateExpirationDate = (campaign.campaignEndDate) ? moment(campaign.campaignEndDate).format('YYYY-MM-DD HH:mm:ss') : moment().add(366, 'days').format('YYYY-MM-DD HH:mm:ss');
    const createdDraft = await page.manager.generatePostBackURL(campaign.campaignName, formateExpirationDate, campaign.previewURL, 678).then(async (response) => {
      setPostBackURL(response.postback_url);
      setIsPostBackURLGenerating(false);
    }).catch(error => {
      if (error) {
        setIsPostBackURLGenerating(false);
      }
    });
    return createdDraft;
  };

  const handleCopyPostBackURL = () => {
    navigator.clipboard.writeText(postBackURL)
      .then(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const FileUploadRow = ({ file, onDelete }) => {
    return (
      <div className="p-fileupload-row" data-pc-section="file">
        <img
          role="presentation"
          className="p-fileupload-file-thumbnail"
          src={file.url ?? file.objectURL}
          width="50"
          data-pc-section="thumbnail"
          alt={file.name}
        />
        <div data-pc-section="details">
          <div className="p-fileupload-filename" data-pc-section="filename">
            {file.name}
          </div>
          <span data-pc-section="filesize">{file.size}</span>
          <span
            className="p-fileupload-file-badge p-badge p-component p-badge-warning"
            data-pc-name="badge"
            data-pc-section="root"
          >
            Pending
          </span>
        </div>
        <div data-pc-section="actions">
          <button
            className="p-button p-component p-button-icon-only p-button-text p-button-rounded p-button-danger"
            type="button"
            data-pc-name="button"
            data-pc-section="root"
            onClick={() => onDelete(file)}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="p-icon"
              aria-hidden="true"
            >
              <path
                /* eslint-disable-next-line max-len,vue/max-len */
                d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z"
                fill="currentColor"
              />
            </svg>
            <span className="p-button-label p-c">&nbsp;</span>
          </button>
        </div>
      </div>
    );
  };

  const FileUploadList = ({ files, onDelete, isNewUpload }) => {
    return (
      <div className={`uploaded-attachments-list ${isNewUpload ? 'show' : 'hide'}`}>
        {(!isEditMode && (files?.length < 1)) ? <p className={'uploadText'}>Drag and drop files to here to upload.</p>
          : files.map(file => (
            <FileUploadRow key={file.id} file={file} onDelete={onDelete} />
          ))
        }
      </div>
    );
  };

  const handleDeleteAttachment = (file) => {
    setCampaign(prevState => ({
      ...prevState,
      attachments: prevState.attachments.filter(attachment => !isSameFile(attachment, file))
    }));
  };

  const handleChangeCalender = (value) => {
    setCampaign(prevState =>
      ({ ...prevState, campaignEndDate: value, isUntilBudget: false, untilBudgetDate: moment(value).format('YYYY-MM-DD HH:mm:ss') }));
    setIsEndDateExact(true);
  }

  const handleChangeUntilBudget = () => {
    if (!campaign.isUntilBudget) {
      setCampaign(prevState => ({
        ...prevState, campaignEndDate: "", isUntilBudget: true,
        untilBudgetDate: (campaign.untilBudgetDate) ? moment(campaign.untilBudgetDate).add(366, 'days').format('YYYY-MM-DD HH:mm:ss')
          : moment().add(366, 'days').format('YYYY-MM-DD HH:mm:ss')
      }))
      setIsEndDateExact(false);
    }
  }
  const handleLanguages = (value) => {
    const selectedLanguage = languageList.find(language => language.value === value);
    setCampaign(prevState => ({ ...prevState, languages: [selectedLanguage] }));
  }
  const handleChangePaymentMethod = (method) => {

    if (isEditMode) {
      setCampaign(prevState => ({
        ...prevState, paymentMethod: method,
        marketTier: (method === 'CPM') ? regionsOptions[0].value : props.campaign?.desiredRegions[0]?.value,
        marketCountries: (method === 'CPM') ? regionsOptions[0].name : props.campaign?.desiredRegions[0]?.name,
        languages: (method === 'CPM') ? props.campaign?.languages : []
      }));
    } else {
      setCampaign(prevState => ({
        ...prevState, paymentMethod: method,
        marketTier: (method === 'CPM') ? regionsOptions[0].value : '',
        marketCountries: (method === 'CPM') ? regionsOptions[0].name : '',
        languages: (method === 'CPM') ? campaign?.languages : []
      }));
    }

  }

  const handleImageChange = (newImage) => {
    setCampaign(prevState => ({ ...prevState, coverImage: newImage }));
    if (isEditMode && newImage) {
      if (props?.setCampaignManagementPreview) {
        props?.setCampaignManagementPreview(newImage);
      }
    }
  };

  const handleChangeTalkingPoint = (data) => {
    setCampaign(prevState => ({ ...prevState, talkingPoints: data }));
  }

  return (
    <div className={'create-campaign'}>
      {currentTab < TabStep.Completed &&
        <div>
          <div className={'titleLayer'}>
            <p className={'title'}>{isEditMode ? 'Edit Campaign' : 'New Campaign'}</p>
            {props.campaign ?
              <div className={'campaignButtonGroup'}>
                <Button className={'secondaryButton'} onClick={() => props.cancelEdit()}>Cancel</Button>
                <Button className={'primaryButton'} disabled={checkDisabled()} onClick={() => handleSaveEdit()}>Update Campaign</Button>
              </div> :
              !isAdmin && <Button className={'secondaryButtonSmall'} disabled={currentTab === 1} onClick={() => setCurrentTab(currentTab - 1)}><BackIconWhite />To the previous step</Button>
            }
          </div>
          <div className={'tabLayer'}>
            {(!props.isCampaignManagement ? createTabItems : managementTabItems).map((item, index) =>
              <div key={index} className={(item.index === currentTab) ? 'tabLayerItemCurrent' : 'tabLayerItem'}
                onClick={() => { if (index + 1 <= reachedTab) setCurrentTab(index + 1) }} style={{ width: props.isCampaignManagement ? '25%' : '20%' }}>
                <div className={(item.index === currentTab) ? 'tabNumberSelected' : 'tabNumber'}>
                  0{item.index}
                </div>
                <span className={'tabName'}>{item.name}</span>
                {(item.index === currentTab) && <RedTriangle />}
              </div>)}
          </div>
        </div>
      }
      <div className={'mainLayer'}>
        {currentTab === TabStep.Start && <div className={'step'}>
          {isAdmin && <div className="input-wrap">
            <p className={'fieldLabel'}>Brand <span className={'redStar'}>*</span></p>
            <Dropdown className={'dropdownField'}
              value={selectedBrand}
              onChange={(e) => setSelectedBrand(e.value)}
              filter
              showClear={selectedBrand !== ""}
              disabled={isEditMode}
              options={brandsOptions}
              optionLabel="name"
              placeholder="Select a brand"
              dropdownIcon={<WhitelistDropdownIcon scale={0.6} />} />
          </div>}
          <div>
            <p className={'fieldLabel'}>Campaign Name <span className={'redStar'}>*</span></p>
            <InputText className={'inputField'} value={campaign.campaignName} onChange={(e) =>
              setCampaign(prevState => ({ ...prevState, campaignName: e.target.value }))} placeholder={'Give your campaign a name'}
              disabled={isEditMode} />
            {validCampaignNameLength &&
              <p className={'draftDisabledText'}>{'The Campaign Name must be fewer than 150 characters.'}</p>
            }
            <div className={'secretTip'}>
              <SecretTip />
              <div>
                <p className={'secretTipTitle'}>Secret Tip</p>
                <p className={'secretTipText'}>Make sure this title is accurate to your product and snappy enough to entice creators!</p>
              </div>
            </div>
          </div>
          <div>
            <p className={'fieldLabel'}>Campaign End Date <span className={'redStar'}>*</span></p>
            <div className={'endDateField'}>
              <Calendar className={'calendarField'} value={campaign.campaignEndDate ? new Date(campaign.campaignEndDate) : ""}
                onChange={(e) => { handleChangeCalender(e.value) }} minDate={endDate} dateFormat="dd/mm/yy" />
              <span className={'endDateLabel'}>Or</span>
              <div className={isEndDateExact ? 'endDateOtherOption' : 'endDateOtherOptionSelected'}
                onClick={() => { handleChangeUntilBudget() }}>
                <i className="pi pi-check" style={{ color: '#FFF', fontSize: '20px', marginRight: '10px' }} />
                Until Budget is Complete
              </div>
            </div>
          </div>
          <div>
            <p className={'fieldLabel'}>Product Category</p>
            <div className={'categoryWrapper'}>
              {categoryItems.map((item, index) =>
                <div key={index} className={campaign.category.type === item.type ? 'categoryItemSelected' : 'categoryItem'}
                  onClick={() => setCampaign(prevState => ({ ...prevState, category: item }))}>
                  {categoryItemsIcons[index]}
                  <p className={'categoryText'}>{item.name}</p>
                </div>)}
            </div>
          </div>
          <div className="dual-field-wrap start">
            <div className="input-wrap">
              <p className={'fieldLabel'}>Campaign Type <span className={'redStar'}>*</span></p>
              <Dropdown className={'dropdownField'} value={campaign.paymentMethod} onChange={(e) => handleChangePaymentMethod(e.value)}
                options={paymentMethodsOptions} optionLabel="title" placeholder="Select payment method" />
              <PaymentMethodInfo class={'paymentMethodText'} paymentMethod={campaign.paymentMethod} />
            </div>
          </div>
          {campaign.paymentMethod !== 'CPM' ? <div>
            <p className={'fieldLabel'}>Market <span className={'redStar'}>*</span></p>
            <div className={'marketField'} onClick={() => setOpenMarketModal(true)}>
              <div>
                <p className={'marketFieldBold'}>{campaign.marketCountries ? campaign.marketTier : 'Select your preferred category'}</p>
                <p className={'marketFieldNormal'}>{campaign.marketCountries ? campaign.marketCountries : 'Make a selection on the type and coverage of the market'}</p>
              </div>
              <i className={'pi pi-plus'} style={{ color: '#FFF', fontSize: '24px', margin: '0 8px' }} />
            </div>
          </div> :
            <div>
              <p className='fieldLabel'>Languages <span className='redStar'>*</span></p>
              <Dropdown className='dropdownField' value={campaign?.languages[0]?.name} onChange={(e) => {
                handleLanguages(e.value);
              }}
                options={languageList} optionLabel="name" placeholder="Select language" />
              {/* <MultiSelect value={campaign.languages} onChange={(e) => handleLanguages(e.value)} options={languageList}
                optionLabel="name" placeholder="Select Language" selectionLimit={1} maxSelectedLabels={1} className="multiSelect" display='chip' /> */}
            </div>}
        </div>}
        {currentTab === TabStep.Details && <div className={'step'}>
          <div>
            <p className={'fieldLabel'}>Campaign Description</p>
            <InputTextarea autoResize value={campaign.campaignDescription} onChange={(e) => setCampaign(prevState => ({ ...prevState, campaignDescription: e.target.value }))}
              rows={5} className={'textArea'} placeholder={'Tell creators about your campaign'} />
          </div>
          <div>
            <p className={'fieldLabel mb-5'}>Campaign URLs</p>
            <div>
              <div className={'urlLabel'}>Preview URL
                <FieldTip title={'Add your Preview URL here.'} />
              </div>
              <span className="p-input-icon-left">
                <WebPageIcon />
                <InputText placeholder={`Enter preview URL`} className={'urlInput'} value={campaign.previewURL} onChange={(e) => {
                  setPostBackURL('');
                  setCampaign(prevState => ({ ...prevState, brandTrackingLink: '', previewURL: e.target.value }))
                }
                } disabled={disableAfterActive} />
              </span>
              {validPreviewUrl &&
                <p className={'draftDisabledText'}>{'Please Add Valid Preview URL.'}</p>
              }
            </div>
          </div>
          <div>
            <div className={'urlLabel'}>Brand Tracking Link Input</div>
            <div className={`post-back-url ${(isPostBackURLGenerating || !campaign.previewURL || (validPreviewUrl) || postBackURL || disableAfterActive) ? 'disable' : ''}`}
              title={`${(isPostBackURLGenerating || !campaign.previewURL || (validPreviewUrl)) ? 'Please add valid Preview URL first or Wait if Post Back URL request is in the progress.' : ''}`}>
              <Button className="post-back-url-btn" onClick={() => { generatePostBackURL() }}
               disabled={isPostBackURLGenerating || !campaign.previewURL || (validPreviewUrl) || postBackURL || disableAfterActive}>
                <GenerateTrackingLinkIcon color={!postBackURL ? "#fff" : "#4BBD07"} /> Generate Post Back URL <FieldTip title={'Click to Generate post back url.'} />
              </Button>
              {isPostBackURLGenerating ? <span class="loader"></span> : ""}
            </div>
            {<div className={`post-back-copy ${!postBackURL || disableAfterActive ? 'disable' : ''}`}>
              <div className='text-wrap'>
                <p>{postBackURL ? postBackURL : "Generated link will be here."}</p>
                <Button className='post-back-url-btn' onClick={() => { postBackURL && handleCopyPostBackURL() }} disabled={disableAfterActive}>
                  <CopyIconMenu scale={0.8} color={postBackURL ? "#fff" : "gray"} />
                </Button>
                <div className={`tooltip ${showTooltip ? 'show' : ''}`}>Copied!</div>
              </div>
            </div>}
            <span className="p-input-icon-left">
              <LinkIcon />
              <InputText value={campaign.brandTrackingLink} onChange={(e) => setCampaign(prevState => ({ ...prevState, brandTrackingLink: e.target.value }))}
                placeholder='Enter your link for tracking generation' className={'urlInput'} disabled={disableAfterActive} />
            </span>
          </div>
          <div>
            <div className={'urlsWrapper'}>
              <div>
                <p className={'urlLabel'}>Gamekeys (if applicable)</p>
                <div className={'otherUrlsWrapper'}>
                  <div className='linkWrapper'>
                    <span className="p-input-icon-left">
                      <LinkIcon />
                      <InputText placeholder={`Enter your link to gamekey folder`} className={'urlInput'} value={campaign.gamekeys} onChange={(e) => setCampaign(prevState => ({ ...prevState, gamekeys: e.target.value }))} />
                    </span>
                  </div>
                  {campaign.otherUrls?.map((item, index) =>
                    <div key={index} className={'linkWrapper'}>
                      <span className="p-input-icon-left">
                        <LinkFieldIcon index={index} />
                        <InputText placeholder={`Enter your ${getLinkFieldText(index)} URL`}
                          className={'urlInput'} value={item} onChange={(e) => handleUrlChange(e, index)} />
                      </span>
                      {index > 2 && <RedX onClick={() => handleRemoveUrl(index)} />}
                    </div>)}
                  {campaign.otherUrls.length <= MAX_URLS && <p className={'addField'} onClick={() => setCampaign((prevState) => ({ ...prevState, otherUrls: [...prevState.otherUrls, ''] }))}>+ Add another URL</p>}
                </div>
              </div>
            </div>
          </div>
        </div>}
        {currentTab === TabStep.Instructions && <div className={'step'}>
          <div>
            <p className='talkingPointsTitle'>Campaign requirements</p>
            <div className='talkingPointsWrapper'>
              <DragDropList initialItems={campaign.talkingPoints} reArrange={handleChangeTalkingPoint} change={handleTalkingPointChange}
                remove={handleRemoveTalkingPoint} />
              {campaign.talkingPoints.length <= MAX_TALKING_POINTS && <p className='addField' onClick={() => setCampaign((prevState) => ({ ...prevState, talkingPoints: [...prevState.talkingPoints, ''] }))}>+ Add another Talking Point</p>}
            </div>
          </div>
          <div>
            <p className={'talkingPointsTitle'}>Call to action</p>
            <InputTextarea value={campaign.callToAction} onChange={(e) => setCampaign(prevState => ({ ...prevState, callToAction: e.target.value }))}
              autoResize rows={5} className={'textArea'} placeholder={'Enter your call to action'} />
          </div>
          <div className={`file-upload-wrap ${!isEditMode ? 'new-upload create' : ''} ${isNewUpload ? 'new-upload' : ''}`}>
            <p className={'talkingPointsTitle'}>Attachments</p>
            <FileUpload className={'fileUpload'} multiple emptyTemplate={
              <>
                {!!isEditMode && ((progressCampaign?.attachments?.length > 0) ?
                  <FileUploadList key="one" files={progressCampaign.attachments} onDelete={handleDeleteAttachment} isNewUpload={false} /> :
                  <p className={'uploadText'}>Drag and drop files to here to upload.</p>)
                }
              </>
            } onRemove={onTemplateRemove}
              chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} onSelect={onTemplateSelect} chooseLabel="Upload"
              onClear={() => setIsNewUpload(false)}
              {...(isEditMode ? {} : { itemTemplate: () => null })}
            />
            {isNewUpload && !!isEditMode && <FileUploadList files={[...progressCampaign?.attachments.filter(attachment => !!isEditMode ? !!attachment.url : !!attachment.objectUrl)]} onDelete={handleDeleteAttachment} isNewUpload={true} />}
            {!isEditMode && <FileUploadList key="two" files={[...progressCampaign?.attachments.filter(attachment => !!attachment)]} onDelete={handleDeleteAttachment} isNewUpload={true} />}
          </div>
        </div>}
        {currentTab === TabStep.Budget && <div className={'step'}>
          <div>
            <p className={'fieldLabel'}>Payment Method</p>
            <PaymentMethodInfo class={'paymentMethodTextWhite'} paymentMethod={campaign.paymentMethod} />
          </div>
          <PaymentStep campaign={campaign} setCampaign={setCampaign} />
        </div>}
        {currentTab === TabStep.Overview && !props.isCampaignManagement &&
          <NewCampaignOverview launchCampaign={launchCampaign} saveDraft={createDraft} disableLaunchButton={disableLaunchButton} campaign={campaign} setCurrentTab={setCurrentTab} loading={isLoader} />
        }
        {currentTab === TabStep.Completed && !props.isCampaignManagement &&
          <NewCampaignComplete campaign={newlyCreatedCampaign} handleRouting={() => handleRouting(newlyCreatedCampaign)} />
        }
        {currentTab < TabStep.Overview &&
          <div className={'rightSide'}>
            <div className={'campaignProgress'}>
              <ImageUploader
                currentTab={currentTab}
                coverImage={campaign.coverImage}
                onImageChange={handleImageChange}
              />
              <p className={'campaignName'}>{campaign.campaignName ? campaign.campaignName : 'Campaign name'}</p>
              {!props.campaign && <p className={'progressBarText'}>{progressBar}% completed</p>}
              {!props.campaign && <ProgressBar value={progressBar} />}
              <div className={'progressPointsWrapper'}>
                {progressPoints.map((point, index) => (
                  point !== "Overview" || !isEditMode ? (
                    <div key={index} className='progressPoint'>
                      <div className={index < reachedTab ? 'progressPointRed' : 'progressPointWhite'} />
                      <span className='progressPointText'>{point}</span>
                    </div>
                  ) : null
                ))}
              </div>
            </div>
            {props.campaign ?
              <div className={'campaignButtonGroup'}>
                <Button className={'secondaryButton'} disabled={currentTab === 1} onClick={() => setCurrentTab(currentTab - 1)}>Go Back</Button>
                <Button className={'primaryButton'} disabled={checkDisabled()} onClick={() => handleContinue()}>{isEditMode && currentTab === TabStep.Budget ? "Update Campaign" : "Continue"}</Button>
              </div> :
              <div className={'campaignButtonGroup'}>
                <Button loading={isLoader} className={'secondaryButton'} disabled={checkDisabledDraft()} onClick={() => createDraft(true)}>Save Draft</Button>
                <Button className={'primaryButton'} disabled={checkDisabled()} onClick={() => handleContinue()}>Continue</Button>
              </div>
            }
            {checkDisabledDraft() &&
              <p className={'draftDisabledText'}>{currentTab !== 2 ? `Fill in the following fields: ${isAdmin ? 'Brand ,' : ''} Campaign name,${isNotCPM ? ' Market' : ' Language'}  , Cover Image.` :
                "Fill in the following fields: Valid Preview URL, Tracking URL"}</p>
            }
          </div>
        }
      </div>
      <NewMarketModal visible={openMarketModal} close={() => setOpenMarketModal(false)} marketTier={campaign.marketTier}
        setMarketTier={(tier) => setCampaign(prevState => ({ ...prevState, marketTier: tier }))} setMarketCountries={(countries) => setCampaign(prevState => ({ ...prevState, marketCountries: countries }))} />
    </div>
  )
}
