import React, { useEffect, useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { PerformanceIcon, UGCICON } from '@/react_app/img/Icons';

import './CampaignTypeDialog.css';

const CampaignTypeDialog = (props) => {
  const { open, close, submit } = props;
  const [currentType, setCurrentType] = useState('');

  const categoryItems = [
    { type: 'UGC', name: 'UGC', icon: <UGCICON /> },
    { type: 'performance', name: 'Performance', icon: <PerformanceIcon /> },
  ];

  useEffect(() => {      
    return () => {
      setCurrentType('');
    }
  }, [])
  

  return (
    <Dialog visible={open} onHide={() => close()} className="campaignTypeDialog">
      <p className="title">Select campaign type</p>

      <div className="categoryWrapper">
        {categoryItems.map((item) => (
          <div
            key={item.type}
            onClick={() => setCurrentType(item.type)}
            className={
              currentType === item.type
                ? 'categoryItemSelected'
                : 'categoryItem'
            }
          >
            {item.icon}
            <p className='categoryText'>{item.name}</p>
          </div>
        ))}
      </div>
      <div className="actionButton">
        <Button className="selectButton" disabled={!currentType} onClick={()=>submit(false,currentType)}>Select</Button>
      </div>
    </Dialog>
  );
};

export default CampaignTypeDialog;