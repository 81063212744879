import moment from 'moment';

export const formatDate = (date?: string | Date, format = 'D MMM YYYY') => {
  if (!date) {
    return '';
  }
  if (format === 'long') {
    format = 'D MMM YYYY, H:m A'; // eslint-disable-line no-param-reassign
  }
  return moment(date).format(format);
};

export const currentDateBefore = (date: string) => {
  if (date) {
    return moment(new Date()).isBefore(date);
  }
  return true;
};

export const timeAgo = (date?: string | Date) => {
  if (!date) {
    return '';
  }
  return moment(date).fromNow();
};

// eslint-disable-next-line consistent-return
export const parseApplicationTimeLeft = (period: any[] | string | Date, returnObject = false) => {
  if (period) {
    let date = null;
    let endDate = null;
    if (Array.isArray(period)) {
      date = period.find((el: { tittle: string }) => el.tittle === 'applications');
      endDate = new Date(date.endDate);
    } else if (typeof period === 'string' || 'Date') {
      endDate = period;
    }
    if (endDate) {
      const total = Date.parse(endDate.toString()) - Date.parse(new Date().toString());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      if (!returnObject) {
        let stringDays = '';
        let stringHours = '';
        let stringMinutes = '';

        if (days <= 0) {
          stringDays = '00';
        } else if (days < 10) {
          stringDays = `0${days}`;
        } else {
          stringDays = `${days}`;
        }

        if (hours <= 0) {
          stringHours = '00';
        } else if (hours < 10) {
          stringHours = `0${hours}`;
        } else {
          stringHours = `${hours}`;
        }

        if (minutes <= 0) {
          stringMinutes = '00';
        } else if (minutes < 10) {
          stringMinutes = `0${minutes}`;
        } else {
          stringMinutes = `${minutes}`;
        }

        stringDays += 'd';
        stringHours += 'h';
        stringMinutes += 'm';
        return `${stringDays} ${stringHours} ${stringMinutes}`;
      }
      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    }
  }
};

// eslint-disable-next-line consistent-return
export const parseCampaignEndTimeLeft = (endDate: string | Date, returnObject = false) => {
  if (endDate) {
    const total = Date.parse(endDate.toString()) - Date.parse(new Date().toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    if (!returnObject) {
      let stringDays = '';
      let stringHours = '';
      let stringMinutes = '';

      if (days <= 0) {
        stringDays = '00';
      } else if (days < 10) {
        stringDays = `0${days}`;
      } else {
        stringDays = `${days}`;
      }

      if (hours <= 0) {
        stringHours = '00';
      } else if (hours < 10) {
        stringHours = `0${hours}`;
      } else {
        stringHours = `${hours}`;
      }

      if (minutes <= 0) {
        stringMinutes = '00';
      } else if (minutes < 10) {
        stringMinutes = `0${minutes}`;
      } else {
        stringMinutes = `${minutes}`;
      }

      stringDays += 'd';
      stringHours += 'h';
      stringMinutes += 'm';
      return `${stringDays} ${stringHours} ${stringMinutes}`;
    }
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }
};

export const getUTCTimestampFromDate = (date: Date) => date.getTime();

enum UnitDate {
  milliseconds = 'milliseconds',
  seconds = 'seconds',
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  month = 'month',
  years = 'years',
}

export const addToDate = (date: Date = new Date(), count: number, unit: UnitDate) => moment(date).add(count, unit);


export const isOver16YearsOld = (date: Date) => {
  const birthMoment = moment(date, "YYYY-MM-DD HH:mm:ss");
  const now = moment();
  const age = now.diff(birthMoment, 'years');
  return age > 16;
}