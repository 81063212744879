import React, { useEffect, useState } from "react";

import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';

import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

import { ExpandArrowDown, ExpandArrowUp } from '../../img/Icons';


const creatorTemplate = (data) => (
    <div className={'creatorTemplate'}>
        <Image src={data.photo} alt={'creator photo'} className={'creatorTemplatePhoto'}></Image>
        <p className={'creatorTemplateName'}>{data.name}</p>
    </div>
);
const creatorEmailTemplate = (data) => (
    <div className="p-d-flex creatorTemplateName">
        <span className="value link" onClick={() => window.location = `mailto:${data?.email}`}>{data?.email || "-"}</span>
    </div>
);
const creatorTypeTemplate = (data) => (
    <div className="p-d-flex creatorTemplateName">
        <span className="value capital">{data?.userType || "-"}</span>
    </div>
);
const totalEarningsTemplate = (data) => (
    <div className="defaultTemplate">
        $ {data.totalEarnings?.toFixed(2) || 0}
    </div>
);
const totalPaidTemplate = (data) => (
    <div className='defaultTemplate'>
        $ {data.totalPaid?.toFixed(2) || 0}
    </div>
);
const eligibleForPayoutTemplate = (data) => (
    <div className='defaultTemplate'>
        $ {data.amountEarnedThisCycle?.toFixed(2) || 0}
    </div>
);
const gigaPayTemplate = (data) => (
    <div className='defaultTemplate'>
        {data.gigapayStatus ? 'Yes' : 'No'}
    </div>
);
const campaignTemplate = (row) => (
    <div className="p-d-flex creatorTemplateName">
        <Avatar className="p-overlay-badge  p-mr-2 photo" image={row?.campaignPhoto} size="xlarge"></Avatar>
        <span className="value">{row?.campaignName}</span>
    </div>
);
const campaignTypeTemplate = (row) => (
    <div className="p-d-flex creatorTemplateName">
        <span className="value">{row?.campaignPaymentMethod}</span>
    </div>
);
const campaignEarnedTemplate = (row, isWithCommission) => (
    <div className="p-d-flex p-flex-column defaultTemplate">
        <span className="value">{isWithCommission ? row?.earnedWithCommission?.toFixed(2) : row?.earnedWithoutCommission?.toFixed(2)} USD</span>
    </div>
);
const campaignPaidTemplate = (row, isWithCommission) => (
    <div className="p-d-flex p-flex-column defaultTemplate">
        <span className="value">{isWithCommission ? row?.paidWithCommission?.toFixed(2) : row?.paidWithoutCommission?.toFixed(2)} USD</span>
    </div>
);
const rowExpansionTemplate = (rowData) => (
    <DataTable value={rowData?.applications} className="w-100" emptyMessage="No UGC earnings found">
        <Column field="campaignName" header="Campaign" body={campaignTemplate} style={{ width: '20%' }} />
        <Column field="campaignPaymentMethod" header="Campaign Type" style={{ width: '10%' }} body={campaignTypeTemplate} />
        <Column field="earnedWithCommission" header="Earned (With Commission)" body={(data) => campaignEarnedTemplate(data, true)} />
        <Column field="earnedWithoutCommission" header="Earned (Without Commission)" body={(data) => campaignEarnedTemplate(data, false)} />
        <Column field="paidWithCommission" header="Paid (With Commission)" body={(data) => campaignPaidTemplate(data, true)} />
        <Column field="paidWithoutCommission" header="Paid  (Without Commission)" body={(data) => campaignPaidTemplate(data, false)} />
    </DataTable>
);

export default function (props) {

    const { earnings, changePage, limit, loading } = props;

    const [first, setFirst] = useState(0);
    const [noResults, setNoResults] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);

    useEffect(() => {
        setNoResults(!(earnings?.creatorEarnings?.length))
    }, [earnings]);

    const expanderTemplate = (rowData) => {
        return (
            <div className='expanderTemplate'>
                {expandedRows.includes(rowData) ?
                    <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
                        <ExpandArrowUp />
                    </div> :
                    <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
                        <ExpandArrowDown />
                    </div>
                }
            </div>
        )
    }
    const onPageChange = (event) => {
        const { first } = event;
        setExpandedRows([])
        setFirst(first);
        changePage(first);
    };

    return (
        <>
            <DataTable value={earnings?.creatorEarnings || []} emptyMessage="No UGC earnings found" rowExpansionTemplate={rowExpansionTemplate}
                expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                loading={loading} loadingIcon={<CustomLoader />} style={{ minHeight: 500 }} >
                <Column expander={true} body={expanderTemplate} />
                <Column field="name" header="Influencer" sortable body={creatorTemplate} style={{ width: '20%' }} />
                <Column field="userType" header="User Type" sortable body={creatorTypeTemplate} style={{ width: '20%' }} />
                <Column field="email" header="Email" body={creatorEmailTemplate} style={{ width: '20%' }} />
                <Column field="totalEarnings" header="Total Earnings" sortable body={totalEarningsTemplate} />
                <Column field="totalPaid" header="Paid Out" sortable body={totalPaidTemplate} />
                <Column field="amountEarnedThisCycle" header="Eligible for Payout" sortable body={eligibleForPayoutTemplate} />
                <Column field="gigapayStatus" header="On Gigapay" sortable body={gigaPayTemplate} />
            </DataTable>
            {!noResults && <Paginator first={first} rows={limit} totalRecords={earnings?.maxCount || 0} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
        </>
    )
}
