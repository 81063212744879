
import { onMounted, ref } from 'vue';
import { useTransfers } from '@/modules';
import { CampaignPaymentMethod } from '@/data/types';
import { formatDate } from '../../utils/globals/date-utils';
import { ITransactionItem } from '@/data/types/payment-types';
import OldPayments from '@/pages/admin/OldPayments.vue';
import NewPayments from '@/react_app/NewAdmin/NewPayments/NewPayments';
import { applyPureReactInVue } from 'veaury';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';

export default {
  components: {
    NewPayments: applyPureReactInVue(NewPayments),
    OldPayments,
  },
  setup() {
    const transfersModule = useTransfers();

    const skip = ref(0);
    const limit = ref(10);
    const loadingUpload = ref('');
    const transfers = ref<Array<ITransactionItem>>();
    const totalCount = ref(0);
    const selectedQuery = ref('active');
    const loading = ref(true);
    const queryesStatus = [
      { name: 'Active', value: 'active' },
      { name: 'Cancelled', value: 'cancelled' },
      { name: 'Paid', value: 'paid' },
    ];
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const byMonth = ref();
    const searchValue = ref('');

    const getPaymentList = (
      skip: number,
      limit: number,
      status: string,
      search: string,
      month?: Date
    ) => {
      loading.value = true;
      transfersModule.list
        .getAdminTransfers(skip, limit, status, search, month)
        .then((data) => {
          if (data) {
            transfers.value = data?.transfers;
            totalCount.value = data?.counts || 0;
            loading.value = false;
          } else {
            transfers.value = [];
            totalCount.value = 0;
            loading.value = false;
          }
        });
    };

    onMounted(() => {
      // Set an initial value or trigger any function
      getPaymentList(
        skip.value,
        limit.value,
        selectedQuery.value,
        searchValue.value
      );
    });

    const uploadInvoice = async (
      id: any,
      file :  File 
    ) => {
      loadingUpload.value = id;
      const formData = new FormData();
      formData.set('invoice', file);
      const updatedTransfer = await transfersModule.manager.updateInvoice(
        id,
        formData
      );
      const currentEditedTransferIndex = transfers.value?.findIndex(
        (tr) => tr?.id === updatedTransfer?.id
      );
      if (
        currentEditedTransferIndex &&
        currentEditedTransferIndex >= 0 &&
        updatedTransfer &&
        transfers.value?.length
      ) {
        transfers.value[currentEditedTransferIndex] = updatedTransfer;
      }
      loadingUpload.value = '';
    };

    const openInvoice = (data: ITransactionItem) => {
      window.open(data?.creditNode);
    };

    const changeTransferStatus = async (
      data: ITransactionItem,
      status: string
    ) => {
      let conformValue = '';
      if (status === 'canceled') {
        conformValue = 'are you sure you want to cancel this transfer?';
      }
      if (status === 'active') {
        conformValue =
          'Do you really want to return this transfer to "Active" status?';
      }
      if (status === 'paid') {
        conformValue = 'Are you sure you want to mark this transfer as "Paid"?';
      }
      const result = confirm(conformValue);
      if (result) {
        const canceled = await transfersModule.manager.changeTransferStatus(
          data.id as string,
          status
        );
        const indexCanceled =
          transfers.value?.findIndex((tr) => tr.id === canceled?.id) || -1;
        transfers.value?.splice(indexCanceled, 1);
      }
    };

    const pageChange = (data: { first: number }) => {
      skip.value = data.first;
      getPaymentList(
        data.first,
        limit.value,
        selectedQuery.value,
        searchValue.value,
        byMonth.value
      );
    };

    const changeStatusQuery = async (selectedState: string) => {
      skip.value = 0;
      searchValue.value = ''
      byMonth.value = null
      selectedQuery.value = selectedState;
      getPaymentList(
        0,
        limit.value,
        selectedState,
        '',      
      );
    };
    const searchPayments = (search: string) => {
      skip.value = 0;
      searchValue.value = search;
      getPaymentList(
        0,
        limit.value,
        selectedQuery.value,
        search,
        byMonth.value
      );
    };

    const handleChangeMonth = (value: Date) => {
      skip.value = 0;
      byMonth.value = value;
      getPaymentList(
        0,
        limit.value,
        selectedQuery.value,
        searchValue.value,
        value
      );
    };

    return {
      changeStatusQuery,
      pageChange,
      changeTransferStatus,
      openInvoice,
      uploadInvoice,
      formatDate,
      byMonth,
      queryesStatus,
      selectedQuery,
      loadingUpload,
      CampaignPaymentMethod,
      transfers,
      totalCount,
      isNewAdminEnabled,
      limit,
      searchPayments,
      loading,
      handleChangeMonth,
      searchValue,
    };
  },
};
