
import { useRoute } from "vue-router";
import {
  useAPI, useAuth, useCreators, useFavorites, usePage,
  useAgencys, useContentItems, useUsers, useCampaigns, useToastModule
} from "@/modules";
import {
  AppPageConfig,
  CampaignData,
  AgencyWorker,
  CampaignPaymentMethod,
  AgencyWorkers,
  BrandData,
  CampaignStatus,
  CampaignApplicationStatus,
  AgencyVideoStatus,
} from "@/data/types";
import { ref, watch, reactive } from "vue";
import { useSocketIo, useSocketMethods } from "@/services/socket";
import { CampaignApplicationStatusValues } from "@/data/enums/campaign-application";
import {
  AgencyApplicationRef, ApplicationRef, CampaignApplicationData, CampaignApplicationPayoutCapProposal, CreatorAgencyCAPRef, CreatorCAPRef
} from '../../data/types/campaign-types';

import NewCampaignActivePage from "@/react_app/NewCampaignActivePage/NewCampaignActivePage";
import OldCampaignActivePage from "@/pages/creators/OldCampaignActivePage.vue";
import { applyPureReactInVue } from 'veaury';
import { isNewCampaignPageEnabled } from '@/utils/globals/feature-flags';
import router from '@/router';
import { calculateCpiRate } from "@/utils/globals/cpi-rate";

export default {
  name: "CampaignActivePage",
  components: {
    NewCampaignActivePage: applyPureReactInVue(NewCampaignActivePage),
    OldCampaignActivePage
  },
  props: {
    item: {
      type: Object,
    },
  },
  setup(props: any) {
    const route = useRoute();
    const user = useAuth();
    const creators = useCreators();
    const favorites = useFavorites();
    const agencyModule = useAgencys();
    const contentItemsModule = useContentItems();
    const { applications: { getApplicationProposals, resolveCapProposal } } = useCampaigns();

    const creator = ref(user.user.value);
    const agencyWorkers = ref<Array<AgencyWorkers>>([]);

    const { config } = usePage<AppPageConfig>({
      title: "campaign-active",
      ...props,
    });

    const creatorsController = useCreators(config.value.viewPerspective);
    const {
      manager: { getUserAppliedCampaigns },
    } = useUsers();
    const socket = useSocketIo(user.user.value?.id as string);
    const socketMethods = useSocketMethods(socket);
    const toast = useToastModule();

    const isUserAppliedToCampaign = ref(false);
    const openCpmPendingModal = ref(false);
    const openProgressChannel = ref();
    const dataProgressChannel = ref();
    const unlistedYouTubeLink = ref();
    const progressCpmCampaign = reactive({
      videoDraft: false,
      draftAccepted: false,
      draftRejected: false,
      liveVideoPosted: false,
      rejectReason: "",
    });
    const progressCpmCampaignChannels = ref<any>([]);

    const showWarningWithdraw = ref(false);
    const showWarningWithdrawChannel = ref(false);
    const withdrawChannelData = ref();
    const showTerms = ref(false);
    const openAcceptWindow = ref(false);
    const searchInfluencers = ref("");
    const selectedChannels = ref<Array<any>>([]);
    const selectedChannelsCap = ref<Array<any>>([]);


    const selectedChannelForSendPendingVideo = ref();
    const showModal = ref(false);
    const showAcceptingKey = "show-accepting";
    const showAccepting = localStorage.getItem(showAcceptingKey);
    const showAcceptingCampaign = ref(!showAccepting);
    const showRedBorder = ref(!showAccepting);
    const showCreatingContent = ref(false);
    const showModalBudgetLeft = ref();

    const showApprovedMessagePrompt = ref(false);
    const showRejectedMessagePrompt = ref(false);

    const isCampaignFav = ref(false);
    const applyRequestLoading = ref(false);
    const termsAgreed = ref(false);
    const agencyAlreadyAgreed = ref(false); // store previous state because if an agency joins with another channel they will get "already applied" error
    const gigapayPrompt = ref(false); // remind to set gigapay account
    const cpmApplicationPrompt = ref(false); // CPM application to inform the application will be reviewed
    const disableApplyButton = ref(false);
    const creatorHasChannel = ref(true);
    const applicationStatus = ref<CampaignApplicationStatus>(CampaignApplicationStatusValues.NOT_APPLIED as unknown as CampaignApplicationStatus);
    const isCampaignCPM = ref(false);

    const currentUserId = user.user?.value?.id;
    const currentApplicationInfoForCreator = ref<ApplicationRef>({ applicationId: "", applicationStatus: "pending" });
    const currentApplicationInfoForAgency = ref<AgencyApplicationRef[]>([]);
    const isLoading = ref(false);
    const currentCreatorCap = ref<CreatorCAPRef>({ CAP: 0 });
    const currentAgencyCreatorsCap = ref<CreatorAgencyCAPRef>({});
    const isLoadingAgencyCap = ref(false);

    watch(showAcceptingCampaign, () => {
      if (!showAcceptingCampaign.value) {
        localStorage.setItem(showAcceptingKey, "true");
      }
    });

    watch(showRedBorder, () => {
      if (!showRedBorder.value) {
        localStorage.setItem(showAcceptingKey, "true");
        showModal.value = true;
      }
    });

    watch(user.user, () => {
      if (creator.value && creator.value.agencyWorkers) {
        agencyWorkers.value = creator.value.agencyWorkers;
      }
    });

    watch(applyRequestLoading, () => {
      if (applyRequestLoading.value) {
        disableApplyButton.value = true;
      }
    });

    watch(termsAgreed, () => {
      showTerms.value = false;

      if (!creator.value?.mangopay) {
        gigapayPrompt.value = true;
      }
    });

    const showCreating = () => {
      window.setTimeout(() => {
        showCreatingContent.value = true;
      }, 1000);
    };

    const { data: campaignEntity, execute: loadCampaign, loading: loadingCampaign } = useAPI<CampaignData>(`/campaigns/${route.params.campaignID}`, true);
    const loadSingleCampaign = (campaignID: string) => loadCampaign({ url: `/campaigns/${campaignID}` });

    // Fetch the application of the current user for this campaign (if it exists)
    const getApplicationForCurrentUser = async () => {
      try {
        if (user.user.value && campaignEntity?.value) {
          const userId = user.user.value.id;

          const getApplicationsFn = async (userId: string, campaignId: string, fetchApplicationObjectOnly = true) => {
            if (user.user.value?.type === "agencyOwner") {
              return agencyModule.manager.getAgencyWorkersCampaignParticipations(userId, campaignId, "all", fetchApplicationObjectOnly);
            } else if (user.user.value?.type === "creator") {
              const allCreatorApplications = await getUserAppliedCampaigns(userId, campaignId, fetchApplicationObjectOnly);
              const creatorApplication = allCreatorApplications.CampaignApplications.filter((application: CampaignApplicationData) => !application.channel);
              return { CampaignApplications: creatorApplication };
            }
          };

          //@ts-ignore
          const { CampaignApplications: applications } = await getApplicationsFn(userId, campaignEntity.value.id, true);
          await setApplicationStateOnLoad(applications, user.user.value.type, campaignEntity.value);

        }
      } catch (err) {
        isLoading.value = false;
      }
    };

    // Fetch creator cap  
    const getCreatorCap = async (campaignId: string, queryData: {
      appliedFee: number;
      maxPayoutCap: number;
      clientCap: number;
    }) => {
      isLoading.value = true;
      await creators.manager.getCreatorCap(campaignId, queryData).then(res => {
        currentCreatorCap.value = res.data;
        isLoading.value = false;
      }).catch(error => {
        if (error) {
          console.log('error', error.message || "Something went wrong.");
        }
      });
    }

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const getAgencyCreatorCap = async (
      channels: AgencyWorker[],
      queryData: {
        appliedFee: number;
        maxPayoutCap: number;
        clientCap: number;
      }
    ) => {
      isLoadingAgencyCap.value = true;
      const requests = channels.map(async (channel, index) => {
        const channelId = channel?.channel?.channelId;
        if (channelId) {

          if (!currentAgencyCreatorsCap.value[channelId]) {
            currentAgencyCreatorsCap.value[channelId] = {
              data: null,
              isLoading: true
            };
          }
          try {
            await delay(index * 500);
            const res = await creators.manager.getCreatorCap(channelId, queryData);
            currentAgencyCreatorsCap.value[channelId].data = res.data;
          } catch (error: any) {
            currentAgencyCreatorsCap.value[channelId].isLoading = false;
          } finally {
            if (currentAgencyCreatorsCap.value[channelId].isLoading !== false) {
              currentAgencyCreatorsCap.value[channelId].isLoading = false;
            }
          }
        } else {
          console.error("Channel ID is undefined");
        }
      });
      await Promise.all(requests);
      isLoadingAgencyCap.value = false;
    };

    // Set ref flags and prepare new state
    const setApplicationStateOnLoad = async (applications: CampaignApplicationData[], userType: string, { paymentMethod, id }: any) => {
      if (applications?.length <= 0) {
        return;
      }

      if (userType === "creator") {
        const application = applications[0]; // they have one application

        if (application.status === CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus) {
          applicationStatus.value = application.status; //added this for remove closed status
          disableApplyButton.value = true;
          cpmApplicationPrompt.value = true;
        } else if (application.status === CampaignApplicationStatusValues.ACCEPTED as unknown as CampaignApplicationStatus &&
          !application.draftUploaded) {
          if (paymentMethod === CampaignPaymentMethod.CPM) {
            if (application?.paymentCapProposals && application?.paymentCapProposals?.length > 0 &&
              application.paymentCapProposals[0].clientDecision === "approved") {
              disableApplyButton.value = true;
              showApprovedMessagePrompt.value = true;
              applicationStatus.value = application.status;
              isUserAppliedToCampaign.value = true;
            } else {
              applicationStatus.value = CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus;
            }
          } else {
            disableApplyButton.value = true;
            showApprovedMessagePrompt.value = true;
            applicationStatus.value = application.status;
            isUserAppliedToCampaign.value = true;
          }
        } else if (application.status === CampaignApplicationStatusValues.REJECTED as unknown as CampaignApplicationStatus) {
          disableApplyButton.value = true;
          showRejectedMessagePrompt.value = true;
          applicationStatus.value = application.status;
        }

        const applicationStateObject = {
          applicationId: application.id,
          applicationStatus: application.status,
          capProposals: application.paymentCapProposals,
        };

        await setPayoutCapProposalsOnLoad([applicationStateObject], id, userType);
      } else if (userType === "agencyOwner") {
        const newApplicationState: AgencyApplicationRef[] = [];
        let agencyHasAnyApproval = false;
        let agencyAllRejected = false; //change default value true to false because it always show reject popup

        applications.forEach((application: CampaignApplicationData) => {
          // If at least one channel participation is accepted display prompt for accepted state
          if (application.status === CampaignApplicationStatusValues.ACCEPTED as unknown as CampaignApplicationStatus) {

            if (application?.paymentCapProposals && application?.paymentCapProposals?.length > 0 &&
              application.paymentCapProposals[0].clientDecision === "approved") {
              showApprovedMessagePrompt.value = true;
              applicationStatus.value = application.status; // UI control ref, no need to differentiate the different application statuses
              agencyHasAnyApproval = true;
              agencyAllRejected = false;
            }
          }

          if (application.status === CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus) {
            agencyAllRejected = false;
          }

          newApplicationState.push({
            applicationId: application.id,
            applicationStatus: application.status,
            applicationChannel: application.channel!,
            capProposals: application.paymentCapProposals,
          });
        });

        // All agency applications are pending, display prompt for pending state
        if (!agencyHasAnyApproval && !agencyAllRejected) {
          cpmApplicationPrompt.value = true;
        }

        // All agency applications are rejected, display prompt for rejected state
        if (agencyAllRejected) {
          showRejectedMessagePrompt.value = true;
        }

        const newFilteredApplicationsState = newApplicationState?.filter(application => application?.applicationChannel);
        await setPayoutCapProposalsOnLoad(newFilteredApplicationsState, id, userType);
      }
    };

    // Fetch the payout cap offerings and wrap up the state object to be set as a ref
    const setPayoutCapProposalsOnLoad = async (applications: AgencyApplicationRef[] | ApplicationRef[], campaignId: string, userType: string) => {
      if (applications?.length > 0) {
        try {
          for await (const application of applications) {
            const proposalsObject = await getApplicationProposals(campaignId, application.applicationId, CampaignApplicationStatusValues.ALL);
            application.capProposals = (proposalsObject as CampaignApplicationPayoutCapProposal[]);
          }

          if (userType === "agencyOwner") {
            currentApplicationInfoForAgency.value = applications as AgencyApplicationRef[];
          } else if (userType === "creator") {
            currentApplicationInfoForCreator.value = applications[0];
          }
          isLoading.value = false;
        } catch (err) {
          isLoading.value = false;
        }
      }
    };

    // Prep work on campaign load
    (async () => {
      isLoading.value = true;
      await loadSingleCampaign(route.params.campaignID as string);

      const { status: campaignStatus, paymentMethod: campaignPaymentMethod, appliedFee, cpm } = campaignEntity!.value!;
      isCampaignCPM.value = campaignPaymentMethod === CampaignPaymentMethod.CPM;

      if (campaignStatus === CampaignStatus.Completed) {
        disableApplyButton.value = true;
      }
      await getApplicationForCurrentUser();

      if (user?.user?.value) {
        const { youtube, type } = user.user.value;

        if (type === "creator" && youtube!.length > 0 && isCampaignCPM.value && campaignPaymentMethod !== CampaignPaymentMethod.UGC && 
        ((applicationStatus.value === (CampaignApplicationStatusValues.NOT_APPLIED || CampaignApplicationStatusValues.PENDING) as unknown as CampaignApplicationStatus))) {
          const latestChannel = youtube!.slice(-1)[0];
          isLoading.value = true;
          await getCreatorCap(latestChannel.id, {
            appliedFee,
            maxPayoutCap: cpm?.payoutCap || 0,
            clientCap: cpm?.amount || 0,
          });
        }

        // Creators without linked channels cannot participate in CPM campaigns
        if (type === "creator" && youtube!.length <= 0 && isCampaignCPM.value) {
          disableApplyButton.value = true;
          creatorHasChannel.value = false;
        }


        isLoading.value = false;
      }
    })();

    const getProgressCpm = () => {
      if (user?.user?.value && route.params.campaignID) {
        const calculatedChannelIDs: Array<string> = [];

        if (user.user.value?.isAgencyOwner() && user.user.value?.agencyWorkers?.length) {
          user.user.value.agencyWorkers.map((worker) => {
            calculatedChannelIDs.push(worker?.channel?.channelId);
            return worker;
          });
        }

        contentItemsModule.manager.getStatusVideoCpm(user?.user?.value?.id as string, route.params.campaignID as string, calculatedChannelIDs).then((data) => {
          if (user.user.value?.isCreator()) {
            progressCpmCampaign.videoDraft = data.videoDraft;
            progressCpmCampaign.draftAccepted = data.draftAccepted;
            progressCpmCampaign.rejectReason = data.rejectReason;
            progressCpmCampaign.draftRejected = data.draftRejected;
            progressCpmCampaign.liveVideoPosted = data.liveVideoPosted;
          }
          if (user.user.value?.isAgencyOwner()) {
            progressCpmCampaignChannels.value = data;
          }
        });
      }
    };

    getProgressCpm();

    const findProgressChannel = (channelId: string) => {
      // Find in the array the first approved draft. If you have multiple rejected drafts and one approved
      // you should be able to submit your final video.
      const approvedDraft = progressCpmCampaignChannels.value?.find((stats: AgencyVideoStatus) => stats.channelId === channelId && stats.draftAccepted);

      if (!approvedDraft) {
        const rejectedDraft = progressCpmCampaignChannels.value?.find((stats: AgencyVideoStatus) => stats.channelId === channelId);
        return rejectedDraft;
      } else {
        return approvedDraft;
      }
    };

    (() => creatorsController.list.load(props.limit))();

    const getIndividualChannelInfo = (channelId: string, campaign: any, info: "applicationId" | "status") => {
      if (user.user.value && user.user.value?.isAgencyOwner() && user.user.value?.agencyWorkers && campaign?.applications) {
        const currentContactChannelIndex = campaign.applications.findIndex((application: { channel: string; status: "pending" | "accepted" | "rejected" }) => {
          if (info === "applicationId") {
            return application.channel === channelId && application.status === "accepted";
          } else if (info === "status") {
            return application.channel === channelId;
          }
        });

        const returnProperty = info === "applicationId" ? "id" : "status";

        return campaign.applications[currentContactChannelIndex]?.[returnProperty];
      }
    };

    const getIndividualChannelApplicationDisplay = (channelId: string, currentApplicationInfoForAgency: AgencyApplicationRef[]) => {
      const relevantApplication = currentApplicationInfoForAgency.find((application: AgencyApplicationRef) => application.applicationChannel === channelId);

      if (relevantApplication?.applicationStatus === "pending") {
        return "Pending application";
      } else if (relevantApplication?.applicationStatus === "rejected") {
        return "Rejected application";
      }

      // if (relevantApplication?.capProposals && relevantApplication?.capProposals?.length > 0) {
      //   return relevantApplication.capProposals[0].clientDecision === "pending" ? "Pending cap proposal" : "Rejected cap proposal";
      // }
    };

    const getIndividualChannelApplicationPayoutCap = (channelId: string, currentApplicationInfoForAgency: AgencyApplicationRef[]) => {
      const relevantApplication = currentApplicationInfoForAgency.find((application: AgencyApplicationRef) => application.applicationChannel === channelId);
      if(relevantApplication && relevantApplication.capProposals){
        return relevantApplication?.capProposals[0]?.proposedPayoutCap || 0;
      }
    };

    const toggleCampaignFavorite = async () => {
      await favorites.likeOrDislike("campaigns", user.user.value?.id as string, route.params.campaignID).then((res) => {
        if (res) isCampaignFav.value = !isCampaignFav.value;
      });
    };

    const isCampaignFavorite = async () => {
      const myFavoriteCampaigns = await favorites.getUSerFavorites("campaigns", user.user.value?.id as string);
      const index = myFavoriteCampaigns.allUserFavorites.findIndex((el: any) => el.campaigns?.id === route.params?.campaignID);
      if (index >= 0) isCampaignFav.value = true;
      if (index < 0) isCampaignFav.value = false;
    };

    isCampaignFavorite();

    // creator
    const withdrawCampaign = async () => {
      if (user.user.value && currentApplicationInfoForCreator?.value?.applicationId) {
        isUserAppliedToCampaign.value = false;
        showWarningWithdraw.value = false;

        await creatorsController.manager.withdrawCampaign(route.params.campaignID as string, currentApplicationInfoForCreator.value.applicationId);
        await getUserAppliedCampaigns(user.user.value?.id);

        await loadSingleCampaign(route.params.campaignID as string);
        await getApplicationForCurrentUser();

        await socketMethods.deleteNotification({
          from: userId,
          to: (campaignEntity as any).value?.brand.creator, // for brand
          type: 'apply',
          campaignId: (campaignEntity as any).value.id,
          brandId: (campaignEntity as any).value.brand?.id,
        });
      }
    };

    const sendCampaignApplicationRequest = async (campaignId: string, costPerInstallMin: number) => {
      applyRequestLoading.value = true;
      const capValue = (campaignEntity.value?.paymentMethod === "UGC") ? campaignEntity.value?.ugc?.costPerCreator  :  currentCreatorCap.value.CAP
      const isDelete = ["CPM","UGC"].includes(campaignEntity.value?.paymentMethod as string);
      await creators.manager.applyCampaign(campaignId, user.user.value?.id as string, costPerInstallMin, false, capValue, isDelete);
      await loadSingleCampaign(campaignId);

      applyRequestLoading.value = false;
      await getApplicationForCurrentUser();

      //isUserAppliedToCampaign.value = true; // comment this code and add below condition because of show pending button issue.
      // isUserAppliedToCampaign.value = campaignEntity.value?.paymentMethod !== "CPM";
      applicationStatus.value = CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus;
      isLoading.value = false;
    };

    const selectChannel = async (channel: AgencyWorker) => {
      const channelId = channel?.channel?.channelId;

      if (!channelId) {
        console.error("Channel ID is undefined");
        return;
      }
      const index = selectedChannels.value.findIndex((el) => el.channel?.channelId === channelId);

      if (index < 0) {
        selectedChannels.value.push(channel);
        if (!currentAgencyCreatorsCap.value[channelId] && isCampaignCPM.value) {
          currentAgencyCreatorsCap.value[channelId] = {
            data: null,
            isLoading: true
          };

          try {
            const res = await creators.manager.getCreatorCap(channelId, {
              appliedFee: (campaignEntity as any)._value.appliedFee || 0,
              maxPayoutCap: (campaignEntity as any)._value.cpm?.payoutCap || 0,
              clientCap: (campaignEntity as any)._value.cpm?.amount || 0
            });
            currentAgencyCreatorsCap.value[channelId].data = res.data;
          } catch (error: any) {
            console.log('error', error?.message || "Something went wrong.");
          } finally {
            currentAgencyCreatorsCap.value[channelId].isLoading = false;
          }
        }
      } else {
        selectedChannels.value.splice(index, 1);
        if (currentAgencyCreatorsCap.value[channelId]?.data === null && isCampaignCPM.value) {
          delete currentAgencyCreatorsCap.value[channelId];
        }
      }
    };

    const resetSelectChannel = () => {
      selectedChannels.value = [];
    };

    const userId = user?.user?.value?.id ? user.user.value.id : "";

    const isSelectedChannel = (channel: AgencyWorker) => {
      const index = selectedChannels.value.findIndex((el) => el?.channel?.channelId === channel?.channel?.channelId);
      if (index >= 0) {
        return true;
      }
      return false;
    };

    const selectAllItems = async (arrayChannels: AgencyWorker[]) => {
      selectedChannels.value = arrayChannels;

      if (isCampaignCPM.value) {
        const channelsToFetch: AgencyWorker[] = arrayChannels.filter(channel => {
          const channelId = channel?.channel?.channelId;
          return channelId && !currentAgencyCreatorsCap.value[channelId];
        });

        if (channelsToFetch.length > 0) {
          await getAgencyCreatorCap(channelsToFetch, {
            appliedFee: (campaignEntity as any)._value.appliedFee || 0,
            maxPayoutCap: (campaignEntity as any)._value.cpm?.payoutCap || 0,
            clientCap: (campaignEntity as any)._value.cpm?.amount || 0
          });
        }
      }
    };

    const acceptChannels = async () => {
      isLoading.value = true;
      if (selectedChannels.value?.length) {
        for await (const channel of selectedChannels.value) {
          const isCPMorUGC = ["CPM","UGC"].includes(campaignEntity.value?.paymentMethod as string);
          const capValue = (campaignEntity.value?.paymentMethod === "UGC") ? 
          campaignEntity.value?.ugc?.costPerCreator  :  
          currentAgencyCreatorsCap.value[channel?.channel?.channelId]?.data?.CAP 
          const updatedChannel = {
          channelData: channel,
          ...(isCPMorUGC && {
            cap: capValue || 0,
          }),
          };
          await agencyModule.manager.applyChannelsCampaign(updatedChannel, route.params.campaignID as string);
          selectedChannels.value = selectedChannels.value.filter((currentChannel) => channel.channel?.channelId !== currentChannel.channel?.channelId);
        }
        await loadSingleCampaign(route.params.campaignID as string).then(async () => {
          isLoading.value = false;
          await getProgressCpm();
          window.location.reload();
          toast.showSuccess("Accepted campaign successfully!");
        }).catch((error) => {
          if (error) {
            isLoading.value = false;
          }
        });
        showTerms.value = true;
      }
    };

    function checkCondition(A: number, B: number) {
      const difference = A - B;
      const percentage = (difference / A) * 100;

      return percentage <= 15;
    }

    const showTutorialModal = () => {
      const key = "hadModal";
      const hadModal = localStorage.getItem(key);
      if (!hadModal) {
        showModal.value = true;
        localStorage.setItem(key, "true");
      }

      showModalBudgetLeft.value = checkCondition(campaignEntity.value?.budget?.amount as number, campaignEntity.value?.budget?.amountLeft as number);
    };

    const withdrawChannelApplication = async (campaign: any, channel: any) => {
      isLoading.value = true;
      let applicationId = "";
      if (user.user.value && user.user.value.isAgencyOwner() && user.user.value.agencyWorkers && campaign.applications) {
        const currentContactChannelIndex = campaign.applications.findIndex(
          (application: { channel: string }) => application.channel === channel?.channel.channelId
        );
        applicationId = campaign.applications[currentContactChannelIndex]?.id;
      }

      agencyModule.manager.withdrawCampaignChannel(campaign.id, applicationId, channel?.channel.channelId).then(async () => {
        showWarningWithdrawChannel.value = false;
        await loadSingleCampaign(route.params.campaignID as string).then(async () => {
          await getProgressCpm();
          isLoading.value = false;
          toast.showSuccess("Left campaign successfully!");
        }).catch((error) => {
          if (error) {
            isLoading.value = false;
          }
        });
      });
    };

    const searchInfluencersUser = (searchValue: string) => {
      const regExp = new RegExp(searchValue, "gi");
      if (creator.value && creator.value.agencyWorkers) {
        agencyWorkers.value = creator.value.agencyWorkers.filter((el: { channel: { title: string } }) => regExp.test(el.channel?.title));
      }
    };

    searchInfluencersUser("");

    const submitUnlistedVideo = () => {
      let IDs = [];

      if (unlistedYouTubeLink.value.includes("youtu.be")) {
        IDs = ["&id=" + unlistedYouTubeLink.value.split("/").filter(Boolean)[2]];
      } else {
        IDs = [`&id=${unlistedYouTubeLink.value.split("=")[1]}`];
      }

      contentItemsModule.manager
        .createUnlistedContent(user.user.value?.id as string, route.params.campaignID as string, IDs, selectedChannelForSendPendingVideo.value)
        .then(async (data) => {
          if (data) {
            progressCpmCampaign.videoDraft = true;
            openCpmPendingModal.value = false;
            unlistedYouTubeLink.value = "";
            await getProgressCpm();
          }
        });
    };

    const applyForCampaign = async ({ id, name, brand, budget }: CampaignData, creatorName?: string) => {
      isLoading.value = true;
      const { amount } = budget;
      const { id: brandId, creator } = brand as BrandData;

      // Only if the creator agreed to the terms and conditions then finalize the process
      if (termsAgreed.value) {
        // CPI and CPC campaigns applications are automatically approved but CPM campaigns need an admin approval
        const requiresApproval = isCampaignCPM.value ? true : false;

        sendCampaignApplicationRequest(id, amount);

        if (requiresApproval) {
          isUserAppliedToCampaign.value = false; // add false if CPM is method to resolve show pending button issue
        } else {
          showTutorialModal();

          socketMethods.addNewNotification({
            message: `<b>${creatorName}</b> applied to the <b>${name}</b> campaign.`,
            from: userId,
            to: creator, // for brand
            type: "apply",
            campaignId: id,
            brandId: brandId,
            status: "new",
          });
        }
      }
    };

    const unmountPrompt = (promptToUnmount: "gigapayPrompt" | "cpmApplicationPrompt") => {
      if (promptToUnmount === "gigapayPrompt") {
        gigapayPrompt.value = false;
      } else if (promptToUnmount === "cpmApplicationPrompt") {
        cpmApplicationPrompt.value = false;
      }
    };

    // Creator
    const getApplyButtonLabel = () => {
      if (campaignEntity.value) {
        if (!creatorHasChannel.value && isCampaignCPM.value) {
          return "Cannot apply without linked YouTube channel.";
        }
        if ((applicationStatus.value === (CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus))) {
          disableApplyButton.value = true;
          return "Pending application";
        }

        if (disableApplyButton.value) {
          return "Closed";
        } else if (!isUserAppliedToCampaign.value) {
          if (isCampaignCPM.value) {
            return "Apply";
          } else {
            return "Accept";
          }
        } else {
          return "";
        }
      }
    };

    const agencyApplyOnClick = () => {
      if (applicationStatus.value === (CampaignApplicationStatusValues.NOT_APPLIED as unknown as CampaignApplicationStatus)) {
        if (termsAgreed.value) {
          return () => (openAcceptWindow.value = true);
        }
      } else if (applicationStatus.value === (CampaignApplicationStatusValues.ACCEPTED as unknown as CampaignApplicationStatus)) {
        // agencies can view the participation dropdown even with 1 accepted application since they can rejoin with another channel
        return () => (openAcceptWindow.value = !openAcceptWindow.value);
      }
    };

    const displayModeratedContent = () => {
      if (applicationStatus.value === (CampaignApplicationStatusValues.PENDING as unknown as CampaignApplicationStatus)) {
        return false;
      } else if (applicationStatus.value === (CampaignApplicationStatusValues.NOT_APPLIED as unknown as CampaignApplicationStatus)) {
        return false;
      } else if (applicationStatus.value === (CampaignApplicationStatusValues.REJECTED as unknown as CampaignApplicationStatus)) {
        return false;
      }

      return true;
    };

    const displayChannelApplicationStatusCondition = (channelId: string, currentApplicationInfoForAgency: any) => {
      const relevantApplication = currentApplicationInfoForAgency?.find((application: AgencyApplicationRef) => application.applicationChannel === channelId);

      if (!relevantApplication) {
        return false;
      }

      // It will display application status as a button instead of being able to upload a video
      if (relevantApplication?.status === "pending" || relevantApplication?.status === "rejected") {
        return true;
      }

      const acceptedProposalCap = relevantApplication?.capProposals?.find((capProposal: CampaignApplicationPayoutCapProposal) => capProposal.clientDecision === "approved");

      if (!acceptedProposalCap) {
        return true;
      }

      return false;
    };

    const resolveCapProposalWrapperFn = (action: "accept" | "reject", campaignId: string, applicationId: string, channelId?: string) => {
      isLoading.value = true;
      resolveCapProposal(action, campaignId, applicationId, channelId).then((res) => {
        if (res) {
          toast.showSuccess('Successfully resolved payout cap proposal.');
          getApplicationForCurrentUser();
          isLoading.value = false;
        } else {
          isLoading.value = false;
        }
      }).catch(() => {
        toast.showError("Unable to resolve payout cap proposal.");
        isLoading.value = false;
      });
    };

    const getPayoutCapDisplayValue = (capValue: number, appliedFee: number, currency: string, applyCommission: boolean) => {
      if (applyCommission) {
        return `${calculateCpiRate(capValue, appliedFee)} ${currency}`;
      }

      return `${capValue} ${currency}`;
    };

    const getPayoutCap = (applications: ApplicationRef | AgencyApplicationRef[], standardCap: number, currency: string, appliedFee: number) => {
      if (Array.isArray(applications) && ((applications as AgencyApplicationRef[]).length > 0)) {
        let clientMessage = "";
        applications = applications as AgencyApplicationRef[];

        applications.forEach((currentApplication: AgencyApplicationRef) => {
          if (currentApplication?.capProposals && currentApplication?.capProposals?.length > 0) {
            const acceptedProposal = currentApplication.capProposals[0]; //only one proposal is possible. It is an array with an idea of scale in mind if requirements change

            if (acceptedProposal?.clientDecision === "approved") {
              clientMessage +=
                `${acceptedProposal.channelTitle}: ${getPayoutCapDisplayValue(acceptedProposal.proposedPayoutCap, appliedFee, currency, false)} \n`;
            }
          }
        });

        if (clientMessage) {
          return clientMessage;
        }

        return `${getPayoutCapDisplayValue(standardCap, appliedFee, currency, true)}`;
      }

      if (applications) {
        applications = applications as ApplicationRef;

        if (applications?.capProposals && applications?.capProposals?.length > 0) {
          if (applications.capProposals[0]?.clientDecision !== "approved") {
            return `${getPayoutCapDisplayValue(standardCap, appliedFee, currency, true)}`;
          }


          return `${getPayoutCapDisplayValue(applications.capProposals[0].proposedPayoutCap, appliedFee, currency, false)}`;
        }

        return `${getPayoutCapDisplayValue(standardCap, appliedFee, currency, true)}`;
      }

      return `${getPayoutCapDisplayValue(standardCap, appliedFee, currency, true)}`;
    };

    const handleContentRouting = (id: string) => {
      router.push({ name: 'content-item', params: { itemID: id } });
    }
    const handleRoutingAll = () => {
      router.push({ name: 'content-items' });
    }
    const handleBackToAll = () => {
      router.push({ name: 'campaigns' });
    }

    const handleRoutingHomepage = () => {
      router.push({ name: 'dashboard' });
    }
    const handleApply = () => {
      termsAgreed.value = true;
      applyForCampaign(campaignEntity.value as any, creator?.value?.name);
    }

    return {
      showRejectedMessagePrompt,
      getIndividualChannelApplicationDisplay,
      displayChannelApplicationStatusCondition,
      showApprovedMessagePrompt,
      agencyApplyOnClick,
      displayModeratedContent,
      getApplyButtonLabel,
      gigapayPrompt,
      unmountPrompt,
      termsAgreed,
      applyForCampaign,
      showModalBudgetLeft,
      showTutorialModal,
      submitUnlistedVideo,
      showModal,
      showAcceptingKey,
      showAccepting,
      showCreating,
      showCreatingContent,
      showRedBorder,
      showAcceptingCampaign,
      socketMethods,
      isCampaignFav,
      openAcceptWindow,
      toggleCampaignFavorite,
      isCampaignFavorite,
      withdrawCampaign,
      isSelectedChannel,
      selectAllItems,
      acceptChannels,
      withdrawChannelApplication,
      searchInfluencersUser,
      agencyWorkers,
      showWarningWithdrawChannel,
      withdrawChannelData,
      loadingAccept: agencyModule.manager.applyChannelsCampaignLoading,
      showWarningWithdraw,
      applyRequestLoading,
      campaign: campaignEntity,
      selectChannel,
      resetSelectChannel,
      getIndividualChannelInfo,
      getIndividualChannelApplicationPayoutCap,
      creator,
      isUserAppliedToCampaign,
      currentUserId,
      showTerms,
      userId,
      searchInfluencers,
      selectedChannels,
      openCpmPendingModal,
      unlistedYouTubeLink,
      progressCpmCampaign,
      selectedChannelForSendPendingVideo,
      findProgressChannel,
      openProgressChannel,
      dataProgressChannel,
      disableApplyButton,
      applicationStatus,
      isCampaignCPM,
      cpmApplicationPrompt,
      currentApplicationInfoForAgency,
      currentApplicationInfoForCreator,
      resolveCapProposal,
      resolveCapProposalWrapperFn,
      progressCpmCampaignChannels,
      getPayoutCap,
      isNewCampaignPageEnabled,
      handleContentRouting,
      handleRoutingAll,
      handleBackToAll,
      handleApply,
      handleRoutingHomepage,
      isLoading,
      getProgressCpm,
      currentCreatorCap,
      currentAgencyCreatorsCap,
      isLoadingAgencyCap
    };
  },
};
