import React from 'react';
import { Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  AppStoreIcon, FieldTip, GooglePlayIcon, RedX, SecretTip, WebPageIcon
} from "@/react_app/img/Icons";
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { getLinkFieldText } from './CreateCampaignAdditional';
import InputNumber from 'primevue/inputnumber';

function LinkFieldIcon(props) {
  return (
    <div>
      {props.index === 0 && <GooglePlayIcon />}
      {props.index === 1 && <AppStoreIcon />}
      {props.index >= 2 && <WebPageIcon />}
    </div>
  )
}


const RenderField = ({
  field, control, register, watch, setValue, getValues, errors,
  setError, isAdmin, clearErrors, trigger,
  handleChangeCalendar, handleChangeUntilBudget, isEditMode
}) => {

  const fieldValue = watch(field.name);
  const renderError = (name) => {
    return (
      field.isRequired && <div className="createError">
        {errors[name] && <p>{errors[name]?.message}</p>}
      </div>
    );
  }

  const secretTip = () => {
    return (
      <div className={'secretTip'}>
        <SecretTip />
        <div>
          <p className={'secretTipTitle'}>Secret Tip</p>
          <p className={'secretTipText'}>Make sure this title is accurate to your product and snappy enough to entice creators!</p>
        </div>
      </div>
    )
  }

  const addUrl = () => {
    const currentUrls = getValues(field.name);
    setValue(field.name, [...currentUrls, '']);
  };

  const removeUrl = (index) => {
    const currentUrls = getValues(field.name);
    const updatedUrls = currentUrls.filter((_, i) => i !== index);
    setValue(field.name, updatedUrls);
  };

  const updateUrl = (index, value) => {
    const currentUrls = getValues(field.name);
    const updatedUrls = [...currentUrls];
    updatedUrls[index] = value;
    setValue(field.name, updatedUrls);
  };

  if (field.isAdminOnly && !isAdmin) {
    return null;
  }

  if (isEditMode && field.isAdminOnly && isAdmin && field.isCreateModeOnly) {
    field.disabled = true;
  }

  switch (field.type) {
    case 'input':
      return (
        <>
          <InputText
            {...register(field.name, {
              ...field.validation,
              onBlur: () => {
                trigger(field.name);
              },
              onChange: (e) => {
                clearErrors(field.name);
                setValue(field.name, e.target.value);
              },
            })}
            placeholder={field.placeholder}
            className={`inputField ${errors[field.name] ? 'p-invalid' : ''}`}
            disabled={field.disabled}

            type={field.isOnlyNumbers ? "number" : "text"}
          />
          {renderError(field.name)}
          {field.showSerectTip && secretTip()}
        </>
      );

    case 'number':
      return (
        <>
          <InputNumber
            {...register(field.name, field.validation)}
            placeholder={field.placeholder}
            className={`inputField ${errors[field.name] ? 'p-invalid' : ''}`}
            disabled={field.disabled}
          />
          {renderError(field.name)}
          {field.showSerectTip && secretTip()}
        </>
      );

    case 'textarea':
      return (
        <>
          <InputTextarea
            {...register(field.name, {
              ...field.validation,
              onBlur: () => {
                trigger(field.name);
              },
              onChange: (e) => {
                clearErrors(field.name);
                setValue(field.name, e.target.value);
              },
            })}
            placeholder={field.placeholder}
            autoResize
            rows={field?.rows || 5}
            className={`textArea ${errors[field.name] ? 'p-invalid' : ''}`}
          />
          {renderError(field.name)}
        </>
      );

    case 'dropdown':
      return (
        <div className="input-wrap">
          <Controller
            name={field.name}
            control={control}
            rules={field.validation}
            render={({ field: dropdownField }) => (
              <Dropdown
                value={dropdownField.value || null}
                filter
                options={field.options}
                onChange={(e) => {
                  dropdownField.onChange(e.value);
                  clearErrors(field.name);
                }}
                placeholder={field.placeholder}
                className={`dropdownField ${errors[field.name] ? 'p-invalid' : ''}`}
                disabled={field.disabled}
              />
            )}
          />
          {renderError(field.name)}
        </div>
      );

    case 'multi-select':
      return (
        <div className="input-wrap">
          <Controller
            name={field.name}
            control={control}
            rules={field.validation}
            render={({ field: multiSelectField }) => (
              <MultiSelect
                value={multiSelectField.value || []}
                options={field.options}
                placeholder={field.placeholder}
                className={`multiSelect ${errors[field.name] ? 'p-invalid' : ''}`}
                display="chip"
                onChange={(e) => multiSelectField.onChange(e.value)}
              />
            )}
          />
          {renderError(field.name)}
        </div>
      );

    case 'calendar':
      return (
        <Controller
          name={field.name}
          control={control}
          rules={field.validation}
          render={({ field: calendarField }) => (
            <Calendar
              value={calendarField.value ? new Date(calendarField.value) : null}
              onChange={(e) => calendarField.onChange(e.value)}
              dateFormat="dd/mm/yy"
              minDate={new Date()}
              className={`inputField ${errors[field.name] ? 'p-invalid' : ''}`}
            />
          )}
        />
      );
    case 'campaignEndDate':
      return (
        <div>
          <div className={'endDateField'}>
            <Controller
              name="campaignEndDate"
              control={control}
              rules={{
                ...field.validation,
                validate: value => {
                  if (!watch('isUntilBudget') && !value) {
                    return 'Campaign end date is required';
                  }
                  return true;
                },
              }}
              render={({ field: calendarField }) => (
                <Calendar
                  value={calendarField.value ? new Date(calendarField.value) : null}
                  onChange={(e) => handleChangeCalendar(e.value)}
                  dateFormat="dd/mm/yy"
                  minDate={new Date()}
                  className={`calendarField ${errors.campaignEndDate && !watch('isUntilBudget') ? 'p-invalid' : ''}`}
                />
              )}
            />
            <span className={'endDateLabel'}>Or</span>
            <div
              className={watch('isUntilBudget') ? 'endDateOtherOptionSelected' : 'endDateOtherOption'}
              onClick={handleChangeUntilBudget}
            >
              <i className="pi pi-check" style={{ color: '#FFF', fontSize: '20px', marginRight: '10px' }} />
              Until Budget is Complete
            </div>
          </div>
          {!watch('isUntilBudget') && renderError('campaignEndDate')}
        </div>
      );

    case 'radio':
      return (
        <div className="radioWrapper">
          {field.options.map((option, index) => (
            <div key={index} className='radioItem'>
              <RadioButton
                inputId={`${field.name}-${option.value}`}
                name={field.name}
                value={option.value}
                checked={fieldValue === option.value}
                onChange={(e) => setValue(field.name, e.value)}
              />
              <label htmlFor={`${field.name}-${option.value}`} className="radioText">{option.label}</label>
            </div>
          ))}
          {renderError(field.name)}
        </div>
      );

    case 'checkbox':
      return (
        <>
          <div className="checkboxWrapper">
            {field.options.map((option, optionIndex) => (
              <div key={optionIndex} className='checkboxItem'>
                <Checkbox
                  inputId={`${field.name}-${option.value}`}
                  name={field.name}
                  value={option.value}
                  checked={field.isMultiSelect ? fieldValue?.includes(option.value) : fieldValue === option.value}
                  onChange={(e) => {
                    if (field.isMultiSelect) {
                      const updatedValue = e.checked
                        ? [...(fieldValue || []), option.value]
                        : fieldValue.filter((v) => v !== option.value);
                      setValue(field.name, updatedValue);
                    } else {
                      setValue(field.name, e.checked ? option.value : null);
                    }
                  }}
                />
                <label htmlFor={`${field.name}-${option.value}`} className="checkboxText">
                  <span>{option.label}</span>
                </label>
              </div>
            ))}
          </div>
          {renderError(field.name)}
        </>
      );
    case 'icon-checkbox':
      return (
        <>
          <div className={`iconCheckboxWrapper ${field.className ? field.className : ''}`}>
            {field.options.map((option, index) => {
              const isChecked = field.isMultiSelect
                ? fieldValue?.some(value => typeof value === 'object'
                  ? value?.value === option.value
                  : value === option.value)
                : typeof fieldValue === 'object'
                  ? fieldValue?.value === option.value
                  : fieldValue === option.value;

              return (
                <div key={index}
                  className={`iconCheckboxItem ${isChecked ? 'selected' : ''} ratio-${option.value.replace(':', '-')}`}
                >
                  <Checkbox
                    inputId={`${field.name}-${option.value}`}
                    value={option.value}
                    checked={isChecked}
                    onChange={() => {
                      if (field.isMultiSelect) {
                        const updatedValue = isChecked
                          ? fieldValue.filter((value) =>
                            typeof value === 'object'
                              ? value?.value !== option.value
                              : value !== option.value)
                          : [...(fieldValue || []), field.storeObject ? option : option.value];
                        setValue(field.name, updatedValue);
                      } else {
                        setValue(field.name, isChecked ? null : field.storeObject ? option : option.value);
                      }
                    }}
                    className="icon-checkbox"
                  />
                  <label htmlFor={`${field.name}-${option.value}`}
                    className={`icon-checkbox-label iconCheckboxText ratio-${option.value.replace(':', '-')}`}>
                    {field?.icons && <div className="icon">{field.icons[index]}</div>}
                    <span>{option.label}</span>
                  </label>
                </div>
              );
            })}
          </div>
          {renderError(field.name)}
        </>
      );
    case 'grouped-fields':
      return (
        <div className="dual-field-wrap" key={field.name}>
          {field.fields.map((subField) => (
            <div className="input-wrap" key={subField.name}>
              <p className="fieldLabel">{subField.label} {subField.isRequired && <span className="redStar">*</span>} {subField?.showTooltip && <FieldTip title={subField?.tooltipInfo} />}</p>
              <RenderField
                field={subField}
                control={control}
                register={register}
                watch={watch}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
                handleChangeCalendar={handleChangeCalendar}
                handleChangeUntilBudget={handleChangeUntilBudget}
                isEditMode={isEditMode}
              />
              {renderError(field.name)}
            </div>

          ))}
        </div>
      );

    case 'other-urls':
      return (
        <div className="urlWrapper">
          <div key={field.name} className={'otherUrlsWrapper'}>
            {field.fields.map((subField, index) => (
              <Controller
                key={subField.name}
                name={`${subField.name}`}
                control={control}
                rules={subField.validation}
                render={({ field: inputField }) => (
                  <>
                    {/* {index == 0 && <div className='linkWrapper'>
                      <span className="p-input-icon-left">
                        <LinkIcon />
                        <InputText
                          {...inputField}
                          placeholder={`Enter your link to gamekey folder`}
                          onChange={(e) => {
                            inputField.onChange(e);
                            setValue(inputField.name, e.target.value);
                          }}
                          className="urlInput"
                          value={inputField.value || ''}
                        />
                      </span>
                    </div>} */}
                    {index == 1 && fieldValue?.map((item, i) => (
                      <div key={i} className="linkWrapper">
                        <span className="p-input-icon-left">
                          <LinkFieldIcon index={i} />
                          <InputText
                            placeholder={`Enter your ${getLinkFieldText(i)} URL`}
                            className="urlInput"
                            value={item || ''}
                            onChange={(e) => updateUrl(i, e.target.value)}
                          />
                        </span>
                        {i > 2 && <RedX onClick={() => removeUrl(i)} />}
                      </div>))
                    }
                  </>
                )}
              />
            ))}
          </div>
          {fieldValue.length <= field.maxLength && <p className={'addField'} onClick={addUrl}>+ Add another URL</p>
          }
          {errors[field.name] && <span>{errors[field.name].message}</span>}
        </div>
      );

    case 'talking-points':
      return (
        <div className="talkingPointsWrapper">
          {fieldValue && fieldValue.map((point, index) => (
            <div key={index} >
              <Controller
                key={index}
                name={`${field.name}[${index}]`}
                control={control}
                render={({ field: dynamicField }) => (
                  <>
                    <p className={`talkingPointLabel ${index > 2 ? 'hasRemove' : ''}`}>Talking point {index + 1}</p>
                    <InputText
                      {...dynamicField}
                      placeholder={`Talking point ${index + 1}`}
                      className={`inputField talkingPointField ${errors[field.name] ? 'p-invalid' : ''}`}
                    />
                    {index > 1 && <RedX onClick={() => removeUrl(index)} />}
                  </>
                )}
              />
            </div>
          ))}
          {fieldValue.length <= field.maxLength && <p className={'addField'} onClick={addUrl}>+ Add another Talking Point</p>}
        </div>
      );

    default:
      return null;
  }
};

export default RenderField;
