import React, { useState } from 'react';

import { Image } from 'primereact/image';
import { Button } from "primereact/button";

import AddChannel from "@/react_app/components/Dialogs/AddChannelDialog/AddChannel";
import NewContentListItem from "@/react_app/NewLatestItems/NewContentListItem/NewContentListItem";
import NewInviteToCampaign from "@/react_app/NewCreatorProfile/NewInviteToCampaign/NewInviteToCampaign";

import { useAgencys, useAuth, useToastModule } from "@/modules";
import { UserType } from "@/data/types";
import { formatSubsCount } from '@/utils/globals/curency-utils';

import youtube from "../img/youtube.png";

import "./NewCreatorProfile.css";
import "./NewCreatorProfileAdditional.css";

const GreenCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="8" cy="8" r="8" fill="#4BBD07" />
    </svg>
  )
}

const FavoriteIconInactive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H37C38.3807 0.5 39.5 1.61929 39.5 3V37C39.5 38.3807 38.3807 39.5 37 39.5H3C1.61929 39.5 0.5 38.3807 0.5 37V3Z" fill="#130A23" stroke="white" />
      {/* eslint-disable-next-line max-len,vue/max-len */}
      <path d="M20.2538 26.3293L20 26.1794L19.746 26.3289L13.9262 29.7527L13.9253 29.7532C13.213 30.1742 12.4053 29.5396 12.5766 28.8204L14.1191 22.3564L14.1905 22.0573L13.9557 21.8586L8.80906 17.5035L8.80888 17.5033C8.22439 17.0092 8.51753 16.0496 9.32415 15.987L9.32415 15.987L9.32673 15.9868L16.1002 15.4252L16.3981 15.4005L16.5174 15.1264L19.1679 9.0319L19.1687 9.02993C19.4721 8.32401 20.5269 8.32401 20.8303 9.02993L20.831 9.03152L23.4815 15.1397L23.6006 15.4142L23.8989 15.4389L30.6723 16.0005L30.6749 16.0007C31.4815 16.0633 31.7746 17.0229 31.1901 17.517L31.19 17.5172L26.0433 21.8723L25.8085 22.071L25.8799 22.3701L27.4225 28.8341C27.5937 29.5533 26.786 30.1879 26.0738 29.7669L26.0736 29.7669L20.2538 26.3293Z" fill="#130A23" stroke="white" />
    </svg>
  )
}

const FavoriteIconActive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H37C38.3807 0.5 39.5 1.61929 39.5 3V37C39.5 38.3807 38.3807 39.5 37 39.5H3C1.61929 39.5 0.5 38.3807 0.5 37V3Z" fill="#130A23" stroke="white" />
      {/* eslint-disable-next-line max-len,vue/max-len */}
      <path d="M20.2538 26.3293L20 26.1794L19.746 26.3289L13.9262 29.7527L13.9253 29.7532C13.213 30.1742 12.4053 29.5396 12.5766 28.8204L14.1191 22.3564L14.1905 22.0573L13.9557 21.8586L8.80906 17.5035L8.80888 17.5033C8.22439 17.0092 8.51753 16.0496 9.32415 15.987L9.32415 15.987L9.32673 15.9868L16.1002 15.4252L16.3981 15.4005L16.5174 15.1264L19.1679 9.0319L19.1687 9.02993C19.4721 8.32401 20.5269 8.32401 20.8303 9.02993L20.831 9.03152L23.4815 15.1397L23.6006 15.4142L23.8989 15.4389L30.6723 16.0005L30.6749 16.0007C31.4815 16.0633 31.7746 17.0229 31.1901 17.517L31.19 17.5172L26.0433 21.8723L25.8085 22.071L25.8799 22.3701L27.4225 28.8341C27.5937 29.5533 26.786 30.1879 26.0738 29.7669L26.0736 29.7669L20.2538 26.3293Z" fill="#E73D38" stroke="white" />
    </svg>
  )
}

export default function (props) {

  const toast = useToastModule();
  const { loadUser } = useAuth();
  const agencyModule = useAgencys();
  const [isAdding, setIsAdding] = useState(false);
  const [inviteDialog, setInviteDialog] = useState(false);
  const [openChannelDialog, setOpenChannelDialog] = useState(false);

  const removeChannel = async (channelId) => {
    await agencyModule.manager.deleteChannel(channelId).then(async (res) => {
      if (res) await props.loadChannels();
    })
  };

  const addChannel = async (channelData) => {
    setIsAdding(true);
    await agencyModule.manager.addCreator(channelData).then(async (res) => {
      if (res) {
        await props.loadChannels();
        handleDialog(false);
        toast.showSuccess("Channel added successfully");
        loadUser();
      }
      setIsAdding(false);
    });
  };

  const handleDialog = (flag) => {
    setOpenChannelDialog(flag);
  }

  return (
    <div className={'creator-profile'}>
      <div className={'firstLayer'}>
        <div className={'darkSquare'}>
          <Image className={'userPhoto'} alt={'user photo'} src={props.creator?.type === UserType.Creator ? props.creator?.profile?.photo : props.creator?.agencyData?.photo} />
          <div className={'layer'}>
            <GreenCircle />
            <p className={'userName'}>{props.creator?.type === UserType.Creator ? props.creator?.name : props.creator?.agencyData?.name}</p>
          </div>
          <p className={'userType'}>{props.creator?.type === UserType.Agency ? "Agency" : "Influencer"}</p>
          {!props.isCreator && <Button className={'inviteButton'} onClick={() => setInviteDialog(true)}>Invite to campaign</Button>}
          {!props.isCreator &&
            <div className={'favorite'} onClick={() => props.addToFavorites(props.creator?.id)}>
              {props.favorites?.allUserFavorites.find((item) => item.influences?.id === props.creator?.id) ?
                <FavoriteIconActive /> : <FavoriteIconInactive />
              }
            </div>
          }
        </div>
        <div className={'channels'}>
          <p className={'channelsTitle'}>Channels</p>
          <div className={'channelGroup'}>
            <div className={'channelItem'}>
              <Image src={youtube} alt={'youtube logo'} width={'23px'} height={'23px'} />
              <span className={'channelLabel'}>Youtube</span>
            </div>

          </div>
          <div>
            {props.channelsYT?.filter(channel => channel !== null).map((item, index) =>
              <div key={index} className={'channelRow'}>
                <div className={'channelSubGroupFirst'}>
                  <Image src={item?.snippet?.thumbnails?.default?.url} alt={'profile photo'} className={'channelPhoto'} />
                  <p className={'channelInfoTextTitle'}>{item?.snippet?.title}</p>
                </div>
                <div className={'channelInfo'}>
                  <div className={'channelSubGroupSecond'}>
                    <p className={'channelInfoLabel'}>Subscribers</p>
                    <p className={'channelInfoText'}>{formatSubsCount(item?.statistics?.subscriberCount)}</p>
                  </div>
                  <div className={'channelSubGroupSecond'}>
                    <p className={'channelInfoLabel'}>Total Views</p>
                    <p className={'channelInfoText'}>{item?.statistics?.viewCount}</p>
                  </div>
                  <div className={'channelSubGroupSecond'}>
                    <p className={'channelInfoLabel'}>Total videos</p>
                    <p className={'channelInfoText'}>{item?.statistics?.videoCount}</p>
                  </div>
                  {(props.creator?.type === UserType.Agency && props.creator?.id === props.user?.id) &&
                    <Button className={'channelInfoRemoveButton'} onClick={() => removeChannel(item.id)}>Remove</Button>}
                </div>
              </div>)}
          </div>
          {(props.creator?.type === UserType.Creator && props.creator?.id === props.user?.id) && <Button className={'addChannelButton'} onClick={() => props.openConnectPopup()} icon="pi pi-plus">add channel</Button>}
          {(props.creator?.type === UserType.Agency && props.creator?.id === props.user?.id) && <Button className={'addChannelButton'} onClick={() => setOpenChannelDialog(true)} icon="pi pi-plus">add creator</Button>}
        </div>
      </div>
      <div className={'contentItemsWrapper'}>
        <p className={'contentLabel'}>Content <br />
          <span className={'aboutContent'}>{props.content?.totalResults} results</span>
        </p>
        <div className={'contentItems'}>
          {props.content?.contentItems?.map((item, index) =>
            <div key={index} className={'contentItemWrapper'}>
              <NewContentListItem item={item} creator={props.creator} handleRouting={() => props.handleContentRouting(item.id)} />
            </div>)}
        </div>
      </div>
      <AddChannel open={openChannelDialog} close={handleDialog} addChannel={addChannel} isAdding={isAdding} />
      {!props.isCreator &&
        <NewInviteToCampaign visible={inviteDialog} onHide={() => setInviteDialog(false)} creator={props.creator}
          campaignOptions={props.campaignOptions} />
      }
    </div>
  )
}
