import React, { useEffect, useMemo, useRef, useState } from "react";

import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Paginator } from 'primereact/paginator';
import { InputTextarea } from 'primereact/inputtextarea';

import { formatDate } from '@/utils/globals/date-utils';
import { useToastModule, useWhitelisting } from '@/modules';
import { calculateCpiRate } from '@/utils/globals/cpi-rate';
import { compactNumber } from '@/utils/globals/curency-utils';

import noImage from "../img/no-image.png";
import { CloseIcon } from '@/react_app/img/Icons';
import youtubeIcon from "./../../../react_app/img/youtube.png"
import {
  ExpandArrowDown, ExpandArrowUp, FilterClearIcon, WhitelistDateIcon,
  WhitelistDeleteActionIcon, WhitelistDropdownIcon
} from "../img/Icons";
import {
  ActualBudgetChart, MainBudgetChart, RemainingBudgetCharts, ViewCountChart, ClientMaxCap
} from "./charts";

import "./WhiteListing.css";


const statusTabs = [
  { value: 'Whitelist influencer', type: "admin" },
];
const statusFilterOptions = [
  { name: "Approved", value: "accepted" },
  { name: "Pending", value: "pending" },
  { name: "Rejected", value: "rejected" },
];
const defaultFilter = {
  search: "",
  date: null,
  brand: "",
  campaign: "",
  status: "pending",
  approvalTab: "admin"
}
const selectedOptionTemplate = (option, props) => {
  if (option) {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  }
  return <span>{props.placeholder}</span>;
};
const dropdownOptionTemplate = (option) => {
  return (
    <div className="flex align-items-center">
      <div>{option.name}</div>
    </div>
  );
};
const dateBodyTemplate = (rowData) => (
  <div className='defaultTextTemplate'>
    {formatDate(rowData.createdAt)}
  </div>
);
const statusBodyTemplate = (rowData) => {
  return <div className={`statusTemplate ${rowData.status}`}>{(rowData.status === 'accepted') ? 'Approved' : rowData.status}</div>
}
const brandBodyTemplate = (rowData) => (
  <div className='brandTemplate'>
    <Image src={rowData?.brand?.photo || noImage} alt='profile photo' className='authorTemplateImage' />
    {rowData?.brand?.name || ""}
  </div>
);
const applicantBodyTemplate = (rowData) => {
  let applicantDetails = {};

  if (rowData.creator && rowData?.creator?.type === "agencyOwner") {
    if (rowData.channel) {
      applicantDetails = rowData?.creator?.agencyWorkers?.find(worker => worker?.channel?.channelId === rowData.channel)
    } else {
      return (
        <div className='titleTemplate' onClick={() => window.open(`https://www.youtube.com/channel/${rowData?.creator?.youtube[0]?.id}`, "_blank")}>
          <Image src={rowData?.creator?.photo ? rowData?.creator?.photo : noImage} alt='thumbnail' className='titleTemplateImage' />
          {rowData?.creator?.name}
        </div>
      )
    }
    return (
      <div className='titleTemplate' onClick={() => (applicantDetails) ? window.open(`https://www.youtube.com/channel/${applicantDetails?.channel?.channelId}`, "_blank") : ""}>
        <Image src={applicantDetails?.channel ? applicantDetails?.channel?.photo : noImage} alt='thumbnail' className='titleTemplateImage' />
        {applicantDetails?.channel?.title}
      </div>
    )
  }

  if (rowData.creator && rowData?.creator?.type === "creator") {
    if (rowData.channel) {
      applicantDetails = rowData?.creator?.agencyWorkers?.find(worker => worker?.channel?.channelId === rowData.channel)
      return (
        <div className='titleTemplate' onClick={() => (applicantDetails) ? window.open(`https://www.youtube.com/channel/${applicantDetails?.channel?.channelId}`, "_blank") : ""}>
          <Image src={applicantDetails?.channel ? applicantDetails?.channel?.photo : noImage} alt='thumbnail' className='titleTemplateImage' />
          {applicantDetails?.channel?.title}
        </div>
      )
    }
    return (
      <div className='titleTemplate' onClick={() => window.open(`https://www.youtube.com/channel/${rowData?.creator?.youtube[0]?.id}`, "_blank")}>
        <Image src={rowData?.creator?.photo ? rowData?.creator?.photo : noImage} alt='thumbnail' className='titleTemplateImage' />
        {rowData?.creator?.name}
      </div>
    )
  }
  if (!rowData?.creator) return <div className='titleTemplate'>N/A</div>
};
const campaignBodyTemplate = (rowData) => {
  return (
    <div className='underlinedTextTemplate'>
      {rowData?.campaignName || '-'}
    </div>
  )
};
const emailBodyTemplate = (rowData) => {
  const email = rowData?.creator?.email || "";
  return (
    <div className='underlinedTextTemplate email'>
      <p onClick={() => window.location = `mailto:${email}`}>{email || '-'}</p>
    </div>
  )
};
const typeBodyTemplate = (rowData) => {
  let applicantType = "Creator";
  if (rowData?.creator) {
    const { type } = rowData?.creator;
    const hasChannel = !!rowData.channel;
    if (type === "agencyOwner" || (type === "creator" && hasChannel)) {
      applicantType = hasChannel ? "Agency" : "Creator";
    }
  }

  return (
    <div className='underlinedTextTemplate'>
      <p>{applicantType}</p>
    </div>
  )
};
const paymentMethodBodyTemplate = (rowData) => {
  return (
    <div className='brandTemplate'>
      {rowData?.paymentMethod || '-'}
    </div>
  )
};

export default function () {

  const rows = 10;
  const searchDebounceRef = useRef(null);
  const dateRangeDebounceRef = useRef(null);
  const previousValuesRef = useRef({});

  const toast = useToastModule();
  const {
    manager: {
      getCpmCampaignApplications,
      getAllBrands,
      getCampaignsOptions,
      resolveRequestsAction,
      resolveRequestsError,
      resolveRequestsProgress,
      deleteRequestsAction,
      getExports, undoRequestsAction
    },
  } = useWhitelisting();
  const activeTab = 'admin';
  const [first, setFirst] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [dates, setDates] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [updatedCurrentNonSelectedValue, setUpdatedCurrentNonSelectedValue] = useState({});
  const [selectedRequests, setSelectedRequests] = useState(null);
  const [currentTotalPayoutCap, setCurrentTotalPayoutCap] = useState(0);
  const [currentTotalViewCount, setCurrentTotalViewCount] = useState(0);
  const [currentCampaignAmountInfo, setCurrentCampaignAmountInfo] = useState(null);
  const [campaignsOptions, setCampaignsOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [campaignRequestData, setCampaignRequestData] = useState([]);
  const [currentCampaignBudget, setCurrentCampaignBudget] = useState(undefined);
  const [campaignTotalRequests, setCampaignTotalRequests] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({ ...defaultFilter, status: "pending", brand: "" });
  const [currentCampaignType, setCurrentCampaignType] = useState('');

  useEffect(() => {
    getAllBrands().then(brands => {
      setBrandsOptions(brands.map(brand => {
        return { name: brand.name, value: brand.id, label: brand.name }
      }));
    }).catch((error) => console.log('error', error))
    handleGetCampaignOptions('pending', '', 'admin');
  }, []);

  useEffect(() => {
    if (filters.campaign) {
      handleGetRequests();
    }
  }, [filters, activeTab, first]);

  const handleGetRequests = async () => {
    setIsLoading(true);
    const updatedFilters = {
      campaignName: filters.campaign, username: filters.search, brand: filters.brand, date: filters.date, approvalTab: activeTab
    };
    if (!filters.date) delete updatedFilters.date;

    await getCpmCampaignApplications(rows, first, filters.status, updatedFilters)
      .then((capApplications) => {
        if (capApplications?.campaignApplications) {
          const defaultCurrentNonSelectedValue = (capApplications?.campaignApplications ?? []).reduce((acc, application) => {
            const proposedPayoutCap = Number(application?.proposedPayoutCap ?? 0);
            const cpmAmount = (application.paymentMethod === 'UGC') ? 0 : calculateCpiRate(application?.cpm?.amount, application?.appliedFee);
            const currentViews = (application.paymentMethod === 'UGC') ? 0 : (proposedPayoutCap / cpmAmount) * 1000;

            acc[application.applicationId] = {
              proposedPayoutCap,
              currentViews
            };

            return acc;
          }, {});

          const currentPagePayoutCap = defaultCurrentNonSelectedValue
            ? Object.values(defaultCurrentNonSelectedValue).reduce((total, item) => {
              return total + (item.proposedPayoutCap ? parseFloat(item.proposedPayoutCap) : 0);
            }, 0)
            : 0;

          const currentPageViews = defaultCurrentNonSelectedValue
            ? Object.values(defaultCurrentNonSelectedValue).reduce((total, item) => {
              return total + (item.currentViews ? parseFloat(item.currentViews) : 0);
            }, 0)
            : 0;

          setCurrentTotalPayoutCap((Number(capApplications?.budget?.assignBudget) -
            Number(filters.status === "pending" ? currentPagePayoutCap : 0)) || 0);
          setCurrentTotalViewCount((Number(capApplications?.budget?.viewCount) -
            Math.round(filters.status === "pending" ? currentPageViews : 0)) || 0);

          setCampaignRequestData(capApplications?.campaignApplications);
          setUpdatedCurrentNonSelectedValue(defaultCurrentNonSelectedValue);
          setCurrentCampaignAmountInfo(capApplications?.campaignApplications[0]?.cpm);
          setCampaignTotalRequests(capApplications?.maxCount);
          setExpandedRows([]);
        } else {
          setCampaignRequestData([]);
          setCurrentCampaignAmountInfo(null);
          setCampaignTotalRequests(0);
          // setCurrentCampaignBudget(undefined);
        }
        setTimeout(() => {
          setCurrentCampaignBudget(capApplications?.budget);
        }, [10])
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleGetCampaignOptions = (status, currentBrand = "", approvalTab = "admin") => {
    getCampaignsOptions(status, currentBrand, approvalTab).then((campaignOptions) => {
      if (campaignOptions) {
        setCampaignsOptions(campaignOptions.map(option => {
          return { name: option.name, value: option.id, label: option.name, type: option.paymentMethod }
        }));
        if (campaignOptions.length > 0) {
          setCurrentCampaignType(campaignOptions[0]?.paymentMethod)
          setFilters(prevState => ({ ...prevState, "campaign": campaignOptions[0]?.id }));
        } else {
          setCampaignRequestData([]);
          setCurrentCampaignAmountInfo(null);
          setCampaignTotalRequests(0);
          // setCurrentCampaignBudget(undefined);
        }
      }
    }).catch((error) => console.log('error', error));
  };
  const handleGetExports = () => {
    setIsExporting(true);
    const updatedFilters = {
      campaignName: filters.campaign, username: filters.search, brand: filters.brand, date: filters.date, approvalTab: activeTab,
      applicationId: selectedRequests?.length > 0 ? selectedRequests?.map(req => req.applicationId)?.join() : ''
    };
    getExports(filters.status, updatedFilters).then((exports) => {
      if (exports) {
        const blob = new Blob([exports], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'Applications.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsExporting(false);
      } else {
        setIsExporting(false);
      }
    }).catch((error) => {
      console.log('error', error)
      setIsExporting(false);
    });
  };
  const handleResolveRequests = (action, selectedRequestsData, singleAction = false) => {
    const isRejectAction = action === "reject";
    const errors = new Set();
    setIsActionLoading(true);
    const requestPayload = selectedRequestsData.map(request => {
      const currentProposedPayoutCap = updatedCurrentNonSelectedValue?.[request?.applicationId]?.proposedPayoutCap;
      const updatedPayload = {
        userId: request?.creator?.id,
        campaignId: request?.campaignId,
        applicationId: request?.applicationId,
        perspective: activeTab,
        proposedPayoutCap: Number(currentProposedPayoutCap),
        contractWork: false,
        paymentMethod: request?.paymentMethod
      };

      if (request?.paymentMethod === "CPM" && !isRejectAction) {
        if (!updatedPayload?.proposedPayoutCap && updatedPayload?.proposedPayoutCap != 0) {
          errors.add(`Max. cap is required!`);
        } else if (updatedPayload?.proposedPayoutCap == 0) {
          errors.add(`Max. cap should be more than 0.`);
        } else if (Math.sign(updatedPayload?.proposedPayoutCap) == -1) {
          errors.add(`Max. cap can't be a negative number!`);
        }
      }
      if (request?.paymentMethod !== "CPM" || isRejectAction) {
        delete updatedPayload.proposedPayoutCap;
      }
      if (request?.creator?.type === "agencyOwner" || (request?.creator?.type === "creator" && request?.channel)) {
        updatedPayload.channelId = request?.creator?.agencyWorkers.find(channel => channel?.channel?.channelId === request?.channel)?.channel?.channelId;
      }
      updatedPayload.admin_reason = singleAction ? ((updatedCurrentNonSelectedValue) ? updatedCurrentNonSelectedValue[request?.applicationId]?.admin_reason : "")
        : request?.admin_reason || updatedCurrentNonSelectedValue[request?.applicationId]?.admin_reason;
      return updatedPayload;
    })

    if (errors.size > 0) {
      errors.forEach(error => toast.showError(error));
      setIsActionLoading(false);
      return;
    }

    resolveRequestsAction(action, requestPayload).then((res) => {
      if (res) {
        setIsActionLoading(false);
        toast.showSuccess(`Requests ${action.includes('accept') ? "accepted" : "rejected"} successfully!`);
        handleGetRequests();
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
        setCurrentTotalPayoutCap(0);
        setCurrentTotalViewCount(0);
        setDates(null);
        setSearchValue('');
      } else {
        setIsActionLoading(false);
        toast.showError(resolveRequestsError.value.message || "Something went wrong, please try again!");
      }
    }).catch((error) => {
      if (error) {
        setIsActionLoading(false);
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
        toast.showError(error.message || "Something went wrong, please try again!");
        setCurrentTotalPayoutCap(0);
        setCurrentTotalViewCount(0);
        setDates(null);
        setSearchValue('');
      }
    });
  };
  const handleDeleteRequests = (selectedRequestsData) => {
    setIsActionLoading(true);
    deleteRequestsAction(selectedRequestsData).then((res) => {
      if (res) {
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
        setIsActionLoading(false);
        setOpenDeleteDialog(false);
        toast.showSuccess("Requests deleted successfully!");
        handleGetRequests();
      }
    }).catch((error) => {
      if (error) {
        setIsActionLoading(false);
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
        toast.showError(error.message || "Something went wrong, please try again!");
      }
    });
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }
    searchDebounceRef.current = setTimeout(() => {
      setFilters(prevState => ({
        ...prevState,
        search: value
      }));
      setFirst(0);
      setSelectedRequests(null);
      setUpdatedCurrentNonSelectedValue(null);
      setCurrentTotalPayoutCap(0);
      setCurrentTotalViewCount(0);
    }, 500);
  };
  const handleClearSearch = () => {
    if (searchValue) {
      setFilters(prevState => ({
        ...prevState,
        search: ""
      }));
      setSearchValue('');
      setFirst(0);
    }
  }
  const handleDateFilter = (e) => {
    const { value } = e.target;
    const filterDateRange = value?.map((date) => { return formatDate(date, 'YYYY-MM-DD') });
    if (value == null) {
      setFilters(prevState => { return ({ ...prevState, date: null }) });
    }
    if (dateRangeDebounceRef.current) {
      clearTimeout(dateRangeDebounceRef.current);
    }
    if (filterDateRange.filter(date => date).length > 1)
      dateRangeDebounceRef.current = setTimeout(() => {
        setFilters(prevState => ({
          ...prevState,
          date: filterDateRange
        }));
      }, 1500);
  };
  const handleSelectedValues = (inputName, inputValue, applicationId, cpmAmount) => {
    setUpdatedCurrentNonSelectedValue(prevState => ({
      ...prevState,
      [applicationId]: {
        ...(prevState?.[applicationId] ?? {}),
        [inputName]: inputValue,
        ...(inputName === "proposedPayoutCap" && {
          "currentViews": (((inputValue) / cpmAmount) * 1000)
        })
      }
    }));

    if (selectedRequests) {
      const updatedSelectedValues = [...selectedRequests].map(selectedReq => {
        if (selectedReq.applicationId === applicationId) {
          selectedReq[inputName] = inputValue;
        }
        return selectedReq
      });
      setSelectedRequests(updatedSelectedValues);
    }
  };

  useEffect(() => {
    if (filters.status === 'pending') recalculateTotals();
  }, [updatedCurrentNonSelectedValue, filters.status])

  const recalculateTotals = () => {
    const currentValues = updatedCurrentNonSelectedValue || {};

    let selectedPayoutCapDifference = 0;
    let selectedViewsDifference = 0;

    Object.entries(currentValues).forEach(([applicationId, item]) => {
      const previousValue = previousValuesRef.current[applicationId] || {};
      const newPayoutCap = item.proposedPayoutCap ? parseFloat(item.proposedPayoutCap) : 0;
      const oldPayoutCap = previousValue.proposedPayoutCap ? parseFloat(previousValue.proposedPayoutCap) : 0;

      if (newPayoutCap !== oldPayoutCap) {
        selectedPayoutCapDifference += newPayoutCap - oldPayoutCap;
      }

      const newViews = item.currentViews ? parseFloat(item.currentViews) : 0;
      const oldViews = previousValue.currentViews ? parseFloat(previousValue.currentViews) : 0;

      if (newViews !== oldViews) {
        selectedViewsDifference += newViews - oldViews;
      }
    });

    setCurrentTotalPayoutCap(prevCap => prevCap + selectedPayoutCapDifference);
    setCurrentTotalViewCount(prevViews => prevViews + selectedViewsDifference);

    previousValuesRef.current = currentValues;
  };
  const handlePagination = (event) => {
    setFirst(event.first);
    setSelectedRequests(null);
    setUpdatedCurrentNonSelectedValue(null);
    setCurrentTotalPayoutCap(0);
    setCurrentTotalViewCount(0);
  };
  const expanderTemplate = (rowData) => {
    return (
      <div className='expanderTemplate'>
        {filters.status === 'pending' && <>
          <Button className='successIcon' icon="pi pi-check" severity="success" aria-label="Bookmark" tooltip='Approve'
            disabled={isActionLoading || isLoading} onClick={() => { handleResolveRequests("accept", [rowData], true) }} />
          <Button className='rejectIcon' icon="pi pi-times" severity="danger" aria-label="Cancel" tooltip='Reject'
            disabled={isActionLoading || isLoading} onClick={() => { handleResolveRequests("reject", [rowData], true) }} /></>
        }
        {expandedRows.includes(rowData) ?
          <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  };
  const rowExpansionTemplate = (rowData) => {
    let agencyChannel = "N/A";
    let applicantType = "Creator";
    let subscribedAgencyCreatorChannel = null;

    if (rowData?.creator) {
      const { type, name, agencyWorkers } = rowData.creator;
      const hasChannel = !!rowData.channel;
      if (type === "agencyOwner" || (type === "creator" && hasChannel)) {
        agencyChannel = hasChannel ? name : "N/A";
        applicantType = hasChannel ? "Agency" : "Creator";
        subscribedAgencyCreatorChannel = agencyWorkers?.find(worker => worker?.channel?.channelId === rowData.channel)?.channel;
      }
    }

    const updatedCurrentSelectedValue = selectedRequests?.find(selectedReq => {
      return selectedReq?.applicationId === rowData?.applicationId
    });

    const currentProposedPayoutCap = (updatedCurrentNonSelectedValue && rowData?.applicationId && updatedCurrentNonSelectedValue[rowData.applicationId]?.proposedPayoutCap);
    const currentTotalView = Math.round(updatedCurrentNonSelectedValue && rowData?.applicationId && updatedCurrentNonSelectedValue[rowData.applicationId]?.currentViews);

    return (
      <>
        <div className='darkLine' />
        <div className='expansionWrapper'>
          <div className='expansionDataSquare'>
            <div className='request-info type-name'>
              <ul>
                <li>
                  <p className='info-label'>Agency Channel :</p>
                  <p className='info-value'>{agencyChannel}</p>
                </li>
                <li>
                  <p className='info-label'>Applicant Type :</p>
                  <p className='info-value'>{applicantType}</p>
                </li>
              </ul>
              {<div className={`request-reason ${activeTab}`}>
                <div className='admin-reason'>
                  <InputTextarea autoResize
                    name="admin_reason"
                    value={updatedCurrentSelectedValue?.admin_reason || rowData?.admin_reason ? rowData?.admin_reason : activeTab === "creator" ? 'N/A' : rowData?.admin_reason}
                    onChange={(e) => {
                      const { value } = e.target;
                      handleSelectedValues('admin_reason', value, rowData?.applicationId);
                    }}
                    rows={5} className='textArea'
                    disabled={filters.status !== "pending"}
                    placeholder='Enter the reason' />
                </div>
              </div>}
            </div>
            <div className={`request-info max-cap ${activeTab}`}>
              <ul>
                {["CPM", "UGC"].includes(rowData.paymentMethod) && <li>
                  <p className='info-label'>Set Max. Cap : {filters.status !== "pending" && <span className='info-value'>{rowData?.proposedPayoutCap || 0}</span>}</p>
                  <div className='info-input'>
                    {filters.status === "pending" &&
                      <InputText name="proposedPayoutCap" className='maxCapInputField' placeholder='Set Cap Value'
                        type="number"
                        min={0}
                        value={currentProposedPayoutCap}
                        onChange={(e) => {
                          const { value } = e.target;
                          handleSelectedValues('proposedPayoutCap', value, rowData?.applicationId, calculateCpiRate(rowData?.cpm?.amount, rowData?.appliedFee));
                        }}
                        disabled={rowData.paymentMethod === "UGC" || filters.status !== "pending"}
                        onWheel={(e) => e.target.blur()}
                      />
                    }
                    {(rowData.paymentMethod === "CPM") && <div className={`sub-info ${activeTab}`}>
                      <p className='sub-info-label'>Total Views : </p>
                      <p className='sub-info-value'>{currentTotalView?.toLocaleString()}</p>
                    </div>}
                  </div>
                </li>}
              </ul>
            </div>
            <div className='request-info youtube-info'>
              {subscribedAgencyCreatorChannel && applicantType === "Agency" && <ul className='youtube-links'>
                <li>
                  <div className='youtube-link'>
                    <img className='info-icon' alt="youtube-logo" src={youtubeIcon} height="20" width="20" />
                    <a className='info-link' href={`https://www.youtube.com/channel/${subscribedAgencyCreatorChannel?.channelId}`} target={'_blank'}>https://www.youtube.com/channel/{subscribedAgencyCreatorChannel?.channelId}</a>
                  </div>
                  <div className='youtube-sub'>
                    <p className='info-label'>Subscribers :</p>
                    <p className='info-value'>{compactNumber(subscribedAgencyCreatorChannel?.followers)}</p>
                  </div>
                </li>
              </ul>
              }
              {rowData?.creator && applicantType === "Creator" && rowData?.creator?.youtube?.length > 0 && <ul className='youtube-links'>
                {rowData?.creator?.youtube?.map((youtube, index) => {
                  return <li key={youtube?.id + index}>
                    <div className='youtube-link'>
                      <img className='info-icon' alt="youtube-logo" src={youtubeIcon} height="20" width="20" />
                      <a className='info-link' href={`https://www.youtube.com/channel/${youtube?.id}`} target={'_blank'}>https://www.youtube.com/channel/{youtube?.id}</a>
                    </div>
                    <div className='youtube-sub'>
                      <p className='info-label'>Subscribers :</p>
                      <p className='info-value'>{compactNumber(youtube?.followers) || 0}</p>
                    </div>
                  </li>
                })}
              </ul>
              }
            </div>
          </div >
        </div >
      </>
    );
  };
  const budgetTemplate = (budget, totalPayoutCap, totalViews) => {
    return (
      <>
        <MainBudgetChart budget={budget} />
        <ActualBudgetChart budget={budget} />
        <RemainingBudgetCharts
          budget={budget}
          totalPayoutCap={totalPayoutCap}
        />
        {currentCampaignType === "CPM" &&
          <>
            {currentCampaignAmountInfo?.amount && <ViewCountChart
              budget={budget}
              currentTotalViewCount={totalViews}
              currentCampaignAmountInfo={currentCampaignAmountInfo} />
            }
            <ClientMaxCap amount={budget?.cpm?.payoutCap || 0} />
          </>
        }
      </>
    )
  };
  const resetOnFilterChange = () => {
    setFirst(0);
    setSelectedRequests(null);
    setUpdatedCurrentNonSelectedValue(null);
    setCurrentTotalPayoutCap(0);
    setCurrentTotalViewCount(0);
  };
  const handleUndoRequest = async () => {
    setIsActionLoading(true);
    const requestPayload = await selectedRequests.map(request => {
      return {
        campaignId: request?.campaignId,
        applicationId: request?.applicationId,
        perspective: activeTab,
      };
    })
    undoRequestsAction(requestPayload).then((res) => {
      setIsActionLoading(false);
      if (res) {
        toast.showSuccess("Requests undo successfully!");
        handleGetRequests();
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
      }
    }).catch((error) => {
      if (error) toast.showError(error.message || "Something went wrong, please try again!");
      setIsActionLoading(false);
    });
  };
  const handleChange = (type, value) => {
    setFilters(prevState => ({ ...prevState, [type]: value, ...(type === 'brand' && { campaign: "" }) }));
    resetOnFilterChange();
    if (type !== 'campaign') {
      const brand = type === 'status' ? filters.brand : value;
      if (type === "brand") {
        handleGetCampaignOptions('pending', brand, activeTab);
      }
    } else {
      const paymentMethod = campaignsOptions.find(camp => camp.value === value).type;
      setCurrentCampaignType(paymentMethod)
    }
  };
  const memoizedBudgetTemplate = useMemo(
    () => budgetTemplate(currentCampaignBudget, currentTotalPayoutCap, currentTotalViewCount),
    [currentCampaignBudget, currentTotalPayoutCap, currentTotalViewCount]
  );
  const isActionDisabled = (filters.status !== "pending") || ((selectedRequests?.length <= 0 || selectedRequests == null)) || isActionLoading || isLoading;
  const isFilterDisabled = isActionLoading || isLoading;
  const isDisableDelete = (filters.status === "rejected") || ((selectedRequests?.length <= 0 || selectedRequests == null)) || isActionLoading || isLoading;

  return (
    <div className='whitelisting'>
      <p className='title'>Pending Campaign Requests</p>
      <div className='tabs-wrapper'>
        <div className='tabs'>
          {statusTabs.map((item) => (
            <div key={item.value} className={`tab ${item.type === activeTab ? 'selected' : 'normal'}`}>
              {item.value}
            </div>))}
        </div>
        <div className='export-action'>
          <Button className='button export-button'
            icon="pi pi-file-export"
            loading={isExporting}
            disabled={isActionLoading || isExporting || isLoading
            }
            onClick={() => { handleGetExports() }}>Export</Button>
        </div>
      </div>
      <div className='filter-action-wrap' >
        <ul className='filters'>
          <li className='filter p-input-icon-left search-wrap'>
            <i className="pi pi-search" />
            <InputText className='input-field' placeholder='Search for creator' value={searchValue} onChange={(e) => handleSearch(e)} disabled={isFilterDisabled} />
            {searchValue && <span className='clear-icon' onClick={() => handleClearSearch()}>
              <FilterClearIcon />
            </span>}
          </li>
          <li className='filter date-range-wrap'>
            <Calendar icon={<WhitelistDateIcon />} iconPos='right' value={dates} showIcon onChange={(e) => {
              setDates(e.value);
              handleDateFilter(e);
              resetOnFilterChange();
            }}
              selectionMode="range"
              showButtonBar
              className='daterange'
              placeholder='Search by date'
              hideOnRangeSelection
              disabled={isFilterDisabled}
            />
          </li>
          <li className='filter dropdown-wrap brand'>
            <Dropdown value={filters.brand} options={brandsOptions}
              onChange={(e) => handleChange('brand', e.target.value)}
              optionLabel="name" placeholder="Select a brand"
              filter
              valueTemplate={selectedOptionTemplate}
              itemTemplate={dropdownOptionTemplate}
              showClear={filters.brand !== ""}
              disabled={isFilterDisabled}
              className="w-full md:w-14rem"
              dropdownIcon={<WhitelistDropdownIcon scale={0.6} />} />
          </li>
          <li className='filter dropdown-wrap campaign'>
            <Dropdown value={filters.campaign} options={campaignsOptions} optionLabel="name" placeholder="Select a campaign"
              onChange={(e) => handleChange('campaign', e.target.value)}
              filter
              valueTemplate={selectedOptionTemplate}
              itemTemplate={dropdownOptionTemplate}
              className="w-full md:w-14rem"
              showClear={filters.campaign !== ""}
              dropdownIcon={<WhitelistDropdownIcon scale={0.6} />}
              disabled={isFilterDisabled}
            />
          </li>
          <li className='filter dropdown-wrap status'>
            <Dropdown value={filters.status} options={statusFilterOptions} optionLabel="name"
              placeholder="Search by status" onChange={(e) => handleChange('status', e.target.value)}
              className="w-full md:w-14rem" dropdownIcon={<WhitelistDropdownIcon scale={0.6} disabled={isFilterDisabled}
              />} />
          </li>
        </ul>
        <div className='action-wrap'>
          {activeTab !== "creator" && filters.status !== 'rejected' &&
            <>
              <Button className='button approve-button'
                disabled={isActionDisabled}
                onClick={() => { handleResolveRequests("accept", selectedRequests, false) }}>Approve</Button>
              <Button className='button reject-button'
                disabled={isActionDisabled}
                onClick={() => { handleResolveRequests('reject', selectedRequests, false) }}>Reject</Button>
            </>
          }
          {filters.status === 'rejected' &&
            <Button className='button undo-button' icon="pi pi-undo"
              loading={isActionLoading} disabled={(selectedRequests?.length <= 0 || selectedRequests == null)}
              onClick={() => handleUndoRequest()}>Undo</Button>
          }
          <Button className='delete-button' rounded text severity="secondary" aria-label="delete"
            onClick={() => { setOpenDeleteDialog(true); }} disabled={isDisableDelete}
          ><WhitelistDeleteActionIcon /></Button>
        </div>
      </div>
      {filters.campaign && <div className='chartsGroup'>
        {!!currentCampaignBudget && memoizedBudgetTemplate}
      </div>}
      <DataTable value={campaignRequestData}
        loading={isLoading || isActionLoading}
        loadingIcon={
          <div className='loading-wrap'>
            <span className="loader wrap"></span>
          </div>
        }
        removableSort emptyMessage="No request found."
        selectionMode="checkbox"
        selection={selectedRequests}
        onSelectionChange={(e) => {
          setSelectedRequests(e.value);
        }}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate} >
        <Column selectionMode="multiple" headerStyle={{ width: '2.5%' }}></Column>
        <Column field="createdAt" header="Date" sortable body={dateBodyTemplate} style={{ width: '10%' }}></Column>
        <Column field="name" header="Applicant Name" body={applicantBodyTemplate} style={{ width: '12.5%' }}></Column>
        <Column field="email" header="Email" body={emailBodyTemplate} style={{ width: '12.5%' }}></Column>
        <Column field="userType" header="Type" body={typeBodyTemplate} style={{ width: '10%' }}></Column>
        <Column field="campaignName" header="Campaign" sortable body={campaignBodyTemplate} style={{ width: '15%' }}></Column>
        <Column field="paymentMethod" header="Campaign Type" sortable body={paymentMethodBodyTemplate} style={{ width: '10%' }}></Column>
        <Column field="brand" header="Brand" body={brandBodyTemplate} style={{ width: '12%' }}></Column>
        <Column field="status" header="Status" body={statusBodyTemplate} style={{ width: '8%' }}></Column>
        <Column header="Action" expander={true} body={expanderTemplate} style={{ width: '8%' }} />
      </DataTable>
      {campaignRequestData && campaignRequestData?.length > 0 && <Paginator first={first} rows={rows} totalRecords={campaignTotalRequests} onPageChange={handlePagination} style={{ background: '#130A23' }} />}
      <Dialog visible={openDeleteDialog} onHide={() => setOpenDeleteDialog(false)} className='reasonDialog'>
        <div className='closeRow'>
          <div className='closeButton' onClick={() => setOpenDeleteDialog(false)}><CloseIcon /></div>
        </div>
        <div className='reasonContent'>
          <p className='reasonTitle'>REJECT APPLICATION</p>
          <p className='reasonText'>Are you sure you want to delete?</p>
          {selectedRequests && selectedRequests?.map((request) => (
            <p className='requestTitle' key={request?.applicationId}>
              <strong>{request?.creator?.name}'s</strong> application to <strong>{request?.campaignName}</strong>
            </p>
          ))
          }
          <div className='reasonButtonGroup'>
            <Button className='reasonButtonCancel' disabled={resolveRequestsProgress.value} onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
            <Button className='reasonButtonSave' disabled={resolveRequestsProgress.value} onClick={() => handleDeleteRequests(selectedRequests)}>Delete</Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}