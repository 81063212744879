import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewDashboard = _resolveComponent("NewDashboard")!
  const _component_OldDashboard = _resolveComponent("OldDashboard")!

  return (_ctx.isHomepageEnabled)
    ? (_openBlock(), _createBlock(_component_NewDashboard, {
        key: 0,
        user: _ctx.user,
        latestInfluencers: _ctx.lastInfluences,
        goToCampaign: _ctx.goToCampaign,
        goToInfluencerProfile: _ctx.goToInfluencerProfile,
        addToFavorites: _ctx.addToFavorites,
        viewPerspective: _ctx.viewPerspective,
        favorites: _ctx.favorites,
        handleRouting: _ctx.handleRouting,
        activeCampaigns: _ctx.latestCampaigns,
        notificationMethods: _ctx.notificationMethods
      }, null, 8, ["user", "latestInfluencers", "goToCampaign", "goToInfluencerProfile", "addToFavorites", "viewPerspective", "favorites", "handleRouting", "activeCampaigns", "notificationMethods"]))
    : (_openBlock(), _createBlock(_component_OldDashboard, {
        key: 1,
        viewPerspective: _ctx.viewPerspective
      }, null, 8, ["viewPerspective"]))
}