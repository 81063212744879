/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";

import { Image } from "primereact/image";
import { Chart } from 'primereact/chart';
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Paginator } from 'primereact/paginator';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputNumber } from "primereact/inputnumber";

import { useAdmins } from '@/modules';

import { STATUS_TAB } from '@/utils/globals/constants';
import { formatDate } from "@/utils/globals/date-utils";
import { formatSubsCount,compactNumber } from '@/utils/globals/curency-utils';
import { CampaignPaymentMethod, CampaignStatus } from "@/data/types";

import CustomLoader from "@/react_app/Other/CustomLoader/CustomLoader";
import DeleteCampaign from "@/react_app/components/Dialogs/DeleteCampaign";

import noImage from "../img/no-image.png";
import {
  AdminsIcon, AndroidIcon, AppleIcon, ApplicationsIcon,
  BrandsIcon, ClicksIcon, CloseIcon, CopyIcon,
  CreatorsIcon,
  ExpandArrowDown,
  ExpandArrowUp,
  ExpansionActionsIcon,
  ExpansionCreatorsIcon,
  ExpansionDetailsIcon, ViewsIcon, TotalViews, TotalLike, TotalComment
} from "../img/Icons";

import "./NewImportantStats.css";
import { PencilIconRed } from '@/react_app/img/Icons';

export default function (props) {

  const { adminStatistics, campaignDeleted, handleCampaign } = props;
  const adminsModule = useAdmins();

  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [expandedRows, setExpandedRows] = useState([]);
  const [openCreatorsDialog, setOpenCreatorsDialog] = useState(false);
  const [openActionsDialog, setOpenActionsDialog] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [selectedCampaignCreators, setSelectedCampaignCreators] = useState([]);
  const [newStatus, setNewStatus] = useState('');
  const [newBudget, setNewBudget] = useState(0);
  const [isEditTrackingLink, setIsEditTrackingLink] = useState(false);
  const [newTrackingLink, setNewTrackingLink] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [first, setFirst] = useState(0);
  const [time, setTime] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [loading, setLoading] = useState({ delete: false, archived: false });
  const statsItems = [
    {
      label: 'Creators',
      icon: <CreatorsIcon />,
      data: adminStatistics.creators,
    },
    {
      label: 'Brands',
      icon: <BrandsIcon />,
      data: adminStatistics.brands,
    },
    {
      label: 'Admins',
      icon: <AdminsIcon />,
      data: adminStatistics.admins,
    },
    {
      label: 'Total Views',
      icon: <TotalViews />,
      data: formatSubsCount(adminStatistics?.youtubeContentItemStatistics?.totalViewCount, 2) || 0,
    }, {
      label: 'Total Likes',
      icon: <TotalLike />,
      data: formatSubsCount(adminStatistics?.youtubeContentItemStatistics?.totalLikeCount, 2) || 0,
    }, {
      label: 'Total Comments',
      icon: <TotalComment />,
      data: formatSubsCount(adminStatistics?.youtubeContentItemStatistics?.totalCommentCount, 2) || 0,
    }
  ]

  useEffect(() => {
    props.addPlatform(STATUS_TAB[activeTab].type);
    setSearchValue('');
    setFirst(0);
  }, [activeTab]);

  useEffect(() => {
    if (selectedCampaign) {
      setIsLoader(true);
      props.parseAppliedCreators(selectedCampaign?.trackingLinks).then((result) => {
        if (result) setSelectedCampaignCreators(result);
        else setSelectedCampaignCreators([]);
        setIsLoader(false);
      });
    }
  }, [selectedCampaign]);

  useEffect(() => {
    setNoResults(!(props.campaigns?.length));
  }, [props.campaigns]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        setFirst(0);
        props.searchCampaigns(e.target.value);
      }, 1000)
    );
  }
  const onPageChange = (event) => {
    setFirst(event.first);
    props.changePage(event)
  };
  const handleTrackingLink = () => {
    if (!isEditTrackingLink) {
      setIsEditTrackingLink(true);
    }
    else {
      props.saveTrackingLink(selectedCampaign, newTrackingLink).then(() => setIsEditTrackingLink(false));
    }
  }
  const campaignBodyTemplate = (rowData) => {
    return (
      <div className={'campaignTemplate'}>
        <Image src={rowData.photo ? rowData.photo : noImage} alt={'campaign cover'} className={'campaignTemplateImage'} />
        <p className={'campaignTemplateTitle'}>{rowData.name}</p>
      </div>
    );
  };
  const brandBodyTemplate = (rowData) => {
    return (
      <div className={'brandTemplate'}>
        <Image src={rowData.brand?.photo ? rowData.brand?.photo : noImage} alt={'brand photo'} className={'brandTemplateImage'} />
        <p className={'brandTemplateName'}>{rowData.brand?.name}</p>
      </div>
    );
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <div className={'statusTemplate'}>
        {rowData.status === CampaignStatus.Active &&
          <div className={'statusTemplateBox statusActive'} >
            Active
          </div>
        }
        {rowData.status === CampaignStatus.Draft &&
          <div className={'statusTemplateBox statusDraft'} >
            Draft
          </div>
        }
        {rowData.status === CampaignStatus.Completed &&
          <div className={'statusTemplateBox statusCompleted'} >
            Completed
          </div>
        }
        {rowData.status === 'pending' &&
          <div className={'statusTemplateBox statusDraft'} >
            Pending
          </div>
        }
        <div className={'statusTemplateDate'}>
          {rowData.dates?.endDate ? formatDate(rowData.dates.endDate) : 'Until budget is complete'}
        </div>
      </div>
    );
  };
  const marketBodyTemplate = (rowData) => {
    return (
      <div className={'textTemplate'}>
        {rowData.targetMarket.title}
      </div>
    );
  };
  const paymentMethodTemplate = (rowData) => {
    return (
      <div className={'textTemplate'}>
        {rowData.paymentMethod}
      </div>
    );
  };
  const expanderTemplate = (rowData) => {
    return (
      <div className='action-wrap'>
        {rowData.isCreatedByAdmin && <Button className={'editIcon'} onClick={() => handleCampaign(rowData._id)} title="Edit Campaign">
          <PencilIconRed />
        </Button>}
        <Button className='rejectIcon' icon="pi pi-trash" severity="danger" aria-label="delete" tooltip='Delete'
          onClick={() => handleDeleteDialog(rowData._id, true)} />
        <div className={'expanderTemplate'}>
          {expandedRows.includes(rowData) ?
            <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
              <ExpandArrowUp />
            </div> :
            <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
              <ExpandArrowDown />
            </div>
          }
        </div>
      </div>
    )
  }
  const handleRemainingBudget = (totalAmount, amountLeft) => {
    if (totalAmount === amountLeft) return amountLeft;
    else if (amountLeft > 0) return compactNumber(totalAmount - amountLeft);
    else return amountLeft
  }
  const findChartPercentage = (totalAmount, amountLeft) => {
    if (totalAmount === amountLeft) return 100;
    else if (amountLeft > 0) return (100 - Math.ceil((amountLeft / totalAmount) * 100));
    else return 0
  }
  const findChartValue = (totalAmount, amountLeft) => {
    if (totalAmount === amountLeft) return [0, 100];
    else if (amountLeft > 0) return [amountLeft, (totalAmount - amountLeft)];
    else return [100, 0]
  }
  const handleShowCreators = (data) => {
    setSelectedCampaign(data);
    setOpenCreatorsDialog(true);
  }
  const handleDeleteDialog = (id, flag) => {
    setSelectedId(id);
    setOpenDeleteDialog(flag);
  }
  const handleDeleteCampaign = (type) => {
    setLoading({ ...loading, [type]: true });
    adminsModule.manager.deleteCampaign(selectedId, type).then((res) => {
      if (res) {
        handleDeleteDialog('', false);
        campaignDeleted();
      }
      setLoading({ ...loading, [type]: false });
    });
  };
  const rowExpansionTemplate = (rowData) => {

    const dataMain = {
      datasets: [{ data: [rowData.budget?.amount ? rowData.budget?.amount : 100], backgroundColor: ['#E73D38'], borderWidth: 0 }],
    };

    const optionsMain = {
      cutout: '70%',
      plugins: { legend: { display: false } },
    };

    const dataRemaining = {
      datasets: [
        {
          data: findChartValue(rowData.budget?.amount, rowData.budget?.amountLeft),
          backgroundColor: ['#130A23', '#E73D38'],
          borderWidth: 0,
        },
      ],
    };

    const optionsRemaining = {
      cutout: '70%',
      plugins: { legend: { display: false } },
    };

    return (
      <div>
        <div className={'darkLine'} />
        <div className={'expansionWrapper'}>
          <div className={'expansionDataSquare'}>
            <div className={'chartsGroup'}>
              <div className={'chartWrapper'}>
                <Chart type="doughnut" data={dataMain} options={optionsMain} className={'chartGraphic'} />
                <div className={'chartPercentage'}>100%</div>
                <div className={'budgetText'}>
                  <p className={'budgetLabel'}>Budget:</p>
                  <p className={'budgetValue'}>$ {rowData.budget?.amount}</p>
                </div>
              </div>
              <div className={'chartWrapper'}>
                <Chart type="doughnut" data={dataRemaining} options={optionsRemaining} className={'chartGraphic'} />
                <div className={'chartPercentage'}>{findChartPercentage(rowData.budget?.amount, rowData.budget?.amountLeft)}%</div>
                <div className={'budgetText'}>
                  <p className={'budgetLabel'}>Budget Remaining:</p>
                  <p className={'budgetValue'}>$ {handleRemainingBudget(rowData.budget?.amount, rowData.budget?.amountLeft)}</p>
                </div>
              </div>
            </div>
            <div className={'statsWrapper'}>
              {["CPC", "CPI"].includes(rowData?.paymentMethod) &&
                <>
                  <div className={'statsItem'}>
                    <div className={'statsItemSquare'}>
                      <AppleIcon />
                    </div>
                    <div className={'statsTextWrapper'}>
                      iOS: {rowData?.paymentMethod === CampaignPaymentMethod.CPI || rowData?.paymentMethod === CampaignPaymentMethod.CPM ? rowData?.forPay?.ios : 0}
                      <br />
                      Total: {rowData?.paymentMethod === CampaignPaymentMethod.CPI || rowData?.paymentMethod === CampaignPaymentMethod.CPM ?
                        (rowData?.forPay?.ios * rowData?.cpi?.ios?.amount) + " " + rowData?.budget?.currency : 0}
                    </div>
                  </div>
                  <div className={'statsItem'}>
                    <div className={'statsItemSquare'}>
                      <AndroidIcon />
                    </div>
                    <div className={'statsTextWrapper'}>
                      Android: {rowData?.paymentMethod === CampaignPaymentMethod.CPI || rowData?.paymentMethod === CampaignPaymentMethod.CPM ? rowData?.forPay?.android : 0}
                      <br />
                      Total: {rowData?.paymentMethod === CampaignPaymentMethod.CPI || rowData?.paymentMethod === CampaignPaymentMethod.CPM ?
                        rowData?.forPay?.android * rowData?.cpi?.android?.amount + " " + rowData?.budget?.currency : 0}
                    </div>
                  </div>
                  <div className={'statsItem'}>
                    <div className={'statsItemSquare'}>
                      <ClicksIcon />
                    </div>
                    <div className={'statsTextWrapper'}>
                      Clicks: {rowData?.forPay?.clicks ? rowData.forPay.clicks : 0}
                      <br />
                      Total: {rowData?.paymentMethod === CampaignPaymentMethod.CPC ? +rowData?.cpc?.amount * rowData?.forPay?.clicks + " " + rowData?.budget?.currency : 0}
                    </div>
                  </div>
                  <div className={'statsItem'}>
                    <div className={'statsItemSquare'}>
                      <ApplicationsIcon />
                    </div>
                    <div className={'statsTextWrapper'}>
                      Applied: {rowData?.trackingLinks?.length}
                      <br />
                      Total: {+rowData?.cpi?.android?.amount * +rowData?.forPay?.android + rowData?.forPay?.ios * rowData?.cpi?.ios?.amount + " " + rowData?.forPay?.currency}
                    </div>
                  </div>
                </>
              }
              {rowData?.paymentMethod === CampaignPaymentMethod.CPM &&
                <div className={'statsItem'}>
                  <div className={'statsItemSquare'}>
                    <ViewsIcon />
                  </div>
                  <div className={'statsTextWrapper'}>
                    Views: {rowData?.paymentMethod === CampaignPaymentMethod.CPM ? rowData?.forPay.views : 0}
                    <br />
                    Total: {rowData?.paymentMethod === CampaignPaymentMethod.CPM && props.campaignExpensesLoaded ? props.getCurrentCampaignExpenses(rowData?._id) : 0} {rowData?.budget?.currency}
                  </div>
                </div>
              }
              {rowData?.paymentMethod === CampaignPaymentMethod.UGC &&
                <div className={'statsItem'}>
                  <div className={'statsItemSquare'}>
                    <ViewsIcon />
                  </div>
                  <div className={'statsTextWrapper'}>
                    No. Influencer: {rowData?.ugc.noOfCreator || 0}
                    <br />
                    Cost Per Influencer: {rowData?.ugc.costPerCreator}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className={'expansionTabsWrapper'}>
            <div className={'expansionTab'} onClick={() => props.seeCampaignDetails(rowData)}>
              <ExpansionDetailsIcon />
              <p className={'expansionTabText'}>See campaign Details</p>
            </div>
            <div className={'expansionTab'} onClick={() => { setSelectedCampaign(rowData); setOpenActionsDialog(true) }}>
              <ExpansionActionsIcon />
              <p className={'expansionTabText'}>Actions for campaigns</p>
            </div>
            <div className={'expansionTab'} onClick={() => handleShowCreators(rowData)}>
              <ExpansionCreatorsIcon />
              <p className={'expansionTabText'}>View applied creators</p>
            </div>
          </div>
        </div>
      </div >
    );
  };

  return (
    <div className={'important-stats'}>
      <div className='title-wrap'>
        <p className={'title'}>Important stats</p>
      </div>
      <div className={'statsSquare'}>
        {statsItems.map((item, index) =>
          <div key={index} className={'statsItem'}>
            <div className={'statsItemIcon'}>
              {item.icon}
            </div>
            <div className={'statsItemNumbers'}>
              <p className={'statsItemLabel'}>{item.label}</p>
              <p className={'statsItemData'}>{item.data}</p>
            </div>
          </div>)}
      </div>
      <div className={'tabsWrapper'}>
        <div className={'tabs'}>
          {STATUS_TAB.map((item, index) =>
            <div key={index} className={index === activeTab ? 'tabSelected' : 'tabNormal'} onClick={() => setActiveTab(index)}>
              {item.value}
            </div>)}
        </div>
      </div>
      <span className="p-input-icon-left inputFieldWrapper">
        <i className="pi pi-search" />
        <InputText className={'inputField'} placeholder={'Search for campaign'} value={searchValue} onChange={(e) => handleSearch(e)} />
      </span >
      <DataTable
        loading={props.loading}
        loadingIcon={
          <CustomLoader />
        }
        value={props.campaigns}
        removableSort emptyMessage={"No campaigns found."}
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        style={{ minHeight: 500 }} >
        <Column field={"name"} header={"Campaign"} body={campaignBodyTemplate} sortable style={{ width: '20%' }}></Column>
        <Column field={"brand.name"} header={"Brand"} body={brandBodyTemplate} sortable style={{ width: '20%' }}></Column>
        <Column field={"status"} header={"Status"} body={statusBodyTemplate} sortable style={{ width: '10%' }}></Column>
        <Column field={"targetMarket.title"} header={"Market"} body={marketBodyTemplate} style={{ width: '15%' }}></Column>
        <Column field={"paymentMethod"} header={"Payment Method"} body={paymentMethodTemplate} style={{ width: '12%' }}></Column>
        <Column header="Action" expander={true} body={expanderTemplate} style={{ width: '10%' }} />
      </DataTable>
      {!noResults && <Paginator first={first} rows={props.limit} totalRecords={props.pagination} onPageChange={onPageChange} style={{ background: '#130A23' }} />}

      <Dialog visible={openCreatorsDialog} onHide={() => setOpenCreatorsDialog(false)} className={'appliedCreatorsDialog'}>
        <div className={'appliedCreatorsDialogClose'} onClick={() => setOpenCreatorsDialog(false)}>
          <CloseIcon />
        </div>
        <div className={'appliedCreatorsDialogContent'}>
          <p className={'title'}>CAMPAIGN: {selectedCampaign.name}</p>
          {isLoader ? <CustomLoader />
            :
            <>
              {selectedCampaignCreators.length ?
                <div className={'creatorsTable'}>
                  <div className={'creatorsTableRow'}>
                    <div className={'tableLabel'}>Creator</div>
                    <div className={'tableLabel'}>E-mail address</div>
                  </div>
                  <ScrollPanel className={'creatorsScrollPanel'}>
                    {selectedCampaignCreators?.map((item, index) =>
                      <div className={'creatorsTableRow'} key={index}>
                        <div className={'creatorsCell'}>
                          <Image src={item.photo} alt={'user photo'} className={'creatorsCellImage'} />
                          {item.name}
                        </div >
                        <div className={'creatorsCell'}>
                          {item.email}
                        </div>
                      </div >)
                    }
                  </ScrollPanel >
                  <div className={'creatorsClose'} onClick={() => setOpenCreatorsDialog(false)}>Close</div>
                </div >
                :
                <p className={'title'}>No creator found</p>
              }
            </>
          }
        </div >
      </Dialog >
      <Dialog visible={openActionsDialog} onHide={() => setOpenActionsDialog(false)} className={'actionsDialog'}>
        <div className={'actionsDialogClose'} onClick={() => { setOpenActionsDialog(false); setNewTrackingLink(''); setNewBudget(''); setNewStatus('') }}>
          <CloseIcon />
        </div>
        <div className={'actionsDialogContent'}>
          <p className={'title'}>CAMPAIGN: {selectedCampaign.name}</p>
          <div>
            <p className={'redLabel'}>Change campaign status</p>
            <p className={'greyLabel'}>Current status: <span className={'textWhite'}>{selectedCampaign.status}</span></p>
            <div className={'newValueDropdownWrapper'}>
              <p className={'greyLabel'}>New status:</p>
              <Dropdown value={newStatus} onChange={(e) => setNewStatus(e.value)} options={STATUS_TAB} optionLabel='value' placeholder={'Select a Campaign Status'} className={'newValueDropdown'} />
            </div >
          </div >
          <Divider className={'actionsDialogDivider'} layout={'horizontal'} />
          <div>
            <p className={'redLabel'}>Change campaign budget</p>
            <p className={'greyLabel'}>Current budget: <span className={'textWhite'}>$ {selectedCampaign.budget?.amount}</span></p>
            <div className={'newValueDropdownWrapper'}>
              <p className={'greyLabel'}>New Budget:</p>
              <InputNumber value={newBudget} min={0} onChange={(e) => setNewBudget(e.value)} className={'newValueInput'} />
            </div >
          </div >
          <Divider className={'actionsDialogDivider'} layout={'horizontal'} />
          <div>
            <p className={'redLabel'}>Tracking link input</p>
            <div className={'trackingLinkWrapper'}>
              {!isEditTrackingLink ?
                <div className={'trackingLinkAndIcon'}>
                  <p className={'trackingLink'}>{newTrackingLink ? newTrackingLink : selectedCampaign.trackingLink}</p>
                  <CopyIcon onClick={() => navigator.clipboard.writeText(selectedCampaign.trackingLink)} />
                </div > :
                <InputText value={newTrackingLink} onChange={(e) => setNewTrackingLink(e.target.value)} className={'newTrackingLinkInput'} placeholder={'New tracking link'} />
              }
              <Button className={'trackingLinkButton'} onClick={() => handleTrackingLink()}>{isEditTrackingLink ? 'Save' : 'Edit'}</Button>
            </div >
            <p className={'trackingLinkWarning'}>After editing the link, you need to wait some time for it to become active and working.</p>
          </div >
          <Divider className={'actionsDialogDivider'} layout={'horizontal'} />
          <div>
            <p className={'redLabel'}>Change rates</p>
            <p className={'greyLabel'}>
              Campaign type: <span className={'textWhite'}>{selectedCampaign.paymentMethod}</span><br />
              <span className={'textWhite'}>
                {selectedCampaign.paymentMethod === CampaignPaymentMethod.CPI &&
                  ` Android - ${selectedCampaign.cpi.android?.amount}; iOS - ${selectedCampaign.cpi.ios?.amount}`
                }
                {selectedCampaign.paymentMethod === CampaignPaymentMethod.CPM &&
                  ` Payout cap - ${selectedCampaign.cpm.payoutCap}`
                }
                {selectedCampaign.paymentMethod === CampaignPaymentMethod.CPC &&
                  ` Click - ${selectedCampaign.cpc.amount}`
                }
                {selectedCampaign.paymentMethod === CampaignPaymentMethod.UGC &&
                  `Payout cap - ${selectedCampaign.ugc.costPerCreator}`
                }
              </span>
            </p>
          </div>
          <div className={'actionsDialogButtons'}>
            <Button className={'actionsDialogSave'} disabled={newBudget === 0 && newStatus === ''} onClick={() => { setOpenActionsDialog(false); props.updateCampaignData(selectedCampaign, newStatus?.toLocaleLowerCase(), newBudget) }}>Save Changes</Button>
            <p className={'actionsDialogCancel'} onClick={() => { setOpenActionsDialog(false); setNewTrackingLink(''); setNewBudget(''); setNewStatus('') }}>Cancel</p>
          </div >
        </div >
      </Dialog >

      <DeleteCampaign open={openDeleteDialog} loading={loading} close={() => setOpenDeleteDialog(false)} deleteCampaign={handleDeleteCampaign} />

    </div >
  )
}
