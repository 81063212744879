import React, { useEffect, useState } from "react";

import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';

import noImage from '@/react_app/img/no-image.png';

import { formatDate } from '@/utils/globals/date-utils';
import { PaymentStatus } from "@/data/types/payment-types";
import { CampaignPaymentMethod, UserType } from "@/data/types";

import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

import "./NewPayments.css";

const statusTabs = [
  { value: 'Active', type: PaymentStatus.Active },
  { value: 'Cancelled', type: PaymentStatus.Cancelled },
  { value: 'Paid', type: PaymentStatus.Paid },
];

const amountTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      $ {data.amount}
    </div>
  )
};

const perMonthTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      {formatDate(data.perMonth)}
    </div>
  )
};

const paidForTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      {data.paymentMethod === CampaignPaymentMethod.CPI &&
        <div>
          <p>Android: <strong>{data.paidFor?.android}</strong></p>
          <p>iOS: <strong>{data.paidFor?.IOS}</strong></p>
        </div>
      }
      {data.paymentMethod === CampaignPaymentMethod.CPM &&
        <div>
          <p>Views:</p>
          <p><strong>{data.paidFor?.views}</strong></p>
        </div>
      }
      {data.paymentMethod === CampaignPaymentMethod.CPC &&
        <div>
          <p>Clicks:</p>
          <p><strong>{data.paidFor?.clicks}</strong></p>
        </div>
      }
    </div>
  )
};

const forUserTemplate = (data) => {
  return (
    <div className={'creatorTemplate'}>
      {data.userType === UserType.Agency ?
        <div className="p-d-flex p-align-center">
          <Image src={data.userId?.agencyData?.photo ? data.userId?.agencyData?.photo : noImage} alt='agency photo' className='creatorTemplatePhoto p-mr-2 ' />
          <p className='creatorTemplateName'>{data.userId?.agencyData?.name}</p>
        </div> :
        <div className="p-d-flex p-align-center">
          <Image src={data.userId?.profile?.photo ? data.userId?.profile?.photo : noImage} alt='creator photo' className='creatorTemplatePhoto p-mr-2 ' />
          <p className='creatorTemplateName'>{data.userId?.name}</p>
        </div>
      }
    </div>
  )
};

const userTypeTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      {data.userType}
    </div>
  )
};

const campaignNameTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      {data.campaignId?.name || 'N/A'}
    </div>
  )
};

const brandTemplate = (data) => {
  return (
    <>
      {data.brand ? <div className={'userTemplate'}>
        <Image src={data.brand?.photo ? data.brand?.photo : noImage} alt={'brand photo'} className={'userTemplatePhoto'} />
        <p className={'userTemplateName'}>{data.brand?.name}</p>
      </div>
        :
        <div>-</div>
      }
    </>
  )
};

const paymentMethodTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      {data.paymentMethod}
    </div>
  )
};

export default function (props) {
  const {
    transfers, changeStatusQuery, changePage, search, pagination, limit, changeTransferStatus, loading, byMonth, changeMonth
  } = props;
  const [activeTab, setActiveTab] = useState(PaymentStatus.Active);
  const [searchValue, setSearchValue] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [first, setFirst] = useState(0);
  const [time, setTime] = useState("");

  const actionTemplate = (data) => {
    return (
      <div className="p-d-flex p-flex-column p-justify-center actions-buttons" key={data.id}>
        {data?.status !== 'paid' ?
          <Button label="Mark paid" className="p-button-success" onClick={() => changeTransferStatus(data, 'paid')} />
          :
          <Button label="Resume transfer" className="p-button-danger" onClick={() => changeTransferStatus(data, 'active')} />
        }
      </div>
    )
  }
  const handleTabChange = (item) => {
    setActiveTab(item.type);
    setSearchValue('');
    changeStatusQuery(item.type);
    setFirst(0);
  }
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setFirst(0);
        search(e.target.value);
      }, 1000)
    );
  }
  useEffect(() => {
    setNoResults(!(transfers))
  }, [transfers]);

  const onPageChange = (event) => {
    setFirst(event.first);
    changePage(event)
  };

  return (
    <div className={'payments'}>
      <p className={'title'}>Payments</p>
      <div className={'tabsWrapper'}>
        <div className={'tabs'}>
          {statusTabs.map((item) =>
            <div key={item.type} className={item.type === activeTab ? 'tabSelected' : 'tabNormal'} onClick={() => handleTabChange(item)}>
              {item.value}
            </div>)}
        </div>
      </div>
      <span className="p-input-icon-left searchFieldWrapper">
        <i className="pi pi-search" />
        <InputText placeholder="Search for payment number, contract # or client" className={'searchField'}
          value={searchValue} onChange={(e) => handleSearch(e)} />
      </span>
      <span className="p-ml-4"> <Calendar value={byMonth} onChange={(e) => changeMonth(e.value)} view="month" dateFormat="mm/yy" placeholder='Search by month' /></span>

      <DataTable value={transfers} emptyMessage="No payments." loading={loading}
        loadingIcon={
          <CustomLoader />
        }
        style={{ minHeight: 500 }}>
        <Column field={"amount"} header={"Amount"} body={amountTemplate} style={{ width: '10%' }}></Column>
        <Column field={"perMonth"} header={"Per Month"} body={perMonthTemplate} style={{ width: '10%' }}></Column>
        <Column field={"paidFor"} header={"Paid For"} body={paidForTemplate} style={{ width: '10%' }}></Column>
        <Column field={"userId"} header={"For User"} body={forUserTemplate} style={{ width: '17%' }}></Column>
        <Column field={"userType"} header={"User Type"} body={userTypeTemplate} style={{ width: '10%' }}></Column>
        <Column field={"campaignId?.name"} header={"Campaign Name"} body={campaignNameTemplate} style={{ width: '18%' }}></Column>
        <Column field={"paymentMethod"} header={"Method"} body={paymentMethodTemplate} style={{ width: '10%' }}></Column>
        <Column field={"brand?.name"} header={"Brand"} body={brandTemplate} style={{ width: '15%' }}></Column>
        <Column field="actions" header="Actions" body={actionTemplate} style={{ width: '10%' }}></Column>
      </DataTable>
      {!noResults && <Paginator first={first} rows={limit} totalRecords={pagination} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
    </div>
  )
}
