import React from 'react';
import { Button } from 'primereact/button';
import { BackIconWhite } from "@/react_app/img/Icons";

const CampaignTitle = ({
  currentTab, isAdmin, isCampaign, cancelEdit, handleSaveEdit, handleContinue, checkDisabled, setCurrentTab, isLoader
}) => {
  return (
    <div className="titleLayer">
      <p className="title">{isCampaign ? 'Edit Campaign' : 'New Campaign'}</p>
      {isCampaign ? (
        <div className="campaignButtonGroup">
          <Button className="secondaryButton" onClick={cancelEdit}>
            Cancel
          </Button>
          <Button className="primaryButton"
            // disabled={checkDisabled()}
            loading={isLoader}
            onClick={(e) => {
              e.preventDefault();
              handleContinue(true);
            }}
          >
            Update Campaign
          </Button>
        </div>
      ) : (
        !isAdmin && (
          <Button className="secondaryButtonSmall" disabled={currentTab === 1} onClick={() => setCurrentTab(currentTab - 1)}>
            <BackIconWhite />
            To the previous step
          </Button>
        )
      )}
    </div>
  );
};

export default CampaignTitle;
