import { CampaignPaymentMethod } from "@/data/types";
import React, { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import {
  AccessoryIcon, AndroidIconSmall, AppleIconSmall, ConsoleIcon, FieldTip,
  OtherIcon, SecretTip, ServiceIcon
} from "@/react_app/img/Icons";

export const TabStep = {
  Start: 1,
  Details: 2,
  Instructions: 3,
  Budget: 4,
  Overview: 5,
  Completed: 6,
}

const baseItems = [
  { index: TabStep.Start, name: 'Start' },
  { index: TabStep.Details, name: 'Details' },
  { index: TabStep.Instructions, name: 'Instructions' },
  { index: TabStep.Budget, name: 'Budget' },
];

export const managementTabItems = [...baseItems];

export const createTabItems = [
  ...baseItems,
  { index: TabStep.Overview, name: 'Overview' }
];

export const categoryItems = [
  { type: 'game', name: 'Game' },
  { type: 'accessory', name: 'Accessory' },
  { type: 'services', name: 'Services' },
  { type: 'other', name: 'Other' }
]

export const chooseOptions = { icon: 'pi pi-fw pi-upload', iconOnly: false, className: 'customChooseButton' };
export const uploadOptions = { icon: 'pi pi-fw pi-upload', iconOnly: true, className: 'customUploadButton' };
export const cancelOptions = { icon: '', iconOnly: false, className: 'customCancelButton' };

export const categoryItemsIcons = [<ConsoleIcon />, <AccessoryIcon />, <ServiceIcon />, <OtherIcon />];

export const progressPoints = ['Start', 'Details', 'Instructions', 'Budget', 'Overview'];

export const MAX_URLS = 4;
export const MAX_TALKING_POINTS = 6;
export const ENGAGEMENT_MINIMUM = 50;

export function getLinkFieldText(index) {
  if (index === 0) {
    return 'Google play';
  } else if (index === 1) {
    return 'Apple store';
  } else {
    return 'Webpage';
  }
}

export function PaymentMethodInfo(props) {
  return (
    <div>
      {props.paymentMethod === CampaignPaymentMethod.CPC &&
        <div className={props.class}>
          <strong>CPC (Cost per Click)</strong> advertising is a model in which advertisers are charged only when
          the actual click on the ad has been made. CPC represents the amount you are willing to pay for a single
          click. With CPC you only pay when the user is interested enough to know more about the product. <br /><br />
          You may see a high click through rate but that does not guarantee conversions. You need to make sure
          the landing page is attractive enough to generate conversions after clicks.
        </div>
      }
      {props.paymentMethod === CampaignPaymentMethod.CPI &&
        <div className={props.class}>
          A <strong>CPI (Cost Per Install)</strong> deal focuses on the number of installs a game or app gets through
          a specific tracking link. <strong>THIS IS FOR MOBILE GAMES/APPS ONLY.</strong> When you opt for this pricing model, you
          essentially pay for the number of installs your game or app earns. This is good for you as you pay strictly
          based on the installs you want. <br /><br />
          However, CPI can be less attractive to many creators as they are often paid less than the actual number of
          downloads they influenced.
        </div>
      }
      {props.paymentMethod === CampaignPaymentMethod.CPM &&
        <div className={props.class}>
          <strong>CPM (cost-per-mille)</strong> values the number of views sponsored videos get, with a cost per 1000 views.
          These views are tracked and paid out for the first 30 days that the video is live - all future views will
          not cost you but will still bring exposure to your ad. CPM enables you to focus on awareness and engagement.<br /><br />
          This payment method is very attractive to creators as they can better gauge the amount that they are likely
          to earn. Do consider that views do not equal conversions; also that one larger creators can use up a lot of
          budget, so make sure to set that max payout cap.
        </div>
      }
    </div>
  )
}

export function PaymentStep(props) {
  const { campaignCPM, campaignBudget, paymentMethod } = props.campaign;
  const [totalViews, setTotalViews] = useState(0);

  useEffect(() => {
    if (paymentMethod === CampaignPaymentMethod.CPM && campaignCPM && campaignBudget) {
      setTimeout(() => {
        setTotalViews(Math.floor(((campaignBudget || 0) / campaignCPM?.amount) * 1000));
      }, 500);
    } else {
      setTotalViews(0);
    }
  }, [campaignCPM, campaignBudget])

  return (
    <div className={'budgetFieldsWrapper'}>
      {props.campaign.paymentMethod === CampaignPaymentMethod.CPC &&
        <div>
          <p className={'fieldLabel'}>Campaign CPC</p>
          <p className={'budgetHelperText'}>This is your CPC for this campaign</p>
          <InputNumber value={props.campaign.campaignCPC} onChange={(e) => props.setCampaign(prevState => ({ ...prevState, campaignCPC: e.value }))}
            mode="currency" currency="USD" currencyDisplay="code" locale="en-US" min={0} className={'currencyInputLabel'} />
        </div>
      }
      {props.campaign.paymentMethod === CampaignPaymentMethod.CPM &&
        <div>
          <p className={'fieldLabel'}>Campaign CPM</p>
          <p className={'budgetHelperText'}>This is your CPM for this campaign</p>
          <InputNumber value={props.campaign.campaignCPM.amount} onChange={(e) => props.setCampaign(prevState => ({ ...prevState, campaignCPM: { amount: e.value, payoutCap: prevState.campaignCPM.payoutCap } }))}
            mode="currency" currency="USD" currencyDisplay="code" locale="en-US" min={0} className={'currencyInputLabel'} />
        </div>
      }
      {props.campaign.paymentMethod === CampaignPaymentMethod.CPM &&
        <div>
          <p className={'fieldLabel'}>Payout cap</p>
          <div className={'budgetHelperText'}>This is your max payout cap for each creator on this campaign
            <FieldTip title={'This is the max payout that a single creator can gain from this campaign.' +
              ' E.g. if the max payout cap is $3000 and the CPM rate is $20 then after the you have gained 150,000' +
              ' views you will no longer earn from further views generated within the 30 days as you will have hit' +
              ' the $3000 cap.'} />
          </div>
          <InputNumber value={props.campaign.campaignCPM.payoutCap} onChange={(e) => props.setCampaign(prevState => ({ ...prevState, campaignCPM: { payoutCap: e.value, amount: prevState.campaignCPM.amount } }))}
            mode="currency" currency="USD" currencyDisplay="code" locale="en-US" min={0} className={'currencyInputLabel'} />
        </div>
      }
      {props.campaign.paymentMethod === CampaignPaymentMethod.CPI &&
        <div>
          <p className={'fieldLabel'}>Payout cap</p>
          <p className={'budgetHelperText'}>This is your CPI for this campaign</p>
          <div className={'cpiGroup'}>
            <div>
              <div className={'urlLabel'}><AndroidIconSmall /> Android CPI</div>
              <InputNumber value={props.campaign.campaignCPI.android} onChange={(e) => props.setCampaign(prevState => ({ ...prevState, campaignCPI: { android: e.value, ios: prevState.campaignCPI.ios } }))}
                mode="currency" currency="USD" currencyDisplay="code" locale="en-US" min={0} className={'currencyInputLabel'} />
            </div>
            <div>
              <div className={'urlLabel'}><AppleIconSmall /> iOS CPI</div>
              <InputNumber value={props.campaign.campaignCPI.ios} onChange={(e) => props.setCampaign(prevState => ({ ...prevState, campaignCPI: { android: prevState.campaignCPI.android, ios: e.value } }))}
                mode="currency" currency="USD" currencyDisplay="code" locale="en-US" min={0} className={'currencyInputLabel'} />
            </div>
          </div>
        </div>
      }
      <div className={`${props.campaign.paymentMethod === CampaignPaymentMethod.CPM ?
        'dual-field-wrap start' : ''} `}>
        <div className="input-wrap">
          <p className={'fieldLabel'}>Campaign Budget</p>
          <p className={'budgetHelperText'}>This is your budget for this campaign</p>
          <InputNumber value={props.campaign.campaignBudget} onChange={(e) => props.setCampaign(prevState => ({ ...prevState, campaignBudget: e.value }))}
            mode="currency" currency="USD" currencyDisplay="code" locale="en-US" min={0} className={'currencyInputLabel'} />
          {(props.campaign.paymentMethod === CampaignPaymentMethod.CPC && (props.campaign.campaignBudget < (ENGAGEMENT_MINIMUM * props.campaign.campaignCPC) || props.campaign.campaignBudget === 0 || !props.campaign.campaignBudget)) &&
            <p className={'budgetWarning'}>The budget should not be less than the cost for 50 clicks based on your CPC rate.</p>}
          {(props.campaign.paymentMethod === CampaignPaymentMethod.CPM && (props.campaign.campaignBudget < (ENGAGEMENT_MINIMUM * props.campaign.campaignCPM.amount) || props.campaign.campaignBudget === 0 || !props.campaign.campaignBudget)) &&
            <p className={'budgetWarning'}>The budget should not be less than the cost for 50 views based on your CPM rate.</p>}
          {(props.campaign.paymentMethod === CampaignPaymentMethod.CPI && (props.campaign.campaignBudget < (ENGAGEMENT_MINIMUM * Math.max(props.campaign.campaignCPI.android, props.campaign.campaignCPI.ios) || props.campaign.campaignBudget === 0) ||
            !props.campaign.campaignBudget)) &&
            <p className={'budgetWarning'}>The budget should not be less than the cost for 50 installations based on your CPI rate.</p>}
        </div>
        {props.campaign.paymentMethod === CampaignPaymentMethod.CPM &&
          <div className="input-wrap">
            <p className={'fieldLabel'}>Paid View</p>
            <p className={'budgetHelperText'}>This is your total view as per budget</p>
            <InputNumber value={totalViews} disabled className='currencyInputLabel' />
          </div>
        }
      </div>
      <div className={'secretTip'}>
        <SecretTip />
        <div>
          <p className={'secretTipTitle'}>Secret Tip</p>
          <p className={'secretTipText'}>{props.campaign.paymentMethod} is important for creators. If you make your {props.campaign.paymentMethod} rate too low, it may discourage creators to take part in the campaign.</p>
        </div>
      </div>
    </div>
  )
}
