import React, { useState } from 'react';

import { InputText } from 'primereact/inputtext';

import { Cross } from '@/react_app/img/Icons';

import './DragDropList.css';


function DragDropList(props) {

  const { initialItems, reArrange, change, remove } = props;
  const [draggingIndex, setDraggingIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggingIndex === index) return;

    const updatedItems = [...initialItems];
    const [draggedItem] = updatedItems.splice(draggingIndex, 1);
    updatedItems.splice(index, 0, draggedItem);
    reArrange(updatedItems);
    setDraggingIndex(index);
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
  };

  return (
    <>
      {initialItems.map((item, index) => (
        <div
          key={index}
          className={`list-item ${draggingIndex === index ? 'dragging' : ''}`}
          draggable
          onDragStart={() => handleDragStart(index)}
          onDragOver={(e) => {
            e.preventDefault();
            handleDragOver(index);
          }}
          onDragEnd={handleDragEnd}>
          <p className={'talkingPointLabel'}>Talking point {index + 1}</p>
          <div className='input-wrapper'>
            <InputText placeholder='Enter your desired talking point' className='talkingPointField' value={item}
              onChange={(e) => change(e, index)}
            />
            {index > 1 && <Cross onClick={() => remove(index)} />}
          </div>
        </div>
      ))}
    </>
  );
}
export default DragDropList;
