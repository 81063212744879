import React from 'react'
import { Checkbox } from 'primereact/checkbox';

import './Terms.css';
import { useAuth } from '@/modules';

export const Terms = (props) => {

    const user = useAuth();
    const { type } = user.user.value
    const { capAmount, isCPMCampaign, handleCheckBox, isChecked } = props;
    const checkboxConfig = [
        {
            id: "terms",
            label: (
                <p>
                    I agree with the{' '}
                    <span className="highlight">
                        <a
                            href="https://gameinfluencer.io/terms-of-use"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms and Conditions
                        </a>
                    </span>{' '}
                    for this campaign.
                </p>
            ),
            visible: true
        },
        {
            id: "maxAmount",
            label: (() => {
                const capText = type === 'agencyOwner'
                    ? "I accept the maximum payout cap"
                    : "I accept the total payout cap";

                return (
                    <p>
                        {capText} of <span className="highlight">{(capAmount)?.toFixed(2) || 0}</span> USD.
                    </p>
                );
            })(),
            visible: isCPMCampaign
        },
        {
            id: "draftVideo",
            label: (
                <p>
                    I understand that once my application is approved, <br />
                    <span className="highlight">
                        I have to provide the draft video for review within 7 days
                    </span>.
                </p>
            ),
            visible: true
        }
    ];
    return (
        <>
            {checkboxConfig
                .filter(item => item.visible)
                .map(({ id, label }) => (
                    <div className="terms" key={id}>
                        <Checkbox
                            inputId={id}
                            checked={isChecked[id]}
                            onChange={(e) => handleCheckBox(id, e.checked)}
                            aria-label={id}
                        />
                        <label htmlFor={id}>{label}</label>
                    </div>
                ))
            }
        </>
    )
}


export default Terms