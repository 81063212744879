
import { defineComponent, PropType, ref, onMounted } from 'vue';
import {
  AppPageConfig, AppPageProps, Perspective
} from '@/data/types';
import { usePage } from '@/modules';
import NewCreatorAccountSetup from "@/react_app/NewCreatorAccount/NewCreatorAccountSetup";
import { applyPureReactInVue } from 'veaury';
import { isInfluencerProfileEnabled } from '@/utils/globals/feature-flags';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    NewCreatorAccountSetup: applyPureReactInVue(NewCreatorAccountSetup),
  },
  props: {
    creatorID: {
      type: String,
    },
    ...AppPageProps,
    viewPerspective: String as PropType<Perspective>,
  },

  setup(props) {
    const router = useRouter();
    const { config } = usePage<AppPageConfig>({
      title: 'Account Set-Up',
      ...props,
    });

    const isInfluencerProfileEnabled = ref(false);

    onMounted(() => {
        isInfluencerProfileEnabled.value = localStorage.getItem('new-design-toggle') === 'activated';
      });

    return {
      config,
      isInfluencerProfileEnabled,
      router
    };
  },
});
