import React, { memo } from 'react';
import { Chart } from 'primereact/chart';


export const ClientMaxCap = memo(({ amount }) => {
  const dataMain = {
    datasets: [{ data: [amount || 0], backgroundColor: ['#E73D38'], borderWidth: 0 }],
  };

  const optionsMain = {
    cutout: '70%',
    plugins: { legend: { display: false } },
    interaction: { mode: false, intersect: false },
  };

  return (
    <div className='chartWrapper'>
      <Chart id='maxCap' type="doughnut" data={dataMain} options={optionsMain} className='chartGraphic' />
      <div className='chartPercentage'>100%</div>
      <div className='budgetText'>
        <p className='budgetLabel'>Client Max Cap :</p>
        <p className='budgetValue'>$ {(amount).toFixed(2)}</p>
      </div>
    </div>
  );
});

export default ClientMaxCap;
