
import { defineComponent, onMounted, ref } from 'vue';

import router from '@/router';
import { applyPureReactInVue } from 'veaury';

import NewInvitations from '@/react_app/NewInvitations/NewInvitations';

import { usePage, useCampaigns, useToastModule } from '@/modules';
import { AppPageConfig, AppPageProps } from '@/data/types';
import { isNewCampaignInvitationsEnabled } from '@/utils/globals/feature-flags';

export default defineComponent({
  components: {
    NewInvitations: applyPureReactInVue(NewInvitations),
  },
  props: AppPageProps,
  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Campaign Invitations',
      ...props,
    });
    const toast = useToastModule();
    const page = useCampaigns(config.value.viewPerspective);
    const loading = ref(true);

    const getInvitationsList = () => {
      loading.value = true;
      page.invitations.loadInvitations().then((res) => {
        loading.value = false;
      });
    };
    onMounted(() => {
      getInvitationsList();
    });
    // delete
    const updateStatusInvitation = (
      campaignId: string,
      invitationId: string,
      status: string,
      capValue?: number
    ) => {
      const param =
        capValue || capValue === 0 ? { status, cap: capValue } : { status };

      page.invitations
        .changeInviteStatus(campaignId, invitationId, param)
        .then((response) => {
          if (response) {
            toast.showSuccess(`Invitation ${status} successfully`);
            getInvitationsList();
          }
        });
    };

    const goToCampaign = (id: string) => {
      router.push({ name: 'campaign-active', params: { campaignID: id } });
    };

    return {
      config,
      loading,
      campaigns: page.invitations.invitations,
      associatedCampaigns: page.invitations.associatedCampaigns,
      filterByCampaignID: page.invitations.filterByCampaignID,
      updateStatusInvitation,
      isNewCampaignInvitationsEnabled,
      goToCampaign,
    };
  },
});
