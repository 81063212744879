import React, { useMemo } from 'react';
import { Chart } from 'primereact/chart';

export const RemainingBudgetCharts = ({ budget, totalPayoutCap }) => {
  budget.assignBudget = 0;
  const dataRemaining = useMemo(() => ({
    datasets: [
      {
        data: [(budget?.totalBudget) - (budget?.assignBudget + totalPayoutCap), (budget?.assignBudget + totalPayoutCap)],
        backgroundColor: ['#E73D38', 'lightgray'],
        borderWidth: 0,
      },
    ],
  }), [budget, totalPayoutCap]);

  const dataRemainingAssigned = useMemo(() => ({
    datasets: [
      {
        data: [(budget?.assignBudget + totalPayoutCap), budget?.totalBudget - (budget?.assignBudget + totalPayoutCap)],
        backgroundColor: ['#E73D38', 'lightgray'],
        borderWidth: 0,
      },
    ],
  }), [budget, totalPayoutCap]);

  const optionsRemaining = {
    cutout: '70%',
    plugins: { legend: { display: false } },
    interaction: { mode: false, intersect: false }
  };


  return (
    <>
      <div className='chartWrapper'>
        <Chart type="doughnut" data={dataRemainingAssigned} options={optionsRemaining} className={'chartGraphic'} id='Assigned' />
        <div className={'chartPercentage'}>{(Math.round(((budget?.assignBudget + totalPayoutCap) / budget?.totalBudget) * 100))}%</div>
        <div className={'budgetText'}>
          <p className={'budgetLabel'}>Budget Assigned:</p>
          <p className={'budgetValue'}>$ {(budget?.assignBudget + totalPayoutCap).toFixed(2)}</p>
        </div>
      </div>
      <div className='chartWrapper'>
        <Chart type="doughnut" data={dataRemaining} options={optionsRemaining} className='chartGraphic' id='Remaining' />
        <div className={'chartPercentage'}>{(100 - Math.round(((budget?.assignBudget + totalPayoutCap) / budget?.totalBudget) * 100))}%</div>
        <div className={'budgetText'}>
          <p className={'budgetLabel'}>Budget Remaining:</p>
          <p className={'budgetValue'}>$ {((budget?.totalBudget) - (budget?.assignBudget + totalPayoutCap)).toFixed(2)}</p>
        </div>
      </div>
    </>
  );
};

export default RemainingBudgetCharts;
