import React, { useEffect, useState } from 'react';

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { useAgencys } from '@/modules';
import { formatSubsCount } from '@/utils/globals/curency-utils';

import { CloseIcon } from "@/react_app/img/Icons";

import "./AddChannel.css";

const AddChannel = (props) => {

    const { open, close, addChannel, isAdding } = props;

    const agencyModule = useAgencys();
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [channelInfo, setChannelInfo] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [currentChannelLink, setCurrentChannelLink] = useState('');

    const searchChannel = async (channelLink) => {
        setLoading(true);
        await agencyModule.manager.searchChannelByLink(channelLink).then((result) => {
            setChannelInfo(result?.length > 0 ? result[0] : '');
            setLoading(false);
        });
    };
    const validateLink = (link) => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
        return youtubeRegex.test(link);
    };

    const handleChangeLink = (event) => {
        const channelLink = event.target.value;
        clearTimeout(time);
        setCurrentChannelLink(channelLink);
        if (channelInfo) setChannelInfo('');
        setTime(setTimeout(() => {
            if (channelLink && validateLink(channelLink)) {
                setErrorMessage('');
            } else {
                setErrorMessage(((channelLink)) ? 'Enter a valid YouTube channel URL' : 'Channel link is required');
            }
        }, 500));
    }

    useEffect(() => {
        if (!open) {
            setTime('');
            setLoading(false);
            setChannelInfo('');
            setErrorMessage('')
            setCurrentChannelLink('')
        }
    }, [open])

    return (
        <Dialog visible={open} onHide={() => close(false)} className='channelDialog'
            showHeader={false} draggable={false}>
            <div className='channelDialogClose'>
                <div onClick={() => close(false)}><CloseIcon /></div>
            </div>
            <div className='channelDialogContent'>
                <p className='channelDialogTitle'>Add influencers to your Agency</p>
                <p className='channelDialogLabel'>Paste a valid Youtube channel URL in the field below:<br/>e.g., https://www.youtube.com/@channel</p>
                <span className="p-input-icon-left channelDialogInput">
                    <InputText placeholder='Paste link here: https://www.youtube.com/@channel' value={currentChannelLink}
                        onChange={handleChangeLink} className={`channelDialogInput ${errorMessage ? 'p-invalid' : ''}`} />
                    {errorMessage && <small className="p-error">{errorMessage}</small>}
                </span>

                {loading ?
                    <div className="loading-wrap">
                        <span className="loader wrap"></span>
                    </div>
                    :
                    <>
                        {channelInfo &&
                            <div className='channelInfo'>
                                <div className='channelImage'>
                                    <img alt="channel-image" src={channelInfo?.snippet?.thumbnails?.default.url} />
                                </div>
                                <div className='channelWrapper'>
                                    <p className='channelName'>{channelInfo?.snippet?.title}</p>
                                    <p className='channelSubs'>{`${formatSubsCount(channelInfo?.statistics?.subscriberCount)} Subscribers`}</p>
                                </div>
                            </div>
                        }
                    </>
                }
                {channelInfo ?
                    <Button className='channelDialogButton' disabled={loading || !channelInfo || isAdding} loading={isAdding} onClick={() => addChannel(channelInfo)}>
                        Save Channel
                    </Button>
                    :
                    <Button rounded aria-label="Search" className='channelDialogButton' disabled={!currentChannelLink} onClick={() => searchChannel(currentChannelLink)} >
                        Submit
                    </Button>
                }
            </div>
        </Dialog>
    )
}

export default AddChannel
