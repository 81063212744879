import React from 'react';
import { Checkbox } from 'primereact/checkbox';

const FilterSection = ({
  title, isExtended, toggleExtended, categories, filters, handleCheckboxChange, showDefaultFilter
}) => {
  return (
    <div>

      <div className="sideMenuCategoryLabel" onClick={toggleExtended}>
        {isExtended ? (
          <i className="pi pi-angle-down" style={{ margin: '0 14px 0 3px' }} />
        ) : (
          <i className="pi pi-angle-right" style={{ margin: '0 14px 0 3px' }} />
        )}
        {title}
      </div>
      {isExtended && (
        <div className="sideMenuGroup">
          {title == "Platform" && showDefaultFilter && <div className="check">
            <Checkbox
              checked={true}
            />
            <span className="sideMenuLabel">YouTube</span>
          </div>}
          {categories?.map((item, index) => (
            <div key={index} className="check">
              <Checkbox
                checked={filters.includes(item)}
                onChange={() => handleCheckboxChange(item, title.toLowerCase())}
              />
              <span className="sideMenuLabel">{item.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const CampaignFilterMenu = ({
  platformOptions,
  subscribersOptions,
  regionsCategories,
  countryCategories,
  influencerCategoryOptions,
  languageOptions,
  ageLimitOptions,
  ugcCreatorOptions,
  extendedCategories,
  toggleExtended,
  campaignFilters,
  handleCheckboxChange,
  showDefaultFilter
}) => {
  return (
    <div className="sideMenu">
      {platformOptions && <FilterSection
        title="Platform"
        key="Platform"
        isExtended={extendedCategories.platform}
        toggleExtended={() => toggleExtended('platform')}
        categories={platformOptions}
        filters={campaignFilters.platform}
        handleCheckboxChange={handleCheckboxChange}
        showDefaultFilter={showDefaultFilter}
      />}
      {subscribersOptions && <FilterSection
        title="Subscribers"
        key="Subscribers"
        isExtended={extendedCategories.subscribers}
        toggleExtended={() => toggleExtended('subscribers')}
        categories={subscribersOptions}
        filters={campaignFilters.subscribers}
        handleCheckboxChange={handleCheckboxChange}
      />}
      {regionsCategories && <FilterSection
        title="Region"
        key="Region"
        isExtended={extendedCategories.region}
        toggleExtended={() => toggleExtended('region')}
        categories={regionsCategories}
        filters={campaignFilters.region}
        handleCheckboxChange={handleCheckboxChange}
      />}
      {countryCategories && <FilterSection
        title="Country"
        key="Country"
        isExtended={extendedCategories.country}
        toggleExtended={() => toggleExtended('country')}
        categories={countryCategories}
        filters={campaignFilters.country}
        handleCheckboxChange={handleCheckboxChange}
      />}
      {influencerCategoryOptions && <FilterSection
        title="Genre"
        key="Genre"
        isExtended={extendedCategories.genre}
        toggleExtended={() => toggleExtended('genre')}
        categories={influencerCategoryOptions}
        filters={campaignFilters.genre}
        handleCheckboxChange={handleCheckboxChange}
      />}
      {languageOptions && <FilterSection
        title="Language"
        key="Language"
        isExtended={extendedCategories.language}
        toggleExtended={() => toggleExtended('language')}
        categories={languageOptions}
        filters={campaignFilters.language}
        handleCheckboxChange={handleCheckboxChange}
      />}
      {ageLimitOptions && <FilterSection
        title="agelimit"
        key="agelimit"
        isExtended={extendedCategories.agelimit}
        toggleExtended={() => toggleExtended('agelimit')}
        categories={ageLimitOptions}
        filters={campaignFilters.agelimit}
        handleCheckboxChange={handleCheckboxChange}
      />}
      {ugcCreatorOptions && <FilterSection
        title="Influencer"
        key="ugccreator"
        isExtended={extendedCategories.creator}
        toggleExtended={() => toggleExtended('creator')}
        categories={ugcCreatorOptions}
        filters={campaignFilters.creator}
        handleCheckboxChange={handleCheckboxChange}
      />}
    </div>
  );
};

export default CampaignFilterMenu;